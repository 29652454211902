import { notificationTypes } from 'constans';

function isZoneRelatedNotification(conditionType) {
  return conditionType === notificationTypes.entry
      || conditionType === notificationTypes.exit
      || conditionType === notificationTypes.standing
      || conditionType === notificationTypes.standingOut;
}

export default isZoneRelatedNotification;
