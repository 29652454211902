import React from 'react';

import { useTranslation } from 'react-i18next';

import MenuItem from '@mui/material/MenuItem';

import { allValuesConstant, noValuesConstant, selectNoOneConstant } from 'constans';
import StyledSelector from './MaterialOutlinedSelector';

export default function OutlinedSelector(props) {
  const {
    allowNoValues,
    allowSelectNoValues,
    allowAllValues,
    className,
    children,
    id,
    label,
    value,
    onChange,
    novalueslabel,
    ...rest
  } = props;
  const { t } = useTranslation(['monitoring']);

  return (
    <StyledSelector
      select
      id={id}
      label={label}
      value={value || ''}
      onChange={onChange}
      className={className}
      inputProps={{ disableUnderline: true }}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
    >
      {allowNoValues ? (
        <MenuItem key={noValuesConstant} value={noValuesConstant}>
          {novalueslabel}
        </MenuItem>
      ) : null}
      {allowAllValues ? (
        <MenuItem key={allValuesConstant} value={allValuesConstant}>
          {t('all')}
        </MenuItem>
      ) : null}
      {allowSelectNoValues ? (
        <MenuItem key={selectNoOneConstant} value={selectNoOneConstant}>
          {t('noOne')}
        </MenuItem>
      ) : null}
      {children}
    </StyledSelector>
  );
}
