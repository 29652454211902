import { makeStyles } from 'tss-react/mui';
import ContactIcon from 'assets/images/reports/icons/team.svg';
import XlsIcon from 'assets/images/reports/icons/xls.svg';
import timeIcon from 'assets/images/reports/icons/timeIcon.svg';

const useStyles = makeStyles()((materialTheme) => {
  const theme = materialTheme.CustomStyles;
  // eslint-disable-next-line no-unused-vars
  const { fontRegular } = materialTheme;
  return {
    icon: {
      width: '27px',
      height: '19px',
      background: `url(${ContactIcon}) no-repeat`,
    },
    textDiv: {
      marginLeft: '17px',
    },
    text: {
      color: materialTheme.CustomStyles.GlobalTextColor,
      fontSize: '15px',
      fontWeight: 400,
    },
    arrow: {
      position: 'absolute',
      marginTop: '15px',
      marginLeft: '100px',
      '&:hover': {
        cursor: 'pointer',
      },
    },
    arrowD: {
      position: 'absolute',
      marginTop: '15px',
      marginLeft: '150px',
      '&:hover': {
        cursor: 'pointer',
      },
    },
    textDevice: {
      color: materialTheme.CustomStyles.GlobalTextColor,
      fontSize: '15px',
      fontWeight: 400,
      '&:hover': {
        cursor: 'pointer',
      },
    },
    spinerWrapper: {
      width: '100%',
      maxHeight: '60vh',
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
    },
    LeftLink: {
      height: '62px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      paddingLeft: theme.MainLeftMargin,
      borderBottom: '1px  solid #e2e6ec',
    },
    leftSelector: {
      width: theme.LeftBarWidth,
      height: 'calc(100vh - 80px)',
      display: 'flex',
      flexDirection: 'column',
    },
    topControls: {
      marginTop: '22px',
      marginBottom: '27px',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
    },
    totalNumbersItem: {
      marginLeft: '14px',
    },
    totalNumbersItemText: {
      color: materialTheme.CustomStyles.GlobalTextColor,
    },
    timeIcon: {
      width: '15px',
      height: '15px',
      background: `url(${timeIcon}) no-repeat`,
    },
    downloadControlContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      '&:hover': {
        cursor: 'pointer',
        backgroundColor: '#12161408',
      },
    },
    downloadIcon: {
      width: 37,
      height: 35,
      background: `url(${XlsIcon}) center no-repeat `,
    },
    downLoadPhraseContainer: {
      width: '100%',
      marginLeft: 14,
    },
    downLoadPhrase: {
      fontSize: '15px',
      fontWeight: '600',
    },
    formControl: {
      borderRadius: 0,
      margin: '0 6px',
      paddingLeft: '17px',
      height: '35px',
      width: '270px',
      border: '1px solid #edeff3',
      backgroundColor: '#ffffff',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    inputAutoComplite: {
      backgrpund: 'red',
    },
    formControls: {
      display: 'flex',
      width: '100%',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      flexWrap: 'wrap',
    },
    totalNumbers: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
    },
    table: {
      width: '100%',
    },
    content: {
      width: 'calc(100vw - 30vh)',
      height: 'calc(100vh - 80px)',
      display: 'flex',
      flexDirection: 'column',
      padding: '0 45px 0 32px',
      overflowY: 'auto',
      [materialTheme.breakpoints.down('800')]: {
        width: '90%',
        marginLeft: '-1vw',
      },
    },
    container: {
      margin: '0 auto',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      height: '100%',
      textAlign: 'left',
      background: '#f8f8f8',
    },
    mainButton: {
      width: '100%',
      root: {
        background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
        borderRadius: 3,
        border: 0,
        color: 'white',
        height: 48,
        padding: '0 30px',
        boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
      },
      label: {
        textTransform: 'capitalize',
      },
    },
    wrapperInput: {
      width: '115px',
      backgroundColor: 'white',
      border: '1px solid #edeff3',
      height: '37px',
      marginTop: '27px',
      marginLeft: '2vw',
    },
    txtInput: {
      input: {
        padding: 0,
        paddingTop: '5px',
        marginTop: '20px',
      },
      inputRoot: {
        height: '37px',
        width: '100%',
        borderRadius: 0,
        color: materialTheme.CustomStyles.GlobalTextColor,
        backgroundColor: '#ffffff',
        '& $input': {
          width: 30,
          minWidth: 30,
          color: materialTheme.CustomStyles.GlobalTextColor,
        },
        '& fieldset': {
          borderRadius: 0,
          border: '1px solid #edeff3',
          color: materialTheme.CustomStyles.GlobalTextColor,
        },
        '&[class*="MuiOutlinedInput-root"]': {
          '& $input': {
            padding: 0,
          },
        },
      },
    },
    filterInput: {
      font: 'inherit',
      color: 'currentColor',
      width: '100 %',
      border: 0,
      height: '1.1876em',
      margin: '0',
      display: 'block',
      padding: '6px 0 7px',
      minWidth: 0,
      background: 'none',
      boxSizing: 'content',
      letterSpacing: 'inherit',
      WebkitTapHighlightColor: 'transparent',
      marginLeft: '1em',
    },
    btn: {
      marginTop: '1vh',
    },
    wrappDuration: {
      marginLeft: '2vw',
      marginTop: '8px',
      [materialTheme.breakpoints.down('982')]: {
        marginLeft: '0vw',
      },
    },
    tableCell: {
      borderBottom: '1px  solid #f4f4f4',
      padding: '19px 30px',
    },
    tableContainer: {
      boxShadow: '0px 4px 30px rgba(2, 0, 120, 0.03)',
      borderRadius: '12px',
    },
  };
});

export default useStyles;
