/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable max-len */
/* eslint-disable no-mixed-operators */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';
import ruLocale from 'date-fns/locale/ru';
import enLocale from 'date-fns/locale/en-US';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TablePagination from '@mui/material/TablePagination';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

import {
  selectFinalReportsNoData,
  selectReportsDateFromNoZones, selectReportsDateToNoZones,
  selectReportsObjectFinalReportsArray, selectReportsOutOfzonesTime,
  setFinalObjectReport,
} from 'store/slices/reports';
import { sortObjectByNumericalKey } from 'helpers/sortByField';

import BarChart from 'components/shared/Charts/Bar';
import PieChart from 'components/shared/Charts/Pie';
import LineChart from 'components/shared/Charts/Line';
import DoughnutChart from 'components/shared/Charts/Doughnut';
import PolarChart from 'components/shared/Charts/Polar';
import RadarChart from 'components/shared/Charts/Radar';
import a11yProps from 'components/shared/Tabs/allProps';
import TabPanel from 'components/shared/Tabs/Tabs';
import PaginationIcon from 'components/shared/Pagination/PaginationIcon';
import CustomTableHeader from 'components/shared/Tables/Header';
import useStyles from '../objectReport/objectReport.component.style';

const datePickerLocales = {
  ru: ruLocale,
  en: enLocale,
};

function FinalReport() {
  const { t, i18n } = useTranslation(['report']);
  const dateLocale = datePickerLocales[i18n.language];
  const dispatch = useDispatch();
  const { classes } = useStyles();

  const objectFinalReportsArray = useSelector(selectReportsObjectFinalReportsArray);
  const outOfZones = useSelector(selectReportsOutOfzonesTime);
  const noZoneData = useSelector(selectFinalReportsNoData);
  const dateFrom = useSelector(selectReportsDateFromNoZones);
  const dateTo = useSelector(selectReportsDateToNoZones);
  const noZoneDataLength = noZoneData && noZoneData.percents > 0
    ? 1 : 0;
  const outOfZoneLength = outOfZones && outOfZones.percents > 0
    ? 1 : 0;

  const formatTime = (time) => {
    if (time <= 0) {
      return `0 ${t('sec')}`;
    }
    const days = Math.trunc(time / 86400);
    const hours = Math.trunc(time / 3600) % 24;
    const minutes = Math.trunc(time / 60) % 60;
    const seconds = time % 60;
    return `${days > 0 ? `${days} ${t('day')}` : ''} ${hours > 0 ? `${hours} ${t('hour')}` : ''} ${minutes > 0 ? `${minutes} ${t('min')}` : ''} ${seconds > 0 ? `${parseFloat((seconds).toFixed(2))} ${t('sec')}` : ''}`;
  };

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [valueTabs, setValueTabs] = useState(0);
  const [graph, setGraph] = useState('Doughnut');
  const [sort, setSort] = useState({
    entry: 'none',
  });

  const handleChangeTabs = (event, newValue) => {
    setValueTabs(newValue);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleChangeGraph = (event) => {
    setGraph(event.target.value);
  };

  function sortColumn(column) {
    const order = sort[column] === 'desc' ? 'asc' : 'desc';
    const result = {};
    Object.keys(sort).forEach((key) => {
      result[key] = key !== column ? 'none' : order;
    });
    setSort(result);
    const array = sortObjectByNumericalKey(order, objectFinalReportsArray, 'inside');
    dispatch(setFinalObjectReport({ list: array, dateFrom, dateTo }));
  }

  return (
    <div>
      {objectFinalReportsArray.length > 0
        ? (
          <AppBar position="static" style={{ background: '#f8f8f8', boxShadow: 'none' }}>
            <Tabs
              value={valueTabs}
              onChange={handleChangeTabs}
              aria-label="simple tabs example"
              TabIndicatorProps={{ style: { background: 'black' } }}
            >
              <Tab label={t('Table')} {...a11yProps(0)} style={{ maxWidth: '10%', color: 'black' }} />
              <Tab label={t('Charts')} {...a11yProps(1)} style={{ maxWidth: '10%', color: 'black' }} />
            </Tabs>
          </AppBar>
        ) : null }
      <TabPanel value={valueTabs} index={1}>
        {objectFinalReportsArray.length > 0
          ? (
            <FormControl variant="standard" style={{ width: '120px' }}>
              <InputLabel id="demo-simple-select-label" />
              <Select
                variant="standard"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={graph}
                defaultValue="Bar"
                onChange={handleChangeGraph}
              >
                <MenuItem value="Doughnut">Doughnut</MenuItem>
                <MenuItem value="Bar">Bar</MenuItem>
                <MenuItem value="Radar">Radar</MenuItem>
                <MenuItem value="Polar">Polar</MenuItem>
                <MenuItem value="Pie">Pie</MenuItem>
                <MenuItem value="Line">Line</MenuItem>
              </Select>
            </FormControl>
          ) : null }
        {objectFinalReportsArray.length > 0 && graph === 'Bar'
          ? (
            <BarChart
              data={objectFinalReportsArray}
              sortingField="total_time_percent"
              legendTitles="name"
            />
          )
          : null}
        {objectFinalReportsArray.length > 0 && graph === 'Pie'
          ? (
            <PieChart
              data={objectFinalReportsArray}
              sortingField="total_time_percent"
              legendTitles="name"
            />
          )
          : null}
        {objectFinalReportsArray.length > 0 && graph === 'Line'
          ? (
            <LineChart
              data={objectFinalReportsArray}
              sortingField="total_time_percent"
              legendTitles="name"
            />
          )
          : null}
        {objectFinalReportsArray.length > 0 && graph === 'Doughnut'
          ? (
            <DoughnutChart
              data={objectFinalReportsArray}
              sortingField="total_time_percent"
              legendTitles="name"
            />
          )
          : null}
        {objectFinalReportsArray.length > 0 && graph === 'Polar'
          ? (
            <PolarChart
              data={objectFinalReportsArray}
              sortingField="total_time_percent"
              legendTitles="name"
            />
          )
          : null}
        {objectFinalReportsArray.length > 0 && graph === 'Radar'
          ? (
            <RadarChart
              data={objectFinalReportsArray}
              sortingField="total_time_percent"
              legendTitles="name"
            />
          )
          : null}
      </TabPanel>
      <TabPanel value={valueTabs} index={0}>
        <div className={classes.table} style={{ maxHeight: '100%' }}>
          <TableContainer className={classes.tableContainer} component={Paper}>
            <Table stickyHeader className={classes.table} aria-label="simple table">
              <TableBody>
                <TableRow className={classes.tableRow}>
                  <TableCell className={classes.tableCell}>
                    {' '}
                    {t('zone')}
                    {' '}
                  </TableCell>
                  <CustomTableHeader sx={{ padding: '19px 30px' }} canBeSorted onClick={() => sortColumn('entry')}>
                    <div className="tableTheadCell">
                      <span className="titleText">
                        {t('entryDate')}
                      </span>
                      { (sort.entry === 'asc')
                          && <ArrowDropUpIcon /> }
                      { (sort.entry === 'desc')
                          && <ArrowDropDownIcon /> }
                    </div>
                  </CustomTableHeader>
                  <TableCell className={classes.tableCell} align="left">{t('entryTime')}</TableCell>
                  <TableCell className={classes.tableCell} align="left">{t('duration')}</TableCell>
                  <TableCell className={classes.tableCell} align="left">{t('timeOfStay')}</TableCell>
                </TableRow>
                {objectFinalReportsArray.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((objectReport) => {
                    const entryDate = new Date(objectReport.inside * 1000);
                    return (
                      <TableRow key={objectReport.id}>
                        <TableCell className={classes.tableCell} component="th" scope="row">
                          {objectReport.name ? objectReport.name : '-'}
                        </TableCell>
                        <TableCell className={classes.tableCell} align="left">
                          {/* eslint-disable-next-line */}
                        {format(entryDate, `dd LLL yyyy '`, { locale: dateLocale })}
                        </TableCell>
                        <TableCell className={classes.tableCell} align="left">
                          {format(entryDate, 'HH:mm:ss.SSS') || 'Wrong time'}
                        </TableCell>
                        <TableCell className={classes.tableCell} align="left">
                          {formatTime(objectReport.total) || 'Wrong time'}
                        </TableCell>
                        <TableCell className={classes.tableCell} align="left">
                          {`${objectReport.total_time_percent} %`}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {outOfZones && outOfZones.percents > 0 && (
                <TableRow>
                  <TableCell className={classes.tableCell} component="th" scope="row">
                    {t('outOfZone')}
                  </TableCell>
                  <TableCell className={classes.tableCell} align="left">
                    -
                  </TableCell>
                  <TableCell className={classes.tableCell} align="left">
                    -
                  </TableCell>
                  <TableCell className={classes.tableCell} align="left">
                    {formatTime(outOfZones.period) || 'Wrong time'}
                  </TableCell>
                  <TableCell className={classes.tableCell} align="left">
                    {`${(+outOfZones.percents)?.toFixed(2)} %`}
                  </TableCell>
                </TableRow>
                )}
                {noZoneData && Number(noZoneData.percents) > 0 && (
                <TableRow>
                  <TableCell className={classes.tableCell} component="th" scope="row">
                    {t('noData')}
                  </TableCell>
                  <TableCell className={classes.tableCell} align="left">
                    -
                  </TableCell>
                  <TableCell className={classes.tableCell} align="left">
                    -
                  </TableCell>
                  <TableCell className={classes.tableCell} align="left">
                    {formatTime(noZoneData.period) || 'Wrong time'}
                  </TableCell>
                  <TableCell className={classes.tableCell} align="left">
                    {`${(+noZoneData.percents)?.toFixed(2)} %`}
                  </TableCell>
                </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50, { label: t('All'), value: -1 }]}
            component="div"
            count={objectFinalReportsArray.length + noZoneDataLength + outOfZoneLength}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${t('of')} ${count !== -1 ? count : `${t('moreThen')} ${to}`}`}
            labelRowsPerPage={t('labelRowsPerPage')}
            SelectProps={{
              IconComponent: PaginationIcon,
            }}
            nextIconButtonProps={{
              'aria-label': 'Next Page',
              style: {
                color: '#000', background: 'white', width: '32px', height: '32px', margin: '5px',
              },
              autoid: 'pagination-button-previous-collector',
            }}
            backIconButtonProps={{
              'aria-label': 'Previous Page',
              style: {
                color: page === 0 ? '#b5b8c4' : '#41afd7', background: 'white', width: '32px', height: '32px',
              },
              autoid: 'pagination-button-next-collector',
            }}
          />
        </div>
      </TabPanel>
    </div>
  );
}

export default FinalReport;
