/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { selectNoOneConstant } from 'constans';

const initialState = {
  zonesMap: new Map(),
  selectedZone: selectNoOneConstant,
  isFetching: false,
  zoneTypes: {},
  selectedZoneType: {},
};

const zonesSlice = createSlice({
  name: 'zones',
  initialState,
  reducers: {
    fetchedFinished(state) {
      state.isFetching = false;
    },
    fetchZonesInProgress(state) {
      state.isFetching = true;
    },
    setSelectedZone(state, action) {
      state.selectedZone = action.payload;
    },
    setSelectedZoneType(state, action) {
      state.selectedZoneType = action.payload;
    },
    setZones(state, action) {
      state.zonesMap = action.payload;
      state.isFetching = false;
      state.selectedZone = selectNoOneConstant;
    },
    setZoneTypes(state, action) {
      state.zoneTypes = action.payload;
      state.isFetching = false;
    },
  },
});

export const selectZones = (state) => state.zones;

export const selectZonesObject = (state) => selectZones(state).zonesObject;
export const selectZonesMap = (state) => selectZones(state).zonesMap;
export const selectSelectedZone = (state) => selectZones(state).selectedZone;
export const selectIsFetchingZones = (state) => selectZones(state).isFetching;
export const selectZoneTypes = (state) => selectZones(state).zoneTypes;
export const selectSelectedZoneType = (state) => selectZones(state).selectedZoneType;

export const {
  fetchZonesInProgress, fetchedFinished, setSelectedZone,
  setSelectedZoneType, setZoneTypes, setZones,
} = zonesSlice.actions;

export default zonesSlice.reducer;
