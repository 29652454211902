import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

// @mui/material components
import MenuItem from '@mui/material/MenuItem';

// custom outlined selector
import OutlinedSelector from 'components/shared/OutlinedSelector/OutlinedSelector';
import { noValuesConstant } from 'constans';

function LocationSelector(props) {
  const {
    onChange, className, allowAllValues, allowSelectNoValues,
    locations, loading, value, label, id, ...rest
  } = props;
  const { t } = useTranslation(['monitoring']);
  const locationsArray = Object.keys(locations);
  const currentValue = locationsArray.length > 0 && value ? value : '';

  return (
    <OutlinedSelector
      className={className}
            // label={t("locationSelectorLabel")}
      novalueslabel={currentValue === noValuesConstant ? t('noLocations') : null}
      allowNoValues={currentValue === noValuesConstant}
      allowAllValues={allowAllValues}
      allowSelectNoValues={allowSelectNoValues}
      value={currentValue}
      onChange={onChange}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
      InputLabelProps={{ shrink: true }}
      inputProps={{ id: id || 'select-location' }}
    >
      {locationsArray.map((locationId) => (
        <MenuItem key={locationId} value={locationId}>
          {locations[locationId].title}
        </MenuItem>
      ))}
    </OutlinedSelector>
  );
}

export default memo(LocationSelector);
