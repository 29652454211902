import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Typography } from '@mui/material';
import TablePagination from '@mui/material/TablePagination';
import CircularProgress from '@mui/material/CircularProgress';
import FormControl from '@mui/material/FormControl';
import distanceFormat from 'helpers/distanceFormatter';

import { objectsByAppFetch } from 'store/actions/objects';
import { fetchDistanceTraveledReport } from 'store/actions/reports';
import { setSelectedGroup } from 'store/slices/groups';
import {
  selectCurrentFloor, selectFloorsObject, setFloor,
} from 'store/slices/floors';
import { selectCurrentLocation, selectLocationsObject, setLocation } from 'store/slices/locations';
import { selectObjectsByAppArray } from 'store/slices/objects';
import {
  selectReportsCurrentObject, selectReportsDateFrom, selectReportsDateTo,
  selectReportsDistanceTraveledReport,
  selectReportsIsFetchingDistanceTraveledReport, setCurrentObject,
} from 'store/slices/reports';
import { setSelectedZone } from 'store/slices/zones';

import { allValuesConstant, selectNoOneConstant } from 'constans';

import BuildButton from 'components/reports/BuildButton.component';
import DatePickers from 'components/reports/datePickers/datePickers.component';
import AutoComplete from 'components/reports/ReportsAutoComplete';
import { selectAppCurrentApp, selectAppOpenMenu } from 'store/slices/app';
import LocationSelector from '../ReportsLocationSelector';
import FloorSelector from '../ReportsFloorSelector';
import containerStyles from '../../shared/Container/styles';
import useStyles from './inventoryReport.component.style';

import { downloadXls } from './xls.export';

function DistanceTraveled() {
  const { classes: classesContainer } = containerStyles();
  const { classes } = useStyles();
  const { t } = useTranslation(['report', 'monitoring', 'translation']);

  const dispatch = useDispatch();
  const openMenu = useSelector(selectAppOpenMenu);
  const currentApp = useSelector(selectAppCurrentApp);
  const floorsObject = useSelector(selectFloorsObject);
  const currentFloor = useSelector(selectCurrentFloor);
  const locationsObject = useSelector(selectLocationsObject);
  const currentLocation = useSelector(selectCurrentLocation);
  const objectsByAppArray = useSelector(selectObjectsByAppArray);
  const dateFrom = useSelector(selectReportsDateFrom);
  const dateTo = useSelector(selectReportsDateTo);
  const currentObject = useSelector(selectReportsCurrentObject);
  const isFetchingReport = useSelector(selectReportsIsFetchingDistanceTraveledReport);
  const report = useSelector(selectReportsDistanceTraveledReport);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [objectsFilter, setObjectsFilter] = useState('');

  const fetchReport = async () => {
    setPage(0);
    await dispatch(fetchDistanceTraveledReport());
  };

  const selectObject = (event, object) => {
    dispatch(setCurrentObject(object));
  };

  const handleChangeLocation = (event) => {
    const locationId = event.target.value;
    dispatch(setLocation(locationsObject[locationId]));
    const floorId = locationsObject[locationId].floors[0].id;
    dispatch(setFloor(floorsObject[floorId]));
    dispatch(setSelectedZone({ id: selectNoOneConstant }));
    dispatch(setSelectedGroup({ id: allValuesConstant }));
  };

  const handleChangeFloor = (event) => {
    const floorId = event.target.value;
    dispatch(setFloor(floorsObject[floorId]));
    dispatch(setSelectedZone({ id: selectNoOneConstant }));
    dispatch(setSelectedGroup({ id: allValuesConstant }));
  };

  const handleDownload = () => {
    downloadXls({
      report,
      translate: t,
    });
  };

  useEffect(() => {
    const getObjects = async () => {
      await dispatch(objectsByAppFetch(objectsFilter, 0, 1000));
    };

    getObjects();
  }, [objectsFilter, page, rowsPerPage, currentApp, dispatch]);

  useEffect(() => {
    setPage(0);
  }, [objectsFilter, rowsPerPage, currentApp, dateFrom, dateTo]);

  const onAutocompliteInput = async (value) => {
    setObjectsFilter(value);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const floorsObjectFiltred = Object.values(floorsObject)
    .filter((object) => object.location === currentLocation.id);

  return (
    <div className={!openMenu ? classes.content : classesContainer.noneContent}>
      <div className={classes.topControls}>
        <div className={classes.formControls}>
          <form className={classes.formControls} noValidate>
            <div className={classes.controlWrapper}>
              <FormControl variant="standard">
                <LocationSelector
                  locations={locationsObject}
                  className={classes.selector}
                  value={currentLocation.id}
                  onChange={handleChangeLocation}
                />
              </FormControl>
            </div>
            <div className={classes.controlWrapper}>
              <FormControl variant="standard">
                <FloorSelector
                  floors={floorsObjectFiltred}
                  className={classes.selector}
                  onChange={handleChangeFloor}
                  value={currentFloor.id}
                  currentLocationId={currentLocation.id}
                />
              </FormControl>
            </div>
          </form>
          <form className={classes.formControls} noValidate>

            <div className={classes.selectorWrapp}>
              <AutoComplete
                filteredObjectsArray={objectsByAppArray}
                currentObject={currentObject}
                selectObject={selectObject}
                onInputChange={onAutocompliteInput}
                bkgColor="#ffffff"
              />
            </div>
            <DatePickers />
          </form>
        </div>
      </div>
      {isFetchingReport
        ? (
          <div className={classes.spinerWrapper}>
            <CircularProgress size={26} />
          </div>
        )
        : (
          <div>
            <div className={classes.headContainer}>
              <div>
                <Typography variant="h6">{t('translation:distanceTraveledReport')}</Typography>
              </div>
              <div className={classes.btnWrapper}>
                <div className={classes.btnWrapper}>
                  <BuildButton
                    variant="outlined"
                    color="primary"
                    disableRipple
                    onClick={fetchReport}
                    disabled={isFetchingReport}
                  >
                    {t('buildReport')}
                  </BuildButton>
                </div>

                {report.length > 0 && currentApp.role !== 'demo' ? (
                  <div
                    className={classes.downloadControlContainer}
                    onClick={handleDownload}
                    onKeyUp={() => handleDownload()}
                    tabIndex="0"
                    role="button"
                  >
                    <div className={classes.downloadIcon} />
                    <div className={classes.downLoadPhraseContainer}>
                      <p className={classes.downLoadPhrase}>
                        {' '}
                        {t('downloadXls')}
                        {' '}
                      </p>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
            <div className={classes.table}>
              <TableContainer className={classes.tableContainer} component={Paper}>
                <Table stickyHeader className={classes.table} aria-label="simple table">
                  <TableBody>
                    <TableRow>
                      <TableCell className={classes.tableCell}>{t('objectTitle')}</TableCell>
                      <TableCell className={classes.tableCell}>{t('monitoring:sublocation')}</TableCell>
                      <TableCell className={classes.tableCell}>{t('Distance')}</TableCell>
                    </TableRow>
                    {report
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((objectReport, index) => {
                        const key = index + 1;
                        return (
                          <TableRow key={key}>
                            <TableCell className={classes.tableCell} component="th" scope="row">
                              {objectReport?.title || ''}
                            </TableCell>
                            <TableCell className={classes.tableCell} component="th" scope="row">
                              {objectReport?.sublocation_name || t('deletedZone')}
                            </TableCell>
                            <TableCell className={classes.tableCell} component="th" scope="row">
                              {distanceFormat(objectReport?.dist, t) || ''}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 25, 50]}
                component="div"
                count={report.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage={t('labelRowsPerPage')}
                labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${t('of')} ${count !== -1 ? count : `${t('moreThen')} ${to}`}`}
              />
            </div>
          </div>
        )}
    </div>
  );
}

export default DistanceTraveled;
