import React, { useEffect } from 'react';
import {
  Route, BrowserRouter, Routes, Navigate,
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import {
  removeAlert, selectAlertMessage, selectAlertOpen,
  selectAlertTimerAlert, selectAlertTitle, selectAlertType,
} from 'store/slices/alert';
import { selectAppCurrentApp } from 'store/slices/app';
import {
  serviceReports, history, monitoring, roles, profile, settings, history3d, monitoring3d,
} from 'constans';

import Login from 'containers/login/login.container';
// import Monitoring from 'containers/monitoring/monitoring.container';
import MonitoringTS from 'containers/monitoringts/monitoring.container';
import Settings from 'containers/settings/settings.container';
import Profile from 'containers/profile/profile.container';
import History from 'containers/history/history.container';
import Reports from 'containers/reports/reports.container';
import Monitoring from 'containers/monitoring/monitoring.container';
import Monitoring3d from 'containers/3d/monitoring3d';
import History3d from 'containers/3d/history3d';

import AuthByToken from 'containers/authByToken/AuthByToken';
import PrivateRoute from './containers/PrivateRoute';
import './App.css';

function Alert(props) {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function App() {
  const dispatch = useDispatch();
  const alertOpen = useSelector(selectAlertOpen);
  const timerAlert = useSelector(selectAlertTimerAlert);
  const alertTitle = useSelector(selectAlertTitle);
  const alertMessage = useSelector(selectAlertMessage);
  const alertType = useSelector(selectAlertType);
  const currentApp = useSelector(selectAppCurrentApp);

  const handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch(removeAlert());
  };

  const accessCheck = (app) => app?.role !== roles.watcher;

  useEffect(() => {
    if (alertOpen === true) {
      setTimeout(() => {
        dispatch(removeAlert());
      }, timerAlert);
    }
  }, [alertOpen, timerAlert, dispatch]);

  return (
    <BrowserRouter>
      <div className="App" id="app">
        <Routes>
          <Route path="/" element={<Navigate to={{ pathname: monitoring }} />} />
          <Route
            path={monitoring}
            element={(
              <PrivateRoute>
                <Monitoring />
              </PrivateRoute>
            )}
          />
          <Route
            path="/monitoringts"
            element={(
              <PrivateRoute>
                <MonitoringTS />
              </PrivateRoute>
            )}
          />
          <Route
            path={history}
            element={(
              <PrivateRoute>
                <History />
              </PrivateRoute>
            )}
          />
          <Route
            path={serviceReports}
            element={(
              <PrivateRoute>
                <Reports />
              </PrivateRoute>
            )}
          />
          <Route
            path={monitoring3d}
            element={(
              <PrivateRoute>
                <Monitoring3d />
              </PrivateRoute>
            )}
          />
          <Route
            path={history3d}
            element={(
              <PrivateRoute>
                <History3d />
              </PrivateRoute>
            )}
          />
          <Route
            path={profile}
            element={(
              <PrivateRoute>
                <Profile />
              </PrivateRoute>
            )}
          />
          {accessCheck(currentApp)
            ? (
              <Route
                path={`${settings}`}
                element={(
                  <PrivateRoute>
                    <Settings />
                  </PrivateRoute>
                )}
              />
            )
            : null}
          <Route path="/login" element={<Login />} />
          <Route path="/authByToken" element={<AuthByToken />} />
          <Route element={<Navigate to={{ pathname: monitoring }} />} />
        </Routes>
        <Snackbar open={alertOpen} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
          <div>
            <Alert onClose={handleCloseAlert} severity={alertType || 'info'}>
              {alertTitle && alertTitle !== '' ? (
                <AlertTitle>
                  {alertTitle?.toString()}
                </AlertTitle>
              ) : null}
              {alertMessage?.toString()}
            </Alert>
          </div>
        </Snackbar>
      </div>
    </BrowserRouter>
  );
}

export default App;
