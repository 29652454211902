import { tokenVerifyAndUpdate } from 'store/actions/auth';
import { fetchFloors } from 'store/actions/floors';
import { fetchGroups } from 'store/actions/groups';
import { fetchLocations } from 'store/actions/locations';
import { getConditions } from 'store/actions/notifications';
import { fetchZones } from 'store/actions/zones';
import { resetHistoryList } from 'store/slices/history';
import { resetReports } from 'store/slices/reports';

const init = async (dispatch) => {
  await tokenVerifyAndUpdate(dispatch);
  await dispatch(fetchLocations());
  await Promise.all([
    dispatch(fetchFloors()),
    dispatch(fetchZones()),
    dispatch(fetchGroups({ page: 0, limit: 300 })),
    dispatch(resetReports()),
    dispatch(resetHistoryList()),
    dispatch(getConditions()),
  ]);
};

export default init;
