import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';

// eslint-disable-next-line
import { InteractionManager } from '../../THREE.Interactive/build/three.interactive';

const initSky = (scene) => {
  const hemiLight = new THREE.HemisphereLight(0xffffff, 0xffffff);
  hemiLight.position.set(0, 1e3, 0);
  scene.add(hemiLight);

  const dirLight = new THREE.DirectionalLight(0xffffff);
  dirLight.position.set(0, 1e3, 10);
  scene.add(dirLight);
};

const addGround = (scene) => {
  const mesh = new THREE.Mesh(new THREE.PlaneGeometry(1e2, 1e2));
  mesh.rotation.x = -Math.PI / 2;
  scene.add(mesh);

  const grid = new THREE.GridHelper(500, 40, 0x000000, 0x000000);
  grid.material.opacity = 0.2;
  grid.material.transparent = true;
  scene.add(grid);
};

const initThreeD = (sceneDomEl, domElWidthPx, domElHeightPx, antialias) => {
  let id;
  const camera = new THREE.PerspectiveCamera(60, domElWidthPx / domElHeightPx, 0.1, 1e10);

  const scene = new THREE.Scene();
  scene.background = new THREE.Color(0xe0e0e0);
  const renderer = new THREE.WebGLRenderer({ antialias });

  renderer.setPixelRatio(window.devicePixelRatio);
  renderer.setSize(domElWidthPx, domElHeightPx);

  renderer.outputColorSpace = THREE.SRGBColorSpace;
  renderer.toneMapping = THREE.ACESFilmicToneMapping;
  renderer.toneMappingExposure = 0.9;

  sceneDomEl.appendChild(renderer.domElement);

  const interactionManager = new InteractionManager(
    renderer,
    camera,
    renderer.domElement,
  );

  const controls = new OrbitControls(camera, renderer.domElement);
  controls.addEventListener('change', () => {
    renderer.render(scene, camera);

    interactionManager.update();
  });

  controls.maxPolarAngle = 1.4;
  controls.minDistance = 7;
  controls.maxDistance = 350;

  const animate = () => {
    id = requestAnimationFrame(animate);
    interactionManager.update();
    renderer.render(scene, camera);
  };

  animate();

  return {
    camera, scene, renderer, controls, interactionManager, id,
  };
};

export { initThreeD, initSky, addGround };
