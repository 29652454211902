import { createAsyncThunk } from '@reduxjs/toolkit';
import request, { endPointsEnum } from 'services/http';
import { formTypeCreate } from 'constans';
import { createAlert, alertTypes } from 'store/slices/alert';
import {
  closeDevicesModal,
  deviceModalFetchStart, setCreatedDevice,
  setDevicesList, setDevicesModalForm, setIsFetchingDevicesList, setUpdateDeviceId,
} from 'store/slices/devices';

export const fetchDevicesList = (
  deviceFilter = null,
  signal = null,
) => async (dispatch, getState) => {
  try {
    const state = getState();

    const params = {
      api_key: state.app.currentApp.api_key,
    };
    dispatch(setIsFetchingDevicesList(true));

    const response = await request.tracking
      .get(endPointsEnum.trackedBeacons, { params, abortSignalController: signal });
    if (!response.data) {
      throw new Error('Error in response');
    }
    if (deviceFilter) {
      const filteredDevices = response.data
        .filter((i) => i.attributes.title.toLowerCase().includes(deviceFilter.toLowerCase())
      || i.attributes.mac_address.toLowerCase().includes(deviceFilter.toLowerCase()));
      dispatch(setDevicesList(filteredDevices));
    } else {
      dispatch(setDevicesList(response.data));
    }
  } catch (error) {
    dispatch(createAlert({ messageType: 'error', message: error.message }));
  }
};
export const createDevice = () => async (dispatch, getState) => {
  try {
    const state = getState();
    const { api_key: apiKey } = state.app.currentApp;
    if (!apiKey) {
      return;
    }
    const form = state.devices.modal;
    const modelId = state.devices.model_id;
    const body = {
      attributes: {
        title: form.title,
        mac_address: form.mac,
        ...(modelId) && { model_id: modelId },
      },
      api_key: apiKey,
    };

    const result = await request.tracking.post(endPointsEnum.trackedBeacons, { body });

    if (!result.data) {
      throw new Error('Error in request');
    }
    dispatch(setCreatedDevice(result.data[0]));
    dispatch(setDevicesModalForm({
      title: '',
      mac: '',
    }));
    dispatch(createAlert({ messageType: 'info', message: 'Device Created' }));
    // eslint-disable-next-line consistent-return
    return result.data[0];
  } catch (error) {
    dispatch(createAlert({ messageType: 'error', message: error.message }));
  }
};
export const deleteDevice = (device, translate) => async (dispatch, getState) => {
  try {
    const state = getState();
    const { api_key: apiKey } = state.app.currentApp;
    if (!device.id || !apiKey) {
      return;
    }
    const path = `${endPointsEnum.trackedBeacons}/${device.id}`;
    const params = {
      api_key: apiKey,
    };
    await request.tracking.delete(path, { params });
    dispatch(createAlert({ messageType: 'info', message: translate('deviceDeletedAlert') }));
    await dispatch(fetchDevicesList());
  } catch (error) {
    dispatch(createAlert({ messageType: 'error', message: translate('groupDeleteError') }));
  }
};

export const createDeviceModalAction = (
  translate,
  deviceProperties,
) => async (dispatch, getState) => {
  try {
    const state = getState();
    const { api_key: apiKey } = state.app.currentApp;
    const modelId = state.devices.model_id;

    if (!apiKey) {
      return;
    }
    const {
      formType,
      modalTitle,
      macAddress,
      updateDeviceId,
      // propsFromBack,
    } = state.devices;
    const body = {
      attributes: {
        title: modalTitle,
        mac_address: macAddress,
        ...(modelId) && { model_id: modelId },
      },
      api_key: apiKey,
    };
    body.properties = deviceProperties;
    dispatch(deviceModalFetchStart());
    if (formType === formTypeCreate) {
      const result = await request.tracking.post(endPointsEnum.trackedBeacons, { body });
      if (!result?.data) {
        throw new Error('Error in request');
      }
      dispatch(createAlert({ messageType: 'info', message: translate('pushDeviceCreated') }));
      dispatch(fetchDevicesList());
      dispatch(closeDevicesModal());
    } else {
      const path = `${endPointsEnum.trackedBeacons}/${updateDeviceId}`;
      const result = await request.tracking.patch(path, { body });
      if (!result?.data) {
        throw new Error('Error in request');
      }
      dispatch(setUpdateDeviceId(null));
      dispatch(createAlert({ messageType: alertTypes.info, message: translate('deviceEditedAlert') }));
    }
    dispatch(fetchDevicesList());
    dispatch(closeDevicesModal());
  } catch (error) {
    dispatch(closeDevicesModal());
    dispatch(createAlert({ messageType: 'error', message: error.message }));
  }
};

export const fetchDeviceModels = createAsyncThunk(
  'devices/fetchDeviceModels',
  async (_, { dispatch }) => {
    try {
      const path = `${endPointsEnum.trackedDevices}/models`;
      const result = await request.tracking.get(path, { params: {} });
      if (!result.length) {
        throw new Error('Error in request');
      }
      return result;
    } catch (error) {
      dispatch(createAlert({ messageType: 'error', message: error.message }));
      return [];
    }
  },
);
