import { jwtDecode } from 'jwt-decode';
import Cookies from 'js-cookie';
import request, { endPointsEnum } from 'services/http';

import store from 'store/configureStore';
import { appFetch } from 'store/actions/app';
import { setUser } from 'store/slices/user';
import { fetchFinished, loginInProgress } from 'store/slices/auth';
import { alertTypes, createAlert } from 'store/slices/alert';

import init from 'helpers/init';
import { cookieTokenName, cookOpts, cookieRefTokenName } from 'constans';
import { logout } from './user';

export const tokenRefresh = async () => {
  try {
    const storedRefreshToken = Cookies.get(cookieRefTokenName);
    const params = {
      token: storedRefreshToken,
    };

    const result = await request.auth.get(endPointsEnum.tokenRefresh, {
      params,
    });
    const { token, refreshToken } = result;

    if (!token || !refreshToken) {
      return false;
    }

    Cookies.set(cookieTokenName, token, cookOpts);
    Cookies.set(cookieRefTokenName, refreshToken, cookOpts);

    return true;
  } catch (error) {
    return false;
  }
};

export const tokenVerifyAndUpdate = async (dispatch) => {
  try {
    let storedToken = Cookies.get(cookieTokenName);
    if (!storedToken) {
      const state = store.getState();
      storedToken = state.user.token;
    }
    const params = {
      token: storedToken,
    };
    const result = await request.auth.get(endPointsEnum.tokenVerify, {
      params,
    }).catch((err) => {
      if (err.httpStatus === 403 || err.httpStatus === 401) {
        return false;
      }
      throw new Error(err);
    });

    if (!result || !result.activeApp || !result.user) {
      const isRefreshed = await tokenRefresh();
      if (!isRefreshed) {
        dispatch(logout());

        return false;
      }
    }

    return true;
  } catch (error) {
    return false;
  }
};

export const loginFetch = (login, password) => async (dispatch) => {
  try {
    const body = {
      email: login,
      password,
    };
    dispatch(loginInProgress());

    const result = await request.auth.post(endPointsEnum.authorization, {
      body,
    });

    const { token, refreshToken } = result;
    const tokenPayload = jwtDecode(token);
    const {
      hash, company, email, name, id, avatar_url: avatarUrl,
    } = tokenPayload.userData;

    Cookies.set(cookieTokenName, token, cookOpts);
    Cookies.set(cookieRefTokenName, refreshToken, cookOpts);
    // this.cookies.set('navtok', token, ttl, path, domain, secure);
    localStorage.setItem('token', token);
    localStorage.setItem('hash', hash);
    localStorage.setItem('id', id);
    const userToken = tokenPayload.userData.token.token;
    localStorage.setItem('userToken', userToken);
    dispatch(
      setUser({
        name,
        email,
        avatar_url: avatarUrl,
        hash,
        company,
        token,
        refreshToken,
      }),
    );

    dispatch(fetchFinished());
    await dispatch(appFetch());
    await init(dispatch);
  } catch (error) {
    dispatch(createAlert({ messageType: alertTypes.err, message: 'Error in login' }));
  }
};
