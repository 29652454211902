import {
  Sprite,
  SpriteMaterial,
} from 'three';
import TextTexture from './TextTexture';

const Class = class extends Sprite {
  constructor(
    {
      fontSize = 1,
      ...options
    } = {},
    material = new SpriteMaterial({
      depthWrite: false,
    }),
  ) {
    super(material);
    const texture = new TextTexture({
      fontSize,
      ...options,
    });
    this.material.map = texture;
  }

  onBeforeRender(renderer, scene, camera) {
    const { material } = this;
    const { map: texture } = material;
    if (texture.checkFontFace()) {
      const { scale } = this;
      const {
        height,
        width,
      } = texture;
      if (width && height) {
        scale.setX(width).setY(height);
        texture.setOptimalPixelRatio(this, renderer, camera);
        texture.redraw();
      } else {
        scale.setScalar(1);
      }
    } else {
      texture.loadFontFace();
    }
  }

  dispose() {
    const { material } = this;
    const { map: texture } = material;
    texture.dispose();
    material.dispose();
  }
};

[
  'alignment',
  'backgroundColor',
  'color',
  'fontFamily',
  'fontSize',
  'fontStyle',
  'fontVariant',
  'fontWeight',
  'lineGap',
  'padding',
  'strokeColor',
  'strokeWidth',
  'text',
].forEach((key) => {
  Object.defineProperty(Class.prototype, key, {
    get() {
      return this.material.map[key];
    },
    set(value) {
      this.material.map[key] = value;
    },
  });
});

Class.prototype.isTextSprite = true;

export default Class;
