import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import i18next from 'i18next';

import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Avatar from '@mui/material/Avatar';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';

import { logout } from 'store/actions/user';
import { selectAppCurrentApp } from 'store/slices/app';
import {
  selectUserAvatarUrl, selectUserCompany,
  selectUserEmail, selectUserHash, selectUserName,
} from 'store/slices/user';

import { RU_DEMO_USER } from 'constans';
import useStyles from './style';

function ProfileContainer() {
  const dispatch = useDispatch();
  const currentApp = useSelector(selectAppCurrentApp);
  const name = useSelector(selectUserName);
  const email = useSelector(selectUserEmail);
  const avatarUrl = useSelector(selectUserAvatarUrl);
  const hash = useSelector(selectUserHash);
  const company = useSelector(selectUserCompany);

  const { t } = useTranslation(['profile']);
  const { classes } = useStyles();
  const navigate = useNavigate();

  const [lang, setLang] = useState(i18next.language);
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onClickLogout = () => {
    dispatch(logout());
    navigate('/login');
  };

  const onChangeLang = (event) => {
    const language = event.target.value;
    i18next.changeLanguage(language, () => {
      setLang(language);
    });
  };

  return (
    <div className={classes.profoleContainer} style={{ background: 'none' }}>
      <div className={classes.userLeftBLock}>
        <Avatar alt="user avatar" src={avatarUrl} className={classes.userAvatar} />
        <p className={classes.title}>{name}</p>
        <p className={classes.company}>{company}</p>
        <Button
          style={{ display: currentApp.role === 'demo' ? 'none' : null }}
          className={classes.btn}
          onClick={handleClickOpen}
          variant="outlined"
        >
          {t('logout')}
        </Button>
      </div>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{t('logoutModalTitle')}</DialogTitle>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {t('cancel')}
          </Button>
          <Button onClick={onClickLogout} color="primary" autoFocus>
            {t('logout')}
          </Button>
        </DialogActions>
      </Dialog>
      <div className={classes.userRightBLock} style={{ flexDirection: 'column' }}>
        <div style={{ margin: '30px 26px' }}>
          <p style={{ fontWeight: 'bold' }}>
            {t('info')}
          </p>
        </div>
        <div>
          <div className={classes.leftBlock}>
            <div className={classes.textWrapper}>
              <p className={classes.topicText}>
                {' '}
                {t('name')}
              </p>
              <p className={classes.valueText}>
                {' '}
                {name || '-'}
                {' '}
              </p>
            </div>
            <div className={classes.textWrapper}>
              <p className={classes.topicText}>
                {' '}
                {t('company')}
              </p>
              <p className={classes.valueText}>
                {' '}
                {company || '-'}
                {' '}
              </p>
            </div>
            <div className={classes.textWrapper}>
              {' '}
              <p className={classes.topicText}>
                {' '}
                {t('email')}
                {' '}
              </p>
              {' '}
              <p className={classes.valueText}>
                {' '}
                {email || '-'}
                {' '}
              </p>
            </div>
            <div className={classes.textWrapper}>
              {' '}
              <p className={classes.topicText}>
                {' '}
                {t('hash')}
                {' '}
              </p>
              {' '}
              <p className={classes.valueText}>
                {' '}
                {hash || '-'}
                {' '}
              </p>
            </div>
            <p className={classes.topicText}>
              {' '}
              {t('language')}
              {' '}
            </p>
            <Select
              variant="standard"
              labelId="language-select"
              id="language-select"
              name="language-select"
              value={lang}
              onChange={onChangeLang}
              className={classes.valueText}
              disabled={email === RU_DEMO_USER}
              disableUnderline
            >
              <MenuItem value="ru">Русский</MenuItem>
              <MenuItem value="en">English</MenuItem>
              <MenuItem value="et">Eestlane</MenuItem>
              <MenuItem value="cn">中文</MenuItem>
              <MenuItem value="jp">日本語</MenuItem>
              <MenuItem value="de">Deutsch</MenuItem>
            </Select>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProfileContainer;
