/* eslint-disable no-param-reassign */
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  conditionSoundTypes, formTypeCreate, notificationTypes, timeConstants,
} from 'constans';
import audioWrapper from 'helpers/audio';
import request, { endPointsEnum } from 'services/http';
import { alertTypes, createAlert } from 'store/slices/alert';
import { setLastId, setNotifications } from 'store/slices/notifications';
import {
  closeMainModal,
  conditionsLoadingEnd, conditionsLoadingStart, setConditionsList,
  setEditingConditionID,
} from 'store/slices/notifications.settings';

const handleSound = (notificationId, file, getState, sosSoundPeriod = 30) => {
  audioWrapper.play(file);
  setTimeout(() => {
    const state = getState();
    const { notificationsArray } = state.notifications;
    const findNotification = notificationsArray.find((notif) => notif.id === notificationId);
    if (findNotification && !findNotification.viewed) {
      audioWrapper.play(file);
    }
  }, sosSoundPeriod * 1000);
};

const playSoundIfRecent = ({
  response, sosSoundPeriod, conditionsArray, getState,
}) => {
  const currentTime = new Date().getTime();
  response.forEach((notif) => {
    const notificationTime = Date.parse(notif.created_at);
    const difference = currentTime - notificationTime;
    if (difference > sosSoundPeriod * 1000) {
      return;
    }
    const condition = conditionsArray.find((el) => el.id === notif.trackedConditions.id);
    if (condition?.attributes?.sound_file) {
      handleSound(notif.id, condition.attributes.sound_file, getState, sosSoundPeriod);
    }
  });
};

export const getConditions = (filter = '', page = 0, limit = 1000) => async (dispatch, getState) => {
  try {
    const state = getState();
    const { api_key: apiKey } = state.app.currentApp;
    if (!apiKey) {
      return;
    }
    dispatch(conditionsLoadingStart());

    const result = await request.tracking.get(endPointsEnum.trackedConditions, {
      params: {
        'like[group.title][]': filter,
        'page[offset]': page,
        'page[limit]': limit,
        api_key: apiKey,
      },
    });
    dispatch(conditionsLoadingEnd());

    dispatch(setConditionsList(result.data));
  } catch (error) {
    dispatch(createAlert({ messageType: 'error', message: `Cannot get notifications ${error.message}` }));
  }
};

export const createCondition = createAsyncThunk(
  'notificationsSettings/createCondition',
  async (translate, { dispatch, getState }) => {
    try {
      const state = getState();
      const {
        formType,
        conditionType,
        conditionTime,
        conditionTimeType,
        updatedConditionId,
        notificationPriority,
        conditionSound,
      } = state.notificationsSettings;

      const { currentLocation } = state.location;
      const { api_key: apiKey } = state.app.currentApp;
      const {
        currentFloor,
      } = state.floor;
      const { selectedGroup } = state.groups;
      const { selectedZone } = state.zones;
      const { subject } = state.notificationsSocial;

      const body = {
        attributes: {
          condition: conditionType,
          priority: notificationPriority,
          subject,
        },
        relationships: {
          'tracked-group': {
            data: {},
          },
          location: {
            data: {},
          },
          sublocation: {
            data: {},
          },
          zone: {
            data: {},
          },
          sound: {
            data: {},
          },
        },
      };

      if (conditionType === notificationTypes.standing
      || conditionType === notificationTypes.standingOut) {
        body.attributes.time = conditionTime * timeConstants[conditionTimeType];
      }
      if (currentLocation.id) {
        body.relationships.location.data = {
          id: currentLocation.id,
          type: 'locations',
        };
      }
      if (currentFloor.id && currentFloor.id > 0) {
        body.relationships.sublocation.data = {
          id: currentFloor.id,
          type: 'sublocations',
        };
      }
      if (selectedGroup.id && selectedGroup.id > 0) {
        body.relationships['tracked-group'].data = {
          id: selectedGroup.id,
          type: 'tracked-groups',
        };
      }
      if (selectedZone.id) {
        body.relationships.zone.data = {
          id: selectedZone.id < 0 ? null : selectedZone.id,
          type: 'zones',
        };
      }
      if (conditionSound.type) {
        body.relationships.sound.data = {
          id: conditionSound.type === conditionSoundTypes.none ? null : 1,
          type: conditionSound.type === conditionSoundTypes.none ? '' : conditionSoundTypes.alarm,
        };
      }
      const requestBody = { ...body, api_key: apiKey };
      if (formType === formTypeCreate) {
        const result = await request.tracking
          .post(endPointsEnum.trackedConditions, { body: requestBody });
        if (!result.data) {
          throw new Error('Error in request');
        }
        dispatch(createAlert({ messageType: alertTypes.info, message: translate('notificationCreatedAlert') }));
        dispatch(closeMainModal());
        const createdId = result.data[0]?.id;
        return createdId;
      }
      const path = `${endPointsEnum.trackedConditions}/${updatedConditionId}`;
      const result = await request.tracking.patch(path, { body: requestBody });
      if (!result.data) {
        throw new Error('Error in request');
      }
      dispatch(setEditingConditionID(null));
      dispatch(createAlert({ messageType: alertTypes.info, message: translate('notificationEditedAlert') }));
      dispatch(closeMainModal());
      return updatedConditionId;
    } catch (error) {
      dispatch(createAlert({ messageType: alertTypes.err, message: translate('notificationMainModalError') }));
      return null;
    }
  },
);

export const deleteCondition = createAsyncThunk(
  'notificationsSettings/deleteCondition',
  async ({ condition, translate }, { dispatch, getState }) => {
    try {
      const state = getState();
      const { api_key: apiKey } = state.app.currentApp;
      if (!condition.id || !apiKey) {
        return;
      }
      const path = `${endPointsEnum.trackedConditions}/${condition.id}`;
      const params = {
        api_key: apiKey,
      };
      await request.tracking.delete(path, { params });
      dispatch(createAlert({ messageType: 'info', message: translate('notificationDeleteAlert') }));
    } catch (error) {
      dispatch(createAlert({ messageType: 'error', message: translate('notificationDeleteError') }));
    }
  },
);

export const getNotifications = (translate) => async (dispatch, getState) => {
  try {
    const state = getState();
    const { api_key: apiKey } = state.app.currentApp;
    const {
      dateFrom, dateTo, notificationsArray, lastId, sosSoundPeriod,
    } = state.notifications;
    const { conditionsArray } = state.notificationsSettings;

    if (!apiKey || !dateFrom || !dateTo) {
      return;
    }
    const response = await request.tracking.get(endPointsEnum.trackedNotifications, {
      params: {
        from: Math.round(dateFrom.getTime() / 1000),
        to: Math.round(dateTo.getTime() / 1000),
        api_key: apiKey,
        last_id: lastId,
        destination: 'push',
      },
    });
    response.forEach((newNotification) => {
      newNotification.viewed = false;
    });
    if (notificationsArray.length === 0) {
      dispatch(setNotifications(response));
    }
    if (response.length !== 0) {
      dispatch(setNotifications([...response, ...notificationsArray]));
      dispatch(setLastId(response[0].id));
    }
    playSoundIfRecent({
      response, sosSoundPeriod, conditionsArray, getState,
    });
  } catch (error) {
    dispatch(createAlert({ messageType: 'error', message: `${translate('errorFetchNotifications')}: ${error.message}` }));
  }
};

export const addNewNotification = (message) => (dispatch, getState) => {
  const state = getState();
  const { notificationsArray, sosSoundPeriod } = state.notifications;
  const { conditionsArray } = state.notificationsSettings;
  const parsedMessage = JSON.parse(message.toString());
  const dynamicKey = Object.keys(parsedMessage)[0];
  const parsedValue = JSON.parse(parsedMessage[dynamicKey]);
  parsedValue.viewed = false;
  const condition = conditionsArray.find((el) => el.id === parsedValue.trackedConditions.id);
  if (condition?.attributes?.sound_file) {
    handleSound(parsedValue.id, condition.attributes.sound_file, getState, sosSoundPeriod);
  }
  dispatch(setLastId(parsedValue.id));
  dispatch(setNotifications([parsedValue, ...notificationsArray]));
};
