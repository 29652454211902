import { CircularProgress } from '@mui/material';
import React, { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import useStyles from './DialogYoutubeStyles';

function YoutubeModal({ handleYoutubeModal }) {
  const { classes } = useStyles();
  const [videoLoading, setVideoLoading] = useState(true);
  return (
    <div className={classes.background}>
      <div className={classes.videoContainer}>
        <div className={classes.circularProgress}>
          {videoLoading && (
          <CircularProgress />
          )}
        </div>
        <iframe
          className={classes.video}
          onLoad={() => setVideoLoading(false)}
          loading="lazy"
          width="800"
          height="500"
          src="https://www.youtube.com/embed/SqcY0GlETPk"
          title="YouTube video player"
          allowFullScreen
        />
      </div>
      <CloseIcon onClick={handleYoutubeModal} className={classes.closeButton} />
    </div>
  );
}

export default YoutubeModal;
