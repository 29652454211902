/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export const alertTypes = {
  err: 'error',
  warn: 'warning',
  info: 'info',
  success: 'success',
};

const initialState = {
  message: '',
  title: '',
  type: '',
  open: false,
  timerAlert: 3000,
};

const alertSlice = createSlice({
  name: 'alert',
  initialState,
  reducers: {
    createAlert(state, action) {
      const {
        message, title, messageType, timerAlert,
      } = action.payload;
      return {
        ...state,
        message,
        title: title || '',
        open: true,
        type: messageType,
        timerAlert: timerAlert || initialState.timerAlert,
      };
    },
    removeAlert(state) {
      return {
        ...state,
        open: false,
      };
    },
    setAlertToDefault(state) {
      return {
        ...state,
        initialState,
      };
    },
  },
});

export const selectAlert = (state) => state.alert;

export const selectAlertMessage = (state) => selectAlert(state).message;
export const selectAlertTitle = (state) => selectAlert(state).title;
export const selectAlertType = (state) => selectAlert(state).type;
export const selectAlertOpen = (state) => selectAlert(state).open;
export const selectAlertTimerAlert = (state) => selectAlert(state).timerAlert;

export const { createAlert, removeAlert, setAlertToDefault } = alertSlice.actions;

export default alertSlice.reducer;
