import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
  formControl: {
    width: '100%',
  },
  label: {
    width: '100%',
    fontSize: '14px',
    color: '#929292',
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '7px',
  },
  infiniteScrollInput: {
    width: '100%',
    '& input': {
      padding: '4px 5px 5px 5px',
      border: 'none',
      height: '23px',
    },
  },
  dataBox: {
    border: 0,
    width: '290px',
    maxHeight: '400px',
    overflowY: 'auto',
    borderRadius: '8px',
  },
  arrowDropdowns: {
    '&:hover': {
      cursor: 'pointer',
    },
    color: 'rgba(0, 0, 0, 0.54)',
  },
  clearText: {
    '&:hover': {
      cursor: 'pointer',
    },
    color: 'rgba(0, 0, 0, 0.4)',
    fontSize: '1.3rem',
  },
}));

export default useStyles;
