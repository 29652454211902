/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

export default function CircularProgressWithLabel(props) {
  const { text, value } = props;
  return (
    <Box position="relative" display="inline-flex">
      {text
      && (
      <Box
        top={-55}
        left={-5}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="caption" component="div" color="textSecondary">
          {text}
        </Typography>
      </Box>
      )}
      <CircularProgress variant="determinate" {...props} />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >

        <Typography variant="caption" component="div" color="textSecondary">
          {`${Math.round(
            value,
          )}%`}

        </Typography>
      </Box>
    </Box>
  );
}
