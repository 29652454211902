import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import CircularProgress from '@mui/material/CircularProgress';
import FormControl from '@mui/material/FormControl';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

import { format } from 'date-fns';
import ruLocale from 'date-fns/locale/ru';
import enLocale from 'date-fns/locale/en-US';

import { selectAppCurrentApp, selectAppOpenMenu } from 'store/slices/app';
import { selectFloorsObject } from 'store/slices/floors';
import { selectLocationsObject } from 'store/slices/locations';
import { selectObjectsByAppArray } from 'store/slices/objects';
import {
  selectReportsCurrentObject,
  selectReportsDateFrom, selectReportsDateFromNoZones,
  selectReportsDateTo, selectReportsDateToNoZones,
  selectReportsIsFetching,
  selectReportsObjectFinalReportsArray,
  selectReportsObjectReportsArray,
  selectReportsOutOfzonesTime,
  setCurrentObject,
  setObjectReport,
} from 'store/slices/reports';
import { objectsByAppFetch } from 'store/actions/objects';
import { fetchObjectReports, fetchFinalObjectReports } from 'store/actions/reports';
import { sortObjectByNumericalKey } from 'helpers/sortByField';

import BuildButton from 'components/reports/BuildButton.component';
import DatePickers from 'components/reports/datePickers/datePickers.component';
import PaginationIcon from 'components/shared/Pagination/PaginationIcon';
import CustomTableHeader from 'components/shared/Tables/Header';
import containerStyles from 'components/shared/Container/styles';
import FinalReport from 'components/reports/finalReport/finalReport';
import AutoComplete from 'components/reports/ReportsAutoComplete';
import FloorSelector from 'components/reports/ReportsFloorSelector';
import LocationSelector from 'components/reports/ReportsLocationSelector';
import { selectNoOneConstant } from 'constans';
import useStyles from './objectReport.component.style';

const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const fileExtension = '.xlsx';

const datePickerLocales = {
  ru: ruLocale,
  en: enLocale,
};

function ObjectReport() {
  const exportToCSV = (csvData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };
  const { classes: classesContainer } = containerStyles();
  const { classes } = useStyles();
  const { t, i18n } = useTranslation(['report']);
  const dateLocale = datePickerLocales[i18n.language];

  const dispatch = useDispatch();
  const openMenu = useSelector(selectAppOpenMenu);
  const currentApp = useSelector(selectAppCurrentApp);
  const objectsByAppArray = useSelector(selectObjectsByAppArray);
  const dateFrom = useSelector(selectReportsDateFrom);
  const dateFromNoZones = useSelector(selectReportsDateFromNoZones);
  const dateTo = useSelector(selectReportsDateTo);
  const dateToNoZones = useSelector(selectReportsDateToNoZones);
  const isFetching = useSelector(selectReportsIsFetching);
  const currentObject = useSelector(selectReportsCurrentObject);
  const objectFinalReportsArray = useSelector(selectReportsObjectFinalReportsArray);
  const objectReportsArray = useSelector(selectReportsObjectReportsArray);
  const outOfzonesTime = useSelector(selectReportsOutOfzonesTime);
  const floorsObject = useSelector(selectFloorsObject);
  const locationsObject = useSelector(selectLocationsObject);

  const [currentLocation, setLocation] = useState({ id: selectNoOneConstant });
  const [currentFloor, setFloor] = useState({ id: selectNoOneConstant });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [objectsFilter, setObjectsFilter] = useState('');
  const [currentObjectLocal, setcurrentObjectLocal] = useState('');
  const [btnExcelState, setBtnExcelState] = useState(false);
  const [btnFinalExcelState, setFinalBtnExcelState] = useState(false);
  const [sort, setSort] = useState({
    entry: 'none',
  });
  const btnDisabledByDate = !(new Date(Date.now()) > dateFrom && dateTo > dateFrom);

  const fetchContactReportsClick = async () => {
    setPage(0);
    await dispatch(fetchObjectReports(currentFloor.id, currentLocation.id));
    setcurrentObjectLocal(currentObject.attributes.title);
    setBtnExcelState(true);
  };
  const fetchFinalReportsClick = async () => {
    setPage(0);
    await dispatch(fetchFinalObjectReports(currentFloor.id, currentLocation.id));
    setcurrentObjectLocal(currentObject.attributes.title);
    setFinalBtnExcelState(true);
  };

  const selectObject = (event, object) => {
    dispatch(setCurrentObject(object));
  };

  const formatTime = (time) => {
    if (time <= 0) {
      return `< 0.1 ${t('sec')}`;
    }
    const days = Math.trunc(time / 86400);
    const hours = Math.trunc(time / 3600) % 24;
    const minutes = Math.trunc(time / 60) % 60;
    const seconds = time % 60;
    return `${days > 0 ? `${days} ${t('day')}` : ''} ${hours > 0 ? `${hours} ${t('hour')}` : ''} ${minutes > 0 ? `${minutes} ${t('min')}` : ''} ${seconds > 0 ? `${parseFloat((seconds).toFixed(2))} ${t('sec')}` : ''}`;
  };

  const handleDownload = () => {
    const formatReportToCsv = (str) => {
      const entryDate = new Date(str.inside_at * 1000);
      const exitDate = typeof str.outside_at === 'number' ? new Date(str.outside_at * 1000) : null;
      const dateFormat = 'dd-MM-yyyy HH:mm:ss';
      const zoneColumn = t('zone') || 'Zone';
      const entryDateTimeColumn = t('Enter') || 'Entry Date';
      const exitDateTimeColumn = t('Exit') || 'Exit Time';
      const durationColumn = t('duration') || 'Duration';
      const nameObject = t('objectTitle') || 'Object name';

      const result = {};
      result[nameObject] = currentObjectLocal;
      if (str.zone?.name) {
        result[zoneColumn] = str.zone.name;
        result[entryDateTimeColumn] = format(entryDate, dateFormat) || 'Wrong time';
        result[exitDateTimeColumn] = str.outside_at && exitDate
          ? format(exitDate, dateFormat) || 'Wrong time' : t('stillZone');
      }
      if (!str.zone?.name && str.outside_at === '-') {
        result[zoneColumn] = t('noData') || 'No Data';
        result[entryDateTimeColumn] = format(entryDate, dateFormat) || 'Wrong time';
        result[exitDateTimeColumn] = '-';
      }
      if (!str.zone?.name && str.outside_at !== '-') {
        result[zoneColumn] = t('outOfZone') || 'Out of Zone';
        result[entryDateTimeColumn] = format(entryDate, dateFormat) || 'Wrong time';
        result[exitDateTimeColumn] = str.outside_at && exitDate
          ? format(exitDate, dateFormat) || 'Wrong time' : t('stillZone');
      }
      result[durationColumn] = formatTime(str.total_time) || '<0.1';

      return result;
    };

    const csvData = objectReportsArray.map((element) => formatReportToCsv(element));

    const fileName = `Detailed object report- ${currentObject.attributes.title}` || 'Detailed object report';

    exportToCSV(csvData, fileName);
  };
  const handleDownloadFinal = () => {
    const formatReportToCsv = (str) => {
      const entryDate = new Date(str.inside * 1000);
      const dateFormat = 'dd-MM-yyyy HH:mm:ss';
      const zoneColumn = t('zone') || 'Zone';
      const durationColumn = t('duration') || 'Duration';
      const entryDateTimeColumn = t('Enter') || 'Entry Date';
      const percent = t('percent') || 'Percent';
      const nameObject = t('objectTitle') || 'Object name';
      const result = {};
      result[nameObject] = currentObjectLocal;
      result[zoneColumn] = str.name;
      result[entryDateTimeColumn] = format(entryDate, dateFormat);
      result[durationColumn] = formatTime(str.total) || 'Wrong time';
      result[percent] = str.total_time_percent || 'Wrong time';

      return result;
    };

    const csvData = objectFinalReportsArray.map((element) => formatReportToCsv(element));

    const fileName = `Final object report- ${currentObject.attributes.title}` || 'Final object report';

    exportToCSV(csvData, fileName);
  };

  useEffect(() => {
    const getObjects = async () => {
      await dispatch(objectsByAppFetch(objectsFilter, 0, 4000));
    };
    getObjects();
  }, [objectsFilter, page, rowsPerPage, currentApp, dispatch]);

  useEffect(() => {
    setPage(0);
  }, [objectsFilter, rowsPerPage, currentApp, dateFrom, dateTo]);

  const onAutocompliteInput = async (value) => {
    setObjectsFilter(value);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  function sortColumn(column) {
    const order = sort[column] === 'desc' ? 'asc' : 'desc';
    const result = {};
    Object.keys(sort).forEach((key) => {
      result[key] = key !== column ? 'none' : order;
    });
    setSort(result);
    const sortBy = column === 'entry' ? 'inside_at' : column;
    const array = sortObjectByNumericalKey(order, objectReportsArray, sortBy);
    dispatch(setObjectReport({ list: array, dateFrom, dateTo }));
  }

  const handleChangeLocation = (event) => {
    const locationId = event.target.value;
    if (locationId === selectNoOneConstant) {
      setLocation({ id: selectNoOneConstant });
      setFloor({ id: selectNoOneConstant });
    } else {
      setLocation(locationsObject[locationId]);
      const floorId = locationsObject[locationId].floors[0].id;
      setFloor(floorsObject[floorId]);
    }
  };

  const handleChangeFloor = (event) => {
    const floorId = event.target.value;
    if (floorId === selectNoOneConstant) {
      setFloor({ id: selectNoOneConstant });
    } else {
      setFloor(floorsObject[floorId]);
    }
  };

  const sliceBy = rowsPerPage > 0 ? page * rowsPerPage + rowsPerPage : objectReportsArray.length;

  return (
    <div className={!openMenu ? classes.content : classesContainer.noneContent}>
      <div className={classes.topControls}>
        <form className={classes.formControls} noValidate>
          <div className={classes.controlWrapper}>
            <FormControl variant="standard">
              <LocationSelector
                locations={locationsObject}
                className={classes.selector}
                value={currentLocation.id}
                onChange={handleChangeLocation}
                allowSelectNoValues
              />
            </FormControl>
          </div>
          <div className={classes.controlWrapper}>
            <FormControl variant="standard">
              <FloorSelector
                floors={floorsObject}
                className={classes.selector}
                onChange={handleChangeFloor}
                value={currentFloor.id}
                currentLocationId={currentLocation.id}
                allowSelectNoValues
                disabled={currentLocation.id === selectNoOneConstant}
              />
            </FormControl>
          </div>
        </form>
        <form className={classes.formControls} noValidate>
          <div className={classes.selectorWrapp}>
            <AutoComplete
              filteredObjectsArray={objectsByAppArray}
              currentObject={currentObject}
              selectObject={selectObject}
              onInputChange={onAutocompliteInput}
              bkgColor="#ffffff"
            />
          </div>
          <DatePickers />
        </form>

      </div>
      <div className={classes.headContainer}>
        <div>
          <Typography variant="h6">{t('finalReport')}</Typography>
        </div>
        <div className={classes.btnWrapper}>
          <div className={classes.btnWrapper}>
            <BuildButton
              className={classes.buildButton}
              variant="outlined"
              color="primary"
              disableRipple
              onClick={fetchFinalReportsClick}
              disabled={!currentObject || isFetching || btnDisabledByDate}
            >
              {t('finalReport')}
            </BuildButton>
          </div>

          <div
            className={classes.downloadControlContainer}
            onClick={handleDownloadFinal}
            onKeyUp={() => handleDownloadFinal()}
            tabIndex="0"
            role="button"
            style={btnFinalExcelState && objectFinalReportsArray.length > 0 && currentApp.role !== 'demo' ? { display: 'inline-flex' } : { display: 'none' }}
          >
            <div className={classes.downloadIcon} />
            <div className={classes.downLoadPhraseContainer}>
              <p className={classes.downLoadPhrase}>
                {' '}
                {t('downloadXlsFinal')}
                {' '}
              </p>
            </div>
          </div>
        </div>
      </div>
      {isFetching
        ? (
          <div className={classes.spinerWrapper}>
            <CircularProgress size={26} />
          </div>
        )
        : (
          <div>
            <FinalReport
              objectFinalReportsArray={objectFinalReportsArray}
              outOfzonesTime={outOfzonesTime}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              page={page}
              rowsPerPage={rowsPerPage}
              dateFromNoZones={dateFromNoZones}
              dateToNoZones={dateToNoZones}
            />
            <div className={classes.headContainer}>
              <Typography variant="h6">{t('detailsReport')}</Typography>
              <div className={classes.btnWrapper}>
                <BuildButton
                  className={classes.buildButton}
                  style={{ marginRight: '5px' }}
                  variant="outlined"
                  color="primary"
                  disableRipple
                  onClick={fetchContactReportsClick}
                  disabled={!currentObject || isFetching || btnDisabledByDate}
                >
                  {t('detailedReport')}
                </BuildButton>
                <div
                  className={classes.downloadControlContainer}
                  onClick={handleDownload}
                  onKeyUp={() => handleDownload()}
                  tabIndex="0"
                  role="button"
                  style={btnExcelState && objectReportsArray.length > 0 && currentApp.role !== 'demo' ? { display: 'inline-flex' } : { display: 'none' }}
                >
                  <div className={classes.downloadIcon} />
                  <div className={classes.downLoadPhraseContainer}>
                    <p className={classes.downLoadPhrase}>
                      {' '}
                      {t('downloadXlsDetailed')}
                      {' '}
                    </p>
                  </div>
                </div>
              </div>

            </div>
            <div className={classes.table}>
              <TableContainer className={classes.tableContainer} component={Paper}>
                <Table stickyHeader className={classes.table} aria-label="simple table">
                  <TableBody>
                    <TableRow>
                      <TableCell className={classes.tableCell}>
                        {' '}
                        {t('zone')}
                        {' '}
                      </TableCell>
                      <CustomTableHeader
                        align="right"
                        sx={{ padding: '19px 30px', alignItems: 'right' }}
                        canBeSorted
                        onClick={() => sortColumn('entry')}
                      >
                        <div className="tableTheadCell">
                          <span className="titleText">
                            {t('entryDate')}
                          </span>
                          { (sort.entry === 'asc')
                          && <ArrowDropUpIcon /> }
                          { (sort.entry === 'desc')
                          && <ArrowDropDownIcon /> }
                        </div>
                      </CustomTableHeader>
                      <TableCell className={classes.tableCell} align="right">{t('entryTime')}</TableCell>
                      <TableCell className={classes.tableCell} align="right">{t('exitTime')}</TableCell>
                      <TableCell className={classes.tableCell} align="right">{t('duration')}</TableCell>
                    </TableRow>
                    {objectReportsArray?.length > 0
                     && objectReportsArray
                       .slice(page * rowsPerPage, sliceBy)
                       .map((objectReport) => {
                         const entryDate = new Date(objectReport.inside_at * 1000);
                         let exitDate;
                         if (objectReport.outside_at === null) {
                           exitDate = null;
                         }
                         if (typeof objectReport.outside_at === 'number') {
                           exitDate = format(new Date(objectReport.outside_at * 1000), 'HH:mm:ss.SSS');
                         }
                         if (objectReport.outside_at === '-') {
                           exitDate = objectReport.outside_at;
                         }
                         let zoneName;
                         if (!objectReport.zone) {
                           zoneName = t('deletedZone');
                         } else if (!objectReport.zone.name) {
                           zoneName = objectReport.is_lost === 0 ? t('outOfZone') : t('noData');
                         } else {
                           zoneName = objectReport.zone.name;
                         }
                         return (
                           <TableRow key={objectReport.id}>
                             <TableCell className={classes.tableCell} component="th" scope="row">
                               {zoneName}
                             </TableCell>
                             <TableCell className={classes.tableCell}>
                               {entryDate ? format(entryDate, 'dd LLL yyyy \'', { locale: dateLocale }) : null}
                             </TableCell>
                             <TableCell className={classes.tableCell} align="right">
                               {format(entryDate, 'HH:mm:ss.SSS')}
                             </TableCell>
                             <TableCell className={classes.tableCell} align="right">
                               {exitDate}
                             </TableCell>
                             <TableCell className={classes.tableCell} align="right">
                               {formatTime(objectReport.total_time) || 'Wrong time'}
                             </TableCell>
                           </TableRow>
                         );
                       })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 25, 50, { label: t('All'), value: -1 }]}
                component="div"
                count={objectReportsArray?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                className={classes.pagination}
                SelectProps={{
                  IconComponent: PaginationIcon,
                }}
                classes={{
                  root: classes.root,
                  select: classes.select,
                  selectIcon: classes.selectIcon,
                  caption: classes.caption,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${t('of')} ${count !== -1
                  ? count : `${t('moreThen')} ${to}`}`}
                labelRowsPerPage={t('labelRowsPerPage')}
                nextIconButtonProps={{
                  'aria-label': 'Next Page',
                  style: {
                    color: '#000', background: 'white', width: '32px', height: '32px', margin: '5px',
                  },
                  autoid: 'pagination-button-previous-collector',
                }}
                backIconButtonProps={{
                  'aria-label': 'Previous Page',
                  style: {
                    color: page === 0 ? '#b5b8c4' : '#41afd7', background: 'white', width: '32px', height: '32px',
                  },
                  autoid: 'pagination-button-next-collector',
                }}
              />
            </div>
          </div>
        )}
    </div>
  );
}

export default ObjectReport;
