import TableCell from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';

const CustomTableHeader = styled(TableCell, {
  shouldForwardProp: (prop) => prop !== 'canBeSorted',
})(({ canBeSorted }) => ({
  borderBottom: '1px  solid #f4f4f4',
  padding: '10px 15px 0',
  '&:hover': {
    cursor: canBeSorted ? 'pointer' : '',
  },
  '.tableTheadCell': {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    height: '1.5em',
    maxWidth: '7.5em',
    whiteSpace: 'nowrap',
    '.titleText': {
      display: 'flex',
      alignItems: 'center',
    },
  },
}));

export default CustomTableHeader;
