import React from 'react';
import { useTranslation } from 'react-i18next';
import TextField from '@mui/material/TextField';
import CustomAutocomplete from 'components/reports/CustomAutocomplete.component';
import dropIconArrow from 'assets/images/chevron-down.svg';

export default function AutoComplete(props) {
  const {
    filteredObjectsArray,
    selectObject,
    onAutocompliteInput,
    bkgColor,
    objectsInMonitoringArray,
  } = props;
  const { t } = useTranslation(['monitoring']);
  const enrichedObjects = filteredObjectsArray.map((firstObj) => {
    // eslint-disable-next-line max-len
    const matchingObject = objectsInMonitoringArray.find((secondObj) => secondObj.attributes.id === firstObj.object_id);

    if (matchingObject) {
      const enrichedObj = { ...firstObj };
      enrichedObj.title = matchingObject.attributes.title;
      return enrichedObj;
    }

    return firstObj;
  });

  const modifiedObjects = enrichedObjects.map((obj) => ({
    title: obj.title,
    object_id: obj.object_id,
  }));

  return (
    <CustomAutocomplete
      id="objects"
      options={modifiedObjects}
      onChange={selectObject}
      onInputChange={onAutocompliteInput}
      getOptionLabel={(option) => option.title}
      noOptionsText={t('noObjects')}
      getOptionSelected={(option, value) => option.device_id === value.device_id}
      style={{ backgroundColor: bkgColor }}
      popupIcon={<img src={dropIconArrow} alt="ArrowDown" />}
      renderInput={(params) => (
        <TextField
          InputProps={{ disableUnderline: true }}
          variant="outlined"
          type="text"
          placeholder={t('autoCompletePlaceholder')}
          style={{ borderRadius: '4px' }}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...params}
        />
      )}
    />

  );
}
