import { makeStyles } from 'tss-react/mui';

const alertEmFontColor = '#A10000';
const alertEmUnreadBg = '#FFF0F0';
const alertUnreadBg = '#F3F6F8';

const useStyles = makeStyles()((materialTheme) => (
  {
    cardEl: {
      padding: '0px 0px 0px 0px',
      width: '400px',
      maxHeight: '400px',
      overflowY: 'scroll',
      '&:last-child': {
        paddingBottom: 0,
      },
    },
    avatar: {
      width: '35px',
      height: '35px',
      marginLeft: '10px',
    },

    badge: {
      marginRight: '10px',
    },
    warnIcon: {
      color: '#FF7374',
      width: '28px',
      height: '28px',
      fontSize: 'large',
    },
    appListContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      marginRight: '20px',
    },
    list: {
      color: materialTheme.CustomStyles.GlobalTextColor,
      width: '150px',
      marginRight: '10px',
      height: '52px',
      minWidth: '221px',
      backgroundColor: '#F3F6F8',
      borderRadius: '8px',
    },
    btnWrapper: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    btn: {
      color: 'black',
      '&:hover': {
        opacity: 1,
      },
    },
    large: {
      width: materialTheme.spacing(4),
      height: materialTheme.spacing(4),
      marginLeft: '10px',
    },
    text: {
      color: materialTheme.CustomStyles.GlobalTextColor,
      fontWeight: 'normal',
    },
    textEm: {
      height: '44px',
      color: alertEmFontColor,
      fontWeight: 'normal',
      margin: 0,
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      '&:hover': {
        whiteSpace: 'normal',
        overflow: 'auto',
        height: '100px',
      },
    },
    unreadTextEm: {
      fontWeight: 'bold',
    },
    boxTxt: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'nowrap',
      justifyContent: 'flex-start',
      alignItems: 'center',
      padding: '0px 16px',
      borderBottom: '1px solid #F2F2F2',
    },
    unreadBoxTxtEm: {
      backgroundColor: alertEmUnreadBg,
    },
    unreadBoxTxt: {
      backgroundColor: alertUnreadBg,
    },
    unviewedNotification: {
      fontWeight: 'bold',
    },
    selectAppLocation: {
      position: 'relative',
      width: '18px',
      top: '3px',
      left: '-10px',
    },
    selectorLanguage: {
      left: '-24px',
      width: '65px',
      minWidth: '50px',
    },
  }
));

export default useStyles;
