import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import ruLocale from 'date-fns/locale/ru';
import enLocale from 'date-fns/locale/en-US';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TextField from '@mui/material/TextField';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

import { fetchGatewayStatus } from 'store/actions/reports';
import {
  selectReportsDevicesMonitoringArray,
  selectReportsGetReportDate,
  selectReportsIsFetching, setGatewayStatus, setReportDate,
} from 'store/slices/reports';
import { selectAppOpenMenu } from 'store/slices/app';
import { sortObjectByNumericalKey, sortObjectByStringKey } from 'helpers/sortByField';

import BuildButton from 'components/reports/BuildButton.component';
import CustomTableHeader from 'components/shared/Tables/Header';
import containerStyles from 'components/shared/Container/styles';
import useStyles from '../socialContacts/socialContacts.component.style';

const datePickerLocales = {
  ru: ruLocale,
  en: enLocale,
};

const normalizeDate = (date) => (
  date && date < 1_000_000_000_000
    ? +date * 1000
    : +date
);

const minToCompare = 15 * 60 * 1000;

function GatewayStatus() {
  const { classes: classesContainer } = containerStyles();
  const { classes } = useStyles();
  const { t, i18n } = useTranslation(['report', 'settings']);
  const dispatch = useDispatch();
  const openMenu = useSelector(selectAppOpenMenu);
  const isFetching = useSelector(selectReportsIsFetching);
  const devicesMonitoringArray = useSelector(selectReportsDevicesMonitoringArray);
  const getReportDate = useSelector(selectReportsGetReportDate);
  const [filter, setFilter] = useState('');
  const [sort, setSort] = useState({
    name: 'none',
    uuid: 'none',
    updated_at: 'none',
  });

  const fetchGatewayStatusReport = async () => {
    const dateNow = new Date().getTime();
    dispatch(setReportDate(dateNow));
    dispatch(fetchGatewayStatus());
  };

  const dateLocale = datePickerLocales[i18n.language];

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const offlineArr = devicesMonitoringArray.filter(
    (el) => !el.updated_at
      || ((getReportDate - normalizeDate(el.updated_at)) > minToCompare),
  );

  function sortColumn(column) {
    if (devicesMonitoringArray.length === 0) return;
    const order = sort[column] === 'desc' ? 'asc' : 'desc';
    const result = {};
    Object.keys(sort).forEach((key) => {
      result[key] = key !== column ? 'none' : order;
    });
    setSort(result);
    let array;
    if (Number.isNaN(+devicesMonitoringArray[0][column])) {
      array = sortObjectByStringKey(order, devicesMonitoringArray, column);
    } else {
      array = sortObjectByNumericalKey(order, devicesMonitoringArray, column);
    }
    dispatch(setGatewayStatus(array));
  }
  const filteredDevicesArray = devicesMonitoringArray ? devicesMonitoringArray
    .filter((device) => ((filter !== '' && device.name)
      ? (device.name.toLowerCase().includes(filter.toLowerCase())
    || device.uuid.toLowerCase().includes(filter.toLowerCase()))
      : true)) : [];
  return (
    <div className={!openMenu ? classes.content : classesContainer.noneContent}>
      <div className={classes.topControls} style={{ justifyContent: 'start' }}>
        <BuildButton
          variant="outlined"
          color="primary"
          disableRipple
          onClick={fetchGatewayStatusReport}
        >
          {t('buildReport')}
        </BuildButton>
        <div>
          <TextField
            variant="standard"
            className={classes.filterInput}
            onChange={(event) => setFilter(event.target.value)}
            disabled={devicesMonitoringArray.length === 0}
            placeholder={t('settings:typeToSearch')}
          />
        </div>
      </div>
      {isFetching ? (
        <div className={classes.spinerWrapper}>
          <CircularProgress size={26} />
        </div>
      ) : (
        <div className={classes.table}>
          {devicesMonitoringArray.length !== 0 ? (
            <Typography variant="h6">
              {t('quantityOfGateways')}
              :
              {' '}
              {devicesMonitoringArray.length}
              {', '}
              &nbsp;
              {t('online')}
              /
              {t('offline')}
              :
              {' '}
              {devicesMonitoringArray.length - offlineArr.length}
              /
              {offlineArr.length}
            </Typography>
          ) : null}

          <TableContainer className={classes.tableContainer} component={Paper}>
            <Table
              stickyHeader
              className={classes.table}
              aria-label="simple table"
            >
              <TableBody>
                <TableRow>
                  <CustomTableHeader
                    className={classes.tableCell}
                    onClick={() => sortColumn('name')}
                    canBeSorted
                  >
                    <div className="tableTheadCell">
                      <span className="titleText">
                        {t('locatorsName')}
                      </span>
                      { (sort.name === 'asc')
                        && <ArrowDropUpIcon /> }
                      { (sort.name === 'desc')
                        && <ArrowDropDownIcon /> }
                    </div>
                  </CustomTableHeader>
                  <CustomTableHeader
                    className={classes.tableCell}
                    align="left"
                    onClick={() => sortColumn('uuid')}
                    canBeSorted
                  >
                    <div className="tableTheadCell">
                      <span className="titleText">
                        {t('MAC address')}
                      </span>
                      { (sort.uuid === 'asc')
                        && <ArrowDropUpIcon /> }
                      { (sort.uuid === 'desc')
                        && <ArrowDropDownIcon /> }
                    </div>
                  </CustomTableHeader>
                  <CustomTableHeader
                    className={classes.tableCell}
                    align="left"
                    onClick={() => sortColumn('updated_at')}
                    canBeSorted
                  >
                    <div className="tableTheadCell">
                      <span className="titleText">
                        {t('lastContactTime')}
                      </span>
                      { (sort.updated_at === 'asc')
                        && <ArrowDropUpIcon /> }
                      { (sort.updated_at === 'desc')
                        && <ArrowDropDownIcon /> }
                    </div>
                  </CustomTableHeader>
                  <TableCell
                    className={classes.tableCell}
                    align="left"
                  >
                    {t('status')}
                  </TableCell>
                </TableRow>
                {devicesMonitoringArray && filteredDevicesArray
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((contactReport) => {
                    const date = normalizeDate(contactReport.updated_at);
                    const isRedRow = date
                      ? (getReportDate - date > minToCompare) : true;
                    const key = contactReport.transmitter_id || contactReport.name;
                    return (
                      <TableRow
                        sx={isRedRow ? { backgroundColor: '#ef3636' } : {}}
                        key={key}
                      >
                        <TableCell className={classes.tableCell} align="left" component="th" scope="row">
                          <p className={classes.text}>
                            {' '}
                            {contactReport.name}
                            {' '}
                          </p>
                        </TableCell>
                        <TableCell className={classes.tableCell} align="left" component="th" scope="row">
                          <p className={classes.text}>
                            {' '}
                            {contactReport.uuid}
                            {' '}
                          </p>
                        </TableCell>
                        <TableCell className={classes.tableCell} align="left">
                          {date ? (format(date, 'dd LLL yyyy  HH:mm:ss', {
                            locale: dateLocale,
                          })) : '-'}
                        </TableCell>
                        {isRedRow ? (
                          <TableCell className={classes.tableCell} align="left">{t('offline')}</TableCell>
                        ) : (
                          <TableCell className={classes.tableCell} align="left">{t('online')}</TableCell>
                        )}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={filteredDevicesArray.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage={t('labelRowsPerPage')}
            labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${t('of')} ${
              count !== -1 ? count : `${t('moreThen')} ${to}`
            }`}
          />
        </div>
      )}
    </div>
  );
}

export default GatewayStatus;
