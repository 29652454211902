export function processObjectReport(array, dateFrom, dateTo) {
  const result = JSON.parse(JSON.stringify(array));
  const insertDictionary = new Map();
  array.forEach((el, index) => {
    if (index === 0 && (el.prevIsLost === 1 || el.prevIsLost === 0)) {
      insertDictionary.set(index, {
        id: el.id - 1,
        is_lost: el.is_lost,
        inside_at: dateFrom / 1000,
        outside_at: el.is_lost === 1 ? '-' : el.inside_at,
        total_time: el.is_lost === 1 ? '-' : el.inside_at - dateFrom / 1000,
        tracked_object: {
          ...el.tracked_object,
        },
        zone: {
          id: null,
          guid: null,
          is_deleted: 0,
          name: null,
        },
        zone_guid: null,
      });
    }
    if (index + 1 < array.length) {
      if (el.outside_at + 1 < array[index + 1].inside_at) {
        const nextElement = array[index + 1];
        const newInside = el.outside_at;
        const newOutside = nextElement.inside_at;
        const difference = newOutside - newInside;
        insertDictionary.set(index + 1, {
          id: (el.id + array[index + 1].id) / 2,
          is_lost: el.is_lost,
          inside_at: newInside,
          outside_at: el.is_lost === 1 ? '-' : newOutside,
          total_time: el.is_lost === 1 ? '-' : difference.toString(),
          tracked_object: {
            ...el.tracked_object,
          },
          zone: {
            id: null,
            guid: null,
            is_deleted: 0,
            name: null,
          },
          zone_guid: null,
        });
      }
    }
    if (index + 1 === array.length && el.outside_at && el.outside_at < dateTo / 1000) {
      const parseDateTo = +dateTo / 1000;
      const difference = parseDateTo - el.outside_at;
      insertDictionary.set(index + 1, {
        id: el.id + 1,
        is_lost: el.is_lost,
        inside_at: el.outside_at,
        outside_at: el.is_lost === 1 ? '-' : parseDateTo,
        total_time: el.is_lost === 1 ? '-' : difference.toString(),
        tracked_object: {
          ...el.tracked_object,
        },
        zone: {
          id: null,
          guid: null,
          is_deleted: 0,
          name: null,
        },
        zone_guid: null,
      });
    }
  });
  let inserted = 0;
  insertDictionary.forEach((value, key) => {
    result.splice(key + inserted, 0, value);
    inserted += 1;
  });
  return result;
}

export default processObjectReport;
