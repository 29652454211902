import React, { useState } from 'react';
import { Menu, MenuItem } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { ReactComponent as CahrtIcon } from 'assets/images/Chart.svg';
import { ReactComponent as SettingsIcon } from 'assets/images/Setting.svg';
import { ReactComponent as ShowIcon } from 'assets/images/Show.svg';
import { ReactComponent as YoutubeIcon } from 'assets/images/Youtube.svg';
import { ReactComponent as RoutingIcon } from 'assets/images/routing.svg';
import { selectAppCurrentApp } from 'store/slices/app';

import {
  history,
  history3d,
  monitoring,
  monitoring3d,
  roles, serviceReports,
  settings,
} from 'constans';
import WelcomeLogo from 'components/login/logo/logo';
import YoutubeModal from 'components/dialog/DialogYoutube/DialogYoutube';
import AppList from './RightMenu/RightMenu';
import useStyles from './styles';

function TopMenu() {
  const currentApp = useSelector(selectAppCurrentApp);

  const { t } = useTranslation(['topMenu']);
  const location = useLocation();
  const path = location.pathname;
  const reportsPartial = serviceReports?.split('/')?.[1];
  const settingsPartial = settings?.split('/')?.[1];
  const { classes, cx } = useStyles();

  const accessCheck = (app) => {
    const { role } = app;
    return role !== roles.watcher;
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const [openYoutube, setOpenYoutube] = useState(false);

  const openDrawer = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closeDrawer = () => {
    setAnchorEl(null);
  };

  const handleYoutubeModal = () => {
    setOpenYoutube((prev) => !prev);
  };

  const is3dEnabled = () => {
    if (!currentApp || !currentApp.properties) {
      return false;
    }
    const prop3d = currentApp.properties.filter(({ type }) => type === '3dDemoEnabled')[0];
    if (!prop3d) {
      return false;
    }
    return true;
  };

  return (
    <div className={classes.menu}>
      <div className={classes.logo}>
        <WelcomeLogo />
      </div>
      <MenuIcon
        className={classes.menuIcon}
        onClick={openDrawer}
        fontSize="large"
      />
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={closeDrawer}
      >
        <MenuItem>
          <Link
            onClick={closeDrawer}
            className={cx(
              classes.listLink,
              (path === monitoring || path === monitoring3d) ? classes.listLinkActive : '',
            )}
            to={monitoring}
          >
            {t('monitoring')}
          </Link>
        </MenuItem>
        <MenuItem>
          <Link
            onClick={closeDrawer}
            className={cx(
              classes.listLink,
              path === history ? classes.listLinkActive : '',
            )}
            to={history}
          >
            {t('history')}
          </Link>
        </MenuItem>
        <MenuItem>
          <Link
            onClick={closeDrawer}
            className={cx(
              classes.listLink,
              (path.includes(reportsPartial)) ? classes.listLinkActive : '',
            )}
            to={serviceReports}
          >
            {t('reports')}
          </Link>
        </MenuItem>
        {currentApp.role !== 'demo'
          ? (
            <MenuItem>
              {accessCheck(currentApp) ? (
                <Link
                  onClick={closeDrawer}
                  className={cx(
                    classes.listLink,
                    (path.includes(settingsPartial)) ? classes.listLinkActive : '',
                  )}
                  to={settings}
                >
                  {t('settings')}
                </Link>
              ) : (
                <p className={cx(classes.listLink, classes.inActiveLink)}>
                  {t('settings')}
                </p>
              )}
            </MenuItem>
          )
          : (
            <div className="temporary-off" style={{ display: 'none' }}>
              <MenuItem
                button
                onClick={() => {
                  setOpenYoutube((prev) => !prev);
                  closeDrawer();
                }}
                className={classes.listLinkVideo}
              >
                <p className={classes.listLink} style={{ margin: '0px' }}>
                  {t('video')}
                </p>
              </MenuItem>
            </div>
          )}
      </Menu>
      <div className={classes.menuList}>
        <ul
          // eslint-disable-next-line react/no-unknown-property
          onClose={closeDrawer}
          className={classes.list}
        >
          <li>
            <Link
              onClick={closeDrawer}
              className={cx(
                classes.listLink,
                (path === monitoring || path === monitoring3d) ? classes.listLinkActive : '',
              )}
              to={monitoring}
            >
              <ShowIcon className={cx(
                classes.svgIcon,
                (path === monitoring || path === monitoring3d) ? classes.svgIconActive : '',
              )}
              />
              {t('monitoring')}
            </Link>
          </li>
          <li>
            <Link
              className={cx(
                classes.listLink,
                (path === history || path === history3d) ? classes.listLinkActive : '',
              )}
              to={history}
            >
              <RoutingIcon className={cx(
                classes.svgIconStroke,
                (path === history || path === history3d) ? classes.svgIconStrokeActive : '',
              )}
              />
              {t('history')}
            </Link>
          </li>
          {is3dEnabled() ? (
            <li>
              <Link
                className={cx(
                  classes.listLink,
                  (path === monitoring3d) ? classes.listLinkActive : '',
                )}
                to={monitoring3d}
              >
                3d
              </Link>
            </li>
          ) : null}
          <li>
            <Link
              className={cx(
                classes.listLink,
                (path.includes(reportsPartial)) ? classes.listLinkActive : '',
              )}
              to={serviceReports}
            >
              <CahrtIcon className={cx(
                classes.svgIconReports,
                (path.includes(reportsPartial)) ? classes.svgIconReportsActive : '',
              )}
              />
              {t('reports')}
            </Link>
          </li>
          {currentApp.role !== 'demo'
           && (
             accessCheck(currentApp) ? (
               <li>
                 <Link
                   className={cx(
                     classes.listLink,
                     (path.includes(settingsPartial)) && classes.listLinkActive,
                   )}
                   to={settings}
                 >
                   <SettingsIcon className={cx(
                     classes.svgIconReports,
                     (path.includes(settingsPartial)) ? classes.svgIconReportsActive : '',
                   )}
                   />
                   {t('settings')}
                 </Link>
               </li>
             ) : (
               <li>
                 <p className={cx(classes.listLink, classes.inActiveLink)}>
                   {t('settings')}
                 </p>
               </li>
             )
           )}
          {currentApp.role === 'demo'
          && (
            <div className="temporary-off" style={{ display: 'none' }}>
              <li
                role="menuitem"
                onClick={() => setOpenYoutube((prev) => !prev)}
                onKeyDown={() => {}}
                className={classes.listLink}
              >
                <YoutubeIcon className={classes.svgIconStroke} />
                {t('video')}
              </li>
            </div>
          )}
        </ul>
      </div>
      <div className={classes.profile}>
        <AppList />
      </div>
      {openYoutube && createPortal(
        <YoutubeModal handleYoutubeModal={handleYoutubeModal} />,
        document.body,
      )}
    </div>
  );
}

export default TopMenu;
