import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CircularProgress from '@mui/material/CircularProgress';
import { useDispatch } from 'react-redux';
import { demoUserPurchase } from 'store/actions/user';
import useStyles from './DialogPurchaseStyles';

function DialogPurchase({ setOpen }) {
  const { classes } = useStyles();
  const { t } = useTranslation(['topMenu', 'profile']);
  const [form, setForm] = useState({
    email: '',
    name: '',
    industry: '',
    company: '',
  });
  const [isSuccessPurchase, setIsSuccessPurchase] = useState(false);
  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line no-useless-escape
  const regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
  const isValidMail = !!form.email.match(regex);
  const dispatch = useDispatch();

  function handleClose() {
    setOpen(false);
  }

  async function handlePurchase() {
    setLoading(true);
    const result = await dispatch(demoUserPurchase(form));
    setLoading(false);
    if (result) {
      setIsSuccessPurchase(true);
    } else {
      handleClose();
    }
  }

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  function handleChangeForm(event) {
    setForm({
      ...form,
      [event.target.name]: event.target.value,
    });
  }

  return (
    <div className={classes.background}>
      {!isSuccessPurchase ? (
        <div className={classes.modalContainer}>
          <header className={classes.headerModal}>
            <section className={classes.headerFirstRow}>
              <p className={classes.textParagraph}>{t('purchase')}</p>
              <CloseIcon onClick={() => handleClose()} className={classes.closeButton} />
            </section>
            <section className={classes.headerSecondRow}>
              <p className={classes.textParagraph}>
                {t('purchaseModalText')}
              </p>
            </section>
          </header>
          <div className={classes.formContent}>
            <section className={classes.rowModal}>
              <InputLabel htmlFor="email" className={classes.labelElement}>
                <span className={!isValidMail ? classes.redText : null}>{'* '}</span>
                {capitalizeFirstLetter(t('profile:email'))}
              </InputLabel>
              <OutlinedInput
                required
                id="email"
                name="email"
                type="text"
                autoComplete="email"
                onChange={(e) => handleChangeForm(e)}
                className={classes.textInput}
              />
            </section>
            <section className={classes.rowModal}>
              <InputLabel htmlFor="name" className={classes.labelElement}>
                <span className={form.name === '' ? classes.redText : null}>{'* '}</span>
                {capitalizeFirstLetter(t('profile:name'))}
              </InputLabel>
              <OutlinedInput
                required
                id="name"
                name="name"
                type="text"
                autoComplete="name"
                onChange={(e) => handleChangeForm(e)}
                className={classes.textInput}
              />
            </section>
            <section className={classes.rowModal}>
              <InputLabel htmlFor="company" className={classes.labelElement}>
                <span className={form.company === '' ? classes.redText : null}>{'* '}</span>
                {capitalizeFirstLetter(t('profile:company'))}
              </InputLabel>
              <OutlinedInput
                id="company"
                name="company"
                type="text"
                autoComplete="off"
                onChange={(e) => handleChangeForm(e)}
                className={classes.textInput}
              />
            </section>
            <section className={classes.rowModal}>
              <InputLabel htmlFor="industry" className={classes.labelElement}>
                {t('industry')}
              </InputLabel>
              <OutlinedInput
                id="industry"
                name="industry"
                type="text"
                autoComplete="off"
                onChange={(e) => handleChangeForm(e)}
                className={classes.textInput}
              />
            </section>
            <div className={classes.rowModal}>
              <button
                type="button"
                onClick={handlePurchase}
                className={classes.purchaseButton}
                disabled={!isValidMail || form.name === '' || form.company === '' || loading}
              >
                {loading
                  ? <CircularProgress size={26} className={classes.circularProgress} />
                  : <p className={classes.textParagraph}>{t('purchase')}</p>}
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div className={classes.resultContainer} onClick={() => handleClose()} role="button" tabIndex="0" onKeyUp={handleClose}>
          <div className={classes.checkIcon}>
            <CheckCircleOutlineIcon />
          </div>
          <p className={classes.checkText}>{t('dataSent')}</p>
        </div>
      )}
    </div>
  );
}

export default DialogPurchase;
