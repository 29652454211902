/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  floorsObject: {},
  currentFloor: {},
  isFetching: false,
  map3DModel: {},
};

const floorSlice = createSlice({
  name: 'floor',
  initialState,
  reducers: {
    fetchFloorsInProgress(state) {
      state.isFetching = true;
    },
    fetchedFloors(state) {
      state.isFetching = false;
    },
    setFloorList(state, action) {
      state.floorsObject = action.payload;
      state.isFetching = false;
    },
    setFloor(state, action) {
      if (action.payload) {
        state.currentFloor = action.payload;
      }
    },
    set3DModel(state, action) {
      const { sublocationId, model } = action.payload;
      if (!model) {
        return;
      }
      const updatedFloorsObject = {
        ...state.floorsObject,
        [sublocationId]: {
          ...state.floorsObject[sublocationId],
          model,
        },
      };
      state.floorsObject = updatedFloorsObject;
    },
  },
});

export const selectFloors = (state) => state.floor;

export const selectFloorsObject = (state) => selectFloors(state).floorsObject;
export const selectCurrentFloor = (state) => selectFloors(state).currentFloor;
export const selectFloorsIsFetching = (state) => selectFloors(state).isFetching;
export const selectMap3DModel = (state) => selectFloors(state).map3DModel;

export const {
  fetchFloorsInProgress, fetchedFloors,
  set3DModel, setFloor, setFloorList,
} = floorSlice.actions;

export default floorSlice.reducer;
