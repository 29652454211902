import { jwtDecode } from 'jwt-decode';
import Cookies from 'js-cookie';
import { cookieTokenName } from 'constans';
import store from 'store/configureStore';

// eslint-disable-next-line import/prefer-default-export
export const isAuth = () => {
  let storedToken = Cookies.get(cookieTokenName);
  if (!storedToken) {
    const state = store.getState();
    if (state.user.token) {
      storedToken = state.user.token;
    } else {
      return false;
    }
  }
  const tokenPayload = jwtDecode(storedToken);
  const {
    hash: storedHash, id: storedId,
  } = tokenPayload.userData;

  return storedToken && storedHash && storedId;
};
