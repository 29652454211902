import AbstractDynamicTexture from './AbstractDynamicTexture';
import toCSSFont from '../helpers/toCSSFont';

const Class = class extends AbstractDynamicTexture {
  constructor({
    alignment = 'center',
    backgroundColor = 'rgba(0,0,0,0)',
    color = '#fff',
    fontFamily = 'Roboto, Helvetica, sans-serif',
    fontSize = 16,
    fontStyle = 'normal',
    fontVariant = 'normal',
    fontWeight = 'normal',
    lineGap = 1 / 4,
    padding = 1 / 2,
    strokeColor = '#fff',
    strokeWidth = 0,
    text = '',
  } = {}) {
    super();
    Object.entries({
      alignment,
      backgroundColor,
      color,
      fontFamily,
      fontSize,
      fontStyle,
      fontVariant,
      fontWeight,
      lineGap,
      padding,
      strokeColor,
      strokeWidth,
      text,
    }).forEach(([key, currentValue]) => {
      Object.defineProperty(this, key, {
        get() {
          return currentValue;
        },
        set(value) {
          if (currentValue !== value) {
            // eslint-disable-next-line no-param-reassign
            currentValue = value;
            this.needsRedraw = true;
          }
        },
      });
    });
  }

  get lines() {
    const { text } = this;
    return text ? text.split('\n') : [];
  }

  get font() {
    return toCSSFont(
      this.fontFamily,
      this.fontSize,
      this.fontStyle,
      this.fontVariant,
      this.fontWeight,
    );
  }

  checkFontFace() {
    try {
      const { font } = this;
      return document.fonts.check(font);
    } catch {
      // pass
    }
    return true;
  }

  async loadFontFace() {
    try {
      const { font } = this;
      await document.fonts.load(font);
    } catch {
      // pass
    }
  }

  createDrawing() {
    let {
      lineGap,
      padding,
      strokeWidth,
    } = this;
    const {
      alignment,
      backgroundColor,
      color,
      font,
      fontSize,
      lines,
      strokeColor,
    } = this;
    padding *= fontSize;
    lineGap *= fontSize;
    strokeWidth *= fontSize;
    const linesCount = lines.length;
    const lineOffset = fontSize + lineGap;
    const contentWidth = (linesCount
      ? (() => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        ctx.font = font;
        return Math.max(...lines.map((text) => ctx.measureText(text).width));
      })()
      : 0
    );
    const contentHeight = (linesCount
      ? (fontSize + lineOffset * (linesCount - 1))
      : 0
    );
    const contentOffset = padding + strokeWidth / 2;
    const width = contentWidth + contentOffset * 2;
    const height = contentHeight + contentOffset * 2;
    return {
      width,
      height,
      draw(ctx) {
        ctx.fillStyle = backgroundColor;
        ctx.fillRect(0, 0, ctx.canvas.width, ctx.canvas.height);
        let left;
        let top = contentOffset + fontSize / 2;
        Object.assign(ctx, {
          fillStyle: color,
          font,
          lineWidth: strokeWidth,
          miterLimit: 1,
          strokeStyle: strokeColor,
          textAlign: (() => {
            switch (alignment) {
              case 'left':
                left = contentOffset;
                return 'left';
              case 'right':
                left = width - contentOffset;
                return 'right';
              default:
                left = width / 2;
                return 'center';
            }
          })(),
          textBaseline: 'middle',
        });
        lines.forEach((text) => {
          ctx.fillText(text, left, top);
          if (strokeWidth) {
            ctx.strokeText(text, left, top);
          }
          top += lineOffset;
        });
      },
    };
  }
};

Class.prototype.isTextTexture = true;

export default Class;
