/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import merge from 'lodash/merge';
import { dynamicTypeId, newDevice, formTypeCreate } from 'constans';

const initialState = {
  objectFilter: '',
  isFetchingCreateObject: false,
  openModal: false,
  objectToDelete: {},
  openDeleteModal: false,
  openDeleteDeviceModal: false,
  modal: {
    formType: formTypeCreate,
    objectUpdateId: null,
    title: '',
    group: {},
    type: dynamicTypeId,
    deviceType: newDevice,
    device: {},
    newDeviceMac: '',
    floor: {},
    location: {},
    x: 0,
    y: 0,
    customPropertyUrl: '',
    cameraUrl: '',
    iconImageUrl: '',
    fileInfo: {},
    image: '',
    color: '#f9eea4',
    objectColor: '#fff',
    sizeObject: 'normal',
    properties: [],
  },
  customProps: [],
};

const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    addColorPropertyObject(state, action) {
      state.colorObject = action.payload;
    },
    addColorPropertyTrack(state, action) {
      state.color = action.payload;
    },
    addSizeObject(state, action) {
      state.size = action.payload;
    },
    addCustomProperty(state, action) {
      state.customProps.push(action.payload);
    },
    closeDeleteModal(state) {
      state.openDeleteModal = false;
    },
    closeModalAction(state) {
      state.openModal = false;
      state.modal = initialState.modal;
    },
    deleteCustomProperty(state, action) {
      state.modal = merge({}, { ...state.modal, properties: [] }, action.payload);
    },
    editCustomProperty(state, action) {
      state.customProps = action.payload;
    },
    openDeleteModalAction(state) {
      state.openDeleteModal = true;
    },
    openModalAction(state, action) {
      state.openModal = true;
      state.modal.formType = action.payload;
    },
    setModalForm(state, action) {
      state.modal = merge({}, state.modal, action.payload);
    },
  },
});

export const selectSettings = (state) => state.settings;

export const selectSettingsObjectFilter = (state) => selectSettings(state).objectFilter;
export const selectSettingsIsFetchingCreateObject = (state) => selectSettings(state)
  .isFetchingCreateObject;
export const selectSettingsOpenModal = (state) => selectSettings(state).openModal;
export const selectSettingsObjectToDelete = (state) => selectSettings(state).objectToDelete;
export const selectSettingsOpenDeleteModal = (state) => selectSettings(state).openDeleteModal;
export const selectSettingsOpenDeleteDeviceModal = (state) => selectSettings(state)
  .openDeleteDeviceModal;
export const selectSettingsModal = (state) => selectSettings(state).modal;
export const selectSettingsCustomProps = (state) => selectSettings(state).customProps;

export const {
  addColorPropertyObject, addColorPropertyTrack, addCustomProperty,
  closeDeleteModal, closeModalAction, deleteCustomProperty,
  editCustomProperty, openDeleteModalAction, openModalAction, setModalForm,
  addSizeObject,
} = settingsSlice.actions;

export default settingsSlice.reducer;
