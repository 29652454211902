import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  background: {
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 1000,
    display: 'flex',
    background: 'rgba(32,32,32, 0.1)',
  },
  modalContainer: {
    position: 'absolute',
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    borderRadius: '12px',
    height: 'auto',
    top: '7em',
    width: '38.5em',
  },
  modalContainer1: {
    right: '5em',
  },
  modalContainer2: {
    right: '5em',
  },
  modalContainer3: {
    left: '22em',
  },
  modalContainer4: {
    left: '22em',
  },
  modalContainer5: {
    left: '22em',
  },
  modalContainer6: {
    left: '22em',
  },
  modalContainer7: {
    right: '5em',
  },
  headerModal: {
    width: '100%',
    display: 'flex',
    padding: '0',
    gap: '1em',
  },
  headerRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'start',
    fontWeight: 400,
    fontSize: '1.1em',
    padding: '10px 10px 0px 0px',
    marginBottom: '1em',
    height: 'auto',
  },
  closeButton: {
    cursor: 'pointer',
    color: '#393939',
    backgroundColor: '#DCE7EE',
    borderRadius: '50%',
  },
  textParagraph: {
    margin: 0,
    padding: '14px 10px 0px 24px',
  },
  imageGuideline: {
    height: '12em',
  },
  footerContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    padding: '17px 24px 10px 24px',
  },
  stepCounterText: {
    color: '#BBBBBB',
    alignSelf: 'center',
  },
  navigateFooter: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  normalNavigation: {
    cursor: 'pointer',
  },
  disabledNavigation: {
    color: 'lightgrey',
  },
  buttonClose: {
    width: '10em',
    height: '3em',
    borderRadius: '8px',
    border: 0,
    padding: '0.571em 1.143em 0.571em 0.914em',
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    fontWeight: 600,
    fontSize: '1em',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '0.5em',
    cursor: 'pointer',
  },
}));

export default useStyles;
