/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/label-has-associated-control */
import {
  React, useCallback, useEffect, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ColorPicker, useColor } from 'react-color-palette';
import { useTranslation } from 'react-i18next';
import 'react-color-palette/lib/css/styles.css';

import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Switch from '@mui/material/Switch';
import IconButton from '@mui/material/IconButton';
import FormControl from '@mui/material/FormControl';
import CircularProgress from '@mui/material/CircularProgress';
import InputAdornment from '@mui/material/InputAdornment';
import Autocomplete from '@mui/material/Autocomplete';

import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import CloseIcon from '@mui/icons-material/Close';

import { ReactComponent as BrushIcon } from 'assets/images/brush.svg';
import {
  staticTypeId,
  dynamicTypeId,
  oldDevice,
  newDevice,
  formTypeCreate,
  formTypeUpdate,
} from 'constans';
import {
  objectsByAppFetch, createObject, unlinkDevice,
} from 'store/actions/objects';

import { fetchDevicesList } from 'store/actions/devices';
import {
  closeModalAction,
  setModalForm,
  addCustomProperty,
  deleteCustomProperty,
  addColorPropertyTrack,
  addColorPropertyObject,
  editCustomProperty,
  selectSettingsOpenModal,
  selectSettingsModal,
  selectSettingsCustomProps,
  addSizeObject,
} from 'store/slices/settings';
import { selectDevicesList } from 'store/slices/devices';
import { selectFloorsObject } from 'store/slices/floors';
import { selectGroupsCount, selectGroupsObject } from 'store/slices/groups';
import { selectObjectsIsFetchingCreateObject } from 'store/slices/objects';

import CustomButtonTitle from 'components/settings/ButtonTitle.component';
import CustomButton from 'components/settings/Button.component';
import InputComponent from 'components/settings/Input.component';
import SelectorComponent from 'components/settings/Selector.component';
import TabPanel from 'components/shared/Tabs/Tabs';
import a11yProps from 'components/shared/Tabs/allProps';
import GroupsInfinite from 'components/shared/GroupSelector/GroupsInfinite';
import useStyles from '../objects.component.style';
import InputTypeFile from './InputTypeFile.component';
import ViewObject from '../ViewObject';

function EditCreateDialog() {
  const { t } = useTranslation(['settings', 'report']);
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const devicesList = useSelector(selectDevicesList);
  const floorsObject = useSelector(selectFloorsObject);
  const groupsObject = useSelector(selectGroupsObject);
  const isFetchingCreateObject = useSelector(selectObjectsIsFetchingCreateObject);
  const openModal = useSelector(selectSettingsOpenModal);
  const modal = useSelector(selectSettingsModal);
  const customProps = useSelector(selectSettingsCustomProps);
  const groupsCount = useSelector(selectGroupsCount);

  const [valueTabs, setValueTabs] = useState(0);
  const [valueProps, setValueProps] = useState();
  const [keyProps, setKeyProps] = useState();
  const [colorTrack, setColorTrack] = useColor('hex', modal.color);
  const [colorObject, setColorObject] = useColor('hex', modal.objectColor);
  const [showPaletteColorTrack, setShowPaletteColorTrack] = useState(false);
  const [showPaletteColorObject, setShowPaletteColorObject] = useState(false);
  const [doLink, setDoLink] = useState(false);
  const [initiallyLinked, setInitiallyLinked] = useState(false);

  const groupsList = Object.keys(groupsObject).map((element) => groupsObject[element]);
  const floorsArray = Object.keys(floorsObject);

  useEffect(() => {
    if (modal.device && Object.keys(modal.device).length === 0) {
      setDoLink(false);
      setInitiallyLinked(false);
    }
    if (modal.device && Object.keys(modal.device).length > 0) {
      setDoLink(true);
      setInitiallyLinked(true);
    }
  // should only check on the first render
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeTabs = (_event, newValue) => {
    setValueTabs(newValue);
  };

  const handleChangeForm = useCallback((fieldName, value) => {
    const resultObject = {};
    resultObject[fieldName] = value;
    dispatch(setModalForm(resultObject));
  }, [dispatch]);

  const addColorTrack = () => {
    dispatch(addColorPropertyTrack(colorTrack.hex));
    handleChangeForm('color', colorTrack.hex);
    setShowPaletteColorTrack(false);
  };
  const addColorObject = () => {
    dispatch(addColorPropertyObject(colorObject.hex));
    handleChangeForm('objectColor', colorObject.hex);
    setShowPaletteColorObject(false);
  };
  const handlePaletteColor = () => {
    setShowPaletteColorTrack(!showPaletteColorTrack);
  };
  const handlePaletteColorObject = () => {
    setShowPaletteColorObject(!showPaletteColorObject);
  };
  const handleCustomKeyChangeForm = (_prop, _index, value) => {
    setKeyProps(value);
  };
  const handleCustomValueChangeForm = (_fieldName, value) => {
    setValueProps(value);
  };

  const addProperty = () => {
    const resultObject = {
      key: keyProps,
      value: valueProps,
      id: Math.floor(Math.random() * 101),
      btn: false,
    };
    dispatch(addCustomProperty(resultObject));
    setKeyProps();
    setValueProps();
  };
  const removeProps = (prop) => {
    const newForm = JSON.parse(JSON.stringify(customProps));
    const newCustomProps = newForm.filter((_i, index) => index !== prop);
    dispatch(editCustomProperty(newCustomProps));
  };

  const deleteProperty = (id) => {
    const newForm = JSON.parse(JSON.stringify(modal));
    newForm.properties = modal.properties.filter((i) => i.id !== id);
    dispatch(deleteCustomProperty(newForm));
  };
  const changeProperties = (prop, value) => {
    const newForm = JSON.parse(JSON.stringify(modal));
    newForm.properties.forEach((i) => {
      if (i.id === prop.id) {
        // eslint-disable-next-line no-param-reassign
        i.key = value;
      }
    });
    dispatch(deleteCustomProperty(newForm));
  };

  const changeValues = (prop, value) => {
    const newForm = JSON.parse(JSON.stringify(modal));
    newForm.properties.forEach((i) => {
      if (i.id === prop.id) {
        // eslint-disable-next-line no-param-reassign
        i.value = value;
      }
    });
    dispatch(deleteCustomProperty(newForm));
  };

  const switchLinkDevice = (event) => {
    setDoLink(event.target.checked);
  };

  const finishAction = async () => {
    await dispatch(objectsByAppFetch('', 0, 4000));
    await dispatch(fetchDevicesList());
    setValueTabs(0);
    setKeyProps();
    dispatch(editCustomProperty([]));
    dispatch(closeModalAction());
  };

  const handleCreateObject = async () => {
    await dispatch(createObject(t));
    await finishAction();
  };

  const handleEditObject = async () => {
    await dispatch(createObject(t));
    if (initiallyLinked && !doLink) {
      await dispatch(unlinkDevice());
    }
    await finishAction();
  };

  const handleClose = () => {
    setShowPaletteColorTrack(false);
    setValueTabs(0);
    dispatch(editCustomProperty([]));
    setKeyProps();
    setValueProps();
    dispatch(closeModalAction());
  };

  const checkFormValidation = () => {
    const {
      title,
      group,
      type,
      device,
      floor,
      x,
      y,
      deviceType,
      newDeviceMac,
      formType,
    } = modal;
    const mainProptiesCheck = !(title && group.id);
    if (!doLink && formType === formTypeUpdate) {
      return false;
    }
    if (type === dynamicTypeId) {
      const deviceIdCheck = !device.id;
      if (deviceType === newDevice) {
        return mainProptiesCheck || !newDeviceMac || isFetchingCreateObject;
      }
      if (deviceType === oldDevice) {
        return mainProptiesCheck || deviceIdCheck || isFetchingCreateObject;
      }
    }
    if (type === staticTypeId) {
      const floorCheck = !floor.id;
      return (
        mainProptiesCheck
          || floorCheck
          || x === ''
          || y === ''
          || isFetchingCreateObject
      );
    }
    return false;
  };
  const handleSizeChange = (event) => {
    dispatch(addSizeObject(event.target.value));
    handleChangeForm('sizeObject', event.target.value);
  };

  return (
    <div>
      <Dialog
        open={openModal}
        onClose={handleClose}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        PaperProps={{
          style: {
            position: 'absolute',
            top: '5vh',
          },
        }}
      >
        <form onSubmit={(e) => e.preventDefault()}>
          <div className={classes.tabsMainWrapper}>
            <div className={classes.tabContainer}>
              <DialogTitle className={classes.dialogTitle} id="scroll-dialog-title">
                <div className={classes.divToH3}>
                  {modal.formType === formTypeCreate
                    ? t('createFormTitle')
                    : t('editFormTitle')}
                  {modal.formType === formTypeCreate
                    ? null : (
                      <div className={classes.idTitle}>
                        id
                        {' '}
                        {modal.objectUpdateId}
                      </div>
                    )}
                </div>
                <IconButton size="small" onClick={handleClose}>
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <AppBar position="static" className={classes.wrappApp}>
                <Tabs
                  value={valueTabs}
                  onChange={handleChangeTabs}
                  className={classes.tabsLabel}
                  TabIndicatorProps={{ style: { background: '#30AAD9' } }}
                >
                  <Tab label={t('base')} {...a11yProps(0)} className={classes.tabLabel} />
                  <Tab label={t('view')} {...a11yProps(1)} className={classes.tabLabel} />
                  <Tab label={t('custom')} {...a11yProps(2)} className={classes.tabLabel} />
                </Tabs>
              </AppBar>
              <TabPanel value={valueTabs} index={0}>
                <DialogContent>
                  <FormControl variant="standard" className={classes.formControl}>
                    <label className={classes.label} htmlFor="object-title">
                      {t('objectFormFieldTitle')}
                      <InputComponent
                        className={classes.inputComponent}
                        type="text"
                        value={modal.title}
                        onChange={(event) => {
                          handleChangeForm('title', event.target.value);
                        }}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{ disableUnderline: true }}
                        inputProps={{ id: 'object-title' }}
                        variant="standard"
                      />
                    </label>
                  </FormControl>
                  <br />
                  {(!groupsCount || groupsCount <= 100)
                    ? (
                      <FormControl variant="standard" className={classes.formControl}>
                        <label className={classes.label} htmlFor="object-group">
                          {t('objectFormFieldGroup')}
                          <SelectorComponent
                            className={classes.inputComponent}
                            select
                            onChange={(event) => {
                              handleChangeForm('group', groupsObject[event.target.value]);
                            }}
                            value={modal.group.id || ''}
                            InputLabelProps={{ shrink: true }}
                            InputProps={{ disableUnderline: true }}
                            inputProps={{ id: 'object-group' }}
                            variant="standard"
                          >
                            {groupsList.map((group) => (
                              <MenuItem key={group.id} value={group.id}>
                                {group.title}
                              </MenuItem>
                            ))}
                          </SelectorComponent>
                        </label>
                      </FormControl>
                    )
                    : (
                      <FormControl variant="standard" className={classes.formControl} sx={{ marginBottom: '7px' }}>
                        <GroupsInfinite
                          value={modal.group}
                        />
                      </FormControl>
                    )}
                  <br />
                  <FormControl variant="standard" className={classes.formControl}>
                    <label className={classes.label} htmlFor="object-type">
                      {t('objectFormFieldType')}
                    </label>
                    <SelectorComponent
                      className={classes.inputComponent}
                      select
                      onChange={(event) => {
                        handleChangeForm('type', event.target.value);
                      }}
                      value={modal.type || ''}
                      InputLabelProps={{ shrink: true }}
                      InputProps={{ disableUnderline: true }}
                      inputProps={{ id: 'object-type' }}
                      disabled={modal.formType === formTypeUpdate}
                      variant="standard"
                    >
                      <MenuItem key={staticTypeId} value={staticTypeId}>
                        {t('static')}
                      </MenuItem>
                      <MenuItem key={dynamicTypeId} value={dynamicTypeId}>
                        {t('dynamic')}
                      </MenuItem>
                    </SelectorComponent>
                  </FormControl>
                  <br />
                  {modal.formType === formTypeUpdate
                  && (
                    <>
                      <FormControl variant="standard" className={classes.formControl}>
                        <label className={classes.label} htmlFor="device-switch">
                          {t('linkDevice')}
                        </label>
                        <Switch id="device-switch" onChange={switchLinkDevice} checked={doLink} />
                      </FormControl>
                      <br />
                    </>
                  )}
                  {(doLink || modal.formType === formTypeCreate)
                  && (
                    modal.type === dynamicTypeId && (
                    <FormControl variant="standard" className={classes.formControl}>
                      <label className={classes.label} htmlFor="device-type">
                        {t('objectFormFieldDeviceType')}
                      </label>
                      <SelectorComponent
                        className={classes.inputComponent}
                        select
                        fullWidth
                        onChange={(event) => {
                          event.stopPropagation();
                          handleChangeForm('deviceType', event.target.value);
                        }}
                        value={modal.deviceType || ''}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{ disableUnderline: true }}
                        inputProps={{ id: 'device-type' }}
                        variant="standard"
                      >
                        <MenuItem key={oldDevice} value={oldDevice}>
                          {t('old')}
                        </MenuItem>
                        <MenuItem key={newDevice} value={newDevice}>
                          {t('new')}
                        </MenuItem>
                      </SelectorComponent>
                      {modal.deviceType === oldDevice ? (
                        <FormControl variant="standard" className={classes.formControl}>
                          <label className={classes.label} htmlFor="device-id">
                            {t('objectFormFieldDevice')}
                          </label>
                          <Autocomplete
                            className={classes.inputComponent}
                            id="device-id"
                            options={devicesList}
                            getOptionLabel={(option) => (option ? option.attributes.title : '')}
                            style={{ width: 300 }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                // label={t('objectFormFieldDevice')}
                                variant="standard"
                                InputProps={{ ...params.InputProps, disableUnderline: true }}
                              />
                            )}
                            onChange={(event, newValue) => {
                              if (newValue) {
                                handleChangeForm('device', newValue);
                              } else {
                                handleChangeForm('device', '');
                              }
                            }}
                            value={devicesList.find(
                              (device) => device.id === modal.device.id,
                            ) || null}
                          />
                        </FormControl>
                      ) : (
                        <FormControl variant="standard" className={classes.formControl}>
                          <label className={classes.label} htmlFor="new-device-mac">
                            {t('deviceMac')}
                          </label>
                          <InputComponent
                            className={classes.inputComponent}
                            value={modal.newDeviceMac}
                            type="text"
                            onChange={(event) => {
                              handleChangeForm('newDeviceMac', event.target.value);
                            }}
                            InputLabelProps={{ shrink: true }}
                            InputProps={{ disableUnderline: true }}
                            inputProps={{ id: 'new-device-mac' }}
                            variant="standard"
                          />
                        </FormControl>
                      )}
                    </FormControl>
                    )
                  )}
                  {modal.type === staticTypeId && (
                    <FormControl variant="standard" className={classes.formControl}>
                      <label className={classes.label} htmlFor="new-device-mac-static">
                        {t('objectFormFieldFloor')}
                        <SelectorComponent
                          className={classes.inputComponent}
                          select
                          onChange={(event) => {
                            handleChangeForm('floor', floorsObject[event.target.value]);
                          }}
                          value={(floorsObject[modal.floor.id] && modal.floor.id) || modal.sublocation_id || ''}
                          InputLabelProps={{ shrink: true }}
                          InputProps={{ disableUnderline: true }}
                          inputProps={{ id: 'new-device-mac-static' }}
                          variant="standard"
                        >
                          {floorsArray.map((floorId) => (
                            <MenuItem key={floorId} value={floorId}>
                              {floorsObject[floorId].title}
                            </MenuItem>
                          ))}
                        </SelectorComponent>
                      </label>
                      <label className={classes.label} htmlFor="custom-property-url-x">
                        {t('objectFormFieldX')}
                        <InputComponent
                          className={classes.inputComponent}
                          value={modal.x}
                          fullWidth
                          type="number"
                          onChange={(event) => {
                            handleChangeForm('x', event.target.value);
                          }}
                          InputLabelProps={{ shrink: true }}
                          InputProps={{ disableUnderline: true }}
                          inputProps={{ id: 'custom-property-url-x' }}
                          variant="standard"
                        />
                      </label>
                      <label className={classes.label} htmlFor="custom-property-url-y">
                        {t('objectFormFieldY')}
                        <InputComponent
                          className={classes.inputComponent}
                          value={modal.y}
                          fullWidth
                          type="number"
                          onChange={(event) => {
                            handleChangeForm('y', event.target.value);
                          }}
                          InputLabelProps={{ shrink: true }}
                          InputProps={{ disableUnderline: true }}
                          inputProps={{ id: 'custom-property-url-y' }}
                          variant="standard"
                        />
                      </label>
                    </FormControl>
                  )}
                </DialogContent>
              </TabPanel>
              <TabPanel value={valueTabs} index={1}>
                <DialogContent>
                  <FormControl variant="standard" className={classes.formControl}>
                    <InputTypeFile
                      image={modal.image}
                      fileInfo={modal.fileInfo}
                      iconImageUrl={modal.iconImageUrl}
                    />
                  </FormControl>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <FormControl variant="standard" className={classes.formControl}>
                      <label className={classes.label} htmlFor="color-object">
                        {t('colorObject')}
                        <InputComponent
                          className={classes.inputComponent}
                          value={modal.objectColor}
                          fullWidth
                          InputProps={{
                            style: { color: 'black' },
                            disableUnderline: true,
                            endAdornment: (
                              <InputAdornment position="start">
                                <BrushIcon onClick={handlePaletteColorObject} fontSize="large" />
                              </InputAdornment>),
                          }}
                          onClick={handlePaletteColorObject}
                          disabled
                          InputLabelProps={{ shrink: true }}
                          inputProps={{ id: 'color-object' }}
                          variant="standard"
                        />
                      </label>
                    </FormControl>
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <FormControl className={classes.formControl}>
                      <label className={classes.label} id="size-selector-label" htmlFor="size-object">
                        {t('size')}
                        <Select
                          className={classes.inputComponent}
                          labelId="size-selector-label"
                          id="size-selector"
                          value={modal.sizeObject}
                          onChange={handleSizeChange}
                          InputLabelProps={{ shrink: true }}
                          InputProps={{ disableUnderline: true }}
                          inputProps={{ id: 'size-selector-label' }}
                          variant="standard"
                        >
                          <MenuItem value="small">{t('small')}</MenuItem>
                          <MenuItem value="normal">{t('normal')}</MenuItem>
                          <MenuItem value="large">{t('large')}</MenuItem>
                        </Select>
                      </label>
                    </FormControl>
                  </div>

                  {showPaletteColorObject ? (
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <ColorPicker
                        width={250}
                        height={228}
                        color={colorObject}
                        onChange={setColorObject}
                        hideRGB
                        hideHSV
                      />
                      <CustomButtonTitle
                        onClick={addColorObject}
                        variant="contained"
                        color="primary"
                      >
                        {t('saveBtn')}
                      </CustomButtonTitle>
                    </div>
                  ) : (
                    null
                  )}
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <FormControl variant="standard" className={classes.formControl}>
                      <label className={classes.label} htmlFor="palette-color">
                        {t('colorTrack')}
                        <InputComponent
                          className={classes.inputComponent}
                          value={modal.color}
                          fullWidth
                          disabled
                          onClick={handlePaletteColor}
                          InputProps={{
                            style: { color: 'black' },
                            disableUnderline: true,
                            endAdornment: (
                              <InputAdornment position="start">
                                <BrushIcon onClick={handlePaletteColor} fontSize="large" />
                              </InputAdornment>),
                          }}
                          inputProps={{ id: 'palette-color' }}
                          InputLabelProps={{ shrink: true }}
                          variant="standard"
                        />
                      </label>
                    </FormControl>
                  </div>
                  {!showPaletteColorTrack && !showPaletteColorObject
                    ? (
                      <ViewObject
                        color={modal.color}
                        colorObject={modal.objectColor}
                        image={modal.image}
                        iconImageUrl={modal.iconImageUrl}
                      />
                    )
                    : (
                      null)}
                  {showPaletteColorTrack ? (
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <ColorPicker
                        width={250}
                        height={228}
                        color={colorTrack}
                        onChange={setColorTrack}
                        hideRGB
                        hideHSV
                      />
                      <CustomButtonTitle
                        onClick={addColorTrack}
                        variant="contained"
                        color="primary"
                      >
                        {t('saveBtn')}
                      </CustomButtonTitle>
                    </div>
                  ) : (null)}
                  <br />
                </DialogContent>
              </TabPanel>
              <TabPanel value={valueTabs} index={2}>
                <div className={classes.customPropsContainer}>
                  <FormControl variant="standard" className={classes.customPropsWrapp}>
                    <label className={classes.label} htmlFor="object-custom-property">
                      Property
                    </label>
                    <TextField
                      variant="standard"
                      className={classes.inputComponent}
                      type="text"
                      value={keyProps || ''}
                      onChange={(event) => {
                        handleCustomKeyChangeForm('a', 'b', event.target.value);
                      }}
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        style: { color: 'black' },
                        disableUnderline: true,
                      }}
                      inputProps={{ id: 'object-custom-property' }}
                    />
                  </FormControl>
                  <FormControl variant="standard" className={classes.customPropsWrapp}>
                    <label className={classes.label} htmlFor="object-custom-value">
                      Value
                      <TextField
                        variant="standard"
                        className={classes.inputComponent}
                        type="text"
                        value={valueProps || ''}
                        onChange={(event) => {
                          handleCustomValueChangeForm('title', event.target.value);
                        }}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                          style: { color: 'black' },
                          disableUnderline: true,
                        }}
                        inputProps={{ id: 'object-custom-value' }}
                      />
                    </label>
                  </FormControl>
                  <div className={classes.btnCusotmContainer}>
                    <IconButton
                      disabled={!keyProps || !valueProps}
                      onClick={addProperty}
                      className={classes.btnCustomProps}
                      size="large"
                    >
                      <AddIcon />
                    </IconButton>
                  </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column-reverse' }}>
                  {customProps.map((prop, index) => {
                    const key = index;
                    return (
                      <div key={key} className={classes.customPropsContainer}>
                        <FormControl variant="standard" className={classes.customPropsWrapp}>
                          <TextField
                            variant="standard"
                            className={classes.customProps}
                            disabled
                            label="Property"
                            type="text"
                            onChange={(event) => {
                              handleCustomKeyChangeForm(prop, index, event.target.value);
                            }}
                            InputLabelProps={{ shrink: true }}
                            value={prop.key}
                          />
                        </FormControl>
                        <FormControl variant="standard" className={classes.customPropsWrapp}>
                          <TextField
                            variant="standard"
                            disabled
                            value={prop.value}
                            className={classes.customProps}
                            label="Value"
                            type="text"
                            onChange={(event) => {
                              handleCustomValueChangeForm('title', event.target.value);
                            }}
                            InputLabelProps={{ shrink: true }}
                          />
                        </FormControl>
                        <div className={classes.btnCusotmContainer}>
                          <IconButton
                            onClick={() => removeProps(index)}
                            className={classes.btnCustomProps}
                            size="large"
                          >
                            <RemoveIcon />
                          </IconButton>
                        </div>

                      </div>
                    );
                  })}
                </div>
                {modal.properties.map((el) => (
                  <div key={el.id} className={classes.customPropsContainer}>
                    <FormControl variant="standard" className={classes.customPropsWrapp}>
                      <TextField
                        variant="standard"
                        className={classes.customProps}
                        label="Property"
                        value={el.key}
                        type="text"
                        onChange={(event) => {
                          changeProperties(el, event.target.value);
                        }}
                        InputLabelProps={{ shrink: true }}
                      />
                    </FormControl>
                    <FormControl variant="standard" className={classes.customPropsWrapp}>
                      <TextField
                        variant="standard"
                        className={classes.customProps}
                        label="Value"
                        type="text"
                        value={el.value}
                        onChange={(event) => {
                          changeValues(el, event.target.value);
                        }}
                        InputLabelProps={{ shrink: true }}
                      />
                    </FormControl>
                    <div className={classes.btnCusotmContainer}>
                      <IconButton
                        onClick={() => deleteProperty(el.id)}
                        className={classes.btnCustomProps}
                        size="large"
                      >
                        <RemoveIcon />
                      </IconButton>
                    </div>
                  </div>
                ))}
              </TabPanel>
            </div>
          </div>
          <DialogActions className={classes.dialogActions}>
            {modal.formType === formTypeCreate
            && (
            <CustomButton
              disabled={checkFormValidation()}
              onClick={handleCreateObject}
              type="submit"
              className={classes.customBtn}
            >
              {isFetchingCreateObject ? (
                <div className={classes.spinerWrapper}>
                  <CircularProgress size={26} />
                </div>
              ) : (
                <>
                  {t('createBtn')}
                </>
              )}
            </CustomButton>
            )}
            {modal.formType === formTypeUpdate
            && (
            <CustomButton
              disabled={checkFormValidation()}
              onClick={handleEditObject}
              type="submit"
              className={classes.customBtn}
            >
              {isFetchingCreateObject ? (
                <div className={classes.spinerWrapper}>
                  <CircularProgress size={26} />
                </div>
              ) : (
                <>
                  { t('saveBtn')}
                </>
              )}
            </CustomButton>
            )}
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}
export default EditCreateDialog;
