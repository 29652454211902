import request, { endPointsEnum } from 'services/http';
import { createAlert } from 'store/slices/alert';
import {
  fetchZonesInProgress, fetchedFinished, setZoneTypes, setZones,
} from 'store/slices/zones';

export const fetchZoneTypesIfNeeded = () => async (dispatch, getState) => {
  try {
    const state = getState();
    if (!state.floor.currentFloor || !state.floor.currentFloor.id || !state.user.hash) {
      return;
    }
    if (Object.entries(state.zones.zoneTypes).length > 1) {
      return;
    }
    const params = {
      userHash: state.user.hash,
      sublocationId: state.floor.currentFloor.id,
    };

    dispatch(fetchZonesInProgress());
    const response = await request.client.get(endPointsEnum.zonesGetAll, { params });

    const typesArray = response.types;
    const typesObject = {};
    typesArray.map((type) => {
      typesObject[type.id] = type;

      return type;
    });

    dispatch(fetchedFinished());
    dispatch(setZoneTypes(typesObject));
  } catch (error) {
    dispatch(fetchedFinished());
    dispatch(createAlert({ messageType: 'error', message: error.message }));
  }
};

export const fetchZones = () => async (dispatch, getState) => {
  const state = getState();
  try {
    const params = {
      api_key: state.app.currentApp.api_key,
    };

    dispatch(fetchZonesInProgress());

    const response = await request.client.get(endPointsEnum.zonesGetAllByApp, { params });
    const { zones: zonesArray, zoneTypes } = response;
    const typesObject = {};
    zoneTypes.map((type) => {
      typesObject[type.id] = type;
      return type;
    });

    const zonesMap = new Map();
    zonesArray
      .sort((a, b) => {
        if (!a.title && !b.title) return 0;
        if (!a.title) return -1;
        if (!b.title) return 1;
        return a.title.localeCompare(b.title);
      })
      .forEach((zone) => {
        const typedColor = typesObject[zone.zone_type]?.zone_type_color;
        zonesMap.set(zone.id, {
          id: zone.id,
          title: zone.title,
          color: typedColor || zone.color,
          points: zone.coordinates,
          object_type: zone.object_type,
          floor: zone.sublocation_id,
          zoneTypeID: zone.zone_type,
        });
      });
    dispatch(setZones(zonesMap));
    dispatch(setZoneTypes(typesObject));
    dispatch(fetchedFinished());
  } catch (error) {
    dispatch(fetchedFinished());
    if (state.app.currentApp.api_key) {
      dispatch(createAlert({ messageType: 'error', message: error.message }));
    }
  }
};
