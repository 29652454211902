import request, { endPointsEnum } from 'services/http';
import { createAlert } from 'store/slices/alert';
import {
  fetchFloorsInProgress, fetchedFloors,
  set3DModel, setFloor, setFloorList,
} from 'store/slices/floors';

export const fetchFloors = () => async (dispatch, getState) => {
  const state = getState();
  try {
    const params = {
      api_key: state.app.currentApp.api_key,
      userId: localStorage.id,
    };
    dispatch(fetchFloorsInProgress());
    const { currentLocation } = state.location;

    const response = await request.client.get(endPointsEnum.floorsGetAll, { params });
    const floorsArray = response.data;
    const floorsObject = {};
    floorsArray.forEach((floor) => {
      floorsObject[floor.id] = {
        id: floor.id,
        title: floor.attributes.name,
        location: floor.relationships.location.data.id,
        h: floor.attributes.dimensions_in_meters.height,
        hor: floor.attributes.hor,
        image_bounds: floor.attributes.image_bounds,
        image_url: floor.attributes.files.length ? floor.attributes.files[0].full_url : null,
        lat: floor.attributes.lat,
        lon: floor.attributes.lon,
        ph: floor.attributes.dimensions_in_pixels.height,
        pw: floor.attributes.dimensions_in_pixels.width,
        w: floor.attributes.dimensions_in_meters.width,
        sort: floor.attributes.sort,
      };
      if (floorsObject[floor.id]?.location === currentLocation?.id) {
        dispatch(setFloor(floorsObject[floor.id]));
      }
    });

    dispatch(setFloorList({ ...floorsObject }));
    dispatch(fetchedFloors());
  } catch (error) {
    dispatch(fetchedFloors());
    if (state.app.currentApp.api_key) {
      dispatch(createAlert({ messageType: 'error', message: error.message }));
    }
  }
};

export const fetch3DModel = (sublocationId, signal = null) => async (dispatch, getState) => {
  const state = getState();
  try {
    const params = {
      api_key: state.app.currentApp.api_key,
      sublocationId,
    };

    const response = await request.client.get(
      endPointsEnum.floorsGetCurrent,
      { params, abortSignalController: signal },
    );
    const floor = response.sublocation;
    if (floor && floor.files.length > 1) {
      dispatch(set3DModel({ sublocationId, model: floor.files[1] }));
    }
    if (floor && floor.files.length < 2) {
      dispatch(set3DModel({ sublocationId, model: null }));
    }
  } catch (error) {
    dispatch(createAlert({ messageType: 'error', message: error.message }));
  }
};
