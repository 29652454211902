import React, { useEffect } from 'react';

import { useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { jwtDecode } from 'jwt-decode';
import Cookies from 'js-cookie';
import { CircularProgress } from '@mui/material';
import { createAlert } from 'store/slices/alert';
import WelcomeLogo from 'components/login/logo/logo';
import {
  monitoring, history as historyPath, cookieTokenName, cookOpts, cookieRefTokenName,
} from 'constans';
import {
  fetchFinished,
} from 'store/slices/auth';
import { appFetch } from 'store/actions/app';
import { setUser } from 'store/slices/user';
import init from 'helpers/init';
import useStyles from './styles';

function AuthByToken() {
  const { t } = useTranslation(['login', 'translation']);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useLocation();
  const query = new URLSearchParams(params.search);
  const { classes } = useStyles();

  useEffect(() => {
    const timer = setTimeout(async () => {
      let tokenFromParams = params.search.substring(1);
      const tokenFromQuery = query?.get('token');
      const historyFromQuery = query?.get('history');
      if (tokenFromQuery) {
        tokenFromParams = tokenFromQuery;
      }
      // eslint-disable-next-line no-underscore-dangle
      const response = await fetch(`${window._configuration_env.AUTH_URL}/loginByUserToken/${tokenFromParams}`);
      const result = await response.json();
      if (response.status === 200) {
        const { token, refreshToken } = result;
        const tokenPayload = jwtDecode(token);
        const {
          hash, company, email, name, id, avatar_url: avatarUrl,
        } = tokenPayload.userData;
        Cookies.set(cookieTokenName, token, cookOpts);
        Cookies.set(cookieRefTokenName, refreshToken, cookOpts);
        localStorage.setItem('token', token);
        localStorage.setItem('hash', hash);
        localStorage.setItem('id', id);
        const userToken = tokenPayload.userData.token.token;
        localStorage.setItem('userToken', userToken);
        dispatch(
          setUser({
            name,
            email,
            avatar_url: avatarUrl,
            hash,
            company,
            token,
            refreshToken,
          }),
        );

        dispatch(fetchFinished());
        await dispatch(appFetch());
        await init(dispatch);
        dispatch(createAlert({ messageType: 'success', message: 'Successful login' }));
        if (historyFromQuery) {
          const queryParams = {
            location_id: query?.get('location_id'),
            floor_id: query?.get('floor_id'),
            group_id: query?.get('group_id'),
            from: query?.get('from'),
            to: query?.get('to'),
            apply: query?.get('apply'),
            track: query?.get('track'),
            speed: query?.get('speed'),
            object_id: query?.get('object_id'),
            zone_id: query?.get('zone_id'),
            zoom_to: query?.get('zoom_to'),
            zones: query?.get('zones'),
          };
          const filteredQueryParams = Object.fromEntries(
            Object.entries(queryParams).filter(([, value]) => value),
          );
          navigate(historyPath, { state: filteredQueryParams });
        } else {
          navigate(monitoring);
        }
      } else {
        dispatch(createAlert({ messageType: 'error', message: t('loginWrongError') }));
        navigate('/login');
      }
    }, 1000);
    return () => clearTimeout(timer);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, dispatch, t]);
  return (
    <div className={classes.loginContainer}>
      <div className={classes.logo}>
        <WelcomeLogo />
      </div>
      <div className={classes.spinerWrapper}>
        <CircularProgress size={26} />
      </div>
    </div>
  );
}

export default AuthByToken;
