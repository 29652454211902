/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const getLastHourBefore = () => {
  const currentDate = new Date().getTime();
  const hour = 60 * 60 * 1000;

  return currentDate - hour;
};

const initialState = {
  notificationsArray: [],
  lastId: '',
  timerID: null,
  updatePeriod: 1000,
  dateFrom: new Date(getLastHourBefore()),
  dateTo: new Date(),
  connectedMQTT: false,
  subbedMQTT: false,
  sosSoundPeriod: 30,
};

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    setConnectedMqtt(state, action) {
      state.connectedMQTT = action.payload;
    },
    setSubbedToMqtt(state, action) {
      state.subbedMQTT = action.payload;
    },
    setLastId(state, action) {
      state.lastId = action.payload;
    },
    setNotifications(state, action) {
      state.notificationsArray = JSON.parse(JSON.stringify(action.payload));
    },
    updateNotificationsPeriod(state) {
      state.dateFrom = new Date(getLastHourBefore());
      state.dateTo = new Date();
    },
  },
});

export const selectNotifications = (state) => state.notifications;

export const selectNotificationsArray = (state) => selectNotifications(state).notificationsArray;
export const selectNotificationsLastId = (state) => selectNotifications(state).lastId;
export const selectNotificationsTimerID = (state) => selectNotifications(state).timerID;
export const selectNotificationsUpdatePeriod = (state) => selectNotifications(state).updatePeriod;
export const selectNotificationsDateFrom = (state) => selectNotifications(state).dateFrom;
export const selectNotificationsDateTo = (state) => selectNotifications(state).dateTo;
export const selectNotificationsConnectedMQTT = (state) => selectNotifications(state).connectedMQTT;
export const selectNotificationsSubbedMQTT = (state) => selectNotifications(state).subbedMQTT;

export const {
  setConnectedMqtt, setSubbedToMqtt,
  setLastId, setNotifications,
  updateNotificationsPeriod,
} = notificationsSlice.actions;

export default notificationsSlice.reducer;
