import React from 'react';
import { makeStyles } from 'tss-react/mui';
import Fade from '@mui/material/Fade';

const useStyles = makeStyles()((materialTheme) => ({
  textLoading: {
    color: materialTheme.palette.primary.main,
  },
  fadeOut: {
    position: 'fixed',
    top: '90vh',
    width: materialTheme.CustomStyles.Logo.imageWidth,
    height: materialTheme.CustomStyles.Logo.imageHeight,
    background: materialTheme.CustomStyles.LogoImageBackground,
  },
  container: {
    position: 'fixed',
    marginTop: '40vh',
    fontSize: 'xx-large',
  },
}
));

function Loading() {
  const { classes } = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.textLoading}>Loading ...</div>
      <Fade in timeout={2000}>
        <div className={classes.fadeOut} alt="logo" />
      </Fade>
    </div>
  );
}

export default Loading;
