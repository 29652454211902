import React from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

function PaginationIcon() {
  return (
    <ExpandMoreIcon
      style={{
        fontSize: '15px',
        pointerEvents: 'none',
        position: 'relative',
        right: '20px',
        color: '#8194A4',
      }}
    />
  );
}

export default PaginationIcon;
