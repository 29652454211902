/* eslint-disable jsx-a11y/control-has-associated-label */
import React, {
  useEffect, useRef, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Backdrop from '@mui/material/Backdrop';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import CircularProgress from '@mui/material/CircularProgress';

import MainMenu from 'components/menu/MainMenu';
import DatePickers from 'components/reports/datePickers/datePickers.component';
import BuildButton from 'components/reports/BuildButton.component';
import LocationSelector from 'components/reports/ReportsLocationSelector';
import FloorSelector from 'components/reports/ReportsFloorSelector';
// import GroupSelector from 'components/reports/ReportsGroupSelector';
import ObjectsList from 'components/history/objectsList';
import ZoneSelector from 'components/reports/ReportsZoneSelector';

import { fetch3DModel } from 'store/actions/floors';
import { getHistoryList } from 'store/actions/history';
import { selectAppCurrentApp, selectAppOpenMenu } from 'store/slices/app';
import { selectCurrentFloor, selectFloorsObject, setFloor } from 'store/slices/floors';
import { setSelectedGroup } from 'store/slices/groups';
import {
  resetHistoryList, selectHistoryHeatmapLength,
  selectHistoryIsFetching, selectHistoryObject, selectHistoryObjectsList,
} from 'store/slices/history';
import { selectCurrentLocation, selectLocationsObject } from 'store/slices/locations';
import { selectReportsDateFrom, selectReportsDateTo } from 'store/slices/reports';
import {
  selectSelectedZone, selectZoneTypes, selectZonesMap, setSelectedZone,
} from 'store/slices/zones';

import HistoryPlayer from 'components/history/HistoryPlayer';
import {
  allValuesConstant, history as historyRoute, selectNoOneConstant,
} from 'constans';

import ThreeD from './3d';
import useStyles from './style';
import CircularProgressWithLabel from './progress';

const modelUrl = 'https://cdn.navigine.com/sublocations/3dmap-1520-1702889963043.glb';

function History3d() {
  const dispatch = useDispatch();
  const openMenu = useSelector(selectAppOpenMenu);
  const currentApp = useSelector(selectAppCurrentApp);
  const floorsObject = useSelector(selectFloorsObject);
  const currentFloor = useSelector(selectCurrentFloor);
  const frames = useSelector(selectHistoryObject);
  const objectsList = useSelector(selectHistoryObjectsList);
  const heatmapLength = useSelector(selectHistoryHeatmapLength);
  const isFetchingHistory = useSelector(selectHistoryIsFetching);
  const locationsObject = useSelector(selectLocationsObject);
  const currentLocation = useSelector(selectCurrentLocation);
  const dateFrom = useSelector(selectReportsDateFrom);
  const dateTo = useSelector(selectReportsDateTo);
  const zonesMap = useSelector(selectZonesMap);
  const selectedZone = useSelector(selectSelectedZone);
  const zoneTypes = useSelector(selectZoneTypes);

  const { classes } = useStyles();
  const { t } = useTranslation(['monitoring', 'history']);
  const navigate = useNavigate();

  const sceneElement = useRef();
  const three = useRef();
  const [show3D, setShow3D] = useState(true);
  const [progress, setProgress] = useState(0);
  const [showLoader, setShowLoader] = useState(false);
  const [showTrackLines, setShowTrackLines] = useState(true);
  const [playedPercentage, setPlayedPercentage] = useState(0);
  const [currentSecond, setCurrentSecond] = useState(0);
  const [timeoutId, setTimeoutId] = useState(null);
  const [playing, setPlaying] = useState(false);
  const [speed, setSpeed] = useState(16);
  const [appTtl, setAppTtl] = useState(60);

  const totalTime = (dateTo && dateFrom)
    ? parseInt(((dateTo.getTime() / 1000) - ((dateFrom.getTime() / 1000))), 10) : 1;
  const frameKeys = Object.keys(frames);
  const correctTimeToFetchHistory = (heatmapLength - (5 * speed)) < 1
    ? heatmapLength - Math.floor(heatmapLength / 2) : (5 * speed);
  const timeToFetchHistory = Object.keys(frames).reverse()[0] - correctTimeToFetchHistory;

  useEffect(() => {
    let evt;
    if (!floorsObject || !floorsObject[currentFloor.id]) {
      return undefined;
    }

    const { model } = floorsObject[currentFloor.id];

    const timer = setTimeout(() => {
      if (three.current instanceof ThreeD) { three.current.destroy(); }
      navigate(historyRoute);
    }, 2500);

    if (!model) {
      return undefined;
    }
    clearTimeout(timer);
    const resize = async (entries, observer) => {
      three.current = new ThreeD(sceneElement, false); // anti-alias false by default
      evt = three.current.onWindowResize;

      setShowLoader(true);
      setProgress(0);
      await three.current.downloadMapModel(model.full_url, setProgress);
      setProgress(0);
      await three.current.downloadObjectModel(modelUrl, setProgress);
      setShowLoader(false);

      observer.unobserve(sceneElement.current);
    };
    if (three.current instanceof ThreeD) {
      return undefined;
    }
    const obs = new ResizeObserver(resize);
    obs.observe(sceneElement.current);

    const cur = sceneElement.current;
    return () => {
      window.removeEventListener('resize', evt, false);
      window.cancelAnimationFrame(three.current.id);
      obs.unobserve(cur);
    };
  }, [currentFloor, floorsObject, navigate]);

  const handleChangeFloor = async (event) => {
    event.preventDefault();
    const floorId = event.target.value;
    dispatch(setFloor(floorsObject[floorId]));
    dispatch(setSelectedGroup({ id: allValuesConstant }));
    await dispatch(fetch3DModel(floorId));
  };

  const handleChangeZone = (event) => {
    event.preventDefault();
    const zoneId = event.target.value;

    const selectedAll = zoneId === allValuesConstant;
    const selectedNone = zoneId === selectNoOneConstant;

    if (selectedAll) {
      dispatch(setSelectedZone({ id: allValuesConstant }));
      [...zonesMap.values()].forEach((zone) => {
        three.current.highlightZone(zone);
      });
      three.current.setDefaultCameraPosition(three.current.scene);
    }

    if (selectedNone) {
      dispatch(setSelectedZone({ id: selectNoOneConstant }));
      [...zonesMap.values()].forEach((zone) => {
        three.current.removeHighlightZone(zone);
      });
      three.current.setDefaultCameraPosition(three.current.scene);
    }

    if (!selectedNone && !selectedAll) {
      dispatch(setSelectedZone(zonesMap.get(+zoneId)));
      three.current.highlightZone(zonesMap.get(+zoneId));
      [...zonesMap.values()].forEach((zone) => {
        if (zone.id !== +zoneId) {
          three.current.removeHighlightZone(zone);
        }
      });

      three.current.lookFromAbove(zonesMap.get(+zoneId).id);
    }
  };

  const stopPlayer = () => {
    setPlaying(false);
    clearTimeout(timeoutId);
  };

  const resetState = () => {
    stopPlayer();
    setCurrentSecond(0);
    setPlayedPercentage(0);
    if (three.current instanceof ThreeD) {
      three.current.disposeTrackLines();
    }
  };

  const updateHistory = async (moveTo) => {
    const offset = moveTo || currentSecond;
    await dispatch(getHistoryList({ offset, packet: null }));
  };

  useEffect(() => {
    if (three.current instanceof ThreeD) {
      if (showTrackLines) {
        three.current.drawTrackLines();
      } else {
        three.current.disposeTrackLines();
      }
    }
  }, [showTrackLines]);

  useEffect(() => {
    resetState();
    const { properties } = currentApp;
    const appTtlProp = properties.filter((prop) => prop.type === 'object_ttl')[0];
    let objTtl = 60;
    if (appTtlProp) {
      objTtl = parseInt(appTtlProp.value, 10);
    }
    setAppTtl(objTtl);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentApp]);

  useEffect(() => {
    if (currentSecond >= totalTime) {
      setPlaying(false);
      setPlayedPercentage(100);
      return;
    }
    const asyncFn = async () => {
      const currentFrame = frames[currentSecond];
      if (objectsList && three.current.trackList) {
        Object.values(objectsList).forEach((obj) => {
          const { id, title } = obj;
          const foundFrame = currentFrame.find((el) => el.id === id);
          if (!three.current.trackList[id] && foundFrame) {
            three.current.addObjectModel(id, foundFrame.kx, foundFrame.ky, title);
          }
          if (three.current.trackList[id] && foundFrame) {
            three.current.updateModelCoordinates(foundFrame.kx, foundFrame.ky, id, showTrackLines);
          }
        });
      }
      setTimeoutId(setTimeout(() => {
        setCurrentSecond((prevSecond) => prevSecond + 1);
      }, 1000 / speed));
      if (currentSecond === timeToFetchHistory) {
        await updateHistory();
      }
      setPlayedPercentage(totalTime ? ((currentSecond / (totalTime)) * 100) : 0);
    };

    if (playing && three.current instanceof ThreeD && frames[currentSecond]) {
      asyncFn();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSecond, frames, playing, speed, totalTime, timeToFetchHistory]);

  useEffect(() => {
    if (three.current instanceof ThreeD) {
      if (showTrackLines) {
        three.current.drawTrackLines();
      } else {
        three.current.disposeTrackLines();
      }
    }
  }, [showTrackLines, currentSecond]);

  const handleShow3D = () => {
    setShow3D((prev) => !prev);
    setTimeout(() => {
      if (three.current instanceof ThreeD) { three.current.destroy(); }
      navigate(historyRoute);
    }, 300);
  };

  const fetchHistory = async () => {
    dispatch(resetHistoryList());
    resetState();
    await dispatch(getHistoryList({ offset: null, packet: null }));
  };

  const moveTo = async (event) => {
    clearTimeout(timeoutId);
    const frameKeysCopy = Object.keys(frames);
    three.current.disposeTrackLines();
    if (!frameKeysCopy.length) {
      return;
    }
    // to - from
    const clientRect = event.currentTarget.getBoundingClientRect();
    const moveToSecond = Math.floor(((event.clientX - clientRect.left) / clientRect.width)
      * totalTime); // total = to - from
    if (moveToSecond && (!(moveToSecond in frameKeysCopy) || moveToSecond < currentSecond)) {
      setCurrentSecond(moveToSecond);
      setPlayedPercentage(totalTime ? ((moveToSecond / (totalTime)) * 100) : 0);
      await updateHistory(moveToSecond);
      return;
    }
    const renderFrameKeys = new Map();
    frameKeysCopy
      .filter((frameID) => frameID < moveToSecond && (moveToSecond - frameID) < appTtl)
      .forEach((frameID) => renderFrameKeys.set(frameID, frames[frameID]));
    const moveToObject = {};

    renderFrameKeys.forEach((leftFrame, leftFrameId) => {
      leftFrame.forEach((frameEl) => {
        moveToObject[frameEl.id] = { ...frameEl, lastFrame: leftFrameId };
      });
    });

    const moveToFrame = Object.values(moveToObject);
    if (moveToFrame.length > 0) {
      // await renderTrackedObjects(moveToFrame, moveToSecond);
    }

    setCurrentSecond(moveToSecond);
    setPlayedPercentage(totalTime ? ((moveToSecond / (totalTime)) * 100) : 0);
  };

  return (
    <div className={!openMenu ? classes.history : classes.noneContainer}>
      <MainMenu openMenu={openMenu} dispatch={dispatch}>
        <div className={classes.mapControls}>
          <section className={classes.control_wrapper}>
            <LocationSelector
              name="location-selector"
              locations={locationsObject}
              className={classes.selector}
              value={currentLocation.id}
            />
          </section>
          <section className={classes.control_wrapper}>
            <FloorSelector
              name="floor-selector"
              floors={floorsObject}
              className={classes.selector}
              onChange={handleChangeFloor}
              value={currentFloor.id}
              currentLocationId={currentLocation.id}
            />
          </section>
          <section className={classes.control_wrapper}>
            <ZoneSelector
              name="zone-selector"
              zones={zonesMap}
              className={classes.selector}
              onChange={handleChangeZone}
              value={selectedZone.id}
              zoneTypes={zoneTypes}
              currentFloorId={currentFloor.id}
            />
          </section>
          {/* <section className={classes.control_wrapper}>
            <GroupSelector
              name="group-selector"
              groups={groupsObject}
              className={classes.selector}
              // onChange={handleChangeGroup}
              value={selectedGroup.id || allValuesConstant}
            />
          </section> */}
          <section className={classes.switch_wrapper}>
            <FormControlLabel
              className={classes.switch_element_history}
              control={(
                <Switch
                  checked={show3D}
                  onChange={handleShow3D}
                  name="show 3d map"
                  color="primary"
                />
              )}
              label={t('show3d')}
            />
          </section>
          <section className={classes.switch_wrapper}>
            <FormControlLabel
              className={classes.switch_element_history}
              control={(
                <Switch
                  checked={showTrackLines}
                  onChange={() => setShowTrackLines((prev) => !prev)}
                  name="show track lines"
                  color="primary"
                />
              )}
              label={t('showTrackLines')}
            />
          </section>
          <DatePickers
            labelStyles={classes.historyDate}
            inputStyles={classes.historyInput}
          />
          <BuildButton
            className={classes.historyButton}
            variant="outlined"
            color="primary"
            disableRipple
            onClick={fetchHistory}
            disabled={isFetchingHistory}
          >
            {isFetchingHistory && <CircularProgress size={18} />}
            {!isFetchingHistory && t('history:apply')}
          </BuildButton>
          <ObjectsList
            objectsList={objectsList}
          />
        </div>

      </MainMenu>
      <div className={classes.sceneElement} ref={sceneElement}>
        <HistoryPlayer
          playedPercentage={playedPercentage}
          playing={playing}
          setPlaying={setPlaying}
          frameKeys={frameKeys}
          resetState={resetState}
          moveCallback={moveTo}
          speed={speed}
          setSpeed={setSpeed}
        />
      </div>
      <Backdrop className={classes.backdrop} open={showLoader}>
        <CircularProgressWithLabel value={progress} />
      </Backdrop>
    </div>
  );
}

export default History3d;
