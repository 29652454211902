import { withStyles } from 'tss-react/mui';
import TextField from '@mui/material/TextField';

const CustomSelector = withStyles(TextField, (materialTheme) => {
  // eslint-disable-next-line no-unused-vars
  const { fontRegular } = materialTheme;

  return {
    root: {
      '& label': {
        fontSize: '19px',
        color: materialTheme.CustomStyles.GlobalTextColor,
        marginLeft: '20px',
      },
      '& .MuiInputBase-root': {
        '& .MuiSelect-icon': {
          marginRight: '8px',
        },
        '& .MuiSelect-select.MuiSelect-select': {
          marginLeft: '20px',
          paddingRight: '15px',
          marginTop: '3px',
          fontSize: '18px',
          color: materialTheme.CustomStyles.GlobalTextColor,
          textAlign: 'left',
          width: '100%',
        },
        '& .MuiInputBase-input': {
          marginLeft: '20px',
        },
      },
    },

  };
});

export default CustomSelector;
