import React from 'react';
import { useTranslation } from 'react-i18next';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';

import useStyles from 'containers/monitoring/style';

function DailogArrayHistory({
  handleHistoricObjectData,
  objectInfoArray,
  openArrayModal,
  setObjectInfo,
  setOpenArrayModal,
  setOpenObjectModal,
  TransitionComponent,
}) {
  const { classes } = useStyles();
  const { t } = useTranslation(['monitoring']);

  const listOnClickCallback = (event) => {
    event.preventDefault();
    objectInfoArray.forEach((obj) => {
      if (obj.title === event.target.innerHTML) {
        setObjectInfo(obj);
        handleHistoricObjectData(obj);
        setOpenArrayModal(false);
        setOpenObjectModal(true);
      }
    });
  };

  const closeDialog = () => {
    setOpenArrayModal(false);
  };

  return (
    <Dialog
      open={openArrayModal}
      TransitionComponent={TransitionComponent}
      keepMounted
      maxWidth="md"
      onClose={closeDialog}
    >
      <DialogContent className={classes.noneDialogContent}>
        <TableContainer>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>
                  {t('numbersObjects')}
                  :
                  {' '}
                  {objectInfoArray.length}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        {objectInfoArray.map((prop, index) => (
        // eslint-disable-next-line react/no-array-index-key
          <TableContainer key={index.toString()}>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell
                    className={classes.listItemTitle}
                    onClick={listOnClickCallback}
                  >
                    {prop.title}
                  </TableCell>
                  <TableCell
                    className={classes.listItems}
                  >
                    {prop.file_url ? (
                      <div>
                        <img
                          className={classes.listImg}
                          src={prop.file_url}
                          alt="file_url"
                        />
                      </div>
                    ) : <div className={classes.circle} />}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>

        ))}
      </DialogContent>
    </Dialog>
  );
}

export default DailogArrayHistory;
