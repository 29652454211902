/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { createConditionsSocial, fetchConditionsSocial } from 'store/actions/notifications.social';

const initialState = {
  conditionsSocial: [],
  conditionSocialTemporary: {
    channel: 'Email',
    destination: '',
    isValid: false,
  },
  subject: undefined,
  isCreating: false,
};

export const NOTIFICATION_CHANNELS = {
  Email: 1,
  Telegram: 2,
  WhatsApp: 3,
  SMS: 4,
};

const notificationsSocialSlice = createSlice({
  name: 'notificationsSocial',
  initialState,
  reducers: {
    setConditionsSocial(state, action) {
      state.conditionsSocial = action.payload || [];
    },
    setConditionSocialSubject(state, action) {
      state.subject = action.payload;
    },
    setTemporaryConditionSocial(state, action) {
      const { channel, destination, isValid } = action.payload;
      state.conditionSocialTemporary = {
        channel: channel || state.conditionSocialTemporary.channel,
        destination,
        isValid: isValid || false,
      };
    },
    resetConditionsSocial(state) {
      state.conditionsSocial = initialState.conditionsSocial;
    },
    resetTemporaryConditionsSocial(state) {
      state.subject = initialState.subject;
      state.conditionSocialTemporary = initialState.conditionSocialTemporary;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchConditionsSocial.fulfilled, (state, action) => {
      state.conditionsSocial = action.payload || [];
    });
    builder.addCase(createConditionsSocial.pending, (state) => {
      state.isCreating = true;
    });
    builder.addCase(createConditionsSocial.fulfilled, (state) => {
      state.isCreating = false;
    });
    builder.addCase(createConditionsSocial.rejected, (state) => {
      state.isCreating = false;
    });
  },
});

export const selectConditionsSocial = (state) => state.notificationsSocial;
export const selectConditionSocialSubject = (state) => selectConditionsSocial(state).subject;
export const selectSocialConditionsArray = (
  state,
) => selectConditionsSocial(state).conditionsSocial;
export const selectTemporaryConditionSocial = (
  state,
) => selectConditionsSocial(state).conditionSocialTemporary;
export const selectConditionSocialCreating = (state) => selectConditionsSocial(state).isCreating;

export const {
  setConditionsSocial,
  setConditionSocialSubject,
  setTemporaryConditionSocial,
  resetConditionsSocial,
  resetTemporaryConditionsSocial,
} = notificationsSocialSlice.actions;

export default notificationsSocialSlice.reducer;
