import {
  MathUtils,
  Texture,
  Vector2,
  Vector3,
} from 'three';

const Class = class extends Texture {
  constructor() {
    super(document.createElement('canvas'));
    let drawing = null;
    const getDrawing = () => {
      if (drawing === null) {
        drawing = this.createDrawing();
      }
      return drawing;
    };
    const getWidth = (() => getDrawing().width);
    const getHeight = (() => getDrawing().height);
    const getSize = ((target) => {
      target.set(getWidth(), getHeight());
      return target;
    });
    const draw = ((...args) => getDrawing().draw(...args));

    let needsRedraw = true;

    let pixelRatio = 1;

    const getDrawingBufferWidth = (() => MathUtils.ceilPowerOfTwo(getWidth() * pixelRatio));
    const getDrawingBufferHeight = (() => MathUtils.ceilPowerOfTwo(getHeight() * pixelRatio));

    const setPixelRatio = ((value) => {
      if (pixelRatio !== value) {
        const oldWidth = getDrawingBufferWidth();
        const oldHeight = getDrawingBufferHeight();
        pixelRatio = value;
        const newWidth = getDrawingBufferWidth();
        const newHeight = getDrawingBufferHeight();
        if ((newWidth !== oldWidth) || (newHeight !== oldHeight)) {
          needsRedraw = true;
        }
      }
    });

    const computeOptimalPixelRatio = (() => {
      const cameraPosition = new Vector3();
      const rendererSize = new Vector2();
      const objectPosition = new Vector3();
      const objectScale = new Vector3();
      const textureSize = new Vector2();
      return ((object, renderer, camera) => {
        getSize(textureSize);
        if (textureSize.x && textureSize.y) {
          object.getWorldPosition(objectPosition);
          camera.getWorldPosition(cameraPosition);
          let distance = objectPosition.distanceTo(cameraPosition);
          if (camera.isPerspectiveCamera) {
            distance *= Math.tan(MathUtils.degToRad(camera.fov) / 2) * 2;
          }
          if (camera.isPerspectiveCamera || camera.isOrthographicCamera) {
            distance /= camera.zoom;
          }
          if (distance) {
            object.getWorldScale(objectScale);
            const maxTextureSize = renderer.capabilities?.maxTextureSize ?? Infinity;
            renderer.getDrawingBufferSize(rendererSize);
            return Math.min(
              Math.max(
                (objectScale.x / distance) * (rendererSize.x / textureSize.x),
                (objectScale.y / distance) * (rendererSize.y / textureSize.y),
              ),
              maxTextureSize / textureSize.x,
              maxTextureSize / textureSize.y,
            );
          }
        }
        return 0;
      });
    })();

    Object.defineProperties(this, {
      width: {
        get: getWidth,
      },
      height: {
        get: getHeight,
      },
      pixelRatio: {
        get() {
          return pixelRatio;
        },
        set: setPixelRatio,
      },
      needsRedraw: {
        set(value) {
          if (value) {
            needsRedraw = true;
            drawing = null;
          }
        },
      },
    });
    Object.assign(this, {
      redraw() {
        if (needsRedraw) {
          const canvas = this.image;
          const ctx = canvas.getContext('2d');
          ctx.clearRect(0, 0, canvas.width, canvas.height);
          canvas.width = getDrawingBufferWidth();
          canvas.height = getDrawingBufferHeight();
          if (canvas.width && canvas.height) {
            ctx.save();
            ctx.scale(canvas.width / getWidth(), canvas.height / getHeight());
            draw(ctx);
            ctx.restore();
          } else {
            // eslint-disable-next-line no-multi-assign
            canvas.width = canvas.height = 1;
          }
          needsRedraw = false;
          this.needsUpdate = true;
        }
      },

      setOptimalPixelRatio(...args) {
        setPixelRatio(computeOptimalPixelRatio(...args));
      },
    });
  }
};

Class.prototype.isDynamicTexture = true;

export default Class;
