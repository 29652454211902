/* eslint-disable no-param-reassign */
import request, { endPointsEnum } from 'services/http';
import { addCustomProperty } from 'store/slices/settings';
import { createDevice } from 'store/actions/devices';
import { setDevicesModalForm } from 'store/slices/devices';
import {
  staticTypeId,
  dynamicTypeId,
  oldDevice,
  formTypeCreate,
} from 'constans';
import { setCurrentObject } from 'store/slices/reports';
import { createAlert } from 'store/slices/alert';
import {
  currentObjectSet, deleteObjectFetchEnd, deleteObjectFetchStart,
  fetchCreateObjectEnd, fetchCreateObjectStart, setObjectsByFloor,
  setObjectsListByApp, setobjectsInMonitoringArray,
} from 'store/slices/objects';

export const objectsByAppFetch = (
  objectTitleFilter = '',
  offset = 0,
  limit = 10,
  signal = null,
) => async (dispatch, getState) => {
  try {
    const state = getState();
    const { api_key: apiKey } = state.app.currentApp;
    if (!apiKey) {
      return;
    }

    const result = await request.tracking.get(
      endPointsEnum.trackedObjectList,
      {
        params: {
          'page[offset]': offset,
          'page[limit]': limit,
          'like[title][]': objectTitleFilter || '',
          'like[group.title][]': objectTitleFilter || '',
          api_key: apiKey,
        },
        abortSignalController: signal,
      },
    );
    if (!result.data) {
      throw new Error('Error in request');
    }
    dispatch(setObjectsListByApp(result.data.reverse()));
  } catch (error) {
    dispatch(createAlert({ messageType: 'error', message: error.message }));
    // eslint-disable-next-line no-console
    console.warn(`Cannot get tracked objects: ${error.message}`);
  }
};
export const deleteObject = (objectToDelete, translate) => async (dispatch, getState) => {
  try {
    const state = getState();
    const { api_key: apiKey } = state.app.currentApp;
    if (!objectToDelete.id) {
      return;
    }
    dispatch(deleteObjectFetchStart());
    const path = `${endPointsEnum.trackedObjects}/${objectToDelete.id}`;
    const params = {
      api_key: apiKey,
    };
    await request.tracking.delete(path, { params });

    await dispatch(objectsByAppFetch());
    await dispatch(setCurrentObject(null));

    dispatch(deleteObjectFetchEnd());
    dispatch(createAlert({ messageType: 'info', message: translate('ObjectDeleteAlert') }));
  } catch (error) {
    dispatch(deleteObjectFetchEnd());
    // eslint-disable-next-line no-console
    console.warn(`Error in deleting object: ${error.message}`);
    dispatch(createAlert({ messageType: 'error', message: translate('objectDeleteError') }));
  }
};

export const createObject = (translate) => async (dispatch, getState) => {
  try {
    const state = getState();
    const appStore = state.app;
    const { api_key: apiKey } = appStore.currentApp;
    const {
      objectUpdateId,
      formType,
      title,
      group,
      type,
      deviceType,
      device,
      newDeviceMac,
      floor,
      x,
      y,
      cameraUrl,
      iconImageUrl,
      image,
      color,
      properties,
      objectColor,
      sizeObject,
    } = state.settings.modal;

    const {
      customProps,
    } = state.settings;

    if (!apiKey) {
      throw new Error();
    }

    const body = {
      attributes: {
        title,
        videostream_url: cameraUrl,
        url: iconImageUrl,
        file: image,
        color,
        objectColor,
      },
      properties: { sizeObject },
      relationships: {
        'tracked-beacon': {
          data: {},
        },
        'tracked-group': {
          data: {
            id: group.id,
            type: 'tracked-groups',
          },
        },
      },
    };
    properties.forEach((prop) => {
      if (prop.key !== 'sizeObject') {
        body.properties[prop.key] = prop.value;
      }
    });
    customProps.forEach((prop) => {
      body.properties[prop.key] = prop.value;
    });
    if (type === dynamicTypeId) {
      let deviceId;
      if (deviceType === oldDevice) {
        deviceId = device.id;
        body.attributes.beaconType = 'old';
      } else {
        const deviceform = {
          title,
          mac: newDeviceMac,
        };
        dispatch(setDevicesModalForm(deviceform));
        const resultDevice = await dispatch(createDevice());
        deviceId = resultDevice.id;
        body.attributes.beaconType = 'new';
        body.attributes.mac_address = newDeviceMac;
      }
      body.attributes = {
        ...body.attributes,
        objectType: type,
        tracked_object_type_id: dynamicTypeId,
      };
      body.relationships['tracked-beacon'].data = {
        id: deviceId,
        type: 'tracked-beacons',
      };
    }
    if (type === staticTypeId) {
      body.attributes = {
        ...body.attributes,
        kx: x / floor.w,
        ky: y / floor.h,
        objectType: 'static',
        sublocation_id: parseInt(floor.id, 10),
        tracked_object_type_id: staticTypeId,
      };
    }
    dispatch(fetchCreateObjectStart());
    const requestBody = { ...body, api_key: apiKey };

    if (formType === formTypeCreate) {
      const result = await request.tracking.post(endPointsEnum.trackedObjects, {
        body: requestBody,
      });
      if (!result.data) {
        throw new Error('Error in request');
      }
      dispatch(createAlert({ messageType: 'info', message: translate('ObjectCreatedAlert') }));
    } else {
      const path = `${endPointsEnum.trackedObjects}/${objectUpdateId}`;
      const result = await request.tracking.put(path, { body: requestBody });
      dispatch(addCustomProperty(result.data.properties));
      if (!result.data) {
        throw new Error('Error in request');
      }
      const { id } = result.data;
      dispatch(currentObjectSet(+id));
      dispatch(createAlert({ messageType: 'info', message: translate('ObjectEditedAlert') }));
    }

    dispatch(fetchCreateObjectEnd());
  } catch (error) {
    dispatch(fetchCreateObjectEnd());
    dispatch(createAlert({ messageType: 'error', message: translate('objectMainModalError') }));
  }
};

export const patchObject = (translate) => async (dispatch, getState) => {
  try {
    const state = getState();
    const appStore = state.app;
    const { api_key: apiKey } = appStore.currentApp;

    if (!apiKey) {
      throw new Error();
    }
    const {
      customProps,
    } = state.settings;
    const {
      objectUpdateId,
      title,
      group,
      cameraUrl,
      iconImageUrl,
      image,
      color,
      properties,
      objectColor,
    } = state.settings.modal;

    const body = {
      attributes: {
        title,
        videostream_url: cameraUrl,
        url: iconImageUrl,
        file: image,
        color,
        objectColor,
      },
      properties: {},
      relationships: {
        'tracked-group': {
          data: {
            id: group.id,
            type: 'tracked-groups',
          },
        },
      },
    };
    properties.forEach((prop) => {
      body.properties[prop.key] = prop.value;
    });
    customProps.forEach((prop) => {
      body.properties[prop.key] = prop.value;
    });
    dispatch(fetchCreateObjectStart());
    const path = `${endPointsEnum.trackedObjects}/${objectUpdateId}`;
    const requestBody = { ...body, api_key: apiKey };
    const result = await request.tracking.patch(path, { body: requestBody });
    dispatch(addCustomProperty(result.data.properties));
    if (!result.data) {
      throw new Error('Error in request');
    }
    const { id } = result.data;
    dispatch(currentObjectSet(+id));
    dispatch(createAlert({ messageType: 'info', message: translate('ObjectEditedAlert') }));

    dispatch(fetchCreateObjectEnd());
  } catch (error) {
    dispatch(fetchCreateObjectEnd());
    dispatch(createAlert({ messageType: 'error', message: translate('objectMainModalError') }));
  }
};

export const objectsFetch = () => async (dispatch, getState) => {
  try {
    const state = getState();
    const { api_key: apiKey, properties } = state.app.currentApp;
    const { objectTTL } = state.worker;
    if (!apiKey || !objectTTL) {
      return;
    }
    const objTtl = properties.filter((obj) => obj.type === 'object_ttl')[0];
    const response = await request.tracking.get(
      `${endPointsEnum.trackedObjects}/${endPointsEnum.trackedObjectsGetAll}`,
      {
        params: {
          actv: objTtl ? objTtl.value : objectTTL,
          api_key: apiKey,
          // "filter%5Bsublocation.id%5D": 6164,
        },
      },
    );
    const result = response.data || [];
    dispatch(setobjectsInMonitoringArray(result));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.warn(`Cannot get tracked objects: ${error.message}`);
  }
};

export const unlinkDevice = () => async (dispatch, getState) => {
  try {
    const state = getState();
    const { currentObject } = state.objects;
    const body = {
      api_key: state.app.currentApp.api_key,
    };

    const response = await request.tracking.patch(
      endPointsEnum.unlinkDevice + currentObject,
      { body },
    );
    if (!response) {
      throw new Error('Error in response');
    }
  } catch (error) {
    dispatch(createAlert({ messageType: 'error', message: error.message }));
  }
};

export const objectsFetchByFloor = (value) => async (dispatch, getState) => {
  try {
    const state = getState();
    const { api_key: apiKey, properties } = state.app.currentApp;
    const { objectTTL } = state.worker;
    if (!apiKey || !objectTTL) {
      return;
    }
    const objTtl = properties.filter((obj) => obj.type === 'object_ttl')[0];
    const response = await request.tracking.get(
      `${endPointsEnum.trackedObjects}`,
      {
        params: {
          actv: objTtl ? objTtl.value : objectTTL,
          api_key: apiKey,
          'filter[sublocation.id]': value,
        },
      },
    );
    const result = response.data || [];
    dispatch(setObjectsByFloor(result));
  } catch (error) {
    dispatch(createAlert({ messageType: 'error', message: error.message }));
  }
};
