import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import OpenLayersMap from '@navigine/openlayers-navigine';
import * as FileSaver from 'file-saver';

import FormControl from '@mui/material/FormControl';
import CircularProgress from '@mui/material/CircularProgress';
import { Typography } from '@mui/material';

import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import {
  allValuesConstant, selectNoOneConstant,
} from 'constans';
import { objectsByAppFetch } from 'store/actions/objects';
import { getGetHeatmapsReport } from 'store/actions/reports';
import { selectAppCurrentApp, selectAppOpenMenu } from 'store/slices/app';
import { selectCurrentFloor, selectFloorsObject, setFloor } from 'store/slices/floors';
import { setSelectedGroup } from 'store/slices/groups';
import { selectCurrentLocation, selectLocationsObject, setLocation } from 'store/slices/locations';
import { selectObjectsByAppArray } from 'store/slices/objects';
import {
  selectReportsAlertReportLimit, selectReportsCurrentObject,
  selectReportsCurrentPage, selectReportsDateFrom, selectReportsDateTo,
  selectReportsIsFetchingHistoryPicReport, setAlertPage,
  setCurrentObject,
} from 'store/slices/reports';
import { setSelectedZone } from 'store/slices/zones';

import { HeatmapComponent } from '@navigine/ts-map';
import checkObjectInWindowConfig from 'helpers/checkEnv';
import BuildButton from 'components/reports/BuildButton.component';
import DatePickers from 'components/reports/datePickers/datePickers.component';
import ReportsAutoComplete from '../ReportsAutoComplete';
import ReportsLocationSelector from '../ReportsLocationSelector';
import ReportsFloorSelector from '../ReportsFloorSelector';
import containerStyles from '../../shared/Container/styles';
import useStyles from './HeatmapReport.styles';

function HeatmapReport() {
  const { classes: classesContainer } = containerStyles();
  const { classes } = useStyles();
  const { t } = useTranslation(['report', 'notifications', 'monitoring']);
  // eslint-disable-next-line no-unused-vars
  const [map, setMap] = useState({});
  const dispatch = useDispatch();
  const openMenu = useSelector(selectAppOpenMenu);
  const currentApp = useSelector(selectAppCurrentApp);
  const floorsObject = useSelector(selectFloorsObject);
  const currentFloor = useSelector(selectCurrentFloor);
  const locationsObject = useSelector(selectLocationsObject);
  const currentLocation = useSelector(selectCurrentLocation);
  const objectsByAppArray = useSelector(selectObjectsByAppArray);
  const isLoadingReport = useSelector(selectReportsIsFetchingHistoryPicReport);
  const currentObject = useSelector(selectReportsCurrentObject);
  const alertReportLimit = useSelector(selectReportsAlertReportLimit);
  const dateFrom = useSelector(selectReportsDateFrom);
  const dateTo = useSelector(selectReportsDateTo);
  const currentPage = useSelector(selectReportsCurrentPage);

  const mapElement = useRef();
  const downloadElement = useRef();
  const parentMapElement = useRef();
  const [dwndBtn, setDwndBtn] = useState(false);
  const [displayState, setDisplayState] = useState(true);
  const [heatmaps, setHeatmaps] = useState(true);

  const downloadMap = async () => {
    const {
      pw: imgWidth,
      ph: imgHeight,
      image_url: imageUrl,
    } = currentFloor;
    // Creat canvas
    const canvas = document.createElement('canvas');
    canvas.style.width = `${imgWidth}px`;
    canvas.style.height = `${imgHeight}px`;
    canvas.style.display = 'block';
    canvas.style.position = 'absolute';
    canvas.style.top = '-99999px';
    // add to DOM
    parentMapElement.current.appendChild(canvas);
    const ctx = canvas.getContext('2d');
    ctx.canvas.width = imgWidth;
    ctx.canvas.height = imgHeight;
    const mapImage = new Image(imgWidth, imgHeight);

    const addImageProcess = () => new Promise((resolve, reject) => {
      mapImage.onload = () => resolve(mapImage.height);
      mapImage.onerror = reject;
    });
    mapImage.src = imageUrl;
    mapImage.setAttribute('crossorigin', 'anonymous');
    await addImageProcess();
    ctx.drawImage(mapImage, 0, 0); // Or at whatever offset you like

    const dateFormat = 'dd-MM-yyyy HH:mm:ss';
    const legendArray = 1;
    ctx.globalAlpha = 0.8;
    const zeroWidth = 0;
    const zeroHeight = 0;
    const rectWidth = imgWidth * 0.3;
    const rowCound = legendArray.length < 4 ? 5 : legendArray.length + 1;
    const textScale = imgHeight * 0.011;
    const textHeight = 24 + textScale;
    const textMargin = 15 + textScale;
    ctx.font = `${textHeight}px Regular`;
    const rectHeight = rowCound * (textHeight + textMargin);
    ctx.fillStyle = '#fffbfb';
    ctx.fillRect(
      zeroWidth,
      zeroHeight,
      rectWidth,
      rectHeight,
    );
    let phraseOffset = 0;
    const leftCommonMargin = textHeight;
    const legInfoOffValueX = leftCommonMargin * 7.5;
    const commonTopMargin = zeroHeight + rectHeight * 0.03;
    const legDetinfo = {
      from: {
        key: t('from'),
        value: format(dateFrom, dateFormat),
      },
      to: {
        key: t('to'),
        value: format(dateTo, dateFormat),
      },
      location: {
        key: t('monitoring:locationSelectorLabel'),
        value: currentLocation.title,
      },
      floor: {
        key: t('monitoring:floorSelectorLabel'),
        value: currentFloor.title,
      },
      object: {
        key: `${t('Objects')}`,
        value: '',
      },
    };
    ctx.fillStyle = '#000000';
    Object.values(legDetinfo).forEach(({ key, value }) => {
      const topOffset = commonTopMargin + phraseOffset + 10;
      ctx.fillText(
        `${key}:`,
        leftCommonMargin,
        topOffset + textMargin,
      );
      ctx.fillText(
        value,
        legInfoOffValueX,
        topOffset + textMargin,
      );
      phraseOffset += textHeight;
    });

    const fullQuality = canvas.toDataURL('image/png', 1.0);
    FileSaver.saveAs(fullQuality, 'screenshot.png');
    canvas.remove();
  };

  const servers = {
    api: checkObjectInWindowConfig('NODE_URL')
              || 'https://api.stage.navigine.com/client',
    imt: `${checkObjectInWindowConfig('API_URL')}`
              || 'https://api.stage.navigine.com/tracking',
    auth: `${checkObjectInWindowConfig('AUTH_URL')}`
              || 'https://api.stage.navigine.com/auth',
  };

  // eslint-disable-next-line no-underscore-dangle
  const nodeUrl = window._configuration_env.NODE_URL;

  const mqttConfig = nodeUrl === 'https://ips.navigine.com/client'
    ? {
      login: 'nprod',
      password: 'RTSfvvEknJHFwbc',
      domain: 'rtls.navigine.com',
      port: 15676,
      topic: 'qtracker_output_mqtt',
    }
    : {
      login: 'front',
      password: 'j3wTt2EKf7',
      domain: 'stage.navigine.com',
      port: 15676,
      topic: 'qtracker_output_mqtt',
    };
  const objConfig = {
    trackLines: false,
    filterGroupId: 1,
  };
  const buildMapReport = async () => {
    const { id: floorId } = currentFloor;
    // eslint-disable-next-line max-len
    const heatmapsArray = await dispatch(getGetHeatmapsReport(dispatch, floorId, dateFrom, dateTo, currentObject));
    setHeatmaps(heatmapsArray.heatmaps);
    setDwndBtn(true);
    if (!mapElement.current) {
      return;
    }
    if (map instanceof OpenLayersMap) {
      map.destroyMap();
    }

    // ResizeObserver is needed to check if a map item is missing in the viewport
    // otherwise, the map will be created with a blank canvas
  };

  const fetchReportsClick = async () => {
    await buildMapReport();
  };

  const selectObject = (event, object) => {
    dispatch(setCurrentObject(object));
  };

  const [objectsFilter, setObjectsFilter] = useState('');

  const handleChangeLocation = (event) => {
    const locationId = event.target.value;
    dispatch(setLocation(locationsObject[locationId]));
    const floorId = locationsObject[locationId].floors[0].id;
    dispatch(setFloor(floorsObject[floorId]));
    dispatch(setSelectedZone({ id: selectNoOneConstant }));
    dispatch(setSelectedGroup({ id: allValuesConstant }));
    setDwndBtn(false);
    if (map instanceof OpenLayersMap) {
      map.destroyMap();
    }
  };

  const handleChangeFloor = (event) => {
    const floorId = event.target.value;
    dispatch(setFloor(floorsObject[floorId]));
    dispatch(setSelectedZone({ id: selectNoOneConstant }));
    dispatch(setSelectedGroup({ id: allValuesConstant }));
    setDwndBtn(false);
    if (map instanceof OpenLayersMap) {
      map.destroyMap();
    }
  };

  useEffect(() => {
    const getObjects = async () => {
      await dispatch(objectsByAppFetch(objectsFilter, 0, 4000));
    };

    getObjects();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [objectsFilter, currentPage, alertReportLimit, currentApp]);

  useEffect(() => {
    dispatch(setAlertPage(0));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [objectsFilter, alertReportLimit, currentApp]);

  const onAutocompliteInput = async (value) => {
    setObjectsFilter(value);
  };
  const displayFilters = () => {
    setDisplayState(!displayState);
  };
  const floorsObjectFiltred = Object
    .values(floorsObject)
    .filter((object) => object.location === currentLocation.id);

  useEffect(() => {
    // Обнаруживаем момент, когда компонент добавлен в DOM
    const mapContainer = document.getElementById('map');

    // Применяем стили
    if (mapContainer) {
      mapContainer.style.width = '100%';
      mapContainer.style.height = '100%';
    }
  }, []);

  const userToken = localStorage.getItem('userToken');

  return (
    // eslint-disable-next-line react/jsx-filename-extension
    <div className={!openMenu ? classes.content : classesContainer.noneContent}>
      <div className={classes.topControls}>
        <div className={displayState ? classes.formControls : classes.formControlsNo}>
          <form className={classes.formControls} noValidate>
            <div className={classes.controlWrapper}>
              <FormControl variant="standard">
                <ReportsLocationSelector
                  locations={locationsObject}
                  className={classes.selector}
                  value={currentLocation.id}
                  onChange={handleChangeLocation}
                />
              </FormControl>
            </div>
            <div className={classes.controlWrapper}>
              <ReportsFloorSelector
                floors={floorsObjectFiltred}
                className={classes.selector}
                onChange={handleChangeFloor}
                value={currentFloor.id}
                currentLocationId={currentLocation.id}
              />
            </div>
          </form>
          <form className={classes.formControls} noValidate>
            <div className={classes.selectorWrapp}>
              <ReportsAutoComplete
                className={classes.selectorObject}
                filteredObjectsArray={objectsByAppArray}
                currentObject={currentObject}
                selectObject={selectObject}
                onInputChange={onAutocompliteInput}
                bkgColor="#ffffff"
              />
            </div>
            <DatePickers />
          </form>
          <div className={classes.headContainer}>
            <div>
              <Typography variant="h6">{t('translation:heatmapReport')}</Typography>
            </div>
            <div className={classes.btnWrapper}>
              <BuildButton
                variant="outlined"
                color="primary"
                disableRipple
                onClick={fetchReportsClick}
                disabled={isLoadingReport}
              >
                {t('buildReport')}
              </BuildButton>
              {map instanceof OpenLayersMap && (
              // eslint-disable-next-line jsx-a11y/interactive-supports-focus
              <div
                className={dwndBtn ? classes.downloadControlContainer
                  : classes.downLoadPhraseContainerNull}
                onClick={downloadMap}
                role="button"
                onKeyUp={downloadMap}
                ref={downloadElement}
                style={{ display: currentApp.role === 'demo' ? 'none' : null }}
              >
                <CloudDownloadIcon
                  fontSize="large"
                />
                <div className={classes.downLoadPhraseContainer}>
                  <p className={classes.downLoadPhrase}>
                    {' '}
                    {t('downloadPng')}
                    {' '}
                  </p>
                </div>
              </div>
              )}
            </div>
          </div>
        </div>
        { displayState ? <ExpandLessIcon color="primary" style={{ margin: '0 auto' }} onClick={displayFilters} />
          : <ExpandMoreIcon color="primary" style={{ margin: '0 auto' }} onClick={displayFilters} />}
      </div>
      {isLoadingReport && (
      <div className={classes.spinerWrapper}>
        <CircularProgress size={26} />
      </div>
      )}
      {heatmaps.length > 0}
      <div className={classes.mapContainer} style={{ display: heatmaps.length > 0 ? 'block' : 'none' }}>
        {userToken && servers && currentFloor
          && currentApp && currentLocation && mqttConfig && objConfig ? (
            <HeatmapComponent
              userToken={userToken}
              servers={servers}
              floorId={currentFloor.id}
              appId={currentApp.id}
              locationId={currentLocation.id}
              mqttConfig={mqttConfig}
              objConfig={objConfig}
              setZones={() => { }}
              heatmaps={heatmaps}
            />
          ) : (
            <p>Loading...</p>
          )}
      </div>

    </div>
  );
}

export default HeatmapReport;
