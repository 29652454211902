import request, { endPointsEnum } from 'services/http';

import { createAlert, alertTypes } from 'store/slices/alert';
import { formTypeCreate } from 'constans';
import {
  closeDeleteGroupModal,
  closeSettingsModal,
  groupModalFetchEnd, groupModalFetchStart,
  setSettingsModalTitle, setUpdateGroupId,
} from 'store/slices/groups';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const fetchGroups = createAsyncThunk(
  'groups/fetchGroups',
  async (
    {
      page = 0, limit = 300, signal = null, desc = 'true',
    },
    { dispatch, getState, rejectWithValue },
  ) => {
    try {
      const state = getState();
      const params = {
        api_key: state.app.currentApp.api_key,
        'page[offset]': page,
        'page[limit]': limit,
        desc,
      };
      const {
        isFetching, groupsListInSettings, groupsCount,
      } = state.groups;
      if (isFetching !== 'succeeded') {
        const result = await request.tracking
          .get(endPointsEnum.trackedGroups, { params, abortSignalController: signal });
        return result;
      }
      return { data: groupsListInSettings, count: groupsCount };
    } catch (error) {
      dispatch(createAlert({ messageType: 'error', message: error.message }));
      return rejectWithValue();
    }
  },
);

export const settingsGroupModalAction = (translate) => async (dispatch, getState) => {
  try {
    const state = getState();
    const {
      formType,
      modalTitle,
      updatedGroupId,
    } = state.groups;
    const { api_key: apiKey } = state.app.currentApp;

    const body = {
      attributes: {
        title: modalTitle,
      },
      api_key: apiKey,
    };

    dispatch(groupModalFetchStart());
    if (formType === formTypeCreate) {
      const result = await request.tracking.post(endPointsEnum.trackedGroups, { body });
      if (!result.data) {
        throw new Error('Error in request');
      }
      dispatch(createAlert({ messageType: alertTypes.info, message: translate('groupCreatedAlert') }));
    } else {
      const path = `${endPointsEnum.trackedGroups}/${updatedGroupId}`;
      const result = await request.tracking.patch(path, { body });
      if (!result.data) {
        throw new Error('Error in request');
      }
      dispatch(setUpdateGroupId(null));
      dispatch(createAlert({ messageType: alertTypes.info, message: translate('groupEditedAlert') }));
    }
    dispatch(closeSettingsModal());
    dispatch(setSettingsModalTitle(''));
    dispatch(groupModalFetchEnd());
  } catch (error) {
    dispatch(groupModalFetchEnd());
    dispatch(createAlert({ messageType: alertTypes.err, message: translate('groupMainModalError') }));
  }
};

export const deleteGroup = (group, translate) => async (dispatch, getState) => {
  try {
    const state = getState();
    const { api_key: apiKey } = state.app.currentApp;
    if (!group.id || !apiKey) {
      return;
    }
    const path = `${endPointsEnum.trackedGroups}/${group.id}`;
    const params = {
      api_key: apiKey,
    };
    await dispatch(groupModalFetchStart());
    await request.tracking.delete(path, { params });
    dispatch(createAlert({ messageType: 'info', message: translate('groupDeleteAlert') }));
    await dispatch(fetchGroups({ page: 0, limit: 300 }));
    dispatch(groupModalFetchEnd());
    dispatch(closeDeleteGroupModal());
  } catch (error) {
    dispatch(groupModalFetchEnd());
    dispatch(createAlert({ messageType: 'error', message: translate('groupDeleteError') }));
  }
};
