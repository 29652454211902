import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { selectHistoryObjectHistoric, selectIsFetchingObjectHistoric } from 'store/slices/history';

import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CustomButton from 'components/settings/Button.component';

import useStyles from 'containers/monitoring/style';

function DialogObjectHistory({
  floor,
  handleCloseObjectModal,
  objectInfo,
  openObjectModal,
  TransitionComponent,
}) {
  const { classes } = useStyles();
  const { t } = useTranslation(['monitoring', 'settings']);
  const historicData = useSelector(selectHistoryObjectHistoric);
  const isFetching = useSelector(selectIsFetchingObjectHistoric);

  if (!objectInfo) {
    return null;
  }

  const coordinates = {
    x: (objectInfo.kx * floor.w).toFixed(2),
    y: (objectInfo.ky * floor.h).toFixed(2),
    z: (objectInfo?.z)?.toFixed(2) || '',
  };
  const notDisplayPropTypes = new Set(['color', 'kx', 'ky', 'file_url', 'imageSourceName', 'objectType', 'objectColor']);

  const propTableDataUnsort = [];
  if (historicData?.properties) {
    historicData.properties.forEach((prop) => {
      const {
        id, key, value,
      } = prop;
      if (!notDisplayPropTypes.has(key) && key !== 'sizeObject') {
        propTableDataUnsort.push({
          key,
          value,
          id,
        });
      }
    });
  }

  const propTableData = [...propTableDataUnsort].sort(
    (a, b) => a.id - b.id,
  );

  return (
    <Dialog
      open={openObjectModal}
      TransitionComponent={TransitionComponent}
      keepMounted
      onClose={handleCloseObjectModal}
      maxWidth="md"
    >
      {objectInfo ? (
        <DialogContent
          className={
          objectInfo.videostream_url
            ? classes.dialogContent
            : classes.noneDialogContent
        }
        >
          <div>
            {objectInfo.file_url && (
              <div>
                <img
                  className={classes.itemImage}
                  src={objectInfo.file_url}
                  alt="file_url"
                />
              </div>
            )}
            {objectInfo.title && (
              <div>
                <h3>{objectInfo ? t('objectTitle') : null}</h3>
                <p>{objectInfo.title}</p>
              </div>
            )}
            {historicData && historicData['tracked-device'] && (
              <div className={classes.deviceWrapper}>
                <h3>{historicData['tracked-device'] ? t('objectDevcie') : null}</h3>
                <div>
                  <span className={classes.spanObject}>
                    {historicData['tracked-device'].title}
                    {' '}
                  </span>
                  <span className={classes.parentheses}>(</span>
                  <span className={classes.spanObject}>{historicData['tracked-device'].mac_address}</span>
                  <span className={classes.parentheses}>)</span>
                </div>
              </div>
            )}
            <div>
              <h3>{t('coordinates')}</h3>
              <p>
                {`
                X: ${coordinates.x} \u00A0 
                Y: ${coordinates.y}
                ${coordinates.z ? ` \u00A0 Z: ${coordinates.z}` : ''}`}
              </p>
            </div>
            {propTableData.length > 0 && (
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>{t('keys')}</TableCell>
                    <TableCell>{t('properties')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {propTableData.map((prop, index) => (
                    <TableRow
                      key={`${prop.id}${prop.value}`}
                      className={`${
                        index % 2 === 0
                          ? classes.tableElementEven
                          : classes.tableElementOdd
                      }`}
                    >
                      <TableCell>{prop.key}</TableCell>
                      <TableCell>{prop.value}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            )}
          </div>
          {objectInfo.videostream_url && (
            <div>
              <div>
                <img
                  className={classes.videoBlock}
                  src={objectInfo.videostream_url}
                  alt={objectInfo.videostream_url}
                />
              </div>
            </div>
          )}
          {isFetching && <CircularProgress size={25} />}
        </DialogContent>
      ) : null}
      <DialogActions>
        <CustomButton onClick={handleCloseObjectModal} color="primary">
          {t('closeBtn')}
        </CustomButton>
      </DialogActions>
    </Dialog>
  );
}

export default DialogObjectHistory;
