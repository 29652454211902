import React, { Suspense } from 'react';
import '../i18n';
import '../index.css';
import CssBaseline from '@mui/material/CssBaseline';

import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';

import { Provider } from 'react-redux';
import store from 'store/configureStore';
import App from '../App';

import createTheme from '../themes-materialUI/createTheme';

function Root(theme) {
  return (
    <Suspense fallback={null}>
      <Provider store={store}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={createTheme((theme))}>
            <CssBaseline />
            <App />
          </ThemeProvider>
        </StyledEngineProvider>
      </Provider>
    </Suspense>
  );
}

export default Root;
