/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  timerID: null,
  updatePeriod: 1000,
  objectTTL: 60,
};

const workerSlice = createSlice({
  name: 'worker',
  initialState,
  reducers: {
    setTimerId(state, action) {
      state.timerID = action.payload;
    },
    setObjectTtl(state, action) {
      state.objectTTL = action.payload;
    },
    setTimerPeriod(state, action) {
      state.updatePeriod = action.payload;
    },
    setTimerIdToNull(state) {
      state.timerID = [];
    },
  },
});

export const selectWorker = (state) => state.worker;

export const selectWorkerTimerID = (state) => selectWorker(state).timerID;
export const selectWorkerUpdatePeriod = (state) => selectWorker(state).updatePeriod;
export const selectWorkerObjectTTL = (state) => selectWorker(state).objectTTL;

export const {
  setObjectTtl, setTimerId, setTimerIdToNull, setTimerPeriod,
} = workerSlice.actions;

export default workerSlice.reducer;
