import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Add from '@mui/icons-material/Add';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { ReactComponent as DeleteButton } from 'assets/images/settings/icons/DeleteSettings.svg';
import { ReactComponent as EditButton } from 'assets/images/settings/icons/EditSettings.svg';

import {
  formTypeCreate, formTypeUpdate,
} from 'constans';

import { fetchDevicesList } from 'store/actions/devices';
import {
  deleteGroup,
  fetchGroups,
  settingsGroupModalAction,
} from 'store/actions/groups';
import { alertTypes, createAlert } from 'store/slices/alert';
import { selectAppCurrentApp, selectAppOpenMenu } from 'store/slices/app';
import {
  closeDeleteGroupModal,
  closeSettingsModal,
  fetchGroupsInProgress,
  fetchedGroups,
  openDeleteGroupModal,
  openSettingsModal,
  selectGroupsCount,
  selectGroupsFormType,
  selectGroupsIsFetching,
  selectGroupsIsModalLoading,
  selectGroupsListInSettings,
  selectGroupsModalTitle,
  selectGroupsOpenDeleteModal,
  selectGroupsOpenModal,
  selectGroupsUpdatedGroupId,
  setGroupsListInSettings,
  setSettingsModalTitle,
  setUpdateGroupId,
} from 'store/slices/groups';
import { sortGroupTitle } from 'helpers/sortByField';

import CustomButton from 'components/settings/Button.component';
import CustomButtonTitle from 'components/settings/ButtonTitle.component';
import containerStyles from 'components/shared/Container/styles';
import PaginationIcon from 'components/shared/Pagination/PaginationIcon';
import arrowStyles from 'components/shared/settings/arrow';
import InputComponent from 'components/settings/Input.component';
import useStyles from './groups.component.style';

function GroupsComponent() {
  const dispatch = useDispatch();
  const { t } = useTranslation(['settings', 'monitoring', 'notifications', 'report']);
  const openMenu = useSelector(selectAppOpenMenu);
  const currentApp = useSelector(selectAppCurrentApp);
  const formType = useSelector(selectGroupsFormType);
  const groupsListInSettings = useSelector(selectGroupsListInSettings);
  const isModalLoading = useSelector(selectGroupsIsModalLoading);
  const modalTitle = useSelector(selectGroupsModalTitle);
  const openDeleteModal = useSelector(selectGroupsOpenDeleteModal);
  const openModal = useSelector(selectGroupsOpenModal);
  const updatedGroupId = useSelector(selectGroupsUpdatedGroupId);
  const isFetchingGroups = useSelector(selectGroupsIsFetching);
  const groupsCount = useSelector(selectGroupsCount);
  const allLabel = groupsCount > 500 ? [] : [{ label: t('All'), value: -1 }];

  const { classes: classesContainer } = containerStyles();
  const { classes } = useStyles();
  const { classes: arrowClasses } = arrowStyles();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [groupToDelete, setGroupToDelete] = useState({});
  const [isSortByTitle, setSortByTitle] = useState('none');

  useEffect(() => {
    const getGroups = async () => {
      await dispatch(fetchGroups({ page: 0, limit: 100000 }));
    };
    getGroups();
  }, [rowsPerPage, currentApp, dispatch]);

  useEffect(() => {
    setPage(0);
  }, [currentApp]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleCreateNewGroup = () => {
    dispatch(openSettingsModal(formTypeCreate));
  };

  const chechFormValidation = () => !modalTitle;

  const handleEditGroup = (group) => {
    try {
      dispatch(setSettingsModalTitle(group.attributes.title));
      dispatch(openSettingsModal(formTypeUpdate));
      dispatch(setUpdateGroupId(group.id));
    } catch (error) {
      dispatch(createAlert({ messageType: alertTypes.warn, message: 'Error in reading group properties' }));
    }
  };

  const handleClose = () => {
    dispatch(closeSettingsModal());
  };

  const handleMainModalActionClick = async () => {
    await dispatch(settingsGroupModalAction(t));
    setPage(0);
    await dispatch(fetchGroups({ page: 0, limit: 100000 }));
    await dispatch(fetchDevicesList());
  };

  const handleCloseDelete = () => {
    dispatch(closeDeleteGroupModal());
  };

  const handleDelete = async (object) => {
    setGroupToDelete(object);
    dispatch(openDeleteGroupModal());
  };

  const handleDeteleConfirm = async () => {
    setPage(0);
    await dispatch(deleteGroup(groupToDelete, t));
    await dispatch(fetchGroups({ page: 0, limit: 100000 }));
    handleCloseDelete();
  };

  const sortTitle = () => {
    const order = isSortByTitle === 'desc' ? 'asc' : 'desc';
    setSortByTitle(order);
    if (groupsCount > 10000) {
      dispatch(fetchGroupsInProgress());
    }
    if (groupsListInSettings.length !== 0) {
      const result = sortGroupTitle(order, groupsListInSettings);
      dispatch(setGroupsListInSettings(result));
      dispatch(fetchedGroups());
    }
  };

  return (
    <div className={!openMenu ? classes.content : classesContainer.noneContent}>
      <div className={classes.topControls}>
        <CustomButtonTitle
          variant="contained"
          color="primary"
          onClick={handleCreateNewGroup}
          className={classes.createBtn}
        >
          <Add className={classes.createIcon} />
          {' '}
          <span>
            {t('createGroupTitle')}
          </span>
        </CustomButtonTitle>
      </div>
      {isFetchingGroups === 'loading'
        ? (
          <div className={classes.spinerWrapper}>
            <CircularProgress size={26} />
          </div>
        )
        : (
          <div className={classes.table}>
            <TableContainer className={classes.tableContainer} component={Paper}>
              <Table stickyHeader className={classes.table} aria-label="simple table">
                <TableBody>
                  <TableRow>
                    <TableCell onClick={sortTitle} className={arrowClasses.titleRow}>
                      {' '}
                      {t('title')}
                      {' '}
                      {
                      (isSortByTitle === 'asc')
                        && <ArrowDropUpIcon className={arrowClasses.arrow} />
                      }
                      {
                      (isSortByTitle === 'desc')
                        && <ArrowDropDownIcon className={arrowClasses.arrow} />
                      }
                    </TableCell>
                    <TableCell className={classes.tableCell}>{t('actions')}</TableCell>
                  </TableRow>
                  {groupsListInSettings.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((group) => (
                      <TableRow key={group.id}>
                        <TableCell className={classes.tableCell} component="th" scope="row">
                          {group.attributes.title}
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          <EditButton
                            onClick={() => handleEditGroup(group)}
                            className={classes.actionButton}
                          />
                          <DeleteButton
                            onClick={() => handleDelete(group)}
                            className={classes.actionButton}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50, ...allLabel]}
              component="div"
              count={groupsListInSettings.length}
              rowsPerPage={rowsPerPage}
              page={page}
              className={classes.pagination}
              SelectProps={{
                IconComponent: PaginationIcon,
                inputProps: {
                  id: 'selector-rows-per-page',
                  name: 'selector-rows-per-page',
                },
              }}
              classes={{
                root: classes.root,
                select: classes.select,
                selectIcon: classes.selectIcon,
                caption: classes.caption,
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${t('of')} ${count !== -1 ? count : `${t('moreThen')} ${to}`}`}
              labelRowsPerPage={<>{t('labelRowsPerPage')}</>}
              nextIconButtonProps={{
                'aria-label': 'Next Page',
                style: {
                  color: '#000',
                  background: 'white',
                  width: '32px',
                  height: '32px',
                  margin: '5px',
                },
                autoid: 'pagination-button-previous-collector',
              }}
              backIconButtonProps={{
                'aria-label': 'Previous Page',
                style: {
                  color: page === 0 ? '#b5b8c4' : '#41afd7',
                  background: 'white',
                  width: '32px',
                  height: '32px',
                },
                autoid: 'pagination-button-next-collector',
              }}
            />
            <div>
              {openDeleteModal
              && (
              <Dialog
                open={openDeleteModal}
                onClose={handleCloseDelete}
                scroll="paper"
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
              >
                <DialogTitle id="scroll-dialog-title">
                  {t('deleteGroupTitle')}
                </DialogTitle>
                <DialogContent dividers>
                  <p>
                    {' '}
                    {t('deleteGroupText')}
                  </p>
                </DialogContent>
                <DialogActions>
                  <CustomButton disabled={isModalLoading} onClick={handleCloseDelete} color="primary">
                    {t('cancelBtn')}
                  </CustomButton>
                  <CustomButton disabled={isModalLoading} onClick={handleDeteleConfirm} color="primary">
                    {isModalLoading ? <CircularProgress size={26} /> : null}
                    {t('deleteBtn')}
                  </CustomButton>
                </DialogActions>
              </Dialog>
              )}
              {openModal
              && (
              <Dialog
                open={openModal}
                onClose={handleClose}
                scroll="paper"
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
              >
                <form onSubmit={(e) => e.preventDefault()}>
                  <DialogTitle className={classes.dialogTitle} id="scroll-dialog-title">
                    <div className={classes.dialogTitleText}>
                      {formType === formTypeCreate
                        ? t('createGroupTitle')
                        : t('editGroupTitle')}
                      {formType === formTypeCreate
                        ? null : (
                          <div className={classes.idTitle}>
                            id
                            {' '}
                            {updatedGroupId}
                          </div>
                        )}
                    </div>
                    <IconButton size="small" onClick={handleClose}>
                      <CloseIcon />
                    </IconButton>
                  </DialogTitle>
                  <DialogContent>
                    <FormControl variant="standard" className={classes.formControl}>
                      <label className={classes.label} htmlFor="group-title">
                        {t('groupModalTitle')}
                        <InputComponent
                          className={classes.inputComponent}
                          value={modalTitle}
                          InputLabelProps={{ shrink: true }}
                          InputProps={{ disableUnderline: true }}
                          // eslint-disable-next-line react/jsx-no-duplicate-props
                          inputProps={{ id: 'group-title' }}
                          onChange={(event) => {
                            dispatch(setSettingsModalTitle(event.target.value));
                          }}
                          variant="standard"
                        />
                      </label>
                    </FormControl>
                  </DialogContent>
                  <DialogActions className={classes.dialogActions}>
                    <CustomButton
                      type="submit"
                      disabled={chechFormValidation()}
                      onClick={handleMainModalActionClick}
                      color="primary"
                      className={classes.customBtn}
                    >
                      {isModalLoading ? <CircularProgress size={26} /> : null}
                      {formType === formTypeCreate ? t('createGroupButton') : t('saveBtn')}
                    </CustomButton>
                  </DialogActions>
                </form>
              </Dialog>
              )}
            </div>
          </div>
        )}
    </div>
  );
}

export default GroupsComponent;
