/*eslint-disable*/

import React from "react";
import { useTranslation } from "react-i18next";

// @mui/material components
import MenuItem from "@mui/material/MenuItem";

// custom outlined selector
import OutlinedSelector from 'components/shared/OutlinedSelector/OutlinedSelector'
import { noValuesConstant } from 'constans';

export default function FloorSelector(props) {
    const { className, allowAllValues, 
    allowSelectNoValues, floors, currentLocationId, 
    loading, value, label, id, ...rest } = props;
    const { t } = useTranslation(["monitoring"]);
    const sortFloors = Object.keys(floors).sort((a,b) => {
        let arr 
        arr = floors[a].sort - floors[b].sort; 
        return arr
        })
    const floorsArray = sortFloors
        .filter((floorId) => {
            const floorLocationId = floors[floorId].location;

            return parseInt(floorLocationId) === parseInt(currentLocationId)
        })
    const currentValue = floorsArray.length > 0 &&
        value ? value : noValuesConstant;
    return (
        <OutlinedSelector
            className={className}
            novalueslabel={currentValue === noValuesConstant ? t("noFloors") : null}
            allowNoValues={currentValue === noValuesConstant}
            allowAllValues={allowAllValues}
            allowSelectNoValues={allowSelectNoValues}
            value={currentValue}
            onChange={props.onChange}
            {...rest}
            InputLabelProps={{ shrink: true }}
            inputProps={{ id: id || 'select-floor' }}
        >
            {floorsArray.map(locationId => {
                return (
                    <MenuItem key={locationId} value={locationId}>
                        {floors[locationId].title}
                    </MenuItem>
                );
            })}
        </OutlinedSelector>
    );
}
