import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((materialTheme) => ({
  history: {
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    textAlign: 'left',
    background: materialTheme.CustomStyles.MainBackgroundColor,
  },
  map: {
    width: '100%',
    height: '100%',
  },
  noneContainer: {
    margin: '0 auto',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    textAlign: 'left',
    background: materialTheme.CustomStyles.MainBackgroundColor,
  },
  controlWrapper: {
    width: '207px',
    minWidth: '207px',
    marginRight: '2vw',
    textAlign: 'left',
    marginLeft: '20px',
    marginBottom: '20px',
  },
  switch_wrapper: {
    marginTop: '15px',
    marginLeft: '5px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  checkboxWrapper: {
    marginLeft: '25px',
    marginBottom: '0px',
    paddingBottom: '0px',
  },
  selectorWrapp: {
    width: '207px',
    marginRight: '2vw',
  },
  selector: {
    width: '207px',
    height: '36px',
    borderRadius: '4px',
    backgroundColor: '#F3F6F8',
    padding: '0 15px',
  },
  selectorObject: {
    marginRight: '10px',
  },
  mapControls: {
    width: '100%',
    background: '#fff',
    height: 'calc(100vh - 80px)',
    display: 'flex',
    flexDirection: 'column',
  },
  objectsCount: {
    marginLeft: '15px',
    fontSize: '16px',
  },
  objectsFilter: {
    width: '100%',
  },
  map_container: {
    background: '#F5F8FB',
    width: 'calc(100vw)',
    [materialTheme.breakpoints.down('800')]: {
      width: '100vw',
    },
    height: 'calc(100vh - 80px)',
    display: 'block',
    margin: '0 auto',
    '&::backdrop': {
      background: '#fafafa',
    },
  },
  swich_element_monitoring: {
    marginLeft: '9px',
  },
  tableElementEven: {
    backgroundColor: '#FFFFFF',
  },
  tableElementOdd: {
    backgroundColor: '#DADADA',
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'row',
  },
  noneDialogContent: {
    width: '400px',
  },
  videoBlock: {
    width: '400px',
    margin: '10px',
    height: '470px',
  },
  itemImage: {
    maxWidth: '100px',
    maxHeight: '100px',
  },
  arrowBtn: {
    display: 'none',
    [materialTheme.breakpoints.down('800')]: {
      display: 'block',
    },
  },
  listImg: {
    width: '30px',
    height: '30px',
  },
  circle: {
    width: '30px',
    height: '30px',
    border: '2px solid black',
    borderRadius: '50%',
  },
  listItemTitle: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  listItems: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  deviceWrapper: {
    display: 'inline-block',
  },
  parentheses: {
    fontFamily: 'Arial, sans-serif',
  },
}));

export default useStyles;
