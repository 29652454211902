/* eslint-disable max-len */
/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import {
  formTypeCreate, notificationTypes, timeTypes, notificationPriorities, conditionSoundTypes,
} from 'constans';
import { createCondition, deleteCondition } from 'store/actions/notifications';

const initialState = {
  conditionsArray: [],
  isConditionsLoading: false,
  updatedConditionId: null,
  conditionFilter: '',
  isFetchingCreateCondition: false,
  openModal: false,
  openDeleteModal: false,
  formType: formTypeCreate,
  conditionType: notificationTypes.entry,
  conditionTime: 60,
  conditionTimeType: timeTypes.seconds,
  isConditionCreating: false,
  notificationPriority: notificationPriorities.normal,
  conditionSound: {
    file: null,
    type: conditionSoundTypes.none,
  },
};

const notificationsSettingsSlice = createSlice({
  name: 'notificationsSettings',
  initialState,
  reducers: {
    closeDeleteConditionModal(state) {
      state.openDeleteModal = false;
    },
    closeMainModal(state) {
      state.openModal = false;
    },
    conditionsLoadingStart(state) {
      state.isConditionsLoading = true;
    },
    conditionsLoadingEnd(state) {
      state.isConditionsLoading = false;
    },
    openDeleteConditionModal(state) {
      state.openDeleteModal = true;
    },
    setConditionSound(state, action) {
      state.conditionSound = action.payload;
    },
    openMainModal(state, action) {
      state.openModal = true;
      state.formType = action.payload;
      if (action.payload === formTypeCreate) {
        state.conditionSound = initialState.conditionSound;
      }
    },
    setConditionsList(state, action) {
      state.conditionsArray = action.payload;
    },
    setConditionTime(state, action) {
      state.conditionTime = action.payload;
    },
    setConditionTimeType(state, action) {
      state.conditionTimeType = action.payload;
    },
    setConditionType(state, action) {
      state.conditionType = action.payload;
    },
    setNotificationPriority(state, action) {
      state.notificationPriority = action.payload;
    },
    setEditingConditionID(state, action) {
      state.updatedConditionId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createCondition.pending, (state) => {
      state.isConditionCreating = true;
    });
    builder.addCase(createCondition.rejected, (state) => {
      state.isConditionCreating = false;
    });
    builder.addCase(createCondition.fulfilled, (state) => {
      state.isConditionCreating = false;
    });
    builder.addCase(deleteCondition.pending, (state) => {
      state.isConditionCreating = true;
    });
    builder.addCase(deleteCondition.rejected, (state) => {
      state.isConditionCreating = false;
    });
    builder.addCase(deleteCondition.fulfilled, (state) => {
      state.isConditionCreating = false;
    });
  },
});

export const selectNotifSet = (state) => state.notificationsSettings;

export const selectNotifSetConditionsArray = (state) => selectNotifSet(state).conditionsArray;
export const selectNotifSetIsConditionsLoading = (state) => selectNotifSet(state).isConditionsLoading;
export const selectNotifSetUpdatedConditionId = (state) => selectNotifSet(state).updatedConditionId;
export const selectNotifSetConditionFilter = (state) => selectNotifSet(state).conditionFilter;
export const selectNotifSetIsFetchingCreateCondition = (state) => selectNotifSet(state).isFetchingCreateCondition;
export const selectNotifSetOpenModal = (state) => selectNotifSet(state).openModal;
export const selectNotifSetOpenDeleteModal = (state) => selectNotifSet(state).openDeleteModal;
export const selectNotifSetFormType = (state) => selectNotifSet(state).formType;
export const selectNotifSetConditionType = (state) => selectNotifSet(state).conditionType;
export const selectNotifSetConditionTime = (state) => selectNotifSet(state).conditionTime;
export const selectNotifSetConditionTimeType = (state) => selectNotifSet(state).conditionTimeType;
export const selectNotifSetIsConditionCreating = (state) => selectNotifSet(state).isConditionCreating;
export const selectNotifSetNotificationPriority = (state) => selectNotifSet(state).notificationPriority;
export const selectNotifSetConditionSound = (state) => selectNotifSet(state).conditionSound;

export const {
  setNotificationPriority, setConditionSound,
  setConditionTime, setConditionTimeType, setConditionType,
  setNotificationsByZone, setNotificationsByGroup,
  setEditingConditionID,
  closeDeleteConditionModal, closeMainModal, openDeleteConditionModal,
  conditionsLoadingEnd, conditionsLoadingStart,
  openMainModal, setConditionsList,
} = notificationsSettingsSlice.actions;

export default notificationsSettingsSlice.reducer;
