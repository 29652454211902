import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import 'react-color-palette/lib/css/styles.css';

import { format } from 'date-fns';
import { debounce } from 'lodash';
import { useTranslation } from 'react-i18next';

import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import enLocale from 'date-fns/locale/en-US';
import ruLocale from 'date-fns/locale/ru';

import Add from '@mui/icons-material/Add';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

import { ReactComponent as ArrowCircleUpRight } from 'assets/images/settings/icons/ArrowCircleUpRight.svg';
import { ReactComponent as DeleteButton } from 'assets/images/settings/icons/DeleteSettings.svg';
import { ReactComponent as EditButton } from 'assets/images/settings/icons/EditSettings.svg';

import { fetchDevicesList } from 'store/actions/devices';
import {
  fetchGroups,
} from 'store/actions/groups';
import { fetchObjectHistoric } from 'store/actions/history';
import {
  deleteObject,
  objectsByAppFetch,
} from 'store/actions/objects';
import { alertTypes, createAlert, removeAlert } from 'store/slices/alert';
import { selectAppCurrentApp, selectAppOpenMenu } from 'store/slices/app';
import { selectDevicesList } from 'store/slices/devices';
import { selectFloorsObject, setFloor } from 'store/slices/floors';
import {
  selectGroupsIsFetching, selectGroupsListInSettings, selectGroupsObject, setSelectedGroup,
} from 'store/slices/groups';
import { resetObjectHistoric, selectHistoryObjectHistoric, selectIsFetchingObjectHistoric } from 'store/slices/history';
import { selectLocationsObject, setLocation } from 'store/slices/locations';
import {
  currentObjectSet, selectObjectsByAppArray,
  selectObjectsInMonitoringArray, selectObjectsIsFetchingDeleteObject,
  selectObjectsIsObjectsByAppFetch,
} from 'store/slices/objects';
import { setCurrentObject } from 'store/slices/reports';
import {
  closeDeleteModal,
  openDeleteModalAction,
  openModalAction,
  selectSettingsOpenDeleteModal,
  selectSettingsOpenModal,
  setModalForm,
} from 'store/slices/settings';
import { setSelectedZone } from 'store/slices/zones';

import {
  allValuesConstant,
  dynamicTypeId,
  formTypeCreate,
  formTypeUpdate,
  monitoring,
  oldDevice,
  selectNoOneConstant,
  staticTypeId,
} from 'constans';
import {
  sortObjectByDeviceTitle,
  sortObjectByGroupName,
  sortObjectByObjectTitle,
  sortObjectByOnlineStatus,
} from 'helpers/sortByField';

import CustomButton from 'components/settings/Button.component';
import CustomButtonTitle from 'components/settings/ButtonTitle.component';
import CustomTextField from 'components/settings/TextFiled';
import containerStyles from 'components/shared/Container/styles';
import PaginationIcon from 'components/shared/Pagination/PaginationIcon';
import EditCreateDialog from './Dialogs/EditCreateDialog';
import useStyles from './objects.component.style';

const datePickerLocales = {
  ru: ruLocale,
  en: enLocale,
};

function Objects() {
  const dispatch = useDispatch();
  const openMenu = useSelector(selectAppOpenMenu);
  const currentApp = useSelector(selectAppCurrentApp);
  const devicesList = useSelector(selectDevicesList);
  const floorsObject = useSelector(selectFloorsObject);
  const groupsListInSettings = useSelector(selectGroupsListInSettings);
  const groupsObject = useSelector(selectGroupsObject);
  const locationsObject = useSelector(selectLocationsObject);
  const objectsByAppArray = useSelector(selectObjectsByAppArray);
  const isObjectsByAppFetch = useSelector(selectObjectsIsObjectsByAppFetch);
  const isFetchingDeleteObject = useSelector(selectObjectsIsFetchingDeleteObject);
  const objectsInMonitoringArray = useSelector(selectObjectsInMonitoringArray);
  const openDeleteModal = useSelector(selectSettingsOpenDeleteModal);
  const openModal = useSelector(selectSettingsOpenModal);
  const isFetchingGroups = useSelector(selectGroupsIsFetching);

  const fetchingObjectHistory = useSelector(selectIsFetchingObjectHistoric);
  const objectHistoric = useSelector(selectHistoryObjectHistoric);

  const navigate = useNavigate();

  const { t, i18n } = useTranslation(['settings', 'monitoring', 'notifications', 'report']);
  const { classes: classesContainer } = containerStyles();
  const { classes, cx } = useStyles();

  const [objectDevices, setObjectDevices] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [shownObjects, setShownObjects] = useState(JSON.parse(JSON.stringify(objectsByAppArray))
  || []);
  const [objectsFilter, setObjectsFilter] = useState('');
  const [objectToDelete, setObjectToDelete] = useState({});
  const [isSortByTitle, setSortByTitle] = useState('none');
  const [isSortByGroupTitle, setIsSortByGroupTitle] = useState('none');
  const [isSortByDevice, setIsSortByDevice] = useState('none');
  const [isSortByOnlineStatus, setIsSortByOnlineStatus] = useState('none');
  const [onlineObjects, setOnlineObjects] = useState([]);
  const dateLocale = datePickerLocales[i18n.language];

  useEffect(() => {
    devicesList.forEach((device) => {
      setObjectDevices((prevObjectDevices) => ({
        ...prevObjectDevices,
        [device.id]: device,
      }));
    });
  }, [devicesList]);

  useEffect(() => {
    const res = objectsInMonitoringArray
      .map((el) => ({ title: el.attributes?.title, id: el.id }))
      .sort((a, b) => a.title - b.title);
    // checks if objects in Online Tracking changed
    if (onlineObjects.length > 0 && isSortByOnlineStatus !== 'none') {
      if (res.length !== onlineObjects.length
        || JSON.stringify(res) !== JSON.stringify(onlineObjects)
      ) {
        // if they changed and objects were sorted by online status
        // sort them again
        const toSort = isSortByOnlineStatus === 'desc' ? 'asc' : 'desc';
        setShownObjects(
          sortObjectByOnlineStatus(toSort, JSON.parse(JSON.stringify(objectsByAppArray)), res),
        );
      }
    }
    setOnlineObjects(res);
  // only enter this useEffect when objects in online tracking change
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [objectsInMonitoringArray]);

  useEffect(() => {
    if (isSortByTitle !== 'none') {
      const toSort = isSortByTitle === 'desc' ? 'asc' : 'desc';
      setShownObjects(sortObjectByObjectTitle(
        toSort,
        JSON.parse(JSON.stringify(objectsByAppArray)),
      ));
      return;
    }
    if (isSortByOnlineStatus !== 'none') {
      const toSort = isSortByOnlineStatus === 'desc' ? 'asc' : 'desc';
      setShownObjects(
        sortObjectByOnlineStatus(
          toSort,
          JSON.parse(JSON.stringify(objectsByAppArray)),
          onlineObjects,
        ),
      );
      return;
    }
    if (isSortByGroupTitle !== 'none') {
      const toSort = isSortByGroupTitle === 'desc' ? 'asc' : 'desc';
      setShownObjects(sortObjectByGroupName(toSort, JSON.parse(JSON.stringify(objectsByAppArray))));
      return;
    }
    if (isSortByDevice !== 'none') {
      const toSort = isSortByDevice === 'desc' ? 'asc' : 'desc';
      setShownObjects(sortObjectByDeviceTitle(
        toSort,
        JSON.parse(JSON.stringify(objectsByAppArray)),
      ));
      return;
    }
    setShownObjects(JSON.parse(JSON.stringify(objectsByAppArray)));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [objectsByAppArray]);

  const handleSearch = useCallback((value) => {
    dispatch(objectsByAppFetch(value, 0, 4000));
  }, [dispatch]);

  const debouncedSendRequest = useMemo(() => debounce(handleSearch, 300), [handleSearch]);

  const handleChangeFilter = (event) => {
    setPage(0);
    setObjectsFilter(event.target.value);
    debouncedSendRequest(event.target.value);
  };

  useEffect(() => {
    setPage(0);
  }, [currentApp]);

  useEffect(() => {
    const getDevices = async () => {
      await Promise.all([
        dispatch(fetchGroups({ page: 0, limit: 100000 })),
        dispatch(fetchDevicesList(null)),
        dispatch(objectsByAppFetch('', 0, 3000)),
      ]);
    };
    getDevices();
  }, [dispatch]);

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const groupsList = Object.keys(groupsObject).map((element) => groupsObject[element]);

  const handleChangeForm = (fieldName, value) => {
    const resultObject = {};
    resultObject[fieldName] = value;
    dispatch(setModalForm(resultObject));
  };
  const handleCreateNewObject = () => {
    dispatch(openModalAction(formTypeCreate));
    if (groupsList.length > 0) {
      handleChangeForm('group', groupsList[0]);
    }
  };

  const formatTime = (input) => format(
    new Date(input * 1000),
    'dd.MM.yy HH:mm',
    { locale: dateLocale },
  );

  const formatProperties = (data) => {
    const { properties, url } = data;
    const result = [];
    if (properties.length > 0) {
      const newProps = [...properties].sort((a, b) => a.key.localeCompare(b.key));
      newProps.forEach((el) => {
        if (el.key !== 'file_url') { result.push(`${el.key}= ${el.value}`); }
      });
    }
    if (url) {
      result.push(`file_url= ${url}`);
    }
    return result.join('\n');
  };

  const handleEditObject = (object) => {
    const formFields = {};
    object.attributes.properties.forEach((objectProp) => {
      const { key, value } = objectProp;
      formFields[key] = value;
    });
    try {
      const objectAttributes = { ...object.attributes };
      const objectRelationships = { ...object.relationships };
      formFields.objectUpdateId = object.id;
      formFields.customPropertyUrl = object.attributes.external_property_url || '';
      formFields.color = objectAttributes.color || '';
      formFields.title = objectAttributes.title || '';
      formFields.customProps = objectAttributes.customProps || '';
      formFields.group = groupsObject[objectRelationships['tracked-group'].data.id] || {};
      if (Object.keys(formFields.group).length === 0) {
        Object.values(groupsObject).forEach((value) => {
          if (value.title === objectRelationships['tracked-group']?.data?.title) {
            formFields.group = value;
          }
        });
      }
      formFields.iconImageUrl = objectAttributes.url || '';
      const rejectedProps = new Set(['videostream_url', 'file_url', 'color', 'objectColor']);
      formFields.properties = objectAttributes.properties
        .filter((prop) => !rejectedProps.has(prop.key));
      objectAttributes.properties.map((property) => {
        if (property.key === 'videostream_url') {
          formFields.cameraUrl = property.value || '';
        }
        if (property.key === 'file_url') {
          formFields.iconImageUrl = property.value || '';
        }

        return property;
      });
      if (objectAttributes?.properties?.tracked_object_property_type_id
        && objectAttributes.properties.tracked_object_property_type_id.length === 0) {
        return;
      }

      if (objectAttributes.tracked_object_type_id === dynamicTypeId) {
        formFields.type = dynamicTypeId;
        formFields.deviceType = oldDevice;
        formFields.device = objectDevices[objectRelationships['tracked-beacon']?.data.id] || {};
      }
      if (objectAttributes.tracked_object_type_id === staticTypeId) {
        formFields.type = staticTypeId;
        const sublocation = object.attributes.properties.filter((i) => i.key === 'sublocation_id')[0];
        const kx = object.attributes.properties.filter((i) => i.key === 'kx')[0];
        const ky = object.attributes.properties.filter((i) => i.key === 'ky')[0];
        const floor = floorsObject[sublocation.value];
        if (floor) {
          formFields.x = kx.value * floor.w;
          formFields.y = ky.value * floor.h;
          formFields.floor = floor;
        }
      }
      dispatch(currentObjectSet(+object.id));
    } catch (error) {
      dispatch(createAlert({ messageType: 'warning', message: 'Error in getting properties from object', title: error }));
    }
    dispatch(setModalForm(formFields));
    dispatch(openModalAction(formTypeUpdate));
  };

  const handleCloseDeleteObject = () => {
    dispatch(closeDeleteModal());
  };

  const handleDelete = async (object) => {
    setObjectToDelete(object);
    dispatch(openDeleteModalAction());
  };

  const handleDeteleConfirm = async () => {
    setObjectsFilter('');
    await dispatch(deleteObject(objectToDelete, t));
    await dispatch(objectsByAppFetch(objectsFilter, 0, 4000));
    dispatch(closeDeleteModal());
  };
  const sortTitle = () => {
    if (shownObjects.length !== 0) {
      setShownObjects(sortObjectByObjectTitle(isSortByTitle, shownObjects));
      setIsSortByOnlineStatus('none');
      setIsSortByDevice('none');
      setIsSortByGroupTitle('none');
    }
    if (isSortByTitle === 'none') {
      setSortByTitle('desc');
    } else if (isSortByTitle === 'desc') {
      setSortByTitle('asc');
    } else if (isSortByTitle === 'asc') {
      setSortByTitle('desc');
    }
  };
  const sortGroup = () => {
    if (shownObjects.length !== 0) {
      setShownObjects(sortObjectByGroupName(isSortByGroupTitle, shownObjects));
      setSortByTitle('none');
      setIsSortByOnlineStatus('none');
      setIsSortByDevice('none');
    }
    if (isSortByGroupTitle === 'none') {
      setIsSortByGroupTitle('desc');
    } else if (isSortByGroupTitle === 'desc') {
      setIsSortByGroupTitle('asc');
    } else if (isSortByGroupTitle === 'asc') {
      setIsSortByGroupTitle('desc');
    }
  };
  const sortDevice = () => {
    if (shownObjects.length !== 0) {
      setShownObjects(sortObjectByDeviceTitle(isSortByDevice, shownObjects));
      setSortByTitle('none');
      setIsSortByOnlineStatus('none');
      setIsSortByGroupTitle('none');
    }
    if (isSortByDevice === 'none') {
      setIsSortByDevice('desc');
    } else if (isSortByDevice === 'desc') {
      setIsSortByDevice('asc');
    } else if (isSortByDevice === 'asc') {
      setIsSortByDevice('desc');
    }
  };

  const sortByOnlineStatus = () => {
    if (shownObjects.length !== 0) {
      setShownObjects(
        sortObjectByOnlineStatus(isSortByOnlineStatus, shownObjects, onlineObjects),
      );
      setSortByTitle('none');
      setIsSortByGroupTitle('none');
      setIsSortByDevice('none');
    }
    if (isSortByOnlineStatus === 'none') {
      setIsSortByOnlineStatus('desc');
    } else if (isSortByOnlineStatus === 'desc') {
      setIsSortByOnlineStatus('asc');
    } else if (isSortByOnlineStatus === 'asc') {
      setIsSortByOnlineStatus('desc');
    }
  };

  const goToObjectOnMap = (object) => {
    const objectDeviceId = object.relationships?.['tracked-beacon']?.data.id;
    const positioning = {};
    const foundObj = objectsInMonitoringArray.find((obj) => +obj.id === +object.id);
    if (!foundObj || !objectDeviceId) {
      return;
    }
    if (foundObj.attributes && foundObj.attributes.sublocation_id) {
      positioning.sublocation_id = +foundObj.attributes.sublocation_id;
    }

    devicesList.forEach((device) => {
      if (device.id === objectDeviceId) {
        device.properties.forEach((prop) => {
          if (prop.key === 'sublocation_id') {
            positioning.sublocation_id = +prop.value;
          }
        });
      }
    });
    if (Object.keys(locationsObject).length > 0) {
      Object.values(locationsObject).forEach((location) => {
        location.floors.forEach((floor) => {
          if (+floor.id === +positioning.sublocation_id) {
            positioning.location_id = +location.id;
          }
        });
      });
    }
    dispatch(setLocation(locationsObject[positioning.location_id]));
    dispatch(setFloor(floorsObject[positioning.sublocation_id]));
    dispatch(setCurrentObject(object));
    dispatch(setSelectedZone({ id: selectNoOneConstant }));
    dispatch(setSelectedGroup({ id: allValuesConstant }));
    navigate(monitoring, { state: { highlightObject: object, positioning } });
  };

  const goToOfflineObject = useCallback((object) => {
    if (!object) return;
    const objectId = object.id;
    const timestamp = Date.parse(new Date(object.last_active_time * 1000 + 1000));
    dispatch(fetchObjectHistoric({
      objectId, timestamp, coordinates: 'true', fetchingObjectHistory,
    }));
  }, [dispatch, fetchingObjectHistory]);

  useEffect(() => {
    if (fetchingObjectHistory === 'pending') {
      dispatch(createAlert({
        message: 'Fetching object data',
        messageType: alertTypes.info,
        timerAlert: 10000,
      }));
      return () => {};
    }

    if (fetchingObjectHistory === 'rejected' && (!objectHistoric || Object.keys(objectHistoric).length === 0
      || !locationsObject || Object.keys(locationsObject).length === 0)) {
      dispatch(removeAlert());
      dispatch(createAlert({ message: 'Error fetching object data', messageType: alertTypes.err }));
      return () => {};
    }
    const sublocationId = objectHistoric.attributes?.sublocation_id;
    if (!sublocationId) return () => {};
    let locationId;
    Object.values(locationsObject).forEach((location) => {
      location.floors.forEach((floor) => {
        if (+floor.id === +sublocationId) {
          locationId = +location.id;
        }
      });
    });
    if (!locationId) return () => {};
    const positioning = {
      sublocation_id: objectHistoric.attributes?.sublocation_id,
      location_id: locationId,
    };
    dispatch(setLocation(locationsObject[positioning.location_id]));
    dispatch(setFloor(floorsObject[positioning.sublocation_id]));
    dispatch(setSelectedZone({ id: selectNoOneConstant }));
    dispatch(setSelectedGroup({ id: allValuesConstant }));
    navigate(monitoring, { state: { offlineObject: objectHistoric, positioning } });
    return () => {
      dispatch(resetObjectHistoric());
      dispatch(removeAlert());
    };
  }, [dispatch, fetchingObjectHistory, floorsObject, locationsObject, navigate, objectHistoric]);

  return (
    <div className={!openMenu ? classes.content : classesContainer.noneContent}>
      <div className={classes.topControls}>
        <CustomButtonTitle
          variant="contained"
          color="primary"
          onClick={handleCreateNewObject}
          disabled={groupsListInSettings.length === 0}
          className={classes.createBtn}
        >
          <Add className={classes.createIcon} />
          {' '}
          <span>{t('createBtn')}</span>
        </CustomButtonTitle>
        <CustomTextField
          id="standard-multiline-flexible"
          label={t('typeToSearch')}
          value={objectsFilter}
          onChange={handleChangeFilter}
          variant="standard"
        />
      </div>
      {(isObjectsByAppFetch || isFetchingGroups === 'loading') ? (
        <div className={classes.spinerWrapper}>
          <CircularProgress size={26} />
        </div>
      ) : (
        <div className={classes.table}>
          <TableContainer className={classes.tableContainer} component={Paper}>
            <Table stickyHeader className={classes.table} aria-label="simple table">
              <TableBody>
                <TableRow className={classes.tableRow}>
                  <TableCell align="left" onClick={sortTitle} className={`${classes.titleTheadCellSort} ${classes.objectTitleRow}`}>
                    <div className={classes.tableTheadCell}>
                      <span className={classes.titleText}>{t('objectsTitle')}</span>
                      { (isSortByTitle === 'asc') && <ArrowDropUpIcon />}
                      { (isSortByTitle === 'desc') && <ArrowDropDownIcon />}
                    </div>
                  </TableCell>
                  <TableCell
                    align="left"
                    onClick={sortByOnlineStatus}
                    className={`${classes.titleTheadCellSort} ${classes.statusRow}`}
                  >
                    <div className={classes.tableTheadCell}>
                      <span className={classes.titleText} style={{ maxWidth: '3.5em' }}>{t('Status')}</span>
                      { (isSortByOnlineStatus === 'asc') && <ArrowDropUpIcon />}
                      { (isSortByOnlineStatus === 'desc') && <ArrowDropDownIcon />}
                    </div>
                  </TableCell>
                  <TableCell align="left" onClick={sortGroup} className={`${classes.titleTheadCellSort} ${classes.groupRow}`}>
                    <div className={classes.tableTheadCell}>
                      <span className={classes.titleText} style={{ maxWidth: '3.5em' }}>{t('groupTitle')}</span>
                      { (isSortByGroupTitle === 'asc') && <ArrowDropUpIcon />}
                      { (isSortByGroupTitle === 'desc') && <ArrowDropDownIcon />}
                    </div>
                  </TableCell>
                  <TableCell align="left" onClick={sortDevice} className={`${classes.titleTheadCellSort} ${classes.beaconRow}`}>
                    <div className={classes.tableTheadCell}>
                      <span className={classes.titleText}>{t('beaconTitle')}</span>
                      { (isSortByDevice === 'asc') && <ArrowDropUpIcon />}
                      { (isSortByDevice === 'desc') && <ArrowDropDownIcon />}
                    </div>
                  </TableCell>
                  <TableCell align="left" className={classes.titleTheadCell}>
                    <div className={classes.tableTheadCell}>
                      <span className={classes.titleText}>{t('Properties')}</span>
                    </div>
                  </TableCell>
                  <TableCell align="left" className={classes.titleTheadCell}>
                    <div className={classes.tableTheadCell}>
                      <span className={classes.titleText}>{t('actions')}</span>
                    </div>
                  </TableCell>
                </TableRow>
                {shownObjects
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((object) => (
                    <TableRow key={object.id} className={classes.tableRow}>
                      <TableCell
                        className={`${classes.tableCell} ${classes.objectTitleRow}`}
                        align="left"
                        component="th"
                        scope="row"
                      >
                        <div className={classes.imageTitle}>
                          {object.attributes.url
                            ? (
                              <img src={object.attributes.url} alt="ObjIcon" />
                            ) : null}
                          {object.attributes.title}
                        </div>
                      </TableCell>
                      <TableCell align="left" className={`${classes.tableCell} ${classes.statusRow}`}>
                        {onlineObjects
                        && onlineObjects.length > 0
                        && onlineObjects.find((onlineObj) => (+onlineObj.id === object.id))
                        && (
                          <div className={classes.onlineText}>
                            Online
                            {' '}
                            <ArrowCircleUpRight
                              className={cx(classes.goToObjectIcon, classes.offlineIcon)}
                              onClick={() => goToObjectOnMap(object)}
                            />
                          </div>
                        )}
                        {onlineObjects
                        && !onlineObjects.find((onlineObj) => (+onlineObj.id === object.id))
                        && (
                          <div className={classes.offlineContainer}>
                            <div className={classes.offlineText}>
                              Offline
                              {object.last_active_time && (
                              <ArrowCircleUpRight
                                className={cx(classes.goToObjectIcon, classes.offlineIcon)}
                                onClick={() => goToOfflineObject(object)}
                              />
                              )}
                            </div>
                            {object.last_active_time
                            && (
                            <div className={classes.offlineTimeText}>
                              Was online at
                              {' '}
                              {' '}
                              {formatTime(object.last_active_time)}
                            </div>
                            )}
                          </div>
                        )}
                      </TableCell>
                      <TableCell align="left" className={`${classes.tableCell} ${classes.groupRow}`}>
                        {object.relationships['tracked-group']
                        && object.relationships['tracked-group'].data.title}
                      </TableCell>
                      {object.relationships['tracked-beacon']
                    && object.relationships['tracked-beacon'].data.title !== '-' ? (
                      <TableCell align="left" className={classes.tableCell}>
                        {object.relationships['tracked-beacon'].data.title}
                      </TableCell>
                        ) : (
                          <TableCell align="left" className={classes.tableCell}>
                            <div className={classes.notAvailable}>N/A</div>
                          </TableCell>
                        )}
                      <TableCell align="left" className={`${classes.tableCell} ${classes.propsCell}`}>
                        {object.attributes && object.attributes.properties
                          ? (
                            <Tooltip
                              enterDelay={500}
                              title={(
                                <Typography className={classes.tooltip}>
                                  {formatProperties(object.attributes)}
                                </Typography>
                              )}
                            >
                              <div>
                                {formatProperties(object.attributes)}
                              </div>
                            </Tooltip>
                          )
                          : '-'}
                      </TableCell>
                      <TableCell align="left" className={`${classes.tableCell} ${classes.actionCell}`}>
                        <div className={classes.actionButtonsContainer}>
                          <EditButton
                            onClick={() => handleEditObject(object)}
                            className={classes.actionButton}
                          />
                          <DeleteButton
                            onClick={() => handleDelete(object)}
                            className={classes.actionButton}
                          />
                        </div>
                      </TableCell>

                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50, { label: t('All'), value: -1 }]}
            component="div"
            count={shownObjects.length}
            rowsPerPage={rowsPerPage}
            page={page}
            className={classes.pagination}
            SelectProps={{
              IconComponent: PaginationIcon,
              inputProps: {
                id: 'selector-rows-per-page',
                name: 'selector-rows-per-page',
              },
            }}
            classes={{
              root: classes.root,
              select: classes.select,
              selectIcon: classes.selectIcon,
              caption: classes.caption,
            }}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelDisplayedRows={({ from, to, count }) => (
              `${from}-${to} ${t('of')} ${count !== -1 ? count : `${t('moreThen')} ${to}`}`
            )}
            labelRowsPerPage={t('labelRowsPerPage')}
            nextIconButtonProps={{
              'aria-label': 'Next Page',
              style: {
                color: page === Math.ceil(shownObjects.length / rowsPerPage) - 1
                  ? '#b5b8c4' : '#41afd7',
                background: 'white',
                width: '32px',
                height: '32px',
                margin: '5px',
              },
              autoid: 'pagination-button-previous-collector',
            }}
            backIconButtonProps={{
              'aria-label': 'Previous Page',
              style: {
                color: page === 0 ? '#b5b8c4' : '#41afd7', background: 'white', width: '32px', height: '32px',
              },
              autoid: 'pagination-button-next-collector',
            }}
          />
        </div>
      )}
      <div>
        {openModal
        && (
        <EditCreateDialog
          objectDevices={objectDevices}
        />
        )}
        {openDeleteModal && (
        <Dialog
          open={openDeleteModal}
          onClose={handleCloseDeleteObject}
          scroll="paper"
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
        >
          <DialogTitle id="scroll-dialog-title">
            {t('deleteFormTitle')}
          </DialogTitle>
          <DialogContent dividers>
            <p>
              {' '}
              {t('deleteFormContentText')}
              &quot;
              {objectToDelete.attributes ? objectToDelete.attributes.title : ''}
              &quot; ?
            </p>
          </DialogContent>
          <DialogActions>
            <CustomButton onClick={handleCloseDeleteObject} color="primary">
              {t('cancelBtn')}
            </CustomButton>
            <CustomButton
              disabled={isFetchingDeleteObject}
              onClick={handleDeteleConfirm}
              color="primary"
            >
              {isFetchingDeleteObject ? (
                <div className={classes.spinerWrapper}>
                  <CircularProgress size={26} />
                </div>
              ) : (
                <>
                  {t('deleteBtn')}
                </>
              )}
            </CustomButton>
          </DialogActions>
        </Dialog>
        )}
      </div>
    </div>
  );
}

export default Objects;
