import { makeStyles } from 'tss-react/mui';
import 'assets/fonts/Roboto/RobotoMono.css';

const useStyles = makeStyles()(() => ({
  player: {
    position: 'absolute',
    bottom: '20px',
    display: 'flex',
    justifyContent: 'start',
    marginLeft: '55px',
    alignItems: 'center',
    height: '50px',
    lineHeight: '30px',
    zIndex: '35',
    // background: '#F5F8FB',
    borderRadius: '8px',
    paddingRight: '20px',
  },
  durationTracker: {
    '& span': {
      margin: '0 5px 0 10px',
      width: '15px',
      fontFamily: 'Roboto Mono',
    },
  },
  timeLine: {
    width: '35vw',
    height: '4px',
    borderRadius: '4px',
    marginTop: '1px',
    float: 'left',
    background: '#BAC3CA',
    cursor: 'pointer',
    position: 'relative',
  },
  line: {
    height: '4px',
    background: 'rgb(52,71,92)',
  },
  carret: {
    position: 'absolute',
    top: '-3px',
    left: '0',
    height: '10px',
    width: '10px',
    background: '#8194A4',
    borderRadius: '50%',
  },
  speedSelect: {
    backgroundColor: '#ffffff',
    padding: '10px',
    width: '67px',
    height: '29px',
    borderRadius: '4px',
    border: '1px solid  rgba(0, 0, 0, 0.12)',
    fontSize: '14px',
    fontWeight: '400',
    marginLeft: '15px',
  },
  playBtn: {
    float: 'left',
    '& > *': {
      backgroundColor: 'black',
    },
  },
  btnP: {
    width: '30px',
    height: '30px',
    border: 'none',
    borderRadius: '8px',
    backgroundColor: 'white',
    '&:disabled': {
      backgroundColor: '#F5F8FB',
    },
    '&:hover': {
      backgroundColor: '#F5F8FB',
    },
  },
}));

export default useStyles;
