import { Pie } from 'react-chartjs-2';
import React from 'react';
import 'chart.js/auto';
import { colorsChartArray } from 'constans';

function PieChart({ data, sortingField, legendTitles }) {
  const state = {
    labels: data.map((i) => i[legendTitles]),
    datasets: [
      {
        label: 'Rainfall',
        backgroundColor: colorsChartArray,
        borderColor: 'rgba(0,0,0,1)',
        borderWidth: 0.3,
        data: data.map((i) => i[sortingField]),
      },
    ],
  };
  return (
    <div>
      <Pie
        data={state}
        width={300}
        height={300}
        options={{
          maintainAspectRatio: false,
          title: {
            display: true,
            text: 'Average Rainfall per month',
            fontSize: 20,
          },
          legend: {
            display: true,
            position: 'right',
          },
        }}
      />
    </div>
  );
}

export default PieChart;
