import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  background: {
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 1000,
    display: 'flex',
    background: 'rgba(32,32,32, 0.7)',
  },
  videoContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  circularProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  video: {
    [theme.breakpoints.down('lg')]: {
      width: '90vw',
      height: 'calc(90vw / 1.6)',
    },
    '@media (max-height:500px)': {
      width: 'calc(90vh * 1.6)',
      height: '90vh',
    },
  },
  closeButton: {
    height: '3em',
    width: '3em',
    position: 'absolute',
    top: '2em',
    right: '2em',
    color: 'white',
    '&:hover': {
      cursor: 'pointer',
    },
  },

}));

export default useStyles;
