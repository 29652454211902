import { withStyles } from 'tss-react/mui';
import TextField from '@mui/material/TextField';

export const CustomTextFieled = withStyles(TextField, () => ({
  root: {
    margin: '-1% 0 0 0',
    color: 'white',
    fontWeight: '600',
    borderRadius: '2px',
    '&:disabled': {
      boxShadow: 'none',
      backgroundColor: 'transparent',
    },
  },
}));
export default CustomTextFieled;
