/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  currentObject: 1,
  objectsInMonitoringArray: [],
  objectsByFloor: [],
  json: '',
  objectsByAppArray: [],
  isObjectsByAppFetch: true,
  isFetchingCreateObject: false,
  isFetchingDeleteObject: false,
};

const objectsSlice = createSlice({
  name: 'objects',
  initialState,
  reducers: {
    currentObjectSet(state, action) {
      state.currentObject = action.payload;
    },
    deleteObjectFetchStart(state) {
      state.isFetchingDeleteObject = true;
    },
    deleteObjectFetchEnd(state) {
      state.isFetchingDeleteObject = false;
    },
    fetchCreateObjectStart(state) {
      state.isFetchingCreateObject = true;
    },
    fetchCreateObjectEnd(state) {
      state.isFetchingCreateObject = false;
    },
    setIsObjectsByAppFetch(state, action) {
      state.isObjectsByAppFetch = JSON.parse(JSON.stringify(action.payload));
    },
    setobjectsInMonitoringArray(state, action) {
      state.objectsInMonitoringArray = JSON.parse(JSON.stringify(action.payload));
    },
    setObjectsListByApp(state, action) {
      state.objectsByAppArray = JSON.parse(JSON.stringify(action.payload));
      state.isObjectsByAppFetch = false;
    },
    setObjectsByFloor(state, action) {
      state.objectsByFloor = JSON.parse(JSON.stringify(action.payload));
    },
    setJson(state, action) {
      state.json = action.payload;
    },

  },
});

export const selectObjects = (state) => state.objects;

export const selectObjectsCurrentObject = (state) => selectObjects(state).currentObject;
export const selectObjectsInMonitoringArray = (state) => selectObjects(state)
  .objectsInMonitoringArray;
export const selectObjectsByFloor = (state) => selectObjects(state).objectsByFloor;
export const selectObjectsJson = (state) => selectObjects(state).json;
export const selectObjectsByAppArray = (state) => selectObjects(state).objectsByAppArray;
export const selectObjectsIsObjectsByAppFetch = (state) => selectObjects(state).isObjectsByAppFetch;
export const selectObjectsIsFetchingCreateObject = (state) => selectObjects(state)
  .isFetchingCreateObject;
export const selectObjectsIsFetchingDeleteObject = (state) => selectObjects(state)
  .isFetchingDeleteObject;

export const {
  deleteObjectFetchStart, deleteObjectFetchEnd, currentObjectSet,
  setIsObjectsByAppFetch, fetchCreateObjectStart, fetchCreateObjectEnd,
  setJson, setObjectsByFloor, setobjectsInMonitoringArray,
  setObjectsListByApp,
} = objectsSlice.actions;

export default objectsSlice.reducer;
