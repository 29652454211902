/* eslint-disable react/jsx-no-duplicate-props */

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import DialogContent from '@mui/material/DialogContent';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';

import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

import { alertTypes, createAlert } from 'store/slices/alert';
import {
  resetTemporaryConditionsSocial,
  selectConditionSocialSubject,
  selectSocialConditionsArray,
  selectTemporaryConditionSocial,
  setConditionSocialSubject,
  setConditionsSocial,
  setTemporaryConditionSocial,
} from 'store/slices/notifications.social';

import useStyles from 'components/settings/notifications/notifications.component.style';
import { noValuesConstant } from 'constans';

const emailRegex = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/i;

export default function NotificationDeliveryTab() {
  const { classes } = useStyles();
  const { t } = useTranslation(['settings', 'monitoring', 'notifications']);
  const dispatch = useDispatch();

  const conditionsSocial = useSelector(selectSocialConditionsArray);
  const conditionsSocialTemp = useSelector(selectTemporaryConditionSocial);
  const subject = useSelector(selectConditionSocialSubject);

  const emailValidation = emailRegex.test(conditionsSocialTemp.destination);
  const isEmailPresent = (conditionsSocialTemp?.channel === 'Email'
  && emailValidation)
  || conditionsSocial?.some((condition) => condition.channel === 'Email');

  function handleChangeDeliveryMethod(event) {
    if (event.target.value < 0) {
      dispatch(resetTemporaryConditionsSocial());
    }
    dispatch(setTemporaryConditionSocial({ channel: event.target.value }));
  }

  function handleChangeDestination(event) {
    let validation;
    if (conditionsSocialTemp.channel === 'Email') {
      validation = emailRegex.test(event.target.value);
    }
    dispatch(setTemporaryConditionSocial({
      destination: event.target.value,
      isValid: validation,
    }));
  }

  function handleAddDeliveryMethod() {
    const findSame = conditionsSocial.find((condition) => {
      const isSameDestination = condition.destination === conditionsSocialTemp.destination;
      const isSameChannel = condition.channel === conditionsSocialTemp.channel;
      return isSameDestination && isSameChannel;
    });
    if (findSame) {
      dispatch(createAlert({
        message: 'This delivery method already exists',
        messageType: alertTypes.warn,
      }));
      return;
    }
    dispatch(setConditionsSocial([
      {
        channel: conditionsSocialTemp.channel,
        destination: conditionsSocialTemp.destination,
      },
      ...conditionsSocial,
    ]));
    dispatch(resetTemporaryConditionsSocial());
  }

  function handleRemoveDeliveryMethod(deliveryToDelete) {
    const filteredArray = conditionsSocial
      .filter((condition) => !(condition.channel === deliveryToDelete.channel
      && condition.destination === deliveryToDelete.destination));
    dispatch(setConditionsSocial(filteredArray));
  }

  function handleChangeSubject(e) {
    dispatch(setConditionSocialSubject(e.target.value));
  }

  return (
    <DialogContent className={classes.customPropsContainer}>
      <FormControl variant="standard" className={classes.deliveryInputsWrapper}>
        <Select
          id="select-delivery"
          className={classes.deliverySelector}
          disableUnderline
          variant="standard"
          value={conditionsSocialTemp.channel}
          onChange={(e) => handleChangeDeliveryMethod(e)}
          disabled
        >
          {/* <MenuItem key={noValuesConstant} value={noValuesConstant}>
            {t('monitoring:noOne')}
          </MenuItem> */}
          <MenuItem key="email" value="Email">Email</MenuItem>
        </Select>
        <TextField
          variant="standard"
          className={`${classes.deliveryText} ${emailValidation
            || conditionsSocialTemp.channel !== 'Email' ? '' : classes.failedValidation}`}
          disabled={conditionsSocialTemp.channel === noValuesConstant}
          type="email"
          onChange={(e) => handleChangeDestination(e)}
          InputLabelProps={{ shrink: true }}
          InputProps={{
            disableUnderline: true,
          }}
          inputProps={{ id: 'object-custom-property' }}
          value={conditionsSocialTemp.destination}
        />
        <IconButton
          disabled={conditionsSocialTemp.channel < 0 || !emailValidation}
          onClick={() => handleAddDeliveryMethod()}
          size="small"
          sx={{ marginLeft: '5px' }}
        >
          <AddIcon />
        </IconButton>
      </FormControl>
      {conditionsSocial && conditionsSocial.length > 0
      && conditionsSocial.map((condition) => (
        <div
          key={`${condition.channel}${condition.destination}`}
          className={classes.savedItemsWrapper}
        >
          <TextField
            className={classes.deliveryTextSaved}
            disabled
            value={condition.channel}
            variant="standard"
            InputLabelProps={{ shrink: true }}
            InputProps={{
              disableUnderline: true,
            }}
          />
          <TextField
            className={classes.deliveryText}
            disabled
            value={condition.destination}
            variant="standard"
            InputLabelProps={{ shrink: true }}
            InputProps={{
              disableUnderline: true,
            }}
          />
          <IconButton
            onClick={() => handleRemoveDeliveryMethod(condition)}
            size="small"
            sx={{ marginLeft: '5px' }}
          >
            <RemoveIcon />
          </IconButton>
        </div>
      ))}
      {isEmailPresent
      && (
      <FormControl variant="standard" sx={{ width: '91%' }}>
        <InputLabel
          className={classes.subjectLabel}
          htmlFor="email-subject-input"
        >
          {t('emailSubject')}
        </InputLabel>
        <TextField
          className={classes.subjectText}
          id="email-subject-input"
          name="email-subject-input"
          placeholder="Navigine Tracking Notification"
          value={subject || ''}
          variant="standard"
          InputProps={{ disableUnderline: true }}
          inputProps={{ maxLength: 60 }}
          onChange={(e) => handleChangeSubject(e)}
        />
      </FormControl>
      )}
    </DialogContent>
  );
}
