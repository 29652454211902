import { withStyles } from 'tss-react/mui';
import TextField from '@mui/material/TextField';

const InputComponent = withStyles(TextField, () => ({
  root: {
    width: '100%',
    margin: '7px 0',
  },
}));

export default InputComponent;
