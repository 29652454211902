/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import i18next from 'i18next';

import { Card, CardContent } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import Popover from '@mui/material/Popover';
import Select from '@mui/material/Select';
import Avatar from '@mui/material/Avatar';
import Badge from '@mui/material/Badge';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';

import DoneAllIcon from '@mui/icons-material/DoneAll';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import {
  changeAppInToken,
} from 'store/actions/app';
import { selectNotificationsArray, setLastId, setNotifications } from 'store/slices/notifications';
import {
  fetchAppInProgress, fetchedAppsList, selectAppAppsObject,
  selectAppCurrentApp, setApp,
} from 'store/slices/app';
import { selectUserAvatarUrl } from 'store/slices/user';
import { selectZonesMap } from 'store/slices/zones';
import { logout } from 'store/actions/user';

import AlertIcon from 'assets/images/Alert.svg';
import NotificationIcon from 'assets/images/Notification.svg';
import {
  allValuesConstant, notificationPriorities as prioritesEnum, notificationTypes, profile,
} from 'constans';
import init from 'helpers/init';
import { setSelectedGroup } from 'store/slices/groups';
import isZoneRelatedNotification from 'helpers/isZoneRelatedNotification';
import useStyles from './RightMenu.styles';

import ProfileIcon from '../../../assets/images/Profile.svg';
import LogoutIcon from '../../../assets/images/Login.svg';

const shortenedLanguages = {
  en: 'EN',
  ru: 'РУС',
  de: 'DE',
  et: 'EST',
  cn: '中文',
  jp: '日本語',
};

const selectRole = (state) => state?.app?.currentApp?.role;

const isNotifEm = (notif) => {
  if (!notif || !notif.trackedConditions) {
    return false;
  }
  const { priority: priorityObj } = notif.trackedConditions;
  if (!priorityObj) {
    return false;
  }
  const { priority } = priorityObj;

  return prioritesEnum.high === priority;
};

function AppList() {
  const dispatch = useDispatch();
  const appsObject = useSelector(selectAppAppsObject);
  const currentApp = useSelector(selectAppCurrentApp);
  const userRole = useSelector(selectRole);
  const notificationsArray = useSelector(selectNotificationsArray);
  const avatarUrl = useSelector(selectUserAvatarUrl);
  const zonesMap = useSelector(selectZonesMap);

  const { t } = useTranslation(['notifications', 'report']);
  const { classes, cx } = useStyles();

  const [unreadAllLen, setUnreadAllLen] = useState(0);
  const [unreadEmLen, setUnreadEmLen] = useState(0);
  const [notifAllArr, setNotifAllArr] = useState(JSON.parse(JSON.stringify(notificationsArray))
   || []);
  const [notifEmArr, setNotifEmArr] = useState([]);
  const [lang, setLang] = useState(i18next.language);
  const navigate = useNavigate();

  const maxBadge = 99;
  useEffect(() => {
    let unreadArrLen = 0;
    let unreadEm = 0;
    const emArr = notifAllArr.filter((notif) => {
      if (!notif.viewed) {
        unreadArrLen += 1;
      }

      const highAlert = isNotifEm(notif);

      if (!notif.viewed && highAlert) {
        unreadEm += 1;
      }

      return highAlert;
    });
    setUnreadAllLen(unreadArrLen);
    setNotifEmArr(emArr);
    setUnreadEmLen(unreadEm);
  }, [notifAllArr]);

  useEffect(() => {
    setNotifAllArr([...notificationsArray]);
  }, [notificationsArray]);

  const defAllProp = {
    color: 'error',
    children: <img alt="badge" src={NotificationIcon} />,
  };

  const defEmProp = {
    color: 'error',
    children: <img alt="badge" src={AlertIcon} />,
  };
  const handleChangeApp = async (event) => {
    dispatch(fetchAppInProgress());
    const selectedAppId = event.target.value;
    const gotNewToken = await changeAppInToken(selectedAppId, dispatch);
    if (!gotNewToken) {
      return;
    }
    dispatch(setApp(appsObject[selectedAppId]));
    dispatch(setSelectedGroup({ id: allValuesConstant }));
    dispatch(setNotifications([]));
    dispatch(setLastId(''));
    setNotifAllArr([]);
    setUnreadAllLen(0);
    setUnreadEmLen(0);
    localStorage.setItem('appID', selectedAppId);
    await init(dispatch);
    dispatch(fetchedAppsList());
  };
  const notificationDateFormat = 'dd.MM.yyyy HH:mm';

  const formatConditionDuration = (time) => {
    if (time <= 0) {
      return `0 ${t('report:sec')}`;
    }
    const minutes = Math.trunc(time / 60);
    const seconds = time % 60;

    return `${minutes > 0 ? `${minutes} ${t('report:min')}` : ''} ${
      seconds > 0 ? `${seconds} ${t('report:sec')}` : ''
    }`;
  };

  const formatNotification = (notification) => {
    const {
      trackedObjects: object,
      trackedGroups: group,
      trackedConditions: condition,
      created_at: createdAt,
      zone_guids: zoneGuids,
    } = notification;
    const zoneGuid = condition?.zone_guid;
    const groupTitle = group ? group.title : '';
    let zoneTitle = '';
    if (zoneGuid && zonesMap.get(+zoneGuid)) {
      zoneTitle = zonesMap.get(+zoneGuid).title;
    }
    const conditionType = condition?.trackedConditionTypes?.type;

    if (!isZoneRelatedNotification(conditionType) && zoneGuids && Array.isArray(zoneGuids)) {
      zoneTitle = zoneGuids.reduce((acc, guid) => {
        const title = zonesMap.get(+guid)?.title;
        if (title) {
          acc += acc ? `, ${title}` : title;
        }
        return acc;
      }, '');
    }
    let conditionDuration = condition.time;
    if (conditionDuration) {
      conditionDuration = formatConditionDuration(conditionDuration);
    }

    const dateFormatted = format(new Date(createdAt), notificationDateFormat);
    let result = '';
    if (object?.title) {
      result += `${object.title} `;
    }
    switch (conditionType) {
      case notificationTypes.standing:
        if (zoneTitle) {
          result += t('STANDING_FULL', {
            zone: zoneTitle,
            time: conditionDuration,
          });
        } else {
          result += t('STANDING');
        }
        break;
      case notificationTypes.standingOut:
        if (zoneTitle) {
          result += groupTitle
            ? `${groupTitle} ${t('STANDING_OUT_ALERT', {
              zone: zoneTitle,
              time: conditionDuration,
            })}`
            : t('STANDING_OUT_ALERT', {
              zone: zoneTitle,
              time: conditionDuration,
            });
        } else {
          result += t('STANDING_OUT');
        }
        break;
      case notificationTypes.exit:
        if (zoneTitle) {
          result += t('EXIT_ALERT', { zone: zoneTitle });
        } else {
          result += t('EXIT');
        }
        break;
      case notificationTypes.entry:
        if (zoneTitle) {
          result += t('ENTRY_ALERT', { zone: zoneTitle });
        } else {
          result += t('ENTRY_FULL');
        }
        break;
      case notificationTypes.sos:
        if (zoneTitle) {
          result += t('SOS_ZONE', { zone: zoneTitle });
        } else {
          result += t('SOS');
        }
        break;
      case notificationTypes.fall:
        result += t('FALL');
        break;
      case notificationTypes.immobility:
        result += t('IMMOBILITY');
        break;
      default:
        result += conditionType;
        break;
    }

    return `${result} ${dateFormatted}`;
  };

  const [anchorAllEl, setAnchorAllEl] = useState(null);
  const [anchorEmEl, setAnchorEmEl] = useState(null);
  const handleAllClick = (event) => {
    setAnchorAllEl(event.currentTarget);
  };

  const handleEmClick = (event) => {
    setAnchorEmEl(event.currentTarget);
  };

  const handleAllClose = () => {
    setAnchorAllEl(null);
  };

  const handleEmClose = () => {
    setAnchorEmEl(null);
  };

  const hoverNotification = (notification) => {
    if (notification.viewed) {
      return;
    }
    const newnotificationsArray = notificationsArray.map((el) => {
      if (el.id === notification.id) {
        return { ...el, viewed: true };
      }
      return el;
    });

    dispatch(setNotifications(newnotificationsArray));
  };

  const onClickReadAll = () => {
    const newnotificationsArray = JSON.parse(JSON.stringify(notificationsArray))
      .map((n) => {
        n.viewed = true;
        return n;
      });
    dispatch(setNotifications(newnotificationsArray));
  };

  const onClickReadAllEm = () => {
    const newNotifEmObj = JSON.parse(JSON.stringify(notificationsArray))
      .map((n) => {
        const { priority: prirObj } = n.trackedConditions;
        if (!prirObj) {
          return n;
        }
        const { priority } = prirObj;

        const highAlert = prioritesEnum.high === priority;
        if (highAlert) {
          n.viewed = true;
        }
        return n;
      });
    dispatch(setNotifications(newNotifEmObj));
  };

  const onClickReadEm = () => {
    const newnotificationsArray = JSON.parse(JSON.stringify(notificationsArray))
      .map((n) => {
        n.viewed = true;
        return n;
      });
    dispatch(setNotifications(newnotificationsArray));
  };

  const openAll = Boolean(anchorAllEl);
  const openEm = Boolean(anchorEmEl);
  const idAll = openAll ? 'simple-all-popover' : undefined;
  const idEm = openEm ? 'simple-em-popover' : undefined;
  const appsArray = Object.keys(appsObject);

  const getNotifTxtJss = (notification) => {
    const highAlert = isNotifEm(notification);
    const txtStyle = highAlert ? classes.textEm : classes.text;
    let isReadTxtStyle;
    if (!notification.viewed) {
      if (highAlert) {
        isReadTxtStyle = classes.unreadTextEm;
      } else {
        isReadTxtStyle = classes.unviewedNotification;
      }
    }

    return cx(
      txtStyle,
      isReadTxtStyle,
    );
  };

  const getNotifBoxJss = (notification) => {
    const highAlert = isNotifEm(notification);
    let jss;
    if (!notification.viewed) {
      if (highAlert) {
        jss = classes.unreadBoxTxtEm;
      } else {
        jss = classes.unreadBoxTxt;
      }
    }

    return cx(
      classes.boxTxt,
      jss,
    );
  };

  const handleChangeLanguage = (event) => {
    const language = event.target.value;
    i18next.changeLanguage(language, () => {
      setLang(language);
    });
  };

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    dispatch(logout());
    navigate('/login');
  };

  const handleProfileClick = () => {
    window.location.replace(profile);
  };

  return (
    <div className={classes.appListContainer}>
      {userRole === 'demo'
      && (
      <FormControl variant="standard" className={classes.selectorLanguage}>
        <Select
          variant="standard"
          labelId="Language-selector-label"
          id="language-select"
          name="language=select"
          label="language=select"
          value={lang}
          disableUnderline
          renderValue={(value) => shortenedLanguages[value]}
          onChange={handleChangeLanguage}
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'center',
            },
          }}
        >
          <MenuItem value="ru">Русский</MenuItem>
          <MenuItem value="en">English</MenuItem>
          <MenuItem value="et">Eestlane</MenuItem>
          <MenuItem value="cn">中文</MenuItem>
          <MenuItem value="jp">日本語</MenuItem>
          <MenuItem value="de">Deutsch</MenuItem>
        </Select>
      </FormControl>
      )}
      {currentApp.id && appsArray.length > 0 ? (
        <Select
          variant="standard"
          className={classes.list}
          labelId="app-select"
          id="app-select"
          name="app-select"
          disableUnderline
          value={currentApp.id}
          onChange={handleChangeApp}
          // eslint-disable-next-line max-len
          // eslint-disable-next-line react/jsx-props-no-spreading, react/no-unstable-nested-components
          IconComponent={(props) => (<ExpandMoreIcon style={{ marginRight: '10px' }} {...props} />)}
        >
          {appsArray.map((appId) => (
            <MenuItem className={classes.text} key={appId} value={appId}>
              {/* <img src={LocationPoint} alt="app" className={classes.selectAppLocation} /> */}
              {appsObject[appId].title}
            </MenuItem>
          ))}
        </Select>
      ) : (
        <div>
          <p>No apps</p>
        </div>
      )}
      <Badge
        className={classes.badge}
        onDoubleClick={onClickReadEm}
        onClick={handleEmClick}
        badgeContent={unreadEmLen}
        max={maxBadge}
        overlap="rectangular"
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...defEmProp}
      />
      <Popover
        id={idEm}
        open={openEm}
        anchorEl={anchorEmEl}
        onClose={handleEmClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Card>
          <CardContent className={classes.cardEl}>
            {notifEmArr.map((notification, index) => {
              const key = `alert${index}${1}`;
              return (
                <div
                  onMouseOver={() => hoverNotification(notification)}
                  onFocus={() => hoverNotification(notification)}
                  key={key}
                  className={cx(
                    classes.boxTxt,
                    !notification.viewed && classes.unreadBoxTxtEm,
                  )}
                >
                  <p
                    className={cx(
                      classes.textEm,
                      !notification.viewed && classes.unreadTextEm,
                    )}
                  >
                    {formatNotification(notification)}
                  </p>
                </div>
              );
            })}
          </CardContent>
          <div className={classes.btnWrapper}>
            <Button
              className={classes.btn}
              color="secondary"
              type="button"
              onClick={onClickReadAllEm}
            >
              <DoneAllIcon color="secondary" />
              {t('readAll')}
            </Button>
            {/* <Button
                className={classes.btn}
                color="secondary"
                type="button"
                onClick={handleEmClose}
              >
                <CloseIcon />
                {t('close')}
              </Button> */}
          </div>
        </Card>
      </Popover>
      <Badge
        overlap="rectangular"
        onDoubleClick={onClickReadAll}
        onClick={handleAllClick}
        badgeContent={unreadAllLen}
        max={maxBadge}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...defAllProp}
      />
      <Popover
        id={idAll}
        open={openAll}
        anchorEl={anchorAllEl}
        onClose={handleAllClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Card>
          <CardContent className={classes.cardEl}>
            {notifAllArr.map((notification, index) => {
              const key = `alert${index}${1}`;
              return (
                <div
                  onMouseOver={() => hoverNotification(notification)}
                  onFocus={() => hoverNotification(notification)}
                  key={key}
                  className={
                    getNotifBoxJss(notification)
}
                >
                  <p
                    className={getNotifTxtJss(notification)}
                  >
                    {formatNotification(notification)}
                  </p>
                </div>
              );
            })}
          </CardContent>
          <div className={classes.btnWrapper}>
            <Button
              className={classes.btn}
              color="secondary"
              type="button"
              onClick={onClickReadAll}
            >
              <DoneAllIcon color="secondary" />
              {t('readAll')}
            </Button>
            {/* <Button
              className={classes.btn}
              color="secondary"
              type="button"
              onClick={handleAllClose}
            >
              <CloseIcon />
              {t('close')}
            </Button> */}
          </div>
        </Card>
      </Popover>
      <Avatar alt="user avatar" src={avatarUrl} onClick={handleClick} className={classes.avatar} />

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem onClick={handleProfileClick}>
          <img src={ProfileIcon} alt="Profile" style={{ marginRight: '8px' }} />
          {t('PROFILE')}
        </MenuItem>
        <MenuItem onClick={handleLogout}>
          <img src={LogoutIcon} alt="Logout" style={{ marginRight: '8px' }} />
          {t('LOGOUT')}
        </MenuItem>
      </Menu>
    </div>
  );
}

export default AppList;
