import { selectNoOneConstant } from 'constans';
import { processObjectReport } from 'helpers/processObjectReports';
import request, { endPointsEnum } from 'services/http';

import { createAlert } from 'store/slices/alert';
import {
  fetchAlertReportEnd, fetchAlertReportInProgress, fetchContactReportsInProgress,
  fetchDistanceTraveledInProgress, fetchDistanceTraveledReportEnd,
  fetchInventoryInProgress, fetchInventoryReportEnd, fetchedContactReports,
  setActivityReport, setAlertReportList,
  setBeaconAnalytic, setContactReports,
  setContactZoneReports, setGatewayStatus, setDistanceTraveledReport,
  setFinalObjectReport, setFinalZoneReport, setInventoryReport, setObjectReport,
  setTimeOutOfZones, setZoneReport, setTimeFinalReportNoZoneData,
} from 'store/slices/reports';

export const fetchInventoryReport = () => async (dispatch, getState) => {
  try {
    dispatch(fetchInventoryInProgress());
    const state = getState();
    const { dateFrom, dateTo, currentObject } = state.reports;
    const { currentFloor } = state.floor;
    const { currentLocation } = state.location;
    const { selectedZone } = state.zones;

    // todo: fix sublocation selectors to have possibility to select all sublocations
    const querryParrams = {
      from: Math.round(dateFrom.getTime() / 1000),
      to: Math.round(dateTo.getTime() / 1000),
      api_key: state.app.currentApp.api_key,
      location_id: currentLocation.id,
      sublocation_id: currentFloor.id || undefined,
      zone_guid: selectedZone?.id > 0 ? selectedZone.id : undefined,
      object_id:
        currentObject && currentObject.id ? currentObject.id : undefined,
    };

    const response = await request.reports.get(endPointsEnum.inventoryReport, {
      params: querryParrams,
    });

    if (!response.report || !response.lastCoordinates) {
      throw new Error('Error in response');
    }

    dispatch(setInventoryReport(response));
    dispatch(fetchInventoryReportEnd());
  } catch (error) {
    dispatch(fetchInventoryReportEnd());
    dispatch(createAlert({ messageType: 'error', message: error.message }));
  }
};

export const fetchDistanceTraveledReport = () => async (dispatch, getState) => {
  try {
    dispatch(fetchDistanceTraveledInProgress());
    const state = getState();
    const { dateFrom, dateTo, currentObject } = state.reports;
    const { currentFloor } = state.floor;
    const { currentLocation } = state.location;
    // todo: fix sublocation selectors to have possibility to select all sublocations
    const queryParams = {
      location_id: currentLocation.id,
      sublocation_id: currentFloor.id || undefined,
      object_id:
        currentObject && currentObject.id ? currentObject.id : undefined,
      from: Math.round(dateFrom.getTime() / 1000),
      to: Math.round(dateTo.getTime() / 1000),
      api_key: state.app.currentApp.api_key,
    };
    const day = 24 * 60 * 61 * 1000;
    if (dateTo - dateFrom > day) {
      throw new Error('More than a 24-hour period selected');
    }
    if (currentObject) {
      const response = await request.reports.get(endPointsEnum.distanceReport, {
        params: queryParams,
      });
      if (!response || response.error) {
        throw new Error('Error in response');
      }
      dispatch(setDistanceTraveledReport(response));
      dispatch(fetchDistanceTraveledReportEnd());
    } else {
      throw new Error('No object selected');
    }
  } catch (error) {
    dispatch(fetchDistanceTraveledReportEnd());
    dispatch(createAlert({ messageType: 'error', message: error.message }));
  }
};

export const fetchAlertReports = (isExport = false) => async (dispatch, getState) => {
  try {
    const state = getState();
    const {
      dateFrom,
      dateTo,
      currentObject,
      // alertReportLimit,
      // currentPage,
    } = state.reports;
    const { currentFloor } = state.floor;
    const { currentLocation } = state.location;
    const { selectedGroup } = state.groups;
    const { selectedZone, zonesMap } = state.zones;
    const queryParrams = {
      from: Math.round(dateFrom.getTime() / 1000),
      to: Math.round(dateTo.getTime() / 1000),
      location_id: currentLocation.id || undefined,
      sublocation_id: currentFloor.id || undefined,
      zone_guid: (selectedZone && selectedZone.id > 0) ? selectedZone.id : undefined,
      group_id:
          selectedGroup && selectedGroup.id > 0 ? selectedGroup.id : undefined,
      object_id:
          currentObject && currentObject.id ? currentObject.id : undefined,
      api_key: state.app.currentApp.api_key,
      destination: 'report',
    };
    if (isExport) {
      queryParrams.export = 'xls';
    }
    dispatch(fetchAlertReportInProgress());
    const response = await request.tracking.get(
      endPointsEnum.trackedNotifications,
      { params: queryParrams },
    );
    if (isExport) {
      const filename = 'report-alerts.xlsx';
      const blob = await response.blob();
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      document.body.appendChild(a);
      a.href = url;
      a.download = filename;
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
      dispatch(fetchAlertReportEnd());
      return;
    }
    if (!response) {
      throw new Error('Error in response');
    }
    if (!zonesMap) {
      throw new Error('Error loading zones data');
    }
    const formatReponse = response.map((alert, i) => {
      const guidsExist = alert.zone_guids
        && Array.isArray(alert.zone_guids)
        && alert.zone_guids.length > 0;
      if (alert.zone_title === null && guidsExist) {
        const findTitles = alert.zone_guids
          .map((guid) => zonesMap.get(+guid)?.title)
          .filter((title) => !!title);
        return { ...alert, id: i, zone_title: findTitles.join(', ') };
      }
      return { ...alert, id: i };
    });
    dispatch(setAlertReportList(formatReponse));
    dispatch(fetchAlertReportEnd());
  } catch (error) {
    dispatch(fetchAlertReportEnd());
    dispatch(createAlert({ messageType: 'error', message: error.message }));
  }
};

export const fetchObjectReports = (floorId, locationId) => async (dispatch, getState) => {
  try {
    const state = getState();
    const { dateFrom, dateTo, currentObject } = state.reports;
    if (!currentObject || !currentObject.id) {
      throw new Error('please select object');
    }
    const params = {
      api_key: state.app.currentApp.api_key,
      from: Math.round(dateFrom.getTime() / 1000),
      to: Math.round(dateTo.getTime() / 1000),
      ...(locationId !== selectNoOneConstant) && { location_id: locationId },
      ...(floorId !== selectNoOneConstant) && { sublocation_id: floorId },
    };
    dispatch(fetchContactReportsInProgress());
    const path = `${endPointsEnum.reportsObject}/${currentObject.id}`;

    const response = await request.reports.get(path, { params });
    dispatch(fetchedContactReports());
    if (!response.data) {
      throw new Error('Error in response');
    }
    const processedData = processObjectReport(response.data, dateFrom, dateTo);
    dispatch(setObjectReport({ list: processedData, dateFrom, dateTo }));
  } catch (error) {
    dispatch(fetchedContactReports());
    dispatch(createAlert({ messageType: 'error', message: error.message }));
  }
};

export const fetchFinalObjectReports = (floorId, locationId) => async (dispatch, getState) => {
  try {
    const state = getState();
    const { dateFrom, dateTo, currentObject } = state.reports;
    if (!currentObject || !currentObject.id) {
      throw new Error('Please select an object');
    }
    const params = {
      api_key: state.app.currentApp.api_key,
      from: Math.round(dateFrom.getTime() / 1000),
      to: Math.round(dateTo.getTime() / 1000),
      ...(locationId !== selectNoOneConstant) && { location_id: locationId },
      ...(floorId !== selectNoOneConstant) && { sublocation_id: floorId },
    };

    dispatch(fetchContactReportsInProgress());
    const path = `${endPointsEnum.groupedObject}/${currentObject.id}`;

    const response = await request.reports.get(path, { params });
    dispatch(fetchedContactReports());
    if (!response.groupReport) {
      throw new Error('Error in response');
    }
    response.groupReport?.sort((a, b) => {
      if (!a.inside && !b.inside) return 0;
      if (!a.inside) return 1;
      if (!b.inside) return 1;
      return +a.inside - +b.inside;
    });
    dispatch(setTimeFinalReportNoZoneData(response.noData));
    dispatch(setTimeOutOfZones(response.outsideOfZones));
    dispatch(setFinalObjectReport({ list: response.groupReport, dateFrom, dateTo }));
  } catch (error) {
    dispatch(fetchedContactReports());
    dispatch(createAlert({ messageType: 'error', message: error.message }));
  }
};

export const fetchContactReports = () => async (dispatch, getState) => {
  try {
    const state = getState();
    const { dateFrom, dateTo, currentObject } = state.reports;
    if (!currentObject || !currentObject.id) {
      throw new Error('please select object');
    }
    const params = {
      api_key: state.app.currentApp.api_key,
      from: Math.round(dateFrom.getTime() / 1000),
      to: Math.round(dateTo.getTime() / 1000),
      objectId: currentObject.id,
    };

    dispatch(fetchContactReportsInProgress());

    const response = await request.reports.get(endPointsEnum.socialContacts, {
      params,
    });
    if (!response.data) {
      throw new Error('Error in response');
    }

    dispatch(setContactReports(response.data || []));
    dispatch(fetchedContactReports());
  } catch (error) {
    dispatch(fetchedContactReports());
    dispatch(createAlert({ messageType: 'error', message: error.message }));
  }
};

export const fetchZoneContactReports = () => async (dispatch, getState) => {
  try {
    const state = getState();
    const {
      dateFrom, dateTo, currentObject, duration,
    } = state.reports;
    if (!currentObject || !currentObject.id) {
      throw new Error('please select object');
    }
    const params = {
      from: Math.round(dateFrom.getTime() / 1000),
      to: Math.round(dateTo.getTime() / 1000),
      id: currentObject.id,
      duration,
    };

    dispatch(fetchContactReportsInProgress());

    const response = await request.reports.get(endPointsEnum.zonesContacts, {
      params,
    });
    if (!response.data) {
      throw new Error('Error in response');
    }
    dispatch(setContactZoneReports(response.data));
    dispatch(fetchedContactReports());
  } catch (error) {
    dispatch(fetchedContactReports());
    dispatch(createAlert({ messageType: 'error', message: error.message }));
  }
};

export const fetchGatewayStatus = () => async (dispatch, getState) => {
  try {
    const state = getState();
    const { api_key: apiKey } = state.app.currentApp;
    const params = {
      api_key: apiKey,
    };

    dispatch(fetchContactReportsInProgress());

    const response = await request.reports.get(endPointsEnum.gatewayStatus, {
      params,
    });
    if (!response.data) {
      throw new Error('Error in response');
    }

    dispatch(setGatewayStatus(response.data));
    dispatch(fetchedContactReports());
  } catch (error) {
    dispatch(fetchedContactReports());
    dispatch(createAlert({ messageType: 'error', message: error.message }));
  }
};

export const getHistoryListByObj = () => async (dispatch, getState) => {
  try {
    const state = getState();
    const { dateFrom, dateTo } = state.reports;
    const { currentApp } = state.app;
    const { currentFloor } = state.floor;
    const { currentObject } = state.reports;
    const dateFromTs = Math.round(dateFrom.getTime() / 1000);
    const querryParams = {
      api_key: currentApp.api_key,
      sublocation_id: currentFloor.id,
      from: dateFromTs,
      to: Math.round(dateTo.getTime() / 1000),
    };
    if (currentObject) {
      querryParams.object_id = currentObject.id;
    }

    const response = await request.reports.get(endPointsEnum.workDaypicture, {
      params: querryParams,
    });
    const { historyLines, legend } = response;

    if (!historyLines || !legend) {
      throw new Error('Wrong response format');
    }
    return { historyLines, legend };
  } catch (error) {
    dispatch(createAlert({ messageType: 'error', message: error.message }));
    return null;
  }
};

export const getGetHeatmapsReport = (
  dispatch,
  floorId,
  dateFrom,
  dateTo,
  currentObject,
) => async () => {
  try {
    const querryParams = {
      floor: floorId,
      from: Math.round(dateFrom.getTime() / 1000),
      to: Math.round(dateTo.getTime() / 1000),
    };
    if (currentObject) {
      querryParams.objects = currentObject.id;
    }
    const response = await request.reports.get(endPointsEnum.heatmaps, {
      params: querryParams,
    });
    return response;
  } catch (error) {
    dispatch(createAlert({ messageType: 'error', message: error.message }));
    return null;
  }
};

export const fetchNumberDevicesReport = () => async (dispatch, getState) => {
  try {
    const state = getState();
    const { dateFrom, dateTo } = state.reports;
    const { currentFloor } = state.floor;
    const { selectedGroup } = state.groups;

    const params = {
      from: Math.round(dateFrom.getTime() / 1000),
      to: Math.round(dateTo.getTime() / 1000),
      'filter[sublocationId]': currentFloor.id,
      group_id: (selectedGroup && selectedGroup.id > 0) ? selectedGroup.id : undefined,
    };
    dispatch(fetchDistanceTraveledInProgress());
    const response = await request.reports.get('beacons-analytic', {
      params,
    });
    dispatch(setBeaconAnalytic(response.data));
    if (!response.data) {
      throw new Error('Error in response');
    }
    dispatch(fetchDistanceTraveledReportEnd());
  } catch (error) {
    dispatch(createAlert({ messageType: 'error', message: error.message }));
  }
};

export const fetchZoneReport = (selectedZone) => async (dispatch, getState) => {
  try {
    const state = getState();
    const { dateFrom, dateTo } = state.reports;
    const params = {
      api_key: state.app.currentApp.api_key,
      from: Math.round(dateFrom.getTime() / 1000),
      to: Math.round(dateTo.getTime() / 1000),
      zone_guid: selectedZone.id,
    };
    dispatch(fetchContactReportsInProgress());
    const response = await request.reports.get('zones', {
      params,
    });
    dispatch(setZoneReport(response.data));
    dispatch(fetchedContactReports());
    if (!response.data) {
      throw new Error('Error in response');
    }
  } catch (error) {
    dispatch(fetchedContactReports());
    dispatch(createAlert({ messageType: 'error', message: error.message }));
  }
};

export const fetchFinalZoneReport = () => async (dispatch, getState) => {
  try {
    const state = getState();
    const { dateFrom, dateTo } = state.reports;
    const { currentFloor } = state.floor;
    const params = {
      api_key: state.app.currentApp.api_key,
      from: Math.round(dateFrom.getTime() / 1000),
      to: Math.round(dateTo.getTime() / 1000),
      sublocation_id: currentFloor.id,
    };
    dispatch(fetchContactReportsInProgress());

    const response = await request.reports.get('zones', { params });
    dispatch(fetchedContactReports());
    if (!response) {
      throw new Error('Error in response');
    }
    dispatch(setFinalZoneReport(response.data));
  } catch (error) {
    dispatch(fetchedContactReports());
    dispatch(createAlert({ messageType: 'error', message: error.message }));
  }
};

export const fetchActivityReport = (objects) => async (dispatch, getState) => {
  try {
    const state = getState();
    const { currentFloor } = state.floor;
    const { currentLocation } = state.location;
    const { api_key: apiKey } = state.app.currentApp;
    const {
      dateTo, dateFrom,
    } = state.reports;
    const from = Math.round(dateFrom.getTime() / 1000);
    const to = Math.round(dateTo.getTime() / 1000);

    const params = {
      api_key: apiKey,
      from,
      to,
      location: currentLocation.id,
      floor: currentFloor.id,
      objects: objects.map((i) => i.id),
    };

    dispatch(fetchContactReportsInProgress());

    const response = await request.reports.get(endPointsEnum.activityReport, {
      params,
    });
    if (!response) {
      throw new Error('Error in response');
    }

    dispatch(setActivityReport(response));
    dispatch(fetchedContactReports());
  } catch (error) {
    dispatch(fetchedContactReports());
    dispatch(createAlert({ messageType: 'error', message: error.message }));
  }
};
