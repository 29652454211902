import { Bar } from 'react-chartjs-2';
import React from 'react';
import 'chart.js/auto';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { colorsChartArray } from 'constans';

function BarChart({ data, dateFrom, dateTo }) {
  const { t } = useTranslation(['report']);
  const y = Object.keys(data).length > 23
    ? Object.keys(data).map((i) => format(new Date(i * 1000), 'dd LLL  HH:mm'))
    : Object.keys(data).map((i) => format(new Date(i * 1000), 'dd LLL yyyy'));

  const dates = Object.keys(data).map((i) => new Date(i * 1000));
  const isMoreThanAWeek = Math.abs(dateTo - dateFrom) > 7 * 24 * 60 * 60 * 1000;
  const formattedDates = dates.map((currentDate, index) => {
    if (index < dates.length - 1) {
      const nextDate = dates[index + 1];
      const timeDiff = (nextDate - currentDate) / (1000 * 60 * 60);

      if (timeDiff > 23) {
        const nextWeek = new Date(currentDate.getTime() + 7 * 24 * 60 * 60 * 1000);
        return `${format(currentDate, 'dd LLL')} - ${format(nextWeek, 'dd LLL yyyy')}`;
      }
      return format(currentDate, 'dd LLL yyyy');
    } if (isMoreThanAWeek && (dateTo - currentDate) < 7 * 24 * 60 * 60 * 1000) {
      return `${format(currentDate, 'dd LLL yyyy')} - ${t('Now')}`;
    }
    return format(currentDate, 'dd LLL yyyy');
  });

  const x = [];
  Object.values(data).forEach((i) => {
    x.push(i.length);
  });
  const maxSize = Math.max(...x) > 10 ? Math.max(...x) + 1 : 10;
  const state = {
    labels: isMoreThanAWeek ? formattedDates : y,
    datasets: [
      {
        backgroundColor: colorsChartArray[1],
        borderColor: 'rgba(0,0,0,1)',
        borderWidth: 0.3,
        data: x,
        barThickness: 35,
      },
    ],
  };
  return (
    <div style={{ width: y.length > 10 ? `${y.length * 40}px` : 400, height: 300 }}>
      <Bar
        data={state}
        options={{
          plugins: {
            legend: {
              display: false,
            },
          },
          type: 'stack',
          indexAxis: 'x',
          maintainAspectRatio: false,
          legend: {
            display: true,
            position: 'right',
          },
          scales: {
            y: {
              title: {
                display: true,
              },
              min: 0,
              max: maxSize,
              ticks: {
                stepSize: 1,
              },
            },
            // xAxes: [{
            //   barThickness: 1, // number (pixels) or 'flex'
            //   maxBarThickness: 1, // number (pixels)
            // }],
          },
        }}
      />
    </div>
  );
}

export default BarChart;
