import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import MenuItem from '@mui/material/MenuItem';

import { noValuesConstant } from 'constans';
import OutlinedSelector from './OutlinedSelector/OutlinedSelector';

function LocationSelector(props) {
  const {
    className, onChange, allowAllValues, allowSelectNoValues, locations, loading, value, label,
    ...rest
  } = props;
  const { t } = useTranslation(['monitoring']);
  const locationsArray = Object.keys(locations);
  const currentValue = locationsArray.length > 0 && value ? value : noValuesConstant;

  return (
    <div>
      <div style={{ color: '#8194A4', fontSize: '12px', fontWeight: '600' }}>{t('location')}</div>
      <OutlinedSelector
        id="select-location"
        className={className}
        novalueslabel={currentValue === noValuesConstant ? t('noLocations') : null}
        allowNoValues={currentValue === noValuesConstant}
        allowAllValues={allowAllValues}
        allowSelectNoValues={allowSelectNoValues}
        value={currentValue}
        onChange={onChange}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...rest}
      >
        {locationsArray.map((locationId) => (
          <MenuItem key={locationId} value={locationId}>
            {locations[locationId].title}
          </MenuItem>
        ))}
      </OutlinedSelector>
    </div>
  );
}

export default memo(LocationSelector);
