import { debounce } from 'lodash';
import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import RefreshIcon from '@mui/icons-material/Refresh';

import CustomTextField from 'components/settings/TextFiled';

import { sortObjectByStringKey } from 'helpers/sortByField';
import {
  getDebugQueues, getQueueData,
} from 'store/actions/debug';
import { selectAppOpenMenu } from 'store/slices/app';
import {
  selectDebugDebugQueues, selectDebugFetchingDebugList,
  setDebugQueues, setFetchingDebugList, setQueueExpanded,
} from 'store/slices/debug';

import containerStyles from 'components/shared/Container/styles';
import PaginationIcon from 'components/shared/Pagination/PaginationIcon';
import useStyles from 'components/shared/settings/DebugStyles';
import CustomTableHeader from 'components/shared/Tables/Header';

function DebugQueues() {
  const dispatch = useDispatch();
  const fetchingDebugList = useSelector(selectDebugFetchingDebugList);
  const debugQueues = useSelector(selectDebugDebugQueues);

  const openMenu = useSelector(selectAppOpenMenu);

  const { t } = useTranslation(['settings']);
  const { classes } = useStyles();
  const { classes: classesContainer } = containerStyles();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [filter, setFilter] = useState('');
  const [sort, setSort] = useState({
    name: 'none',
    error_messages_per_sec: 'none',
    input_messages_per_sec: 'none',
    first_message_time: 'none',
    last_message_time: 'none',
  });

  const [shownLogMessage, setShownLogMessages] = useState({});

  useEffect(() => {
    dispatch(getDebugQueues());
  }, [dispatch]);

  useEffect(() => {
    if (debugQueues) {
      const transformedObject = debugQueues.reduce((result, item) => {
        if (item) {
          // eslint-disable-next-line no-param-reassign
          result[item.id] = (item.extra && item.extra.log_messages)
            ? item.extra.log_messages.slice(0, 20) : null;
        }
        return result;
      }, {});
      setShownLogMessages(transformedObject);
    }
  }, [debugQueues]);

  const handleSearch = useCallback((value) => {
    dispatch(setFetchingDebugList(true));
    dispatch(getDebugQueues(value));
  }, [dispatch]);

  const debouncedSendRequest = useMemo(() => debounce(handleSearch, 300), [handleSearch]);

  const handleChangeFilter = (event) => {
    setFilter(event.target.value);
    debouncedSendRequest(event.target.value);
  };

  function expandData(queue) {
    if (queue && typeof queue.name === 'string') {
      dispatch(setQueueExpanded(queue.name));
      if (!queue.expanded) {
        dispatch(getQueueData(queue));
      }
    }
  }

  async function refreshLogMessages(queue) {
    await dispatch(getQueueData(queue));
  }

  function showMore(queue, directive) {
    const identifier = queue.id;
    const currentLength = shownLogMessage[identifier]?.length;
    const potentialLength = queue.extra?.log_messages?.length;
    if (currentLength && potentialLength && queue.extra?.log_messages) {
      if (directive === 'more') {
        setShownLogMessages((prev) => ({
          ...prev,
          [identifier]: queue.extra.log_messages
            .slice(0, Math.min(currentLength + 20, potentialLength)),
        }));
      }
      if (directive === 'all') {
        setShownLogMessages((prev) => ({
          ...prev,
          [identifier]: queue.extra.log_messages,
        }));
      }
    }
  }

  function sortColumn(column) {
    const order = sort[column] === 'desc' ? 'asc' : 'desc';
    const result = {};
    Object.keys(sort).forEach((key) => {
      result[key] = key !== column ? 'none' : order;
    });
    setSort(result);
    const array = sortObjectByStringKey(order, debugQueues, column);
    if (filter !== '') {
      dispatch(setDebugQueues(array
        .filter((queue) => queue.name?.toLowerCase()
          .includes(filter.toLowerCase()))));
    } else {
      dispatch(setDebugQueues(array));
    }
  }

  return (
    <div className={!openMenu ? classes.content : classesContainer.noneContent}>
      <div className={classes.topControls}>
        <CustomTextField
          id="standard-multiline-flexible"
          label={t('typeToSearch')}
          value={filter}
          onChange={handleChangeFilter}
          variant="standard"
        />
      </div>
      {fetchingDebugList
        && (
          <div className={classes.spinerWrapper}>
            <CircularProgress size={26} />
          </div>
        )}
      {debugQueues && !fetchingDebugList && (
      <div className={classes.table}>
        <TableContainer className={classes.tableContainer} component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <CustomTableHeader
                  onClick={() => sortColumn('name')}
                  sx={{ width: '27em ' }}
                  canBeSorted
                >
                  <div className="tableTheadCell">
                    <span className="titleText">
                      Name
                    </span>
                    { (sort.name === 'asc')
                        && <ArrowDropUpIcon /> }
                    { (sort.name === 'desc')
                        && <ArrowDropDownIcon /> }
                  </div>
                </CustomTableHeader>
                <CustomTableHeader
                  onClick={() => sortColumn('error_messages_per_sec')}
                  sx={{ width: '4vw' }}
                  canBeSorted
                >
                  <div className="tableTheadCell">
                    <span className="titleText">
                      Error/sec
                    </span>
                    { (sort.error_messages_per_sec === 'asc')
                        && <ArrowDropUpIcon /> }
                    { (sort.error_messages_per_sec === 'desc')
                        && <ArrowDropDownIcon /> }
                  </div>
                </CustomTableHeader>
                <CustomTableHeader
                  onClick={() => sortColumn('input_messages_per_sec')}
                  sx={{ width: '4vw' }}
                  canBeSorted
                >
                  <div className="tableTheadCell">
                    <span className="titleText">
                      Input/sec
                    </span>
                    { (sort.input_messages_per_sec === 'asc')
                        && <ArrowDropUpIcon /> }
                    { (sort.input_messages_per_sec === 'desc')
                        && <ArrowDropDownIcon /> }
                  </div>
                </CustomTableHeader>
                <CustomTableHeader
                  onClick={() => sortColumn('first_message_time')}
                  sx={{ width: '9vw' }}
                  canBeSorted
                >
                  <div className="tableTheadCell">
                    <span className="titleText">
                      First Message
                    </span>
                    { (sort.first_message_time === 'asc')
                        && <ArrowDropUpIcon /> }
                    { (sort.first_message_time === 'desc')
                        && <ArrowDropDownIcon /> }
                  </div>
                </CustomTableHeader>
                <CustomTableHeader
                  onClick={() => sortColumn('last_message_time')}
                  sx={{ width: '9vw' }}
                  canBeSorted
                >
                  <div className="tableTheadCell">
                    <span className="titleText">
                      Last Message
                    </span>
                    { (sort.last_message_time === 'asc')
                        && <ArrowDropUpIcon /> }
                    { (sort.last_message_time === 'desc')
                        && <ArrowDropDownIcon /> }
                  </div>
                </CustomTableHeader>
                <CustomTableHeader>
                  More
                </CustomTableHeader>
              </TableRow>
            </TableHead>
            <TableBody>
              {debugQueues && debugQueues
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((queue, index) => (
                  <React.Fragment key={queue.id}>
                    <TableRow
                      onClick={() => expandData(queue)}
                      style={index % 2 === 0 ? { backgroundColor: 'white' } : { backgroundColor: '#ebebeb' }}
                    >
                      <TableCell>
                        {queue.name}
                      </TableCell>
                      <TableCell>
                        {queue.error_messages_per_sec
                          ? queue.error_messages_per_sec.toFixed(3)
                          : queue.error_messages_per_sec}
                      </TableCell>
                      <TableCell>
                        {queue.input_messages_per_sec
                          ? queue.input_messages_per_sec.toFixed(3)
                          : queue.input_messages_per_sec}
                      </TableCell>
                      <TableCell>
                        {queue.first_message_time}
                      </TableCell>
                      <TableCell>
                        {queue.last_message_time}
                      </TableCell>
                      <TableCell className={classes.expandCell}>
                        <div className={classes.expandIconContainer}>
                          {queue.expanded
                            ? (
                              <ExpandLessIcon
                                className={classes.expandButton}
                              />
                            ) : (
                              <ExpandMoreIcon
                                className={classes.expandButton}
                              />
                            )}
                        </div>
                      </TableCell>
                    </TableRow>
                    {queue.extra && queue.expanded
                        && (
                        <TableRow style={index % 2 === 0 ? { backgroundColor: 'white' } : { backgroundColor: '#ebebeb' }}>
                          <TableCell colSpan={7} className={classes.extraTableContainer}>
                            <Table className={classes.containedTable}>
                              <TableBody>
                                <TableRow>
                                  <TableCell className={classes.extraDataCell}>
                                    <ul className={classes.logMessageList}>
                                      {shownLogMessage[queue.id]
                                       && shownLogMessage[queue.id]
                                         .map((message, i) => {
                                           const ind = i;
                                           return (
                                             <li key={ind + queue.id + message}>
                                               {message}
                                             </li>
                                           );
                                         })}
                                    </ul>
                                    <div className={classes.buttonsDiv}>
                                      <Button
                                        variant="contained"
                                        onClick={() => showMore(queue, 'more')}
                                        disabled={shownLogMessage[queue.id]?.length
                                        === queue.extra?.log_messages?.length
                                        || queue.loading === true}
                                      >
                                        Show more
                                      </Button>
                                      <Button
                                        variant="contained"
                                        onClick={() => showMore(queue, 'all')}
                                        disabled={shownLogMessage[queue.id]?.length
                                        >= queue.extra?.log_messages?.length
                                        || queue.loading === true}
                                      >
                                        Show all
                                        {queue.extra?.log_messages?.length
                                          ? ` (${queue.extra.log_messages.length})` : null}
                                      </Button>
                                      <div className={classes.refreshExtraDataContainer}>
                                        <Button
                                          onClick={() => refreshLogMessages(queue)}
                                          disabled={queue.loading === true}
                                        >
                                          <RefreshIcon />
                                          Refresh data
                                        </Button>
                                        {queue.loading === true && <CircularProgress size="1.5em" />}
                                      </div>
                                    </div>
                                  </TableCell>
                                </TableRow>
                              </TableBody>
                            </Table>

                          </TableCell>

                        </TableRow>

                        )}
                    {!queue.extra && queue.expanded === true && (
                    <TableRow>
                      <TableCell className={classes.spinnerCell} colSpan={3}>
                        <CircularProgress />
                      </TableCell>
                    </TableRow>
                    )}
                  </React.Fragment>
                ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[25, 50, 100, { label: t('All'), value: -1 }]}
            component="div"
            count={debugQueues ? debugQueues.length : 0}
            rowsPerPage={rowsPerPage}
            page={page}
            SelectProps={{
              IconComponent: PaginationIcon,
              inputProps: {
                id: 'selector-rows-per-page',
                name: 'selector-rows-per-page',
              },
            }}
            onPageChange={(_, pageNumber) => setPage(pageNumber)}
            onRowsPerPageChange={(event) => {
              setPage(0);
              setRowsPerPage(+event.target.value);
            }}
            labelDisplayedRows={({ from, to, count }) => (
              `${from}-${to} ${t('of')} ${count !== -1 ? count : `${t('moreThen')} ${to}`}`
            )}
            labelRowsPerPage={t('labelRowsPerPage')}
            nextIconButtonProps={{
              'aria-label': 'Next Page',
              style: {
                color: debugQueues
                    && page === Math.ceil(debugQueues.length / rowsPerPage) - 1
                  ? '#b5b8c4' : '#41afd7',
                background: 'white',
                width: '32px',
                height: '32px',
                margin: '5px',
              },
              autoid: 'pagination-button-previous-collector',
            }}
            backIconButtonProps={{
              'aria-label': 'Previous Page',
              style: {
                color: page === 0 ? '#b5b8c4' : '#41afd7', background: 'white', width: '32px', height: '32px',
              },
              autoid: 'pagination-button-next-collector',
            }}
          />
        </TableContainer>
      </div>
      )}
    </div>
  );
}

export default DebugQueues;
