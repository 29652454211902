import React, { useEffect } from 'react';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CustomButton from 'components/settings/Button.component';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';

import useStyles from '../../containers/monitoring/style';

function DialogMonitoring({
  objectInfo,
  objectInfoArray,
  handleCloseObjectModal,
  openObjectModal,
  Transition,
  setOpenObjectModal,
  floor,
}) {
  const { classes } = useStyles();
  const { t } = useTranslation(['monitoring', 'settings']);
  useEffect(() => {
    if (objectInfo) {
      setOpenObjectModal(true);
    }
  }, [objectInfo, setOpenObjectModal, objectInfoArray]);

  if (!objectInfo) {
    return null;
  }

  const coordinates = {
    x: (objectInfo.kx * floor.w).toFixed(2),
    y: (objectInfo.ky * floor.h).toFixed(2),
    // z: getZ(objectInfo.properties),
    z: (objectInfo?.z)?.toFixed(2) || '',
  };
  const notDisplayPropTypes = new Set(['color', 'kx', 'ky', 'file_url', 'imageSourceName', 'objectType', 'objectColor']);

  const formatTime = (input) => format(
    new Date(input * 1000),
    'yyyy.MM.dd HH:mm:ss',
  );

  const propTableDataUnsort = [];
  if (objectInfo.properties) {
    objectInfo.properties.forEach((prop) => {
      const {
        id, key, value, updated_at: updated,
      } = prop;
      if (!notDisplayPropTypes.has(key) && key !== 'sizeObject') {
        propTableDataUnsort.push({
          key,
          value,
          typeId: id,
          updated,
        });
      }
    });
  }

  const propTableData = [...propTableDataUnsort].sort(
    (a, b) => a.typeId - b.typeId,
  );

  return (
    <div>
      <Dialog
        open={openObjectModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseObjectModal}
        maxWidth="md"
      >
        {objectInfo ? (
          <DialogContent
            className={
          objectInfo.videostream_url
            ? classes.dialogContent
            : classes.noneDialogContent
        }
          >
            <div>
              {objectInfo.file_url ? (
                <div>
                  <img
                    className={classes.itemImage}
                    src={objectInfo.file_url}
                    alt="file_url"
                  />
                </div>
              ) : null}
              {objectInfo.title ? (
                <div>
                  <h3>{objectInfo ? t('objectTitle') : null}</h3>
                  <p>{objectInfo.title}</p>
                </div>
              ) : null}
              {objectInfo.beacon_title ? (
                <div className={classes.deviceWrapper}>
                  <h3>{objectInfo ? t('objectDevcie') : null}</h3>
                  <div>
                    <span className={classes.spanObject}>
                      {objectInfo.beacon_title}
                      {' '}
                    </span>
                    <span className={classes.parentheses}>(</span>
                    <span className={classes.spanObject}>{objectInfo.uuid}</span>
                    <span className={classes.parentheses}>)</span>
                  </div>
                </div>
              ) : null}
              {objectInfo.group_title ? (
                <div>
                  <h3>{objectInfo ? t('objectGroup') : null}</h3>
                  <p>{objectInfo.group_title}</p>
                </div>
              ) : null}
              <div>
                <h3>{t('coordinates')}</h3>
                <p>
                  {`
                X: ${coordinates.x} \u00A0 
                Y: ${coordinates.y}
                ${coordinates.z ? ` \u00A0 Z: ${coordinates.z}` : ''}`}
                </p>
              </div>
              {objectInfo.last_active_time && typeof objectInfo.last_active_time === 'number' ? (
                <div>
                  <h3>{t('settings:Last signal')}</h3>
                  <p>{formatTime(objectInfo.last_active_time)}</p>
                </div>
              ) : null }
              {propTableData.length > 0 && (
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>{t('keys')}</TableCell>
                        <TableCell>{t('properties')}</TableCell>
                        {/* <TableCell>{t('update')}</TableCell> */}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {propTableData.map((prop, index) => (
                        <TableRow
                          // eslint-disable-next-line react/no-array-index-key
                          key={index.toString()}
                          className={`${
                            index % 2 === 0
                              ? classes.tableElementEven
                              : classes.tableElementOdd
                          }`}
                        >
                          <TableCell>{prop.key}</TableCell>
                          <TableCell>{prop.value}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </div>
            {objectInfo.videostream_url ? (
              <div>
                <div>
                  <img
                    className={classes.videoBlock}
                    src={objectInfo.videostream_url}
                    alt={objectInfo.videostream_url}
                  />
                </div>
              </div>
            ) : null}
          </DialogContent>
        ) : null}
        <DialogActions>
          <CustomButton onClick={handleCloseObjectModal} color="primary">
            {t('closeBtn')}
          </CustomButton>
        </DialogActions>
      </Dialog>

    </div>
  );
}

export default DialogMonitoring;
