import React, {
  useState, useEffect, useCallback, useMemo,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';
import FormControl from '@mui/material/FormControl';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

import { format } from 'date-fns';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

import { objectsByAppFetch } from 'store/actions/objects';
import { fetchAlertReports } from 'store/actions/reports';
import { selectAppCurrentApp, selectAppOpenMenu } from 'store/slices/app';
import { selectCurrentFloor, selectFloorsObject, setFloor } from 'store/slices/floors';
import {
  selectGroupsCount, selectGroupsObject, selectGroupsSelectedGroup, setSelectedGroup,
} from 'store/slices/groups';
import { selectCurrentLocation, selectLocationsObject, setLocation } from 'store/slices/locations';
import { selectObjectsByAppArray } from 'store/slices/objects';
import {
  selectReportsAlertReportLimit, selectReportsAlertReportsArray,
  selectReportsCurrentObject, selectReportsCurrentPage, selectReportsIsFetchingAlertReport,
  setAlertPage, setCurrentObject,
} from 'store/slices/reports';
import {
  selectSelectedZone, selectZoneTypes, selectZonesMap, setSelectedZone,
} from 'store/slices/zones';

import { allValuesConstant, selectNoOneConstant } from 'constans';
import DatePickers from 'components/reports/datePickers/datePickers.component';
import BuildButton from 'components/reports/BuildButton.component';
import PaginationIcon from 'components/shared/Pagination/PaginationIcon';
import containerStyles from 'components/shared/Container/styles';
import ZoneSelector from 'components/reports/ReportsZoneSelector';
import GroupsInfinite from 'components/shared/GroupSelector/GroupsInfinite';
import GroupSelector from 'components/reports/ReportsGroupSelector';
import LocationSelector from 'components/reports/ReportsLocationSelector';
import FloorSelector from 'components/reports/ReportsFloorSelector';
import AutoComplete from 'components/reports/ReportsAutoComplete';
import { fetchZones } from 'store/actions/zones';
import useStyles from './alertReport.component.style';

const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const fileExtension = '.xlsx';

function AlertReport() {
  const { classes: classesContainer } = containerStyles();
  const { classes } = useStyles();
  const { t } = useTranslation(['report', 'notifications', 'monitoring']);
  const dispatch = useDispatch();
  const openMenu = useSelector(selectAppOpenMenu);
  const currentApp = useSelector(selectAppCurrentApp);
  const floorsObject = useSelector(selectFloorsObject);
  const currentFloor = useSelector(selectCurrentFloor);
  const groupsObject = useSelector(selectGroupsObject);
  const selectedGroup = useSelector(selectGroupsSelectedGroup);
  const locationsObject = useSelector(selectLocationsObject);
  const currentLocation = useSelector(selectCurrentLocation);
  const objectsByAppArray = useSelector(selectObjectsByAppArray);
  const isLoadingReport = useSelector(selectReportsIsFetchingAlertReport);
  const alertReportsArray = useSelector(selectReportsAlertReportsArray);
  const currentObject = useSelector(selectReportsCurrentObject);
  const alertReportLimit = useSelector(selectReportsAlertReportLimit);
  const currentPage = useSelector(selectReportsCurrentPage);
  const groupsCount = useSelector(selectGroupsCount);
  const zonesMap = useSelector(selectZonesMap);
  const selectedZone = useSelector(selectSelectedZone);
  const zoneTypes = useSelector(selectZoneTypes);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [btnExcelState, setBtnExcelState] = useState(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const fetchReport = async () => {
    setPage(0);
    await dispatch(fetchAlertReports());
    setBtnExcelState(true);
  };

  const selectObject = useCallback((event, object) => {
    dispatch(setCurrentObject(object));
  }, [dispatch]);

  const [objectsFilter, setObjectsFilter] = useState('');

  const handleChangeGroup = useCallback((value) => {
    const groupId = value;
    if (groupId === allValuesConstant) {
      dispatch(setSelectedGroup({ id: allValuesConstant }));
      return;
    }

    const newSelectedGroup = groupsObject[groupId];
    dispatch(setSelectedGroup(newSelectedGroup));
  }, [dispatch, groupsObject]);

  const filteredObjectsArray = useMemo(() => objectsByAppArray.filter((object) => {
    const groupFilter = selectedGroup.id === allValuesConstant
        || parseInt(object.relationships['tracked-group'].data.id, 10)
          === parseInt(selectedGroup.id, 10);
    return groupFilter;
  }), [objectsByAppArray, selectedGroup.id]);

  const handleChangeLocation = useCallback((event) => {
    const locationId = event.target.value;
    dispatch(setLocation(locationsObject[locationId]));
    const floorId = locationsObject[locationId].floors[0].id;
    dispatch(setFloor(floorsObject[floorId]));
    dispatch(setSelectedZone({ id: selectNoOneConstant }));
    dispatch(setSelectedGroup({ id: allValuesConstant }));
  }, [dispatch, floorsObject, locationsObject]);

  const handleChangeFloor = useCallback((event) => {
    const floorId = event.target.value;
    dispatch(setFloor(floorsObject[floorId]));
    dispatch(setSelectedZone({ id: selectNoOneConstant }));
    dispatch(setSelectedGroup({ id: allValuesConstant }));
  }, [dispatch, floorsObject]);

  useEffect(() => {
    const getObjects = async () => {
      await dispatch(objectsByAppFetch(objectsFilter, 0, 4000));
    };
    dispatch(setSelectedZone({ id: selectNoOneConstant }));
    getObjects();
  }, [objectsFilter, currentPage, alertReportLimit, currentApp, dispatch]);

  useEffect(() => {
    if (!selectedZone) {
      dispatch(fetchZones());
    }
  }, [selectedZone, dispatch]);

  useEffect(() => {
    dispatch(setAlertPage(0));
  }, [objectsFilter, alertReportLimit, currentApp, dispatch]);

  const onAutocompliteInput = useCallback(async (event) => {
    event.preventDefault();
    setObjectsFilter(event);
  }, []);
  // const handleChangePage = async (event, newPage) => {
  //   dispatch(setAlertPage(newPage));
  //   await dispatch(fetchAlertReports());
  // };
  const handleChangeZone = (event) => {
    event.preventDefault();
    const zoneId = event.target.value;

    if (zoneId === selectNoOneConstant) {
      dispatch(setSelectedZone({ id: selectNoOneConstant }));
    }
    if (zoneId === allValuesConstant) {
      dispatch(setSelectedZone({ id: allValuesConstant }));
    }
    if (zoneId !== selectNoOneConstant && zoneId !== allValuesConstant) {
      dispatch(setSelectedZone(zonesMap.get(zoneId)));
    }
  };

  // const handleChangeRowsPerPage = async (event) => {
  //   dispatch(setAlertReportLimit(+event.target.value));
  //   dispatch(setAlertPage(0));
  //   await dispatch(fetchAlertReports());
  // };
  const notificationTypes = {
    standing: 'STANDING',
    standingOut: 'STANDING_OUT',
    exit: 'EXIT',
    entry: 'ENTRY',
    sos: 'SOS',
    fall: 'FALL',
    temperature: 'Fever heat',
  };
  const formatCondition = (condition) => {
    let result = '';
    if (!condition) {
      return result;
    }
    switch (condition) {
      case notificationTypes.standing:
        result = t('notifications:STANDING');
        break;
      case notificationTypes.standingOut:
        result = t('notifications:STANDING_OUT');
        break;
      case notificationTypes.exit:
        result = t('notifications:EXIT_FULL');
        break;
      case notificationTypes.entry:
        result = t('notifications:ENTRY_FULL');
        break;
      case notificationTypes.sos:
        result = 'SOS';
        break;
      case notificationTypes.fall:
        result = t('notifications:FALL');
        break;
      case notificationTypes.temperature:
        result = t('heat');
        break;
      default:
        result = condition;
        break;
    }
    return result;
  };
  const exportToCSV = (csvData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };
  const handleDownloadReport = () => {
    const formatReportToCsv = (str) => {
      const alertDate = new Date(str.created_at);
      const objectTitle = t('objectTitle') || 'Object';
      const zoneTitle = t('zoneTitle') || 'Zone';
      const alertType = t('alertType') || 'Type';
      const percent = t('alertTime') || 'Time';
      const result = {};
      result[objectTitle] = str.object_title || `${t('monitoring:objectGroup')} ${str.group_title}` || '';
      result[zoneTitle] = str.zone_title || '';
      result[alertType] = formatCondition(str.notification_type) || 'Wront Type';
      result[percent] = format(alertDate, 'dd LLL yyyy HH:mm:ss') || 'Wrong Time';
      return result;
    };

    const csvData = alertReportsArray.map((element) => formatReportToCsv(element));

    const fileName = 'Alert report';

    exportToCSV(csvData, fileName);
  };
  const floorsObjectFiltred = useMemo(
    () => Object.values(floorsObject)
      .filter((object) => object.location === currentLocation.id),
    [currentLocation.id, floorsObject],
  );

  return (
    // eslint-disable-next-line react/jsx-filename-extension
    <div className={!openMenu ? classes.content : classesContainer.noneContent}>
      <div className={classes.topControls}>
        <form className={classes.formControls} noValidate>
          <div className={classes.controlWrapper}>
            <FormControl variant="standard">
              <LocationSelector
                name="location-selector"
                locations={locationsObject}
                className={classes.selector}
                value={currentLocation.id}
                onChange={handleChangeLocation}
              />
            </FormControl>
          </div>
          <div className={classes.controlWrapper}>
            <FloorSelector
              name="floor-selector"
              floors={floorsObjectFiltred}
              className={classes.selector}
              onChange={handleChangeFloor}
              value={currentFloor.id}
              currentLocationId={currentLocation.id}
            />
          </div>
        </form>
        <form className={`${classes.formControls} ${classes.secondForm}`} noValidate>
          <div className={classes.controlWrapper}>
            {(!groupsCount || groupsCount <= 100)
              ? (
                <GroupSelector
                  name="group-selector"
                  onChange={(e) => handleChangeGroup(e.target.value)}
                  groups={groupsObject}
                  value={selectedGroup.id}
                  className={classes.selector}
                />
              ) : (
                <GroupsInfinite
                  value={selectedGroup}
                  handleChangeGroup={handleChangeGroup}
                  allowSelectAll
                  inputStyles={{ border: 'none', backgroundColor: 'white' }}
                  borderStyle="none"
                  dropdownStyles={{ width: '200px !important' }}
                />
              )}
          </div>
          <div className={classes.controlWrapper}>
            <ZoneSelector
              className={classes.selector}
              onChange={handleChangeZone}
              zones={zonesMap}
              value={selectedZone?.id || allValuesConstant}
              zoneTypes={zoneTypes}
              currentFloorId={currentFloor.id}
            />
          </div>
        </form>
        <form className={classes.formControls} noValidate>
          <div className={classes.selectorWrapp}>
            <AutoComplete
              filteredObjectsArray={filteredObjectsArray}
              currentObject={currentObject}
              selectObject={selectObject}
              onInputChange={onAutocompliteInput}
              bkgColor="#ffffff"
            />
          </div>
          <DatePickers />
        </form>
        <div className={classes.headContainer}>
          <div>
            <Typography variant="h6">{t('alertReport')}</Typography>
          </div>
          <div className={classes.btnWrapper}>
            <div className={classes.btnWrapper}>
              <BuildButton
                className={classes.buildButton}
                variant="outlined"
                color="primary"
                disableRipple
                onClick={fetchReport}
                disabled={isLoadingReport}
              >
                {t('buildReport')}
              </BuildButton>
            </div>
            <div
              className={classes.downloadControlContainer}
              onClick={handleDownloadReport}
              role="button"
              onKeyUp={handleDownloadReport}
              tabIndex="0"
              style={btnExcelState && alertReportsArray.length > 0 && currentApp.role !== 'demo' ? { display: 'inline-flex' } : { display: 'none' }}
            >
              <div className={classes.downloadIcon} />
              <div className={classes.downLoadPhraseContainer}>
                <p className={classes.downLoadPhrase}>
                  {' '}
                  {t('downloadXls')}
                  {' '}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isLoadingReport
        ? (
          <div className={classes.spinerWrapper}>
            <CircularProgress size={26} />
          </div>
        )
        : (
          <div>
            <div className={classes.table}>
              <TableContainer className={classes.tableContainer} component={Paper}>
                <Table stickyHeader className={classes.table} aria-label="simple table">
                  <TableBody>
                    <TableRow>
                      <TableCell className={classes.tableCell} align="left">{t('objectTitle')}</TableCell>
                      <TableCell className={classes.tableCell} align="left">{t('zoneTitle')}</TableCell>
                      <TableCell className={classes.tableCell} align="left">{t('alertType')}</TableCell>
                      <TableCell className={classes.tableCell} align="left">{t('alertTime')}</TableCell>
                    </TableRow>
                    {alertReportsArray
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((alertReport) => {
                        const alertDate = alertReport.created_at
                          && new Date(alertReport.created_at);
                        return (
                          <TableRow key={alertReport.id}>
                            <TableCell className={classes.tableCell} component="th" scope="row">
                              {alertReport.object_title
                              || `${t('monitoring:objectGroup')} ${alertReport.group_title}`
                              || ''}
                            </TableCell>
                            <TableCell className={classes.tableCell} align="left">
                              {alertReport.zone_title || ''}
                            </TableCell>
                            <TableCell className={classes.tableCell} align="left">
                              {formatCondition(alertReport.notification_type)}
                            </TableCell>
                            <TableCell className={classes.tableCell} align="left">
                              {alertDate && format(alertDate, 'dd LLL yyyy HH:mm:ss')}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 25, 50, { label: t('All'), value: -1 }]}
                component="div"
                count={alertReportsArray.length}
                rowsPerPage={rowsPerPage}
                page={page}
                className={classes.pagination}
                SelectProps={{
                  IconComponent: PaginationIcon,
                }}
                classes={{
                  root: classes.root,
                  select: classes.select,
                  selectIcon: classes.selectIcon,
                  caption: classes.caption,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${t('of')} ${count !== -1 ? count : `${t('moreThen')} ${to}`}`}
                labelRowsPerPage={t('labelRowsPerPage')}
                nextIconButtonProps={{
                  'aria-label': 'Next Page',
                  style: {
                    color: '#000', background: 'white', width: '32px', height: '32px', margin: '5px',
                  },
                  autoid: 'pagination-button-previous-collector',
                }}
                backIconButtonProps={{
                  'aria-label': 'Previous Page',
                  style: {
                    color: page === 0 ? '#b5b8c4' : '#41afd7', background: 'white', width: '32px', height: '32px',
                  },
                  autoid: 'pagination-button-next-collector',
                }}
              />
            </div>
          </div>
        )}
    </div>
  );
}

export default AlertReport;
