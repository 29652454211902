import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import MenuItem from '@mui/material/MenuItem';

import { noValuesConstant, selectNoOneConstant } from 'constans';
import OutlinedSelector from './OutlinedSelector/OutlinedSelector';

const ZonesSelector = memo((props) => {
  const {
    className, zones, zoneTypes, currentFloorId, value,
    onChange, ...rest
  } = props;
  const { t } = useTranslation(['monitoring']);
  const zonesArray = [];

  zones?.forEach((zone, key) => {
    if (zone.floor === +currentFloorId) {
      zonesArray.push(key);
    }
  });
  const zoneTypesLength = Object.keys(zoneTypes).length;

  const checkCurrentValue = () => {
    if (zonesArray.length > 0) {
      if (value) {
        return value;
      }
      return selectNoOneConstant;
    }
    return noValuesConstant;
  };
  const currentValue = checkCurrentValue();

  const fieldValidation = () => currentValue !== noValuesConstant;

  const createNoValuesLabel = () => {
    if (fieldValidation()) {
      return null;
    }
    if (zoneTypesLength > 0) {
      return t('noZones');
    }
    return t('noZoneTypes');
  };

  return (
    <div>
      <div style={{ color: '#8194A4', fontSize: '12px', fontWeight: '600' }}>{t('zonesSelectorLabel')}</div>
      <OutlinedSelector
        id="select-zone"
        className={className}
        novalueslabel={createNoValuesLabel()}
        allowNoValues={!fieldValidation()}
        allowAllValues={fieldValidation() && true}
        allowSelectNoValues={fieldValidation() && true}
        value={currentValue}
        onChange={onChange}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...rest}
      >
        {zonesArray.map((zoneId) => (
          <MenuItem key={zoneId} value={zoneId}>
            {zones.get(zoneId).title}
          </MenuItem>
        ))}
      </OutlinedSelector>
    </div>
  );
});

export default ZonesSelector;
