import React, { useState, memo } from 'react';
import { useTranslation } from 'react-i18next';

import DialogPurchase from 'components/dialog/DialogPurchase/DialogPurchase';
import DialogGuidelines from 'components/dialog/DialogGuidelines/DialogGuidelines';

import { ReactComponent as PurchaseBagIcon } from 'assets/images/PurchaseBag.svg';
import useStyles from './DemouserFooterStyles';

function DemoUserFooter() {
  const { classes, cx } = useStyles();
  const { t } = useTranslation(['topMenu']);

  const [openPurchaseModal, setOpenPurchaseModal] = useState(false);
  const [openGuidelinesModal, setOpenGuidelinesModal] = useState(false);
  const [guidelinesStep, setGuidelinesStep] = useState(1);

  const handleOpenPurchase = (e) => {
    e.stopPropagation();
    setOpenPurchaseModal(true);
  };

  const handleOpenGuidelines = (e) => {
    e.stopPropagation();
    setOpenGuidelinesModal(true);
  };

  return (
    <>
      <div className={classes.buttonPurchaseContainer}>
        <button type="button" onClick={handleOpenPurchase} className={cx(classes.buttonElement, classes.purchaseButton)}>
          <div>
            <PurchaseBagIcon />
          </div>
          <p>
            {t('purchase')}
          </p>
        </button>
      </div>

      <div className={classes.buttonGuidelinesContainer}>
        <button type="button" onClick={handleOpenGuidelines} className={cx(classes.buttonElement, classes.guidelinesButton)}>
          <p>
            {t('openGuidelines')}
          </p>
        </button>
      </div>

      {openGuidelinesModal
      && (
      <DialogGuidelines
        setOpen={setOpenGuidelinesModal}
        step={guidelinesStep}
        setStep={setGuidelinesStep}
      />
      )}
      {openPurchaseModal && (
        <DialogPurchase setOpen={setOpenPurchaseModal} />
      )}
    </>
  );
}

export default memo(DemoUserFooter);
