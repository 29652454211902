import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((materialTheme) => ({
  profoleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    flexWrap: 'wrap',
    alignItems: 'start',
    margin: '80px auto 0 auto',
    whiteSpace: 'nowrap',
    background: materialTheme.CustomStyles.MainBackgroundColor,
  },
  userRightBLock: {
    width: '500px',
    maxWidth: '500px',
    display: 'flex',
    backgroundColor: '#FFFFFF',
    textAlign: 'left',
    borderRadius: '12px',
    lineHeight: '14px',
    fontSize: '20px',
    [materialTheme.breakpoints.down('800')]: {
      fontSize: '18px',
    },
    boxShadow: '0px 4px 30px rgba(2, 0, 120, 0.03)',
  },
  rightBlock: {
    margin: '0 0 0 5vw',
  },
  userLeftBLock: {
    backgroundColor: '#FFFFFF',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    alignItems: 'center',
    marginRight: '25px',
    marginBottom: '25px',
    borderRadius: '12px',
    boxShadow: '0px 4px 30px rgba(2, 0, 120, 0.03)',
  },
  topicText: {
    fontSize: '20px',
    margin: '0 0 0 26px',
  },
  valueText: {
    marginTop: '12px',
    marginLeft: '26px',
    marginRight: '26px',
    paddingBottom: '12px',
    borderBottom: '1px solid rgba(2, 0, 120, 0.03)',
    fontSize: '18px',
    color: '#8F8F8F',
    '& .MuiSvgIcon-root': {
      // color: 'white',
      top: '5px',
    },
  },
  userAvatar: {
    width: '160px',
    height: '160px',
    margin: '34px',
  },
  title: {
    margin: '0px',
    fontWeight: '600',
    fontSize: '20px',
    [materialTheme.breakpoints.down('800')]: {
      fontSize: '18px',
    },
  },
  company: {
    margin: '0px',
    color: '#8F8F8F',
    fontSize: '16px',
    [materialTheme.breakpoints.down('800')]: {
      fontSize: '14px',
    },
  },
  btn: {
    width: '90px',
    height: '28px',
    margin: '20px',
    fontSize: '16px',
    color: '#FF7374',
    textTransform: 'capitalize',
    border: '1px solid #FF7374',
    borderRadius: '25px',
  },
  'MuiSelect-icon': {
    top: 0,
  },
}));

// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
export default useStyles;
