/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { fetchObjectHistoric, getHistoryList } from 'store/actions/history';

const initialState = {
  history: {},
  objectsList: {},
  heatmapLength: 0,
  isFetching: 'not-started',
  objectSelected: null,
  isFetchingObjectHistoric: 'not-started',
  objectHistoric: {},
};

const historySlice = createSlice({
  name: 'history',
  initialState,
  reducers: {
    resetHistoryList(state) {
      state.history = initialState.history;
      state.objectsList = initialState.objectsList;
      state.heatmapLength = initialState.heatmapLength;
      state.isFetching = initialState.isFetching;
    },
    setObjectSelected(state, action) {
      state.objectSelected = action.payload;
    },
    resetObjectHistoric(state) {
      state.objectHistoric = initialState.objectHistoric;
    },
    resetisFetchingObjectHistoric(state) {
      state.isFetchingObjectHistoric = initialState.isFetchingObjectHistoric;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getHistoryList.pending, (state) => {
      state.isFetching = 'pending';
    });
    builder.addCase(getHistoryList.fulfilled, (state, action) => {
      const { historyObject, objectsList, heatmapLength } = action.payload;
      state.history = historyObject;
      state.heatmapLength = heatmapLength;
      state.objectsList = objectsList;
      state.isFetching = 'done';
    });
    builder.addCase(getHistoryList.rejected, (state) => {
      state.isFetching = 'rejected';
    });
    builder.addCase(fetchObjectHistoric.pending, (state) => {
      state.isFetchingObjectHistoric = 'pending';
    });
    builder.addCase(fetchObjectHistoric.fulfilled, (state, action) => {
      if (!action.payload) { return; }
      state.isFetchingObjectHistoric = 'done';
      state.objectHistoric = action.payload;
    });
    builder.addCase(fetchObjectHistoric.rejected, (state) => {
      state.isFetchingObjectHistoric = 'rejected';
    });
  },
});

export const selectHistory = (state) => state.history;

export const selectHistoryObject = (state) => selectHistory(state).history;
export const selectHistoryObjectsList = (state) => selectHistory(state).objectsList;
export const selectHistoryHeatmapLength = (state) => selectHistory(state).heatmapLength;
export const selectHistoryIsFetching = (state) => selectHistory(state).isFetching;
export const selectHistoryObjectSelected = (state) => selectHistory(state).objectSelected;
export const selectHistoryObjectHistoric = (state) => selectHistory(state).objectHistoric;
export const selectIsFetchingObjectHistoric = (state) => selectHistory(state)
  .isFetchingObjectHistoric;

export const {
  fetchedHistory,
  fetchHistoryInProgress,
  resetHistoryList,
  resetObjectHistoric,
  resetisFetchingObjectHistoric,
  setObjectSelected,
} = historySlice.actions;

export default historySlice.reducer;
