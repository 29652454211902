/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import OpenLayersMap from '@navigine/openlayers-navigine';
import React, {
  useCallback,
  useEffect, useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import FormControlLabel from '@mui/material/FormControlLabel';
import Slide from '@mui/material/Slide';
import Switch from '@mui/material/Switch';

import { findLatestObjectTime, findObjectId } from 'helpers/findObjectDataHistory';
import zoomByCoords from 'helpers/openlayers/zoomByCoords';
import { fetch3DModel } from 'store/actions/floors';
import { fetchObjectHistoric, getHistoryList } from 'store/actions/history';
import { fetchZoneTypesIfNeeded } from 'store/actions/zones';
import { alertTypes, createAlert } from 'store/slices/alert';
import { selectAppCurrentApp, selectAppOpenMenu } from 'store/slices/app';
import { selectCurrentFloor, selectFloorsObject, setFloor } from 'store/slices/floors';
import {
  selectGroupsCount, selectGroupsObject, selectGroupsSelectedGroup, setSelectedGroup,
} from 'store/slices/groups';
import {
  resetHistoryList, resetObjectHistoric, selectHistoryHeatmapLength, selectHistoryIsFetching,
  selectHistoryObject, selectHistoryObjectSelected, selectHistoryObjectsList, setObjectSelected,
} from 'store/slices/history';
import { selectCurrentLocation, selectLocationsObject, setLocation } from 'store/slices/locations';
import {
  selectReportsDateFrom, selectReportsDateTo, setDateFrom, setDateTo,
} from 'store/slices/reports';
import {
  selectSelectedZone, selectZoneTypes,
  selectZonesMap, setSelectedZone,
} from 'store/slices/zones';

import DialogObjectHistory from 'components/dialog/DialogHistory/DialogObjectHistory';
import DialogArrayHistory from 'components/dialog/DialogHistory/DialogArrayHistory';
import HistoryPlayer from 'components/history/HistoryPlayer';
import ObjectsList from 'components/history/objectsList';
import MainMenu from 'components/menu/MainMenu';
import FloorSelector from 'components/reports/ReportsFloorSelector';
import GroupSelector from 'components/reports/ReportsGroupSelector';
import LocationSelector from 'components/reports/ReportsLocationSelector';
import ZoneSelector from 'components/reports/ReportsZoneSelector';
import DatePickers from 'components/reports/datePickers/datePickers.component';
import GroupsInfinite from 'components/shared/GroupSelector/GroupsInfinite';
import {
  HISTORY_SPEEDS,
  allValuesConstant,
  history3d,
  selectNoOneConstant,
  styleUrl,
  token,
} from 'constans';

import useStyles from './style';

// eslint-disable-next-line react/jsx-props-no-spreading
const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

function History() {
  const { t } = useTranslation(['monitoring', 'history']);
  const dispatch = useDispatch();
  const openMenu = useSelector(selectAppOpenMenu);
  const currentApp = useSelector(selectAppCurrentApp);
  const floorsObject = useSelector(selectFloorsObject);
  const currentFloor = useSelector(selectCurrentFloor);
  const groupsObject = useSelector(selectGroupsObject);
  const selectedGroup = useSelector(selectGroupsSelectedGroup);
  const frames = useSelector(selectHistoryObject);
  const objectsList = useSelector(selectHistoryObjectsList);
  const heatmapLength = useSelector(selectHistoryHeatmapLength);
  const isFetchingHistory = useSelector(selectHistoryIsFetching);
  const locationsObject = useSelector(selectLocationsObject);
  const currentLocation = useSelector(selectCurrentLocation);
  const dateFrom = useSelector(selectReportsDateFrom);
  const dateTo = useSelector(selectReportsDateTo);
  const zonesMap = useSelector(selectZonesMap);
  const selectedZone = useSelector(selectSelectedZone);
  const zoneTypes = useSelector(selectZoneTypes);
  const groupsCount = useSelector(selectGroupsCount);
  const objectSelected = useSelector(selectHistoryObjectSelected);

  const { classes } = useStyles();

  const [map, setMap] = useState({});
  const [playing, setPlaying] = useState(false);
  const [currentSecond, setCurrentSecond] = useState(0);
  const [speed, setSpeed] = useState(16);
  const [playedPercentage, setPlayedPercentage] = useState(0);
  const [timeTimeout, setTimeTimeout] = useState(null);
  const [showTrackLines, setShowTrackLines] = useState(true);
  const mapElement = useRef();
  const [show3DState, setShow3DState] = useState(false);
  const navigate = useNavigate();
  const urlLocation = useLocation();
  const [appTtl, setAppTtl] = useState(60);

  const [openObjectModal, setOpenObjectModal] = useState(false);
  const [openArrayModal, setOpenArrayModal] = useState(false);
  const [objectInfo, setObjectInfo] = useState(null);
  const [objectInfoArray, setObjectInfoArray] = useState([]);

  const frameKeys = Object.keys(frames);
  const correctTimeToFetchHistory = (heatmapLength - (5 * speed)) < 1
    ? heatmapLength - Math.floor(heatmapLength / 2) : (5 * speed);
  const timeToFetchHistory = frameKeys[frameKeys.length - 1] - correctTimeToFetchHistory;

  const totalTime = (dateTo && dateFrom)
    ? parseInt(((dateTo.getTime() / 1000) - ((dateFrom.getTime() / 1000))), 10) : 1;

  const zonesArray = [...zonesMap.keys()]
    .filter((zoneId) => parseInt(zonesMap.get(zoneId).floor, 10) === parseInt(currentFloor.id, 10));

  const stopPlayer = () => {
    setPlaying(false);
    clearTimeout(timeTimeout);
  };

  const resetState = () => {
    stopPlayer();
    setCurrentSecond(0);
    setPlayedPercentage(0);
    if (map instanceof OpenLayersMap) {
      map.addTrackedObjects([]);
      map.removeTrackHistory();
    }
  };

  useEffect(() => {
    if (!(map instanceof OpenLayersMap)) {
      return () => {};
    }
    if (showTrackLines) {
      map.addTrackHistory();
    } else {
      map.removeTrackHistory();
    }
    return () => {
      map.removeTrackHistory();
    };
  }, [map, showTrackLines, playing]);

  useEffect(() => {
    stopPlayer();
    setTimeout(() => {
      resetState();
      dispatch(resetHistoryList());
    }, 0);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateFrom, dateTo]);

  useEffect(() => {
    if (locationsObject && floorsObject && urlLocation.state) {
      Object.keys(urlLocation.state).forEach((key) => {
        switch (key) {
          case 'track':
            setShowTrackLines(urlLocation.state[key] === 'true');
            break;
          case 'location_id':
            dispatch(setLocation(locationsObject[+urlLocation.state[key]]));
            break;
          case 'floor_id':
            dispatch(setFloor(floorsObject[+urlLocation.state[key]]));
            break;
          case 'group_id': {
            const group = groupsObject[+urlLocation.state[key]];
            if (group) {
              dispatch(setSelectedGroup());
            }
            break;
          }
          case 'from':
            dispatch(setDateFrom(new Date(+urlLocation.state[key] * 1000)));
            break;
          case 'to':
            dispatch(setDateTo(new Date(+urlLocation.state[key] * 1000)));
            break;
          case 'apply':
            setTimeout(() => {
              setShowTrackLines(urlLocation.state[key] === 'true');
              // eslint-disable-next-line no-use-before-define
              fetchHistory();
            }, 0);
            break;
          case 'speed':
            if (HISTORY_SPEEDS.includes(+urlLocation.state[key])) {
              setSpeed(+urlLocation.state[key]);
            }
            break;
          case 'object_id':
            dispatch(setObjectSelected(+urlLocation.state[key]));
            break;
          case 'zones': {
            if (!map || !(map instanceof OpenLayersMap) || !zoneTypes) {
              break;
            }
            map.setZoneTypes(zoneTypes);
            const parsedZones = JSON.parse(urlLocation.state[key]);
            if (parsedZones instanceof Array) {
              const zones = zonesArray
                .filter((el) => parsedZones.find((parsed) => parsed === +el))
                .map((el) => zonesMap.get(+el));
              map.addZones(zones);
            }
            if (parsedZones instanceof Number) {
              map.addZone(parsedZones);
            }
            break;
          }
          case 'zone_id':
            dispatch(setSelectedZone(zonesMap.get(+urlLocation.state[key])));
            if (!(map instanceof OpenLayersMap)) {
              break;
            }
            map.addZone(zonesMap.get(+urlLocation.state[key]));
            break;
          case 'zoom_to': {
            if (!(map instanceof OpenLayersMap)) {
              break;
            }
            const parsed = JSON.parse(urlLocation.state[key]);
            if (parsed instanceof Array) {
              zoomByCoords(map, parsed);
            }
            break;
          }
          default:
        }
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlLocation.state, dispatch, locationsObject, floorsObject, groupsObject, map]);

  // load 3D map if it exists
  useEffect(() => {
    async function fetch3d() {
      try {
        dispatch(fetch3DModel(currentFloor.id));
      } catch (error) {
        dispatch(createAlert({ messageType: 'warn', message: 'Error in fetching 3D map' }));
      }
    }
    if (currentFloor?.id) {
      fetch3d();
    }
  }, [currentFloor?.id, dispatch]);

  useEffect(() => {
    const fetchZoneTypes = () => {
      dispatch(fetchZoneTypesIfNeeded());
    };
    fetchZoneTypes();
  }, [currentFloor?.id, dispatch]);

  useEffect(() => {
    resetState();
    const { properties } = currentApp;
    const appTtlProp = properties.filter((prop) => prop.type === 'object_ttl')[0];
    let objTtl = 60;
    if (appTtlProp) {
      objTtl = parseInt(appTtlProp.value, 10);
    }
    setAppTtl(objTtl);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentApp]);

  useEffect(() => {
    if (map instanceof OpenLayersMap) {
      dispatch(setSelectedZone({ id: selectNoOneConstant }));
      dispatch(setSelectedGroup({ id: allValuesConstant }));
      map.destroyMap();
    }

    try {
      const translate = {
        global: t('global'), local: t('local'), street: t('street'), satellite: t('satellite'), img: t('img'), noImg: t('noImg'),
      };
      const {
        lat, lon, hor, w, h,
      } = currentFloor;
      const newMap = new OpenLayersMap({
        target: mapElement.current,
        imageURL: currentFloor.image_url,
        imageWidth: currentFloor.pw,
        imageHeight: currentFloor.ph,
        imageBounds: currentFloor.image_bounds,
        lat,
        lon,
        w,
        h,
        hor,
        isGlobalMap: false,
        token,
        styleUrl,
        translate,
      });
      const { clientWidth, clientHeight } = mapElement.current;
      if (!clientWidth || !clientHeight) {
        const resize = (entires, observer) => {
          newMap.map.updateSize();
          observer.unobserve(mapElement.current);
        };
        new ResizeObserver(resize).observe(mapElement.current);
      }
      setMap(newMap);
    } catch (error) {
      setMap({});
    }

    return () => {
      if (map instanceof OpenLayersMap) {
        map.destroyMap();
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFloor?.id]);

  const handleChangeLocation = useCallback((event) => {
    const locationId = event.target.value;
    dispatch(setLocation(locationsObject[locationId]));
    const floorId = locationsObject[locationId].floors[0].id;
    dispatch(setFloor(floorsObject[floorId]));
    dispatch(setSelectedZone({ id: selectNoOneConstant }));
    dispatch(setSelectedGroup({ id: allValuesConstant }));
  }, [dispatch, floorsObject, locationsObject]);

  const handleChangeGroup = useCallback((value) => {
    const groupId = value;
    if (groupId === allValuesConstant) {
      dispatch(setSelectedGroup({ id: allValuesConstant }));
      return;
    }
    const newSelectedGroup = groupsObject[groupId];
    dispatch(setSelectedGroup(newSelectedGroup));
  }, [dispatch, groupsObject]);

  const handleChangeGroupHoc = useCallback((e) => {
    e.preventDefault();
    handleChangeGroup(e.target.value);
  }, [handleChangeGroup]);

  const handleChangeFloor = useCallback((event) => {
    const floorId = event.target.value;
    dispatch(setFloor(floorsObject[floorId]));
    dispatch(setSelectedZone({ id: selectNoOneConstant }));
    dispatch(setSelectedGroup({ id: allValuesConstant }));
  }, [dispatch, floorsObject]);

  const handleChangeZone = useCallback((event) => {
    event.preventDefault();
    const zoneId = event.target.value;
    map.deleteAllFeaturesFromSourse(map.enum.zones);

    if (zoneId === allValuesConstant) {
      const zones = zonesArray.map((zoneID) => zonesMap.get(+zoneID));
      dispatch(setSelectedZone({ id: allValuesConstant }));
      map.addZones(zones);
      return;
    }

    if (zoneId === selectNoOneConstant) {
      dispatch(setSelectedZone({ id: selectNoOneConstant }));
    }

    if (zoneId !== selectNoOneConstant && zoneId !== allValuesConstant) {
      dispatch(setSelectedZone(zonesMap.get(+zoneId)));
      map.addZone(zonesMap.get(+zoneId));
      map.zoomToFeature(map.getZoneFeature(zoneId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, map, zonesMap]);

  const fetchHistory = () => {
    dispatch(resetHistoryList());
    resetState();
    dispatch(getHistoryList({ offset: null, packet: null }));
  };

  const updateHistory = (moveTo) => {
    const offset = moveTo || currentSecond;
    dispatch(getHistoryList({ offset, packet: null }));
  };

  useEffect(() => {
    if (!(map instanceof OpenLayersMap)) {
      return;
    }
    if (zoneTypes) {
      map.setZoneTypes(zoneTypes);
    }

    if (selectedZone && selectedZone.id) {
      if (selectedZone.id === allValuesConstant) {
        const zones = zonesArray.map((zoneID) => zonesMap.get(+zoneID));
        map.addZones(zones);
      } else if (selectedZone.id > 0) {
        map.addZone(zonesMap.get(+selectedZone.id));
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, zoneTypes, zonesMap]);

  const renderTrackedObjects = async (frameID, frame = []) => {
    const trackedObjects = frame
      .filter((frameEl) => (objectSelected ? frameEl.id === objectSelected : true)
       && (frameEl.id) && (frameEl.kx != null) && (frameEl.ky != null))
      .map((frameEl) => {
        const date = frameEl.time ? new Date(frameEl.time) : null;
        return {
          attributes: {
            kx: frameEl?.kx ? parseFloat(frameEl?.kx) : null,
            ky: frameEl?.ky ? parseFloat(frameEl?.ky) : null,
            file_url: frameEl?.id ? objectsList[frameEl.id].file_url : null,
            title: frameEl.id ? objectsList[frameEl.id].title : null,
            color: frameEl.id ? objectsList[frameEl.id].color : null,
          },
          id: frameEl.id,
          lastFrame: frameEl.lastFrame || frameID,
          time: date instanceof Date ? date.getTime() : 0,
        };
      });
    const trackedObjectsOnMap = map.getAllTrackedObjects();
    if (trackedObjectsOnMap.length > 0) {
      const updatedObjects = {};
      try {
        const promiseArr = trackedObjects.map(async (currentObject) => {
          const trackedObjFeature = map.getTrackedObjectFeature(currentObject?.id);
          const objectOnMap = map.getFeaturePayload(trackedObjFeature);
          if (!objectOnMap && currentObject.time) {
            await map.addTrackedObject(currentObject);
            return;
          }
          if (objectOnMap) {
            await objectOnMap.updateTrackedObject(currentObject);
            updatedObjects[currentObject.id] = true;
          }
        });
        await Promise.all(promiseArr);
        trackedObjectsOnMap.forEach((featureOnMap) => {
          const currentObjectId = featureOnMap.getId();
          if (!updatedObjects[currentObjectId]) {
            const objectOnMap = map.getFeaturePayload(
              map.getTrackedObjectFeature(currentObjectId),
            );
            const lastFrame = objectOnMap.trackedObject?.lastFrame;

            if (lastFrame && frameID - lastFrame > appTtl) {
              map.deleteTrackedObject(featureOnMap);
            }
          }
        });
      } catch (error) {
        dispatch(createAlert({ messageType: alertTypes.warn, message: 'Error in updating objects on the map' }));
        resetState();
      }
    } else {
      const filteredObjects = trackedObjects.filter((object) => object.id);
      if (filteredObjects.length > 0) {
        map.addTrackedObjects(filteredObjects);
      }
    }
  };

  useEffect(() => {
    const asyncFn = async () => {
      const currentFrame = frames[currentSecond];
      if (currentSecond >= totalTime) {
        setPlaying(false);
        setPlayedPercentage(100);
        return;
      }
      setTimeTimeout(setTimeout(() => {
        setCurrentSecond((prevSecond) => prevSecond + 1);
      }, 1000 / speed));
      if (currentFrame && currentFrame.length > 0) {
        await renderTrackedObjects(currentSecond, currentFrame);
      }
      if (currentSecond === timeToFetchHistory && isFetchingHistory !== 'done') {
        await updateHistory();
      }
      setPlayedPercentage((totalTime && currentSecond) ? ((currentSecond / totalTime) * 100) : 0);
    };

    if (playing) {
      asyncFn();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, currentSecond, frames, playing, speed, totalTime, timeToFetchHistory]);

  const moveTo = async (event) => {
    clearTimeout(timeTimeout);
    const frameKeysCopy = Object.keys(frames);
    if (!frameKeysCopy.length) {
      return;
    }
    // to - from
    const clientRect = event.currentTarget.getBoundingClientRect();
    const moveToSecond = Math.floor(((event.clientX - clientRect.left) / clientRect.width)
      * totalTime); // total = to - from
    if (!moveToSecond) {
      return;
    }
    if (!(moveToSecond in frameKeysCopy)) {
      stopPlayer();
      setCurrentSecond(moveToSecond);
      setPlayedPercentage(totalTime ? ((moveToSecond / (totalTime)) * 100) : 0);
      await updateHistory(moveToSecond);
      return;
    }
    const renderFrameKeys = new Map();
    frameKeysCopy
      .filter((frameID) => frameID < moveToSecond && (moveToSecond - frameID) < appTtl)
      .forEach((frameID) => renderFrameKeys.set(frameID, frames[frameID]));
    const moveToObject = {};

    renderFrameKeys.forEach((leftFrame, leftFrameId) => {
      leftFrame.forEach((frameEl) => {
        moveToObject[frameEl.id] = { ...frameEl, lastFrame: leftFrameId };
      });
    });

    const trackedObjType = map.enum.trackedObjects;
    const trackLineType = map.enum.trackLines;
    map.deleteAllFeaturesFromSourse(trackedObjType);
    map.deleteAllFeaturesFromSourse(trackLineType);

    const moveToFrame = Object.values(moveToObject);
    if (moveToFrame.length > 0) {
      await renderTrackedObjects(moveToSecond, moveToFrame);
    }

    setCurrentSecond(moveToSecond);
    setPlayedPercentage(totalTime ? ((moveToSecond / (totalTime)) * 100) : 0);
  };

  const handleShow3D = async () => {
    setShow3DState((prev) => !prev);
    dispatch(setSelectedZone({ id: selectNoOneConstant }));
    setTimeout(() => {
      navigate(history3d);
    }, 300);
  };

  const handleHistoricObjectData = useCallback((trackedObjectData) => {
    const objectId = findObjectId(objectsList, trackedObjectData.title);
    if (!objectId) return;
    const time = findLatestObjectTime(frames, currentSecond, objectId);
    if (!time) return;
    const timestamp = Date.parse(time);
    dispatch(fetchObjectHistoric({ objectId, timestamp }));
    setObjectInfo(trackedObjectData);
    setOpenObjectModal(true);
  }, [currentSecond, dispatch, frames, objectsList]);

  const mapOnClickCallback = useCallback((data) => {
    if (!data.feature) {
      return;
    }
    const { feature, featuresArray } = data;
    if (featuresArray.length < 2) {
      const trackedObjectData = feature.trackedObject
        ? feature.trackedObject.attributes
        : null;
      if (trackedObjectData) {
        handleHistoricObjectData(trackedObjectData);
      }
    } else {
      const filteredData = featuresArray.filter((item) => !!(item.trackedObject));
      setObjectInfoArray(filteredData.map((att) => att.trackedObject?.attributes));
      setOpenArrayModal(true);
    }
  }, [handleHistoricObjectData]);

  useEffect(() => {
    if (!(map instanceof OpenLayersMap)) {
      return () => {};
    }
    map.on('mapClicked', mapOnClickCallback.bind(this));
    return () => {
      map.off('mapClicked', mapOnClickCallback.bind(this));
    };
  }, [map, mapOnClickCallback]);

  const handleCloseObjectModal = () => {
    setOpenObjectModal(false);
    setObjectInfo(null);
    setObjectInfoArray([]);
    dispatch(resetObjectHistoric());
  };

  return (
    <div className={!openMenu ? classes.history : classes.noneContainer}>
      <MainMenu openMenu={openMenu} dispatch={dispatch}>
        <div className={classes.mapControls}>
          <div className={classes.controlWrapper}>
            <LocationSelector
              name="location-selector"
              locations={locationsObject}
              className={classes.selector}
              value={currentLocation?.id}
              onChange={handleChangeLocation}
            />
          </div>
          <div className={classes.controlWrapper}>
            <FloorSelector
              name="floor-selector"
              floors={floorsObject}
              className={classes.selector}
              onChange={handleChangeFloor}
              value={currentFloor?.id}
              currentLocationId={currentLocation?.id}
            />
          </div>
          <div className={classes.controlWrapper}>
            <ZoneSelector
              name="zone-selector"
              onChange={handleChangeZone}
              className={classes.selector}
              zones={zonesMap}
              value={selectedZone?.id}
              zoneTypes={zoneTypes}
              currentFloorId={currentFloor?.id}
            />
          </div>
          <div className={classes.controlWrapper}>
            {(!groupsCount || groupsCount <= 100)
              ? (

                <GroupSelector
                  name="group-selector"
                  onChange={handleChangeGroupHoc}
                  groups={groupsObject}
                  value={selectedGroup?.id || allValuesConstant}
                  className={classes.selector}
                />
              ) : (
                <GroupsInfinite
                  value={selectedGroup}
                  handleChangeGroup={handleChangeGroup}
                  allowSelectAll
                  inputStyles={{ border: 'none', backgroundColor: '#F3F6F8' }}
                  borderStyle="none"
                  dropdownStyles={{ width: '200px !important' }}
                />
              )}
          </div>
          {floorsObject[currentFloor?.id] && floorsObject[currentFloor?.id].model
          && (
          <div className={classes.switch_wrapper}>
            <FormControlLabel
              className={classes.swich_element}
              control={(
                <Switch
                  checked={show3DState}
                  onChange={handleShow3D}
                  name="show 3d map"
                  color="primary"
                />
              )}
              label={t('show3d')}
            />
          </div>
          )}
          <div className={classes.switch_wrapper}>
            <FormControlLabel
              className={classes.swich_element}
              control={(
                <Switch
                  checked={showTrackLines}
                  onChange={(e) => setShowTrackLines(e.target.checked)}
                  name="show track lines"
                  color="primary"
                />
                )}
              label={t('showTrackLines')}
            />
          </div>

          <DatePickers
            labelStyles={classes.historyDate}
            inputStyles={classes.historyInput}
          />

          <ObjectsList
            objectsList={objectsList}
          />

        </div>
      </MainMenu>
      <div ref={mapElement} className={classes.map_container}>
        <HistoryPlayer
          playedPercentage={playedPercentage}
          playing={playing}
          setPlaying={setPlaying}
          frameKeys={frameKeys}
          resetState={resetState}
          moveCallback={moveTo}
          speed={speed}
          setSpeed={setSpeed}
          stopPlayer={stopPlayer}
        />
      </div>
      {openObjectModal
      && (
      <DialogObjectHistory
        floor={currentFloor}
        handleCloseObjectModal={handleCloseObjectModal}
        objectInfo={objectInfo}
        openObjectModal={openObjectModal}
        TransitionComponent={Transition}
      />
      )}
      {openArrayModal
      && (
      <DialogArrayHistory
        handleHistoricObjectData={handleHistoricObjectData}
        objectInfoArray={objectInfoArray}
        openArrayModal={openArrayModal}
        setObjectInfo={setObjectInfo}
        setOpenObjectModal={setOpenObjectModal}
        setOpenArrayModal={setOpenArrayModal}
        TransitionComponent={Transition}
      />
      )}
    </div>
  );
}

export default History;
