import React, { memo } from 'react';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()({
  legendWrapper: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    // calculating marginLeft from Active Objects title and margin from Items
    marginLeft: 'calc(20px - 0.7vw)',
    paddingRight: '10px',
  },
  legendItem: {
    display: 'flex',
    flexDirection: 'row',
    margin: '0.7vw',
  },
  legendColor: {
    width: '20px',
    height: '5px',
    marginTop: '7px',
    marginRight: '3px',
  },
  activeObjectsTitle: {
    color: 'rgb(129, 148, 164)',
    marginTop: '10px',
    marginLeft: '20px',
  },
});

const ObjectsList = memo(({ objectsList }) => {
  const { classes } = useStyles();
  return (
    <>
      {objectsList && Object.values(objectsList).length > 0
      && (
      <div className={classes.activeObjectsTitle}>
        Active Objects:
      </div>
      )}
      <div
        className={classes.legendWrapper}
      >
        {objectsList ? Object.values(objectsList).map((object) => (
          <div
            className={classes.legendItem}
            key={object?.id}
          >
            <div>
              {' '}
              {object.title}
              {' '}
            </div>
          </div>
        )) : null}
      </div>
    </>
  );
});

export default ObjectsList;
