import { Line } from 'react-chartjs-2';
import React from 'react';
import 'chart.js/auto';
import { colorsChartArray } from 'constans';

function LineChart({ data, eventData }) {
  const options = {
    maintainAspectRatio: false,
    title: {
      fontSize: 20,
    },
    layout: {
      padding: {
        left: 10,
        right: 10,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          title: () => '',
          label: (context) => (eventData[context.dataIndex]
            ? `${eventData[context.dataIndex]?.condition}` : ''),
        },
      },
    },
    scales: {
      x: {
        min: data.data[0]?.timestamp,
        max: data.data[data.data.length - 1]?.timestamp,
        type: 'linear',
        display: false,
      },
      y: {
        display: false,
      },
    },
  };

  function findClosestLeftIndex(dataPoints, targetTimestamp) {
    let leftIndex = -1;
    for (let i = 0; i < dataPoints.length; i += 1) {
      if (dataPoints[i].timestamp > targetTimestamp) {
        break;
      }
      leftIndex = i;
    }
    return leftIndex;
  }

  function interpolateY(dataPoints, targetTimestamp) {
    const leftIndex = findClosestLeftIndex(dataPoints, targetTimestamp);
    const rightIndex = leftIndex + 1;
    if (leftIndex === -1 || rightIndex >= dataPoints.length) {
      return { x: targetTimestamp, y: null };
    }
    const leftData = dataPoints[leftIndex];
    const rightData = dataPoints[rightIndex];
    const ratioX = (targetTimestamp - leftData.timestamp)
    / (rightData.timestamp - leftData.timestamp);
    const interpolatedY = leftData.value + ratioX * (rightData.value - leftData.value);
    return { x: targetTimestamp, y: interpolatedY };
  }

  const state = {
    datasets: [
      {
        type: 'line',
        backgroundColor: colorsChartArray,
        // borderColor: '#30AAD9',
        pointRadius: 0,
        // borderWidth: 2,
        data: data.data.map((i) => ({ x: i.timestamp, y: i.value })),
        tension: 0.4,
      },
      {
        type: 'scatter',
        data: eventData.map((event) => {
          const { y: interpolatedY } = interpolateY(data.data, event.timestamp);
          return { x: event.timestamp, y: interpolatedY };
        }),
        borderColor: 'red',
        backgroundColor: 'red',
        pointRadius: 3,
        pointHoverRadius: 5,
        showLine: false,
      },
    ],
  };
  return (
    <div style={{ height: '100px' }}>
      <Line
        data={state}
        width={300}
        height={50}
        options={options}
      />
    </div>
  );
}

export default LineChart;
