import { makeStyles } from 'tss-react/mui';
import 'assets/fonts/Roboto/RobotoMono.css';

const debugStyles = makeStyles()((theme) => ({
  content: {
    width: '100vw',
    height: 'calc(100vh - 80px)',
    display: 'flex',
    flexDirection: 'column',
    padding: '0 45px 0 32px',
    overflowY: 'auto',
    [theme.breakpoints.down('800')]: {
      width: '90%',
      marginLeft: '-1vw',
    },
  },
  topControls: {
    display: 'flex',
    flexDirection: 'row',
    margin: '26px 0px',
  },
  spinerWrapper: {
    width: '100%',
    maxHeight: '60vh',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  tableContainer: {
    boxShadow: '0px 4px 30px rgba(2, 0, 120, 0.03)',
    borderRadius: '12px',
  },
  table: {
    width: '100%',
  },
  expandCell: {
    padding: '0',
    height: 'inherit',
    width: '5%',
  },
  expandIconContainer: {
    padding: '10px 20px 10px 20px',
  },
  expandButton: {
    cursor: 'pointer',
    '&:hover': { background: 'rgb(223, 227, 229)' },
  },
  selectorWrap: {
    marginLeft: '20px',
    width: '207px',
  },
  spinnerCell: {
    textAlign: 'center',
  },
  extraTableContainer: {
    margin: '0',
    padding: '0',
    borderBottom: '1px solid black',
  },
  containedTable: {
    width: '100%',
    backgroundColor: 'inherit',
    padding: '0',
    tableLayout: 'fixed',
  },
  extraDataCell: {
    padding: '0px 15px 10px 15px',
  },
  logMessageList: {
    padding: '0 10px 10px 10px',
    margin: '0',
    fontFamily: 'Roboto Mono',
    wordWrap: 'break-word',
  },
  refreshExtraDataContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '0',
    gap: '5px',
  },
  buttonsDiv: {
    display: 'flex',
    flexDirection: 'row',
    gap: '1em',
  },
}));

export default debugStyles;
