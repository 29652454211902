import Cookies from 'js-cookie';
import { jwtDecode } from 'jwt-decode';

import request, { endPointsEnum } from 'services/http';

import { cookieTokenName, cookOpts } from 'constans';
import {
  destroyStore, fetchUserFinished, fetchUserInProgress, setUser,
} from 'store/slices/user';
import { createAlert } from 'store/slices/alert';

export const logout = () => async (dispatch) => {
  localStorage.removeItem('token');
  localStorage.removeItem('hash');
  Cookies.remove(cookieTokenName, cookOpts);

  dispatch(destroyStore());
};

export const userFetchIfNeeded = () => async (dispatch, getState) => {
  try {
    const state = getState();
    const {
      name, email, hash, company, token,
    } = state.user;
    if (name && email && hash && company) {
      return false;
    }

    const storedToken = Cookies.get(cookieTokenName) || token;
    const tokenPayload = jwtDecode(storedToken);
    const {
      hash: storedHash, id: storedId,
    } = tokenPayload.userData;

    if (!storedToken || !storedHash || !storedId) {
      dispatch(logout());
    }

    dispatch(fetchUserInProgress());

    const result = await request.client.get(endPointsEnum.userGet, {
      params: {
        id: storedId,
        userHash: storedHash,
      },
    });

    dispatch(fetchUserFinished());
    dispatch(setUser(result));
    return true;
  } catch (error) {
    dispatch(fetchUserFinished());
    dispatch(createAlert({ messageType: 'error', message: error.message }));
    dispatch(logout());
    return false;
  }
};

export const demoUserPurchase = (form) => async (dispatch) => {
  try {
    // needs to be hardcoded
    const INQUIRY_ID = 1173631;
    const data = JSON.stringify({ ...form, inquiry_id: INQUIRY_ID });
    const response = await fetch('https://navigine.com/rest/', {
      method: 'POST',
      body: data,
    });
    const result = await response.json();
    if (result && result.success === 'OK') {
      return true;
    }
    return false;
  } catch (error) {
    dispatch(createAlert({ messageType: 'error', message: error.message }));
    return false;
  }
};
