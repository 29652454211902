import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((materialTheme) => {
  const theme = materialTheme.CustomStyles.Login;

  return {
    overrides: {
      MuiFormControl: {
        root: {
          margin: 0,
        },
      },
      MuiInputLabel: {
        root: {
          paddingLeft: 0,
        },
        formControl: {
          top: '-4',
        },
      },
    },
    root: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    FormItemGrid: {
      backgroundColor: '#ffffff',
      marginTop: '11px',
      width: theme.formItem.width,
      height: theme.formItem.height,
      border: '1px solid #edeff3',
    },
    FormItemGridFirst: {
      marginTop: 0,
    },
    textField: {
      width: theme.textField.width,
      height: theme.formItem.height,
      marginLeft: '25px',
      display: 'flex',
      justifyContent: 'center',
    },
    textFieldLogin: {
      width: theme.textFieldLogin.width,
    },
    FormLoginIcon: {
      background: theme.loginIconBackground,
      width: '18px',
      height: '19px',
      marginLeft: '20px',
    },
    FormPasswordIcon: {
      background: theme.passwordIconBackground,
      width: '18px',
      height: '21px',
      marginLeft: '20px',
    },
    buttonLogin: {
      marginTop: '11px',
      width: theme.formItem.width,
      height: theme.formItem.height,
      backgroundColor: materialTheme.palette.primary.main,
    },
    loginContainer: {
      minHeight: '100vh',
      width: '100%',
      height: '100%',
      fontSize: '16px',
      backgroundSize: 'cover',
      WebkitFontSmoothing: 'antialiased',
      background: materialTheme.CustomStyles.MainBackgroundColor,
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    loginForm: {
      width: theme.formItem.width,
      overflow: 'hidden',
      display: 'inline-block',
      textAlign: 'center',
    },
    logo: {
      position: 'absolute',
      top: '24px',
      left: '15px',
    },
    spinerWrapper: {
      width: '100%',
      maxHeight: '60vh',
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
    },
  };
});

export default useStyles;
