/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  name: '',
  email: '',
  avatar_url: '',
  hash: '',
  company: '',
  role: '',
  isFetching: false,
  token: '',
  refreshToken: '',
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser(state, action) {
      const {
        name, email, avatar_url: avatar, hash, company, company_name: companyName, role,
        token, refreshToken,
      } = action.payload;
      state.hash = hash;
      state.name = name;
      state.email = email;
      state.avatar_url = avatar;
      state.company = company || companyName;
      state.role = role;
      if (token && refreshToken) {
        state.token = token;
        state.refreshToken = refreshToken;
      }
    },
    destroyStore() {
    },
    fetchUserInProgress(state) {
      return {
        ...state,
        isFetching: true,
      };
    },
    fetchUserFinished(state) {
      return {
        ...state,
        isFetching: false,
      };
    },
  },
});

export const selectUser = (state) => state.user;

export const selectUserName = (state) => selectUser(state).name;
export const selectUserEmail = (state) => selectUser(state).email;
export const selectUserAvatarUrl = (state) => selectUser(state).avatar_url;
export const selectUserHash = (state) => selectUser(state).hash;
export const selectUserCompany = (state) => selectUser(state).company;
export const selectUserRole = (state) => selectUser(state).role;
export const selectUserIsFetching = (state) => selectUser(state).isFetching;

export const {
  setUser, destroyStore, fetchUserFinished, fetchUserInProgress,
} = userSlice.actions;

export default userSlice.reducer;
