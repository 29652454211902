import { Howl, Howler } from 'howler';

const audioWrapper = {
  loadedSounds: [],
  unload: () => {
    Howler.unload();
    audioWrapper.loadedSounds = [];
  },
  stopAll: () => {
    Howler.stop();
  },
  mute: (isMuted) => {
    Howler.mute(isMuted);
  },
  volumeLevel: (volumeLevel) => {
    Howler.volume(volumeLevel);
  },
  resetVolumeLevel: () => {
    Howler.volume(1);
  },
  load: async (url, name = '', options = {}) => {
    const storedName = name || url;
    return new Promise((res, rej) => {
      const howl = new Howl({
        src: [storedName],
        ...options,
      });
      audioWrapper.loadedSounds[storedName] = howl;
      howl.once('load', () => {
        res(howl);
      });
      howl.once('loaderror', () => {
        rej(howl);
      });
    });
  },
  loadAll: async (sounds, options = {}) => Promise.all(sounds.map(async (sound) => {
    await audioWrapper.load(sound, '', options);
  })),
  play: async (nameOrUrl) => {
    let sound = null;
    if (typeof audioWrapper.loadedSounds[nameOrUrl] === 'undefined') {
      sound = await audioWrapper.load(nameOrUrl);
    } else {
      sound = audioWrapper.loadedSounds[nameOrUrl];
    }
    return new Promise((res) => {
      sound.once('end', () => {
        res(sound);
      });
      sound.once('stop', () => {
        res(sound);
      });
      sound.play();
    });
  },
  pause: (nameOrUrl) => {
    const sound = audioWrapper.loadedSounds[nameOrUrl];
    if (typeof sound !== 'undefined') {
      sound.pause();
    }
  },
  stop: (nameOrUrl) => {
    const sound = audioWrapper.loadedSounds[nameOrUrl];
    if (typeof sound !== 'undefined') {
      sound.stop();
    }
  },
};

export default audioWrapper;
