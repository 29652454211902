import { withStyles } from 'tss-react/mui';
import Button from '@mui/material/Button';

export const customButton = withStyles(Button, (materialTheme) => {
  // eslint-disable-next-line no-unused-vars
  const { fontRegular } = materialTheme;

  return {
    root: {
      '&:disabled': {
        boxShadow: 'none',
        backgroundColor: 'transparent',
      },
    },
  };
});
export default customButton;
