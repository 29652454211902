import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((materialTheme) => {
  const MODAL_WIDTH = '500px';
  return ({
    content: {
      width: 'calc(100vw - 20vh)',
      height: 'calc(100vh - 80px)',
      display: 'flex',
      flexDirection: 'column',
      padding: '0 45px 0 32px',
      overflowY: 'auto',
      [materialTheme.breakpoints.down('800')]: {
        width: '90%',
        marginLeft: '-1vw',
      },
    },
    table: {
      width: '100%',
    },
    topControls: {
      display: 'flex',
      flexDirection: 'row',
      margin: '26px 0px',
    },
    titleTheadCell: {
      borderBottom: '1px  solid #f4f4f4',
      padding: '10px 30px 0',
    },
    tableTheadCell: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
      height: '1.5em',
      maxWidth: '7.5em',
      whiteSpace: 'nowrap',
    },
    titleTheadCellSort: {
      borderBottom: '1px  solid #f4f4f4',
      padding: '10px 30px 0',
      '&:hover': {
        cursor: 'pointer',
      },
    },
    titleText: {
      display: 'flex',
      alignItems: 'center',
    },
    tableRow: {
      height: '4.5em',
    },
    spinerWrapper: {
      width: '100%',
      maxHeight: '60vh',
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
    },
    controlWrapper: {
      paddingTop: '15px',
      display: 'flex',
      flexDirection: 'column',
    },
    txtControl: {
      display: 'flex',
      flexDirection: 'column',
      minWidth: '280px',
      marginTop: '15px',
    },
    tableCell: {
      borderBottom: '1px  solid #f4f4f4',
      padding: '10px 30px',
    },
    tableContainer: {
      boxShadow: '0px 4px 30px rgba(2, 0, 120, 0.03)',
      borderRadius: '12px',
    },
    tooltip: {
      whiteSpace: 'pre-line',
      wordWrap: 'break-word',
      fontSize: '17px',
    },
    createBtn: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '10px 16px 10px 10px',
      margin: '0px 10px 0px 0px',
      height: '40px',
      borderRadius: '8px',
      fontSize: '14px',
      fontWeight: '400',
      '& span': {
        marginTop: '0.1rem',
      },
      '& .MuiSvgIcon-root': {
        fontSize: '1.2rem',
        marginBottom: '0.1rem',
      },
    },
    inputComponent: {
      height: '35px',
      width: '100%',
      padding: '0 5px 0 5px',
      border: '1px solid rgba(0, 0, 0, 0.12)',
      borderRadius: '8px',
    },
    formControl: {
      width: '100%',
    },
    label: {
      width: '100%',
      fontSize: '14px',
      color: '#929292',
      display: 'flex',
      flexDirection: 'column',
    },
    dialogTitle: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '0 10px',
      borderBottom: '1px solid rgba(0, 0, 0, 0.04)',
      color: 'black',
    },
    dialogActions: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      // padding: '0 10px',
      borderBottom: '1px solid rgba(0, 0, 0, 0.04)',
      color: 'black',
    },
    customBtn: {
      width: '90%',
      background: materialTheme.palette.primary.main,
      color: 'white',
      '&:disabled': {
        background: '#D7D7D7',
        color: '#fff',
      },
      '&:hover': {
        cursor: 'pointer',
        background: materialTheme.palette.primary.main,
      },
    },
    tabContainer: {
      margin: '0 auto',
      width: MODAL_WIDTH,
      borderBottom: 'none',
    },
    tabsMainWrapper: {
      maxWidth: `${+MODAL_WIDTH + 10}px`,
    },
    divToH3: {
      display: 'block',
      marginBlockStart: '1em',
      marginBlockEnd: '1em',
      fontWeight: 'bold',
      marginBottom: '0.5em',
    },
    actionButton: {
      width: '45px',
      height: '24px',
      marginRight: '5px',
      marginLeft: '5px',
      borderRadius: '47px',
      cursor: 'pointer',
      background: 'rgb(233, 237, 239)',
      '&:hover': { background: 'rgb(223, 227, 229)' },
      '&:active': { background: 'rgb(213, 217, 219)' },
    },
    wrappApp: {
      boxShadow: '0px 0px 0px 0px grey',
    },
    tabsLabel: {
      maxWidth: MODAL_WIDTH,
      background: '#fff',
      '& .MuiTab-root': {
        minWidth: '50%',
        color: materialTheme.palette.primary.main,
        fontWeight: '600',
      // opacity: 0.4,
      },
    },
    deliveryInputsWrapper: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
    },
    deliverySelector: {
      width: '30%',
      marginRight: '5px',
      '& .MuiSelect-select.MuiSelect-select': {
        border: '1px solid rgba(0, 0, 0, 0.12)',
        borderRadius: '8px',
        padding: '7px 7px',
      },
    },
    deliveryText: {
      width: '60%',
      border: '1px solid rgba(0, 0, 0, 0.12)',
      borderRadius: '8px',
      padding: '2px 7px 0',
    },
    subjectLabel: {
      margin: '0px 5px 15px',
      position: 'relative',
      fontSize: '14px',
      color: '#929292',
    },
    subjectText: {
      width: '100%',
      border: '1px solid rgba(0, 0, 0, 0.12)',
      borderRadius: '8px',
      padding: '2px 7px 0',
      position: 'relative',
      marginTop: '10px',
    },
    savedItemsWrapper: {
      marginTop: '10px',
    },
    deliveryTextSaved: {
      width: '30%',
      border: '1px solid rgba(0, 0, 0, 0.12)',
      borderRadius: '8px',
      padding: '2px 7px 0',
      marginRight: '5px',
    },
    failedValidation: {
      border: '1px solid rgba(220, 10, 0, 0.3)',
    },
  });
});

export default useStyles;
