import React from 'react';
import { Navigate } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import { isAuth } from 'helpers/checkAuth';

import PrivateComponent from 'components/private.component';
import Header from './header/header';

const styles = makeStyles()({
  privateComponent: {
    width: '100vw',
    height: 'calc(100vh - 80px)',
  },
});

function PrivateRoute({ children }) {
  const isAuthenticated = isAuth();
  const { classes: classNames } = styles();

  return (
    isAuthenticated
      ? (
        <PrivateComponent>
          <Header />
          <div className={classNames.privateComponent}>
            {children}
          </div>
        </PrivateComponent>
      )
      : <Navigate replace to={{ pathname: '/login' }} />
  );
}

export default PrivateRoute;
