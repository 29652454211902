import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { format } from 'date-fns';
import enLocale from 'date-fns/locale/en-US';
import ruLocale from 'date-fns/locale/ru';

import { Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import FormControl from '@mui/material/FormControl';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

import { objectsByAppFetch } from 'store/actions/objects';
import { fetchInventoryReport } from 'store/actions/reports';
import {
  selectCurrentFloor,
  selectFloorsObject, setFloor,
} from 'store/slices/floors';
import { setSelectedGroup } from 'store/slices/groups';
import { selectCurrentLocation, selectLocationsObject, setLocation } from 'store/slices/locations';
import { selectObjectsByAppArray } from 'store/slices/objects';
import {
  selectReportsCurrentObject, selectReportsDateFrom,
  selectReportsDateTo, selectReportsInventoryRepLastCoords,
  selectReportsInventoryReport, selectReportsIsFetchingInventoryReport,
  setCurrentObject,
  setInventoryReport,

} from 'store/slices/reports';
import {
  selectSelectedZone, selectZoneTypes,
  selectZonesMap, setSelectedZone,
} from 'store/slices/zones';
import { selectAppCurrentApp, selectAppOpenMenu } from 'store/slices/app';
import { commonFormatDuration, dateTableFormat } from 'helpers/formatters';

import { allValuesConstant, selectNoOneConstant } from 'constans';

import BuildButton from 'components/reports/BuildButton.component';
import AutoComplete from 'components/reports/ReportsAutoComplete';
import DatePickers from 'components/reports/datePickers/datePickers.component';
import containerStyles from 'components/shared/Container/styles';
import PaginationIcon from 'components/shared/Pagination/PaginationIcon';
import CustomTableHeader from 'components/shared/Tables/Header';
import { sortObjectByNumericalKey } from 'helpers/sortByField';
import FloorSelector from '../ReportsFloorSelector';
import LocationSelector from '../ReportsLocationSelector';
import ZoneSelector from '../ReportsZoneSelector';
import useStyles from './inventoryReport.component.style';

import { downloadXls, formatCoordinates } from './xls.export';

const datePickerLocales = {
  ru: ruLocale,
  en: enLocale,
};

function InventoryReport() {
  const { classes: classesContainer } = containerStyles();
  const { classes } = useStyles();
  const { t, i18n } = useTranslation(['report', 'monitoring', 'translation']);
  const dateLocale = datePickerLocales[i18n.language];

  const dispatch = useDispatch();
  const openMenu = useSelector(selectAppOpenMenu);
  const currentApp = useSelector(selectAppCurrentApp);
  const floorsObject = useSelector(selectFloorsObject);
  const currentFloor = useSelector(selectCurrentFloor);
  const locationsObject = useSelector(selectLocationsObject);
  const currentLocation = useSelector(selectCurrentLocation);
  const objectsByAppArray = useSelector(selectObjectsByAppArray);
  const currentObject = useSelector(selectReportsCurrentObject);
  const dateFrom = useSelector(selectReportsDateFrom);
  const dateTo = useSelector(selectReportsDateTo);
  const lastCoordinates = useSelector(selectReportsInventoryRepLastCoords);
  const report = useSelector(selectReportsInventoryReport);
  const isFetching = useSelector(selectReportsIsFetchingInventoryReport);
  const zonesMap = useSelector(selectZonesMap);
  const selectedZone = useSelector(selectSelectedZone);
  const zoneTypes = useSelector(selectZoneTypes);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [objectsFilter, setObjectsFilter] = useState('');
  const [sort, setSort] = useState({
    inside_at: 'none',
    outside_at: 'none',
    total_time: 'none',
  });

  const fetchReport = async () => {
    setPage(0);
    await dispatch(fetchInventoryReport());
  };

  const selectObject = (event, object) => {
    dispatch(setCurrentObject(object));
  };

  const handleChangeLocation = (event) => {
    const locationId = event.target.value;
    dispatch(setLocation(locationsObject[locationId]));
    const floorId = locationsObject[locationId].floors[0].id;
    dispatch(setFloor(floorsObject[floorId]));
    dispatch(setSelectedZone({ id: selectNoOneConstant }));
    dispatch(setSelectedGroup({ id: allValuesConstant }));
  };

  const handleChangeFloor = (event) => {
    const floorId = event.target.value;
    dispatch(setFloor(floorsObject[floorId]));
    dispatch(setSelectedZone({ id: selectNoOneConstant }));
    dispatch(setSelectedGroup({ id: allValuesConstant }));
  };

  const handleChangeZone = (event) => {
    event.preventDefault();
    const zoneId = event.target.value;

    if (zoneId === allValuesConstant) {
      dispatch(setSelectedZone({ id: allValuesConstant }));
      return;
    }
    if (zoneId === selectNoOneConstant) {
      dispatch(setSelectedZone({ id: selectNoOneConstant }));
    }
    if (zoneId !== selectNoOneConstant && zoneId !== allValuesConstant) {
      dispatch(setSelectedZone(zonesMap.get(zoneId)));
    }
  };

  const handleDownload = () => {
    downloadXls({
      report,
      lastCoordinates,
      currentObject,
      translate: t,
      locale: dateLocale,
    });
  };

  useEffect(() => {
    const getObjects = async () => {
      await dispatch(objectsByAppFetch(objectsFilter, 0, 1000));
    };

    getObjects();
  }, [objectsFilter, page, rowsPerPage, currentApp, dispatch]);

  useEffect(() => {
    setPage(0);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [objectsFilter, rowsPerPage, currentApp, dateFrom, dateTo]);

  const onAutocompliteInput = async (value) => {
    setObjectsFilter(value);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  function sortColumn(column) {
    const order = sort[column] === 'desc' ? 'asc' : 'desc';
    const result = {};
    Object.keys(sort).forEach((key) => {
      result[key] = key !== column ? 'none' : order;
    });
    setSort(result);
    const array = sortObjectByNumericalKey(order, report, column);
    dispatch(setInventoryReport({ report: array }));
  }

  return (
    <div className={!openMenu ? classes.content : classesContainer.noneContent}>
      <div className={classes.topControls}>
        <div className={classes.formControls}>
          <form className={classes.formControls} noValidate>
            <div className={classes.controlWrapper}>
              <LocationSelector
                locations={locationsObject}
                className={classes.selector}
                value={currentLocation.id}
                onChange={handleChangeLocation}
              />
            </div>
            <div className={classes.controlWrapper}>
              <FormControl variant="standard">
                <FloorSelector
                  floors={floorsObject}
                  className={classes.selector}
                  onChange={handleChangeFloor}
                  value={currentFloor.id}
                  currentLocationId={currentLocation.id}
                />
              </FormControl>
            </div>
            <div className={classes.controlWrapper}>
              <FormControl variant="standard">
                <ZoneSelector
                  className={classes.selector}
                  onChange={handleChangeZone}
                  zones={zonesMap}
                  value={selectedZone.id}
                  zoneTypes={zoneTypes}
                  currentFloorId={currentFloor.id}
                />
              </FormControl>
            </div>
          </form>
          <form className={classes.formControls} noValidate>

            <div className={classes.selectorWrapp}>
              <AutoComplete
                filteredObjectsArray={objectsByAppArray}
                currentObject={currentObject}
                selectObject={selectObject}
                onInputChange={onAutocompliteInput}
                bkgColor="#ffffff"
              />
            </div>
            <DatePickers />
          </form>
        </div>
      </div>
      {isFetching
        ? (
          <div className={classes.spinerWrapper}>
            <CircularProgress size={26} />
          </div>
        )
        : (
          <div>
            <div className={classes.headContainer}>
              <Typography variant="h6">{t('translation:inventoryReports')}</Typography>
              <div className={classes.btnWrapper}>
                <BuildButton
                  variant="outlined"
                  color="primary"
                  disableRipple
                  onClick={fetchReport}
                  disabled={isFetching}
                >
                  {t('buildReport')}
                </BuildButton>
                {report.length > 0 ? (
                  <div
                    className={classes.downloadControlContainer}
                    onClick={handleDownload}
                    onKeyUp={() => handleDownload()}
                    tabIndex="0"
                    role="button"
                  >
                    <div className={classes.downloadIcon} />
                    <div className={classes.downLoadPhraseContainer}>
                      <p className={classes.downLoadPhrase}>
                        {' '}
                        {t('downloadXls')}
                        {' '}
                      </p>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
            <div className={classes.table}>

              <TableContainer className={classes.tableContainer} component={Paper}>
                <Table stickyHeader className={classes.table} aria-label="simple table">
                  <TableBody>
                    <TableRow>
                      <TableCell className={classes.tableCell}>{t('objectTitle')}</TableCell>
                      <TableCell className={classes.tableCell}>{t('monitoring:group')}</TableCell>
                      <TableCell className={classes.tableCell}>{t('zone')}</TableCell>
                      <TableCell className={classes.tableCell}>{t('monitoring:coordinates')}</TableCell>
                      <CustomTableHeader
                        onClick={() => sortColumn('inside_at')}
                        align="right"
                        canBeSorted
                        sx={{ padding: '19px 50px' }}
                      >
                        <div className="tableTheadCell">
                          <span className="titleText">
                            {t('Enter')}
                          </span>
                          { (sort.inside_at === 'asc')
                          && <ArrowDropUpIcon /> }
                          { (sort.inside_at === 'desc')
                          && <ArrowDropDownIcon /> }
                        </div>
                      </CustomTableHeader>
                      <CustomTableHeader
                        onClick={() => sortColumn('outside_at')}
                        align="right"
                        canBeSorted
                        sx={{ padding: '19px 50px' }}
                      >
                        <div className="tableTheadCell">
                          <span className="titleText">
                            {t('Exit')}
                          </span>
                          { (sort.outside_at === 'asc')
                          && <ArrowDropUpIcon /> }
                          { (sort.outside_at === 'desc')
                          && <ArrowDropDownIcon /> }
                        </div>
                      </CustomTableHeader>
                      <CustomTableHeader
                        onClick={() => sortColumn('total_time')}
                        align="right"
                        canBeSorted
                        sx={{ padding: '19px 50px' }}
                      >
                        <div className="tableTheadCell">
                          <span className="titleText">
                            {t('duration')}
                          </span>
                          { (sort.total_time === 'asc')
                          && <ArrowDropUpIcon /> }
                          { (sort.total_time === 'desc')
                          && <ArrowDropDownIcon /> }
                        </div>
                      </CustomTableHeader>
                    </TableRow>
                    {report.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((objectReport, index) => {
                        const entryDate = new Date(objectReport.inside_at * 1000);
                        // eslint-disable-next-line max-len
                        const exitDate = objectReport.outside_at === null ? null : new Date(objectReport.outside_at * 1000);
                        const key = index + 1;
                        return (
                          <TableRow key={key}>
                            <TableCell className={classes.tableCell} component="th" scope="row">
                              {objectReport?.tracked_object?.title || ''}
                            </TableCell>
                            <TableCell className={classes.tableCell} component="th" scope="row">
                              {objectReport?.tracked_object?.trackedGroups?.title || ''}
                            </TableCell>
                            <TableCell className={classes.tableCell} component="th" scope="row">
                              {objectReport?.zone?.name || t('deletedZone')}
                            </TableCell>
                            <TableCell className={classes.tableCell} component="th" scope="row">
                              {formatCoordinates(objectReport?.coordinates)}
                            </TableCell>
                            <TableCell className={classes.tableCell} align="right">
                              {format(entryDate, dateTableFormat, { locale: dateLocale })}
                            </TableCell>
                            <TableCell className={classes.tableCell} align="right">
                              {exitDate ? format(exitDate, dateTableFormat, { locale: dateLocale }) : t('stillZone')}
                            </TableCell>
                            <TableCell className={classes.tableCell} align="right">
                              {commonFormatDuration(
                                objectReport.total_time,
                                datePickerLocales[i18n.language],
                              )
                               || 'Wrong time'}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 25, 50, { label: t('All'), value: -1 }]}
                component="div"
                count={report.length}
                rowsPerPage={rowsPerPage}
                page={page}
                className={classes.pagination}
                SelectProps={{
                  IconComponent: PaginationIcon,
                }}
                classes={{
                  root: classes.root,
                  select: classes.select,
                  selectIcon: classes.selectIcon,
                  caption: classes.caption,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${t('of')} ${count !== -1 ? count : `${t('moreThen')} ${to}`}`}
                labelRowsPerPage={t('labelRowsPerPage')}
                nextIconButtonProps={{
                  'aria-label': 'Next Page',
                  style: {
                    color: '#000', background: 'white', width: '32px', height: '32px', margin: '5px',
                  },
                  autoid: 'pagination-button-previous-collector',
                }}
                backIconButtonProps={{
                  'aria-label': 'Previous Page',
                  style: {
                    color: page === 0 ? '#b5b8c4' : '#41afd7', background: 'white', width: '32px', height: '32px',
                  },
                  autoid: 'pagination-button-next-collector',
                }}
              />
            </div>
          </div>
        )}
    </div>
  );
}
export default InventoryReport;
