import { withStyles } from 'tss-react/mui';
import Button from '@mui/material/Button';

export const buildReportButton = withStyles(Button, (materialTheme) => {
  // eslint-disable-next-line no-unused-vars
  const { fontRegular } = materialTheme;

  return {
    root: {
      boxShadow: 'none',
      textTransform: 'none',
      fontSize: 16,
      padding: '6px 12px',
      border: '1px solid #edeff3',
      lineHeight: 1.5,
      backgroundColor: '#30AAD9',
      color: '#fff',
      width: '167px',
      height: '36px',
      background: '#30AAD9',
      borderRadius: '4px',
      marginRight: '5px',
      '&:hover': {
        backgroundColor: materialTheme.palette.primary.main,
        borderColor: materialTheme.palette.primary.main,
        boxShadow: 'none',
      },
      '&:active': {
        boxShadow: 'none',
        backgroundColor: materialTheme.palette.primary.main,
        borderColor: materialTheme.palette.primary.main,
      },
      '&:disabled': {
        boxShadow: 'none',
        backgroundColor: '#edeff3',
        borderColor: '#edeff3',
        '&:hover': {
          cursor: 'move',
        },
      },
      '&:focus': {
        boxShadow: '0 0 0 0.2rem #edeff3',
      },
    },
  };
});
export default buildReportButton;
