export function findLatestObjectTime(frames, currentSecond, objectId) {
  if (!(currentSecond in frames)) {
    return null;
  }
  const keys = Object.keys(frames).filter((key) => key <= currentSecond);
  let correctTime;
  for (let i = (keys.length - 1); i > 0; i -= 1) {
    const iterationValue = frames[keys[i]];
    if (iterationValue && Array.isArray(iterationValue)) {
      for (let j = 0; j < iterationValue.length; j += 1) {
        if (iterationValue[j].id === objectId) {
          correctTime = iterationValue[j].time;
          break;
        }
      }
      if (correctTime) {
        break;
      }
    }
  }
  return correctTime;
}

export function findObjectId(objectsList, title) {
  const keys = Object.keys(objectsList);
  for (let i = 0; i < keys.length; i += 1) {
    if (objectsList[keys[i]].title === title) {
      return objectsList[keys[i]].id;
    }
  }
  return null;
}
