/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { formTypeCreate } from 'constans';
import { fetchDeviceModels } from 'store/actions/devices';

const initialState = {
  devicesList: [],
  creatingResult: {},
  openModal: false,
  openDeleteModal: false,
  isModalLoading: false,
  formType: formTypeCreate,
  isFetchingSettingsList: false,
  updateDeviceId: null,
  modalTitle: '',
  macAddress: '',
  propsFromBack: [],
  customProps: [],
  model_id: 1,
  device_models: [],
  fetchingModels: false,
  modal: {
    title: '',
    mac: '',
  },
};

const devicesSlice = createSlice({
  name: 'devices',
  initialState,
  reducers: {
    addCustomProperty(state, action) {
      const existsInProps = state.customProps
        .findIndex((element) => element.key === action.payload.key);
      if (existsInProps >= 0) {
        state.customProps[existsInProps] = action.payload;
      } else {
        state.customProps.push(action.payload);
      }
    },
    deleteCustomProperty(state, action) {
      state.customProps = action.payload;
    },
    deleteBackProperty(state, action) {
      state.propsFromBack = action.payload;
    },
    setDevicesModalForm(state, action) {
      state.modal = { ...state.modal, ...action.payload };
    },
    openDevicesModal(state, action) {
      state.openModal = true;
      state.isModalLoading = false;
      state.formType = action.payload;
    },
    closeDevicesModal(state) {
      state.customProps = initialState.customProps;
      state.modal = initialState.modal;
      state.modalTitle = initialState.modalTitle;
      state.macAddress = initialState.macAddress;
      state.openModal = false;
      state.isModalLoading = false;
      state.model_id = initialState.model_id;
    },
    openDeleteDevicesModal(state) {
      state.openDeleteModal = true;
    },
    closeDeleteDevicesModal(state) {
      state.openDeleteModal = false;
    },
    setSettingsModalDeviceTitle(state, action) {
      state.modalTitle = action.payload;
    },
    setSettingsModalMac(state, action) {
      state.macAddress = action.payload;
    },
    setProperty(state, action) {
      state.propsFromBack = action.payload;
    },
    setUpdateDeviceId(state, action) {
      state.updateDeviceId = action.payload;
    },
    deviceModalFetchStart(state) {
      state.isModalLoading = true;
    },
    setCreatedDevice(state, action) {
      state.creatingResult = { ...action.payload };
    },
    setIsFetchingDevicesList(state, action) {
      state.isFetchingSettingsList = action.payload;
    },
    setDevicesList(state, action) {
      state.devicesList = action.payload;
      state.isFetchingSettingsList = false;
    },
    setDeviceModelId(state, action) {
      state.model_id = action.payload ? action.payload : state.model_id;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchDeviceModels.fulfilled, (state, action) => {
      state.device_models = action.payload;
      state.fetchingModels = false;
    });
    builder.addCase(fetchDeviceModels.pending, (state) => {
      state.fetchingModels = true;
    });
  },
});

export const selectDevices = (state) => state.devices;

export const selectDevicesList = (state) => selectDevices(state).devicesList;
export const selectDevicesCreatingResult = (state) => selectDevices(state).creatingResult;
export const selectDevicesOpenModal = (state) => selectDevices(state).openModal;
export const selectDevicesOpenDeleteModal = (state) => selectDevices(state).openDeleteModal;
export const selectDevicesIsModalLoading = (state) => selectDevices(state).isModalLoading;
export const selectDevicesFormType = (state) => selectDevices(state).formType;
export const selectDevicesIsFetchingSettingsList = (
  state,
) => selectDevices(state).isFetchingSettingsList;
export const selectDevicesUpdateDeviceId = (state) => selectDevices(state).updateDeviceId;
export const selectDevicesModalTitle = (state) => selectDevices(state).modalTitle;
export const selectDevicesMacAddress = (state) => selectDevices(state).macAddress;
export const selectDevicesPropsFromBack = (state) => selectDevices(state).propsFromBack;
export const selectDevicesCustomProps = (state) => selectDevices(state).customProps;
export const selectDevicesModal = (state) => selectDevices(state).modal;
export const selectDeviceModels = (state) => selectDevices(state).device_models;
export const selectDeviceModelId = (state) => selectDevices(state).model_id;
export const selectFetchingDeviceModels = (state) => selectDevices(state).fetchingDeviceModels;

export const {
  addCustomProperty, setDeviceModelId,
  deleteBackProperty, deleteCustomProperty, deviceModalFetchStart, closeDevicesModal,
  openDevicesModal, setCreatedDevice, setDevicesList, setDevicesModalForm,
  setProperty, setSettingsModalDeviceTitle, setSettingsModalMac, setUpdateDeviceId,
  openDeleteDevicesModal, closeDeleteDevicesModal, setIsFetchingDevicesList,
} = devicesSlice.actions;

export default devicesSlice.reducer;
