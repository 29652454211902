import React from 'react';
import { useTranslation } from 'react-i18next';

import CloseIcon from '@mui/icons-material/Close';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';

import useStyles from './DialogGuidelinesStyles';

function DialogGuidelines({ setOpen, step, setStep }) {
  const TOTAL_STEPS = 7;
  const { classes } = useStyles();
  const { t } = useTranslation(['topMenu', 'report']);
  const dynamicModalContainer = `modalContainer${step}`;

  function handleClose() {
    setOpen(false);
  }

  return (
    <div className={classes.background}>
      <div className={`${classes.modalContainer} ${classes[dynamicModalContainer]}`}>

        <header className={classes.headerModal}>
          <section className={classes.headerRow}>
            <p className={classes.textParagraph}>
              {t(`guidelineStep${step}`)}
            </p>
            <CloseIcon
              onClick={() => handleClose()}
              className={classes.closeButton}
              style={{ fontSize: '1.2em' }}
            />
          </section>
        </header>

        <main>
          <img className={classes.imageGuideline} loading="lazy" src={`/guidelines/step${step}.gif`} alt={`Step #${step}`} />
          <footer className={classes.footerContainer}>
            <div className={classes.stepCounterText}>
              {step}
              {' '}
              {t('report:of')}
              {' '}
              {TOTAL_STEPS}
            </div>
            <div className={classes.navigateFooter}>
              <NavigateBeforeIcon
                className={step === 1
                  ? classes.disabledNavigation : classes.normalNavigation}
                onClick={() => setStep((prev) => (prev > 1 ? prev - 1 : 1))}
              />
              <NavigateNextIcon
                className={step === TOTAL_STEPS
                  ? classes.disabledNavigation : classes.normalNavigation}
                onClick={() => setStep((prev) => (prev < TOTAL_STEPS ? prev + 1 : 7))}
              />
              <button type="button" onClick={handleClose} className={classes.buttonClose}>
                {t('closeGuidelines')}
              </button>
            </div>
          </footer>
        </main>

      </div>
    </div>
  );
}

export default DialogGuidelines;
