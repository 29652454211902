import { makeStyles } from 'tss-react/mui';

const containerStyles = makeStyles()((materialTheme) => ({
  noneContent: {
    height: 'calc(100vh - 80px)',
    display: 'flex',
    flexDirection: 'column',
    padding: '0 45px 0 32px',
    overflowY: 'auto',
    [materialTheme.breakpoints.down('800')]: {
      width: '90%',
      marginLeft: '-1vw',
    },
  },
}));

export default containerStyles;
