import { withStyles } from 'tss-react/mui';
import Button from '@mui/material/Button';

const CustomButtonTitle = withStyles(Button, (materialTheme) => {
  // eslint-disable-next-line no-unused-vars
  const { fontRegular } = materialTheme;

  return {
    root: {
      margin: '0 2% 2% 0',
      color: 'white',
      fontWeight: '600',
      borderRadius: '2px',
      '&:disabled': {
        boxShadow: 'none',
        backgroundColor: 'transparent',
      },
    },
  };
});
export default CustomButtonTitle;
