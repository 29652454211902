import Cookies from 'js-cookie';
import request, { endPointsEnum } from 'services/http';
import { cookieTokenName, cookOpts } from 'constans';
import { createAlert } from 'store/slices/alert';
import {
  fetchAppInProgress, fetchedAppsList, setApp, setAppsObject,
} from 'store/slices/app';

export const changeAppInToken = async (appId, dispatch) => {
  try {
    const params = {
      appId,
    };
    const { newToken } = await request.auth.get(endPointsEnum.appChange, { params });
    Cookies.set(cookieTokenName, newToken, cookOpts);

    return true;
  } catch (e) {
    dispatch(createAlert({ messageType: 'error', message: e.message }));
    return false;
  }
};

export const appFetch = () => async (dispatch, getState) => {
  try {
    const state = getState();
    const params = {
      userHash: state.user.hash,
    };

    dispatch(fetchAppInProgress());

    const appsArray = await request.client.get(endPointsEnum.appsGetAll, { params });
    const appsObject = {};
    appsArray.map((app) => {
      appsObject[app.id] = app;
      return app;
    });

    dispatch(setAppsObject(appsObject));
    const appIDFormStorage = localStorage.getItem('appID');

    if (appIDFormStorage && appsObject[appIDFormStorage]) {
      await changeAppInToken(appIDFormStorage, dispatch);
      dispatch(setApp(appsObject[appIDFormStorage]));
    } else {
      dispatch(setApp(appsArray[0]));
      localStorage.setItem('appID', appsArray[0].id);
    }

    dispatch(fetchedAppsList());
  } catch (error) {
    dispatch(fetchedAppsList());
    dispatch(createAlert({ messageType: 'error', message: error.message }));
  }
};
