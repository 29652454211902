import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import useStyles from '../../containers/monitoring/style';

function DailogArray({
  setObjectInfoArray,
  setObjectInfo,
  objectInfoArray,
  handleCloseObjectModal,
  openObjectModal,
  Transition,
  setOpenObjectModal,
}) {
  useEffect(() => {
    if (objectInfoArray.length > 1) {
      setOpenObjectModal(true);
    }
  }, [setOpenObjectModal, objectInfoArray]);
  const { classes } = useStyles();
  const { t } = useTranslation(['monitoring']);
  // Click on map event callback
  const listOnClickCallback = (event) => {
    event.preventDefault();
    // eslint-disable-next-line array-callback-return
    objectInfoArray.filter((obj) => {
      if (obj.title === event.target.innerHTML) {
        setObjectInfo(obj);
        setObjectInfoArray([]);
      }
    });
  };

  return (
    <div>

      {objectInfoArray.length > 1 ? (
        <Dialog
          open={openObjectModal}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleCloseObjectModal}
          maxWidth="md"
        >
          <DialogContent className={classes.noneDialogContent}>
            <TableContainer>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      {t('numbersObjects')}
                      :
                      {' '}
                      {objectInfoArray.length}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            {/* <div className={classes.titleList}>

            </div> */}
            {objectInfoArray.map((prop, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <TableContainer key={index.toString()}>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell
                        className={classes.listItemTitle}
                        onClick={listOnClickCallback}
                      >
                        {prop.title}
                      </TableCell>
                      <TableCell
                        className={classes.listItems}
                      >
                        {prop.file_url ? (
                          <div>
                            <img
                              className={classes.listImg}
                              src={prop.file_url}
                              alt="file_url"
                            />
                          </div>
                        ) : <div className={classes.circle} />}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>

            ))}
          </DialogContent>
        </Dialog>
      ) : null }
    </div>
  );
}

export default DailogArray;
