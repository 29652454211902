import { createAsyncThunk } from '@reduxjs/toolkit';
import request from 'services/http';
import { createAlert } from 'store/slices/alert';
import {
  setDebugDevices, setDebugLocations, setDebugQueues, setFetchingDebugList,
  setDeviceExtra, setDeviceExtraLoading,
  setLocationExtra, setLocationExtraLoading,
  setQueueExtra, setQueueExtraLoading,
} from 'store/slices/debug';

export const getDebugDevices = (
  filter = null,
  appFilter = null,
  locationFilter = null,
) => async (dispatch) => {
  try {
    dispatch(setFetchingDebugList(true));
    const response = await request.qttracker
      .get(`debug/devices?app_id=${appFilter}&location_id=${locationFilter}`, {});
    if (response && response.devices) {
      const devicesObjects = response.devices
        ?.map((device, index) => ({
          ...device,
          id: index,
          expanded: false,
          extra: null,
        }));
      if (filter) {
        dispatch(setDebugDevices(devicesObjects
          .filter((device) => device.mac_address.toLowerCase()
            .includes(filter.toLowerCase()))));
      } else {
        dispatch(setDebugDevices(devicesObjects));
      }
      dispatch(setFetchingDebugList(false));
    }
  } catch (error) {
    dispatch(setFetchingDebugList(false));
    dispatch(createAlert({ messageType: 'error', message: 'Error fetching debug devices' }));
    // eslint-disable-next-line no-console
    console.error(error);
  }
};

export const getDeviceData = (device) => async (dispatch) => {
  try {
    dispatch(setDeviceExtraLoading({
      id: device.id,
      bind_location_id: device.bind_location_id,
      bind_sublocation_id: device.bind_sublocation_id,
      loading: true,
    }));
    const response = await request.qttracker.get(`debug/device?mac_address=${device.mac_address}`, {});
    if (response && response.devices) {
      const respDevice = response.devices
        .find((item) => item.bind_sublocation_id === device.bind_sublocation_id
        && item.bind_location_id === device.bind_location_id);
      dispatch(setDeviceExtra({
        extra: respDevice,
        id: device.id,
        bind_location_id: device.bind_location_id,
        bind_sublocation_id: device.bind_sublocation_id,
      }));
    }
    dispatch(setDeviceExtraLoading({
      id: device.id,
      bind_location_id: device.bind_location_id,
      bind_sublocation_id: device.bind_sublocation_id,
      loading: false,
    }));
  } catch (error) {
    dispatch(createAlert({ messageType: 'error', message: 'Error fetching device data' }));
    // eslint-disable-next-line no-console
    console.error(error);
  }
};

export const getDebugLocations = (filter = null) => async (dispatch) => {
  try {
    dispatch(setFetchingDebugList(true));
    const response = await request.qttracker.get('stat', {});
    if (response && response.locations) {
      const locationObjects = response.locations
        .map((location) => ({
          ...location,
          expanded: false,
          extra: null,
        }));
      if (filter) {
        dispatch(setDebugLocations(locationObjects
          .filter((location) => location.id.toString().toLowerCase()
            .includes(filter.toLowerCase())
            || location.name.toLowerCase().includes(filter.toLowerCase()))));
      } else {
        dispatch(setDebugLocations(locationObjects));
      }
      dispatch(setFetchingDebugList(false));
    }
  } catch (error) {
    dispatch(setFetchingDebugList(false));
    dispatch(createAlert({ messageType: 'error', message: 'Error fetching debug locations' }));
    // eslint-disable-next-line no-console
    console.error(error);
  }
};

export const getLocationData = (location) => async (dispatch) => {
  try {
    dispatch(setLocationExtraLoading({ id: location.id, loading: true }));
    const response = await request.qttracker.get(`debug/location?id=${location.id}`, {});
    if (response) {
      dispatch(setLocationExtra({ extra: response, id: location.id }));
    }
    dispatch(setLocationExtraLoading({ id: location.id, loading: false }));
  } catch (error) {
    dispatch(createAlert({ messageType: 'error', message: 'Error fetching location data' }));
    // eslint-disable-next-line no-console
    console.error(error);
  }
};

export const getDebugQueues = (filter = null) => async (dispatch) => {
  try {
    dispatch(setFetchingDebugList(true));
    const response = await request.qttracker.get('debug/queues', {});
    if (response && response.queues) {
      const queuesObjects = response.queues?.map((queue, index) => ({
        ...queue,
        id: index,
        expanded: false,
        extra: null,
      }));
      if (filter) {
        dispatch(setDebugQueues(queuesObjects.filter((queue) => queue.name.toLowerCase()
          .includes(filter.toLowerCase()))));
      } else {
        dispatch(setDebugQueues(queuesObjects));
      }
      dispatch(setFetchingDebugList(false));
    }
  } catch (error) {
    dispatch(setFetchingDebugList(false));
    dispatch(createAlert({ messageType: 'error', message: 'Error fetching debug queues' }));
    // eslint-disable-next-line no-console
    console.error(error);
  }
};

export const getQueueData = (queue) => async (dispatch) => {
  try {
    dispatch(setQueueExtraLoading({ name: queue.name, loading: true }));
    const response = await request.qttracker.get(`debug/queue?name=${queue.name}`, {});
    if (response) {
      dispatch(setQueueExtra({ extra: response, name: queue.name }));
    }
    dispatch(setQueueExtraLoading({ name: queue.name, loading: false }));
  } catch (error) {
    dispatch(createAlert({ messageType: 'error', message: 'Error fetching queue data' }));
    // eslint-disable-next-line no-console
    console.error(error);
  }
};

export const getNetworkErrors = createAsyncThunk(
  'debug/getNetworkErrors',
  async (_, { dispatch }) => {
    try {
      const response = await request.qttracker.get('network_errors', {});
      return response;
    } catch (err) {
      dispatch(createAlert({ messageType: 'error', message: 'Error fetching network errors' }));
      return [];
    }
  },
);
