import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { format } from 'date-fns';
import { dateExelFormat, commonFormatDuration } from 'helpers/formatters';

const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const fileExtension = '.xlsx';

const fitToColumn = (colums) => colums.map((col) => ({
  wch: col.toString().length,
}));

const exportToCSV = (csvData, fileName) => {
  const ws = XLSX.utils.json_to_sheet(csvData);
  ws['!cols'] = fitToColumn(Object.keys(csvData[0]));
  const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
  const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
  const data = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(data, fileName + fileExtension);
};
export const formatCoordinates = ({ x, y, z }) => `x: ${x}\u00A0y:${y}${z ? `\u00A0z:${z}` : ''}`;

const formatReportToCsv = ({
  record, lastCoordinates, translate, locale,
}) => {
  const titleObject = translate('objectTitle') || 'Object name';
  const titleGroup = translate('monitoring:group') || 'Group';
  const zoneColumn = translate('zone') || 'Zone';
  const coordinatesColumn = translate('monitoring:coordinates') || 'Coordinates';
  const entryDateTimeColumn = translate('Enter') || 'Entry Date';
  const entryDate = new Date(record.inside_at * 1000);
  const exitDateTimeColumn = translate('Exit') || 'Exit Time';
  const exitDate = new Date(record.outside_at * 1000);
  const durationColumn = translate('duration') || 'Duration';
  const lastCoordsColumn = translate('lastCoordinates') || 'Last coordinates';

  const result = {};
  result[titleObject] = record?.tracked_object?.title || '-';
  result[titleGroup] = record?.tracked_object?.trackedGroups?.title || '-';
  result[zoneColumn] = record?.zone?.name || '-';
  result[coordinatesColumn] = record.coordinates ? formatCoordinates(record.coordinates) || '-' : '-';
  result[entryDateTimeColumn] = format(entryDate, dateExelFormat) || 'Wrong time';
  result[exitDateTimeColumn] = format(exitDate, dateExelFormat) || 'Wrong time';
  result[durationColumn] = commonFormatDuration(record.total_time, locale) || 'Wrong time';

  const lastObjCoords = lastCoordinates[record?.tracked_object?.id];
  result[lastCoordsColumn] = lastObjCoords ? formatCoordinates(lastObjCoords) || '-' : '-';

  return result;
};

export const downloadXls = ({
  report, lastCoordinates,
  translate,
  locale,
}) => {
  const csvData = report.map((record) => formatReportToCsv({
    record,
    lastCoordinates,
    translate,
    locale,
  }));

  const fileName = 'Inventory report';

  exportToCSV(csvData, fileName);
};
