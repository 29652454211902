/* eslint-disable no-underscore-dangle */
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import detector from 'i18next-browser-languagedetector';
import Backend from 'i18next-xhr-backend';
import defaultTheme from './themes-materialUI/default.theme';

const configEnv = window._configuration_env;
let theme = defaultTheme;
(async () => {
  try {
    if (configEnv.THEME_URL) {
      theme = await (await fetch(`${configEnv.THEME_URL}/theme.json`)).json();
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error in theme loading. Using default. Error:', error);
  }
})();

i18n
  .use(detector)
  .use(Backend)
  .use(initReactI18next)
  .init({
    debug: false,
    fallbackLng: 'en',
    whitelist: theme.locales ? theme.locales : ['en', 'ru', 'et', 'cn', 'jp', 'de'],
    interpolation: {
      escapeValue: false,
    },
    preload: theme.locales ? theme.locales : ['en', 'ru', 'et', 'cn', 'jp', 'de'],
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json',
      allowMultiLoading: true,
    },
  });

export default i18n;
