import { makeStyles } from 'tss-react/mui';

const arrowStyle = makeStyles()(() => ({
  titleRow: {
    borderBottom: '1px  solid #f4f4f4',
    padding: '10px 30px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  arrow: {
    position: 'relative',
    top: '10px',
    // [materialTheme.breakpoints.down('1100')]: {
    //   top: '27px',
    // },
    // [materialTheme.breakpoints.down('980')]: {
    //   top: '40px',
    // },
    // [materialTheme.breakpoints.down('800')]: {
    //   top: '40px',
    // },
  },
}));

export default arrowStyle;
