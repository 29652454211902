import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((materialTheme) => ({
  content: {
    width: 'calc(100vw - 30vh)',
    height: 'calc(100vh - 80px)',
    display: 'flex',
    flexDirection: 'column',
    padding: '0 45px 0 32px',
    overflowY: 'auto',
    [materialTheme.breakpoints.down('800')]: {
      width: '90%',
      marginLeft: '-1vw',
    },
  },
  iconCancel: {
    float: 'right',
    marginLeft: '-50px',
    fontSize: '20px',
    color: 'black',
    '&:hover': {
      color: '#f44336',
    },
  },
  iconAttention: {
    marginLeft: '1.5rem',
    color: 'red',
  },
  table: {
    width: '100%',
  },
  tableRow: {
    height: '4.5em',
  },
  titleTheadCellSort: {
    borderBottom: '1px  solid #f4f4f4',
    padding: '10px 30px 0',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  titleTheadCell: {
    borderBottom: '1px  solid #f4f4f4',
    padding: '10px 30px 0',
  },
  tableTheadCell: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    height: '1.5em',
    maxWidth: '7.5em',
    whiteSpace: 'nowrap',
  },
  titleText: {
    display: 'flex',
    alignItems: 'center',
  },
  objectTitleRow: {
    width: '20%',
  },
  statusRow: {
    width: '20%',
  },
  groupRow: {
    width: '18%',
  },
  beaconRow: {
    width: '15%',
  },
  topControls: {
    display: 'flex',
    flexDirection: 'row',
    margin: '26px 0px',
  },
  createBtn: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '10px 16px 10px 10px',
    margin: '0px 10px 0px 0px',
    height: '40px',
    borderRadius: '8px',
    fontSize: '14px',
    fontWeight: '400',
    '& span': {
      marginTop: '0.1rem',
    },
    '& .MuiSvgIcon-root': {
      fontSize: '1.2rem',
      marginBottom: '0.1rem',
    },
  },
  spinerWrapper: {
    width: '100%',
    maxHeight: '60vh',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  tabsMainWrapper: {
    maxWidth: '410px',
  },
  tabContainer: {
    margin: '0 auto',
    width: '350px',
    borderBottom: 'none',
  },
  wrappApp: {
    background: 'black',
    boxShadow: '0px 0px 0px 0px grey',
  },
  tabLabel: {
    width: '33%',
  },
  propsCell: {
    maxWidth: '15vw',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    '&:hover': {
      cursor: 'cursor',
    },
  },
  tooltip: {
    whiteSpace: 'pre-line',
    wordWrap: 'break-word',
    fontSize: '17px',
  },
  tabsLabel: {
    maxWidth: '400px',
    background: '#fff',
    '& .MuiTab-root': {
      minWidth: '100px',
      color: materialTheme.palette.primary.main,
      fontWeight: '600',
      // opacity: 0.4,
    },
  },
  customPropsContainer: {
    display: 'flex',
    margin: '0 auto',
    width: '300px',
  },
  customPropsWrapp: {
    margin: '0 0 0 5px',
  },
  customProps: {
    maxWidth: '150x',
    marginLeft: '10px',
    marginTop: '10px',
  },
  btnCusotmContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
  },
  btnCustomProps: {
    width: '20px',
    height: '20px',
    marginTop: '20px',
    marginLeft: '5px',
    '&:hover': {
      cursor: 'pointer',
      color: materialTheme.palette.primary.main,
    },
  },
  btnSetColor: {
    zIndex: '1000000',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  multilineColor: {
    color: 'green',
  },
  idTitle: {
    color: 'grey',
    fontSize: '0.9rem',
  },
  tableCell: {
    borderBottom: '1px  solid #f4f4f4',
    padding: '10px 30px',
  },
  tableContainer: {
    boxShadow: '0px 4px 30px rgba(2, 0, 120, 0.03)',
    borderRadius: '12px',
  },
  imageTitle: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    '& img': {
      marginRight: materialTheme.spacing(0.4),
      height: '1.5em',
    },
  },
  inputComponent: {
    height: '35px',
    padding: '0 5px 0 5px',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    borderRadius: '8px',
  },
  divToH3: {
    display: 'block',
    marginBlockStart: '1em',
    marginBlockEnd: '1em',
    fontWeight: 'bold',
    marginBottom: '0.5em',
  },
  label: {
    width: '100%',
    fontSize: '14px',
    color: '#929292',
    display: 'flex',
    flexDirection: 'column',
  },
  dialogTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 10px',
    borderBottom: '1px solid rgba(0, 0, 0, 0.04)',
    color: 'black',
  },
  dialogActions: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    // padding: '0 10px',
    borderBottom: '1px solid rgba(0, 0, 0, 0.04)',
    color: 'black',
  },
  customBtn: {
    width: '90%',
    background: materialTheme.palette.primary.main,
    color: 'white',
    '&:disabled': {
      background: '#D7D7D7',
      color: '#fff',
    },
    '&:hover': {
      cursor: 'pointer',
      background: materialTheme.palette.primary.main,
    },
  },
  formControl: {
    width: '100%',
  },
  onlineText: {
    color: materialTheme.palette.success.main,
    display: 'flex',
  },
  offlineContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  offlineText: {
    color: materialTheme.palette.error.main,
    display: 'flex',
    textAlign: 'center',
  },
  offlineTimeText: {
    color: 'grey',
    fontSize: '0.7em',
  },
  goToObjectIcon: {
    width: '1.5em',
    height: '1.5em',
    '&.onlineIcon': {
      color: materialTheme.palette.success.main,
    },
    '&.offlineIcon': {
      color: materialTheme.palette.error.main,
    },
    marginLeft: materialTheme.spacing(0.4),
    paddingBottom: materialTheme.spacing(0.6),
    cursor: 'pointer',
  },
  notAvailable: {
    color: materialTheme.palette.error.main,
  },
  actionCell: {
    padding: '0',
    height: 'inherit',
    width: '5%',
  },
  actionButtonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    padding: '10px 20px 10px 20px',
  },
  actionButton: {
    width: '45px',
    height: '24px',
    marginRight: '5px',
    marginLeft: '5px',
    borderRadius: '47px',
    cursor: 'pointer',
    background: 'rgb(233, 237, 239)',
    '&:hover': { background: 'rgb(223, 227, 229)' },
    '&:active': { background: 'rgb(213, 217, 219)' },
  },
}));

export default useStyles;
