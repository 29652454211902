import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';

import { useTheme } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import CalendarIcon from 'assets/images/Calendar.svg';
import enLocale from 'date-fns/locale/en-US';
import etLocale from 'date-fns/locale/et';
import ruLocale from 'date-fns/locale/ru';
import {
  selectReportsDateFrom, selectReportsDateTo, setDateFrom, setDateTo,
} from 'store/slices/reports';

import { useDispatch, useSelector } from 'react-redux';
import StyledDataPicker from './styledDataPicker';

const useStyles = makeStyles()({
  wrapper: {
    paddingTop: '1vh',
    // marginLeft: '2vw',
  },
  defaultInputStyles: {
    marginRight: '2vw',
  },
  '@media screen and (max-width: 1123px)': {
    wrapper: {
      marginLeft: '0px',
    },
  },
  secondWrapper: {
    paddingTop: '1vh',
  },
  history: {
    marginLeft: '20px',
  },
  defaultLabelStyles: {
    marginLeft: '0px',
    marginBottom: '4px',
    color: '#8194A4',
    fontSize: '12px',
    fontWeight: '600',
  },
  calendarIconStyle: {
    padding: '12px',
  },
});

const inputFieldStyles = {
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      border: '0',
    },
    width: '222px',
    height: '36px',
    padding: '6px 0 7px',
    borderRadius: '4px',
    backgroundColor: '#ffffff',
  },
};

const DatePickers = memo((props) => {
  const { classes } = useStyles();
  const datePickerLocales = {
    ru: ruLocale,
    en: enLocale,
    et: etLocale,
  };
  const { t, i18n } = useTranslation(['report']);
  const datePickerLocale = datePickerLocales[i18n.language];
  const {
    labelStyles, inputStyles, minutesStep,
  } = props;
  const dispatch = useDispatch();
  const dateFrom = useSelector(selectReportsDateFrom);
  const dateTo = useSelector(selectReportsDateTo);

  const theme = useTheme();

  const toolbarStyles = ({
    backgroundColor: theme ? theme.palette.primary.main : '',
    '& .Mui-selected.MuiTypography-root': {
      color: '#ffffff',
    },
    '& .MuiTypography-root': {
      color: 'rgba(255, 255, 255, 0.54)',
    },
  });

  const layoutStyles = ({
    height: '540px',
    '.css-1j9v0by-MuiClock-root': {
      height: '300px ',
    },
  });

  const calendarIconInput = ({
    InputProps: {
      endAdornment: (
        <InputAdornment position="end" className={classes.calendarIconStyle}>
          <IconButton aria-label="pick a date">
            <img width="16px" src={CalendarIcon} alt="calendar" />
          </IconButton>
        </InputAdornment>
      ),
    },
  });

  const dateFormat = 'dd LLL yyyy HH:mm';
  const handleDateChangeFrom = (date) => {
    dispatch(setDateFrom(date));
  };
  const handleDateChangeTo = (date) => {
    dispatch(setDateTo(date));
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={datePickerLocale}>
      <div className={classes.wrapper}>
        <div className={labelStyles || classes.defaultLabelStyles}>{t('dateStart')}</div>
        <StyledDataPicker
          ampm={false}
          autoOk
          className={inputStyles || classes.defaultInputStyles}
          closeOnSelect
          disableFuture
          disableUnderline
          format={dateFormat}
          localeText={{ toolbarTitle: '' }}
          maxDate={dateTo}
          maxDateMessage={t('maxDateMessage')}
          minutesStep={minutesStep || 1}
          onChange={handleDateChangeFrom}
          strictCompareDates
          slotProps={{
            textField: calendarIconInput,
            toolbar: { sx: toolbarStyles },
            layout: { sx: layoutStyles },
            // switchViewButton: { sx: { backgroundColor: 'blue' } },
          }}
          sx={inputFieldStyles}
          value={dateFrom}
        />
      </div>
      <div className={classes.secondWrapper}>
        <div className={labelStyles || classes.defaultLabelStyles}>{t('dateFinish')}</div>
        <StyledDataPicker
          ampm={false}
          autoOk
          className={inputStyles || classes.defaultInputStyles}
          closeOnSelect
          disableFuture
          disableUnderline
          format={dateFormat}
          localeText={{ toolbarTitle: '' }}
          minDate={dateFrom}
          minDateMessage={t('minDateMessage')}
          minutesStep={minutesStep || 1}
          onChange={handleDateChangeTo}
          strictCompareDates
          slotProps={{
            textField: calendarIconInput,
            toolbar: { sx: toolbarStyles },
            layout: { sx: layoutStyles },
          }}
          sx={inputFieldStyles}
          value={dateTo}
        />
      </div>
    </LocalizationProvider>
  );
});

export default DatePickers;
