/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  locationsObject: {},
  currentLocation: {},
  isFetching: false,
};

const locationsSlice = createSlice({
  name: 'location',
  initialState,
  reducers: {
    fetchLocationsInProgress(state) {
      state.isFetching = true;
    },
    fetchedLocations(state) {
      state.isFetching = false;
    },
    setLocation(state, action) {
      if (action.payload) {
        state.currentLocation = action.payload;
      }
    },
    setLocationsLst(state, action) {
      state.locationsObject = action.payload;
      state.isFetching = false;
    },
  },
});

export const selectLocations = (state) => state.location;

export const selectLocationsObject = (state) => selectLocations(state).locationsObject;
export const selectCurrentLocation = (state) => selectLocations(state).currentLocation;
export const selectLocationsIsFetching = (state) => selectLocations(state).isFetching;

export const {
  fetchLocationsInProgress, fetchedLocations,
  setLocation, setLocationsLst,
} = locationsSlice.actions;

export default locationsSlice.reducer;
