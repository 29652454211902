import { createTheme } from '@mui/material/styles';
import LoginIcon from 'assets/images/login/login.svg';
import PasswordIcon from 'assets/images/login/password.svg';

import CircleRegular from 'assets/fonts/font-Circe/Circe-Regular-webfont.woff';
import CircleLight from 'assets/fonts/font-Circe/Circe-Light-webfont.woff';
import CircleBold from 'assets/fonts/font-Circe/Circe-Bold-webfont.woff';

const circleRegular = `{
  font-family: 'Regular';
  font-style: 'normal';
  font-display: 'swap';
  font-weight: 'normal';
  src: 'local('SalesforceSans')', 'local('SalesforceSans-Regular')', url('${CircleRegular}') format('woff');
}`;

const light = `{
  font-family: 'Light';
  font-style: 'normal';
  font-display: 'swap';
  font-weight: 'normal';
  src: 'local('SalesforceSans')', 'local('SalesforceSans-Regular')', url('${CircleLight}') format('woff');
}`;

const bold = `{
  font-family: 'Bold';
  font-style: 'normal';
  font-display: 'swap';
  font-weight: 'normal';
  src: 'local('SalesforceSans')', 'local('SalesforceSans-Regular')', url('${CircleBold}') format('woff');
}`;

export default ({ theme }) => {
  if (theme) {
    const { colors, images } = theme;
    const { logo, favicon: faviconIcon } = images;

    const favicon = document.getElementById('favicon');

    favicon.href = faviconIcon;

    const themeData = {

      typography: {
        fontFamily: 'CirceRegular, sans-serif',
      },
      components: {
        MuiCssBaseline: {
          styleOverrides: `
            @font-face ${[circleRegular, light, bold]}
          `,
        },
      },
      fontRegular: 'Regular, sans-serif',
      fontBold: 'Bold, sans-serif',
      fontLight: 'Light, sans-serif',

      CustomStyles: {
        MainTopMenu: {
          background: '#ffffff',
        },
        Login: {
          formItem: {
            height: '55px',
            width: '290px',
          },
          textField: {
            width: '175px',
          },
          textFieldLogin: {
            width: '195px',
          },
          loginIconBackground: `url(${LoginIcon}) center no-repeat`,
          passwordIconBackground: `url(${PasswordIcon}) center no-repeat`,
        },
        MainBackgroundColor: '#F5F8FB',
        LogoImageBackground: `url(${logo}) center no-repeat`,
        GlobalTextColor: '#001424',
        Logo: {
          containerWidth: '200px',
          containerHeight: '50px',
          imageWidth: '200px',
          imageHeight: '50px',
        },
        LeftBarWidth: '14vw',
        MainLeftMargin: '16px',
      },
      palette: colors,
    };

    return createTheme((themeData));
  }
  return createTheme();
};
