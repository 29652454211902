/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { allValuesConstant, formTypeCreate } from 'constans';
import { fetchGroups } from 'store/actions/groups';

const initialState = {
  formType: formTypeCreate,
  groupsListInSettings: [],
  groupsObject: {},
  groupToDelete: {},
  isFetching: 'loading',
  isModalLoading: false,
  modalTitle: '',
  openDeleteModal: false,
  openModal: false,
  selectedGroup: { id: allValuesConstant },
  updatedGroupId: null,
  groupsCount: 0,
};

const groupsSlice = createSlice({
  name: 'groups',
  initialState,
  reducers: {
    closeSettingsModal(state) {
      state.modalTitle = '';
      state.openModal = false;
    },
    closeDeleteGroupModal(state) {
      state.openDeleteModal = false;
    },
    fetchedGroups(state) {
      state.isFetching = false;
    },
    fetchGroupsInProgress(state) {
      state.isFetching = true;
    },
    groupModalFetchStart(state) {
      state.isModalLoading = true;
    },
    groupModalFetchEnd(state) {
      state.isModalLoading = false;
    },
    openDeleteGroupModal(state) {
      state.openDeleteModal = true;
    },
    openSettingsModal(state, action) {
      state.openModal = true;
      state.formType = action.payload;
    },
    setSelectedGroup(state, action) {
      state.selectedGroup = action.payload;
      state.isFetching = false;
    },
    setGroupsListInSettings(state, action) {
      state.groupsListInSettings = action.payload;
    },
    setSettingsModalTitle(state, action) {
      state.modalTitle = action.payload;
    },
    setUpdateGroupId(state, action) {
      state.updatedGroupId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchGroups.pending, (state) => {
      state.isFetching = 'loading';
    });
    builder.addCase(fetchGroups.fulfilled, (state, action) => {
      const { data, count } = action.payload;
      const groupsObject = {};
      if (data) {
        data.map((group) => {
          groupsObject[group.id] = {
            id: group.id,
            title: group.attributes.title,
          };
          return group;
        });
      }
      state.groupsListInSettings = data;
      state.groupsCount = count;
      state.groupsObject = groupsObject;
      state.isFetching = 'succeeded';
    });
    builder.addCase(fetchGroups.rejected, (state) => {
      state.groupsListInSettings = [];
      state.groupsCount = 0;
      state.groupsObject = {};
      state.isFetching = 'failed';
    });
  },
});
export const selectGroups = (state) => state.groups;

export const selectGroupsFormType = (state) => selectGroups(state).formType;
export const selectGroupsListInSettings = (state) => selectGroups(state).groupsListInSettings;
export const selectGroupsObject = (state) => selectGroups(state).groupsObject;
export const selectGroupToDelete = (state) => selectGroups(state).groupToDelete;
export const selectGroupsIsFetching = (state) => selectGroups(state).isFetching;
export const selectGroupsIsModalLoading = (state) => selectGroups(state).isModalLoading;
export const selectGroupsModalTitle = (state) => selectGroups(state).modalTitle;
export const selectGroupsOpenDeleteModal = (state) => selectGroups(state).openDeleteModal;
export const selectGroupsOpenModal = (state) => selectGroups(state).openModal;
export const selectGroupsSelectedGroup = (state) => selectGroups(state).selectedGroup;
export const selectGroupsUpdatedGroupId = (state) => selectGroups(state).updatedGroupId;
export const selectGroupsCount = (state) => selectGroups(state).groupsCount;

export const {
  groupModalFetchStart, groupModalFetchEnd, closeDeleteGroupModal,
  closeSettingsModal, fetchGroupsInProgress, fetchedGroups,
  openSettingsModal, setGroupsListInSettings,
  setSelectedGroup, setSettingsModalTitle,
  setUpdateGroupId, openDeleteGroupModal,
} = groupsSlice.actions;

export default groupsSlice.reducer;
