// eslint-disable-next-line max-classes-per-file
import { cookieTokenName } from 'constans';
import fetch from 'cross-fetch';
import checkObjectInWindowConfig from 'helpers/checkEnv';
import Cookies from 'js-cookie';
import store from 'store/configureStore';

export const endPointsEnum = {
  appsGetAll: 'apps/getAll',
  appChange: 'app/change',
  authorization: 'login',
  authByToken: 'loginByUserToken',
  tokenVerify: 'token/verify',
  tokenRefresh: 'token/refresh',
  floorsGetAll: 'sublocations/getAllByApp',
  floorsGetCurrent: 'sublocations/get',
  locationsGetAll: 'locations/getAllByApp',
  trackedGroups: 'tracked-groups',
  heatmapHistory: 'heatmap-history',
  trackedBeacons: 'tracked-beacons',
  trackedConditions: 'tracked-conditions',
  trackedConditionsSocial: 'tracked-conditions-social',
  trackedDevices: 'tracked-devices',
  trackedNotifications: 'tracked-notifications',
  trackedObjects: 'tracked-objects',
  trackedObjectsGetAll: 'activ/time',
  trackedObjectList: 'tracked-objects-list',
  socialContacts: 'contacts/social',
  reportsObject: 'objects/detailed',
  groupedObject: 'objects/grouped',
  inventoryReport: 'inventory',
  distanceReport: 'distance',
  userGet: 'users/get',
  zonesGetAll: 'zone/getAll',
  zonesGetAllByApp: 'zone/getAllByApp',
  unlinkDevice: 'tracked-objects/unlink/',
  zonesContacts: 'contacts/zones',
  gatewayStatus: 'hardware',
  workDaypicture: 'work-day-picture',
  heatmaps: 'heatmaps',
  beaconsAnalytic: 'beacons-analytic',
  activityReport: 'activity',
};

const formatQueryString = (paramsObject = {}) => {
  let queryString = '';
  const paramsKeysArray = Object.keys(paramsObject);
  if (paramsKeysArray.length > 0) {
    queryString = '?';
    paramsKeysArray.map((key, index, array) => {
      if (!paramsObject[key]) {
        return key;
      }
      const andSymbmol = index + 1 === array.length ? '' : '&';
      queryString += `${key}=${paramsObject[key]}${andSymbmol}`;
      return key;
    });
  }
  return queryString;
};

const formatHeaders = (headersObject = {}) => {
  const token = Cookies.get(cookieTokenName);

  const result = headersObject;

  if (!headersObject['content-type']) {
    result['content-type'] = 'application/json';
  }
  if (token && !headersObject.authorization) {
    result.authorization = `Bearer ${token}`;
  }
  if (!token && !headersObject.authorization) {
    const state = store?.getState();
    if (state && state.user.token) {
      const tokenStore = state.user.token;
      result.authorization = `Bearer ${tokenStore}`;
    }
  }

  return result;
};

class ApiError extends Error {
  constructor(error, originResponse = {}) {
    super();
    const statusText = error.status ? `Status: ${error.status}` : '';
    const defaultError = `Unknown http Error, ${statusText}`;
    this.name = 'Server Error Response';
    this.httpStatus = originResponse.status
      ? parseInt(originResponse.status, 10)
      : null;
    this.message = error.error || error.msg || error.data || error.message || defaultError;
  }
}

const apiList = {
  tracking:
    `${checkObjectInWindowConfig('API_URL')}/v1`
    || 'https://api.stage.navigine.com/tracking/v1',
  client:
    checkObjectInWindowConfig('NODE_URL')
    || 'https://api.stage.navigine.com/client',
  reports:
    `${checkObjectInWindowConfig('REPORTS_URL')}/v1`
    || 'https://api.stage.navigine.com/reports/v1',
  auth:
    `${checkObjectInWindowConfig('AUTH_URL')}`
    || 'https://api.stage.navigine.com/auth',
  qttracker:
    `${checkObjectInWindowConfig('QTTRACKER_URL')}`
    || 'https://api.stage.navigine.com/qttracker',
};

const methods = ['get', 'delete', 'post', 'patch', 'put'];

class HttpMethod {
  constructor(api, method) {
    this.method = method;
    this.api = apiList[api];
    // eslint-disable-next-line no-constructor-return
    return this.fetchApi.bind(this);
  }

  async fetchApi(endPoint, {
    params, body, abortSignalController, headers,
  }) {
    try {
      const queryString = formatQueryString(params);

      const url = `${this.api}/${endPoint}${queryString}`;
      const options = {
        method: this.method.toUpperCase(),
        signal: abortSignalController || undefined,
        body:
          this.method === 'post'
          || this.method === 'patch'
          || this.method === 'put'
            ? JSON.stringify(body)
            : undefined,
        headers: formatHeaders(headers),
      };

      const response = await fetch(url, options);

      if (response.status >= 400) {
        const bodyError = await response.json();
        throw new ApiError(bodyError, response);
      }

      if (
        response.headers.map['content-type'].indexOf('application/json') >= 0
      ) {
        return await response.json();
      }

      return response;
    } catch (err) {
      if (err instanceof ApiError) {
        throw err;
      }
      // eslint-disable-next-line no-console
      console.warn('httpGet error ', err);
      if (err.name === 'AbortError') {
        return undefined;
      }

      throw new Error(err.message);
    }
  }
}

class Api {
  constructor(api, methodsArray) {
    methodsArray.forEach((element) => {
      this[element] = new HttpMethod(api, element);
    });
  }
}

class Request {
  constructor(methodsArray, list) {
    Object.keys(list).forEach((element) => {
      this[element] = new Api(element, methodsArray);
    });
  }
}

const request = new Request(methods, apiList);

export default request;
