import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import WelcomeLogo from 'components/login/logo/logo';
import { monitoring } from 'constans';
import { useTranslation } from 'react-i18next';
import { createAlert } from 'store/slices/alert';

import { loginFetch } from 'store/actions/auth';
import {
  fetchFinished,
  selectAuthIsFetching,
  selectAuthLogin,
  selectAuthPassword,
  selectAuthShowPassword,
  setLogin,
  setPassword,
  setShowPassword,
} from 'store/slices/auth';

import useStyles from './styles';

function Login() {
  const { t } = useTranslation(['login', 'translation']);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const login = useSelector(selectAuthLogin);
  const password = useSelector(selectAuthPassword);
  const showPassword = useSelector(selectAuthShowPassword);
  const isFetching = useSelector(selectAuthIsFetching);

  const { classes, cx } = useStyles();
  const [wasInitiallyAutofilled, setWasInitiallyAutofilled] = useState(false);
  const loginRef = useRef();
  const passwordRef = useRef();
  const [userNameHasValue, setUserNameHasValue] = useState(false);
  const [passwordHasValue, setPasswordHasValue] = useState(false);

  const animationHandler = (stateSetter) => (e) => {
    if (e.animationName === 'mui-auto-fill') {
      stateSetter(true);
    }
  };

  const onClickLogin = (event) => {
    event.preventDefault();
    if (login && password) {
      dispatch(loginFetch(login, password))
        .then(() => {
          dispatch(setPassword(''));
          dispatch(setLogin(''));
          navigate(monitoring);
        })
        .catch((err) => {
          dispatch(fetchFinished());
          dispatch(setPassword(''));
          if (err.httpStatus === 400) {
            dispatch(createAlert({ messageType: 'error', message: t('loginWrongError') }));
          } else if (err.httpStatus === 500) {
            dispatch(createAlert({ messageType: 'error', message: t('translation:serverError') }));
          } else {
            dispatch(createAlert({ messageType: 'error', message: err.message }));
          }
        });
    }
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    if (userNameHasValue && passwordHasValue) {
      setWasInitiallyAutofilled(true);
    }
  }, [userNameHasValue, passwordHasValue]);

  return (
    <div className={classes.loginContainer}>
      <div className={classes.logo}>
        <WelcomeLogo />
      </div>
      <form onSubmit={onClickLogin} className={classes.loginForm}>
        <Grid
          className={classes.FormItemGrid}
          container
          spacing={0}
          justifyContent="flex-start"
          alignItems="center"
        >
          <Grid item>
            <div
              className={cx(classes.FormLoginIcon, classes.FormItemGridFirst)}
            />
          </Grid>
          <Grid item>
            <FormControl
              variant="standard"
              margin="none"
              className={cx(classes.textField, classes.textFieldLogin)}
            >
              <InputLabel
                htmlFor="standard-adornment-login"
              >
                {t('login')}
              </InputLabel>
              <Input
                ref={loginRef}
                autoFocus
                inputProps={{
                  onAnimationStart: animationHandler(setUserNameHasValue),
                }}
                disableUnderline
                margin="none"
                autoComplete="email"
                id="standard-adornment-login"
                type="email"
                className={classes.Inputs}
                value={login}
                onChange={(event) => {
                  dispatch(setLogin(event.target.value));
                }}
              />
            </FormControl>
          </Grid>
        </Grid>
        <Grid
          className={classes.FormItemGrid}
          container
          spacing={0}
          justifyContent="flex-start"
          alignItems="center"
        >
          <Grid
            onClick={() => {
              dispatch(setShowPassword(!showPassword));
            }}
            onMouseDown={handleMouseDownPassword}
            item
          >
            <div className={showPassword ? (classes.FormPasswordIcon)
              : (classes.FormPasswordIcon)}
            />
          </Grid>
          <Grid item>
            <FormControl variant="standard" margin="none" className={classes.textField}>
              <InputLabel
                htmlFor="standard-adornment-password"
              >
                {t('password')}
              </InputLabel>
              <Input
                ref={passwordRef}
                inputProps={{
                  onAnimationStart: animationHandler(setPasswordHasValue),
                }}
                disableUnderline
                margin="none"
                autoComplete="current-password"
                id="standard-adornment-password"
                type={showPassword ? 'text' : 'password'}
                className={classes.Inputs}
                value={password}
                onChange={(event) => {
                  dispatch(setPassword(event.target.value));
                }}
              />
            </FormControl>
          </Grid>
          <Grid item>
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => {
                dispatch(setShowPassword(!showPassword));
              }}
              onMouseDown={handleMouseDownPassword}
              size="large"
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </Grid>
        </Grid>
        <FormControl variant="standard">
          <Button
            className={classes.buttonLogin}
            onClick={onClickLogin}
            type="submit"
            disabled={(login === '' || password === '' || isFetching) && !wasInitiallyAutofilled}
            variant="contained"
          >
            {t('login:loginBtn')}
          </Button>
        </FormControl>
      </form>
    </div>
  );
}

export default Login;
