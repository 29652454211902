/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable max-len */
/* eslint-disable no-mixed-operators */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TablePagination from '@mui/material/TablePagination';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import a11yProps from 'components/shared/Tabs/allProps';
import PaginationIcon from 'components/shared/Pagination/PaginationIcon';
import BarChart from './BarChart';
import TabPanel from '../../../shared/Tabs/Tabs';
import useStyles from '../objectReport.component.style';

function FinalReport({
  zoneFinalReportsArray,
  zonesObject: zonesMap,
}) {
  const { t } = useTranslation(['report']);
  const { classes } = useStyles();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [valueTabs, setValueTabs] = React.useState(0);
  const handleChangeTabs = (event, newValue) => {
    setValueTabs(newValue);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const zoneNames = Object.keys(zoneFinalReportsArray);
  const zonesFilter = [...zonesMap.values()];
  const countZones = Object.values(zoneFinalReportsArray);
  const zoneCount = [];
  countZones.forEach((item) => {
    let count = 0;
    item.forEach((item2) => {
      count += item2.totalCountInside;
    });
    zoneCount.push(count);
  });
  const tempArr = zonesFilter.filter((item) => zoneNames.includes(item.id.toString()));
  const zones = tempArr.map((item, index) => ({ name: item.title, count: zoneCount[index] }));
  return (
    <div>
      {zoneNames.length > 0
        ? (
          <AppBar position="static" style={{ background: '#f8f8f8', boxShadow: 'none' }}>
            <Tabs
              value={valueTabs}
              onChange={handleChangeTabs}
              aria-label="simple tabs example"
              TabIndicatorProps={{ style: { background: 'black' } }}
            >
              <Tab label={t('Table')} {...a11yProps(0)} style={{ maxWidth: '10%', color: 'black' }} />
              <Tab label={t('Charts')} {...a11yProps(1)} style={{ maxWidth: '10%', color: 'black' }} />
            </Tabs>
          </AppBar>
        ) : null }
      <TabPanel value={valueTabs} index={1}>
        {zoneNames.length > 0
          ? (
            <BarChart
              zoneNames={zones}
              countZones={zoneCount}
            />
          )
          : null}
      </TabPanel>
      <TabPanel value={valueTabs} index={0}>
        <div className={classes.table} style={{ maxHeight: '100%' }}>
          <TableContainer className={classes.tableContainer} component={Paper}>
            <Table stickyHeader className={classes.table} aria-label="simple table">
              <TableBody>
                <TableRow className={classes.tableRow}>
                  <TableCell className={classes.tableCell}>
                    {' '}
                    {t('zone')}
                    {' '}
                  </TableCell>
                  <TableCell className={classes.tableCell} align="left">{t('Enter')}</TableCell>
                </TableRow>
                {zones.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map(
                    (objectReport, index) => {
                      const key = index + 1;
                      // const entryDate = new Date(objectReport.inside * 1000);
                      return (
                        <TableRow key={key}>
                          <TableCell className={classes.tableCell} component="th" scope="row">
                            {objectReport.name ? objectReport.name : '-'}
                          </TableCell>
                          <TableCell className={classes.tableCell} align="left">
                            {objectReport.count ? objectReport.count : '-'}
                          </TableCell>

                        </TableRow>
                      );
                    },
                  )}
              </TableBody>

            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50, { label: t('All'), value: -1 }]}
            component="div"
            count={zones.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${t('of')} ${count !== -1 ? count : `${t('moreThen')} ${to}`}`}
            labelRowsPerPage={t('labelRowsPerPage')}
            SelectProps={{
              IconComponent: PaginationIcon,
            }}
            nextIconButtonProps={{
              'aria-label': 'Next Page',
              style: {
                color: '#000', background: 'white', width: '32px', height: '32px', margin: '5px',
              },
              autoid: 'pagination-button-previous-collector',
            }}
            backIconButtonProps={{
              'aria-label': 'Previous Page',
              style: {
                color: page === 0 ? '#b5b8c4' : '#41afd7', background: 'white', width: '32px', height: '32px',
              },
              autoid: 'pagination-button-next-collector',
            }}
          />
        </div>
      </TabPanel>
    </div>
  );
}

export default FinalReport;
