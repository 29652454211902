import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import * as FileSaver from 'file-saver';
import OpenLayersMap from '@navigine/openlayers-navigine';

import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FormControl from '@mui/material/FormControl';
import CircularProgress from '@mui/material/CircularProgress';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { Typography } from '@mui/material';

import { objectsByAppFetch } from 'store/actions/objects';
import { getHistoryListByObj } from 'store/actions/reports';
import { selectAppCurrentApp, selectAppOpenMenu } from 'store/slices/app';
import { selectCurrentFloor, selectFloorsObject, setFloor } from 'store/slices/floors';
import { setSelectedGroup } from 'store/slices/groups';
import { selectCurrentLocation, selectLocationsObject, setLocation } from 'store/slices/locations';
import { selectObjectsByAppArray } from 'store/slices/objects';
import {
  setCurrentObject, setAlertPage, selectReportsIsFetchingHistoryPicReport,
  selectReportsDateFrom, selectReportsDateTo,
  selectReportsCurrentObject, selectReportsAlertReportLimit,
  selectReportsCurrentPage,
} from 'store/slices/reports';
import { setSelectedZone } from 'store/slices/zones';

import {
  allValuesConstant, selectNoOneConstant, styleUrl, token,
} from 'constans';
import DatePickers from 'components/reports/datePickers/datePickers.component';
import BuildButton from 'components/reports/BuildButton.component';
import containerStyles from 'components/shared/Container/styles';
import ReportsAutoComplete from '../ReportsAutoComplete';
import ReportsLocationSelector from '../ReportsLocationSelector';
import ReportsFloorSelector from '../ReportsFloorSelector';
import LegendComponent from './legendComponent';
import useStyles from './workDayPic.component.style';

function WorkDayPicReport() {
  const dispatch = useDispatch();
  const openMenu = useSelector(selectAppOpenMenu);
  const currentApp = useSelector(selectAppCurrentApp);
  const floorsObject = useSelector(selectFloorsObject);
  const currentFloor = useSelector(selectCurrentFloor);
  const locationsObject = useSelector(selectLocationsObject);
  const currentLocation = useSelector(selectCurrentLocation);
  const objectsByAppArray = useSelector(selectObjectsByAppArray);
  const isLoadingReport = useSelector(selectReportsIsFetchingHistoryPicReport);
  const dateFrom = useSelector(selectReportsDateFrom);
  const dateTo = useSelector(selectReportsDateTo);
  const currentObject = useSelector(selectReportsCurrentObject);
  const alertReportLimit = useSelector(selectReportsAlertReportLimit);
  const currentPage = useSelector(selectReportsCurrentPage);

  const { classes: classesContainer } = containerStyles();
  const { classes } = useStyles();
  const { t } = useTranslation(['report', 'notifications', 'monitoring', 'translation']);
  const [map, setMap] = useState({});
  const mapElement = useRef();

  const [legendState, setLegendState] = useState(null);
  const [linesState, setLinesState] = useState(null);
  const parentMapElement = useRef();
  const [displayState, setDisplayState] = useState(true);

  const downloadMap = async () => {
    const {
      pw: imgWidth,
      ph: imgHeight,
      image_url: imageUrl,
    } = currentFloor;
    // Creat canvas
    const canvas = document.createElement('canvas');
    canvas.style.width = `${imgWidth}px`;
    canvas.style.height = `${imgHeight}px`;
    canvas.style.display = 'block';
    canvas.style.position = 'absolute';
    canvas.style.top = '-99999px';
    // add to DOM
    parentMapElement.current.appendChild(canvas);
    const ctx = canvas.getContext('2d');
    ctx.canvas.width = imgWidth;
    ctx.canvas.height = imgHeight;
    const mapImage = new Image(imgWidth, imgHeight);

    const addImageProcess = () => new Promise((resolve, reject) => {
      mapImage.onload = () => resolve(mapImage.height);
      mapImage.onerror = reject;
    });
    mapImage.src = imageUrl;
    mapImage.setAttribute('crossorigin', 'anonymous');
    await addImageProcess();
    ctx.drawImage(mapImage, 0, 0); // Or at whatever offset you like
    Object.keys(linesState).forEach((objID) => {
      const objectLines = linesState[objID];

      // const hisLineArray = historyLines[objID];
      objectLines.forEach((hisLineObj) => {
        const firstPoint = hisLineObj.points[0];
        ctx.beginPath();
        ctx.strokeStyle = legendState[objID].color;
        ctx.moveTo(
          parseFloat(firstPoint.kx) * imgWidth,
          (1 - parseFloat(firstPoint.ky)) * imgHeight,
        );
        hisLineObj.points.forEach(({ kx, ky }) => {
          ctx.lineTo(parseFloat(kx) * imgWidth, (1 - parseFloat(ky)) * imgHeight);
        });
        ctx.lineWidth = imgWidth * 0.001;
        ctx.stroke();
      });
    });

    const dateFormat = 'dd-MM-yyyy HH:mm:ss';
    const legendArray = Object.values(legendState);
    ctx.globalAlpha = 0.8;
    const zeroWidth = 0;
    const zeroHeight = 0;
    const rectWidth = imgWidth * 0.3;
    const rowCound = legendArray.length < 4 ? 5 : legendArray.length + 1;
    const textScale = imgHeight * 0.011;
    const textHeight = 24 + textScale;
    const textMargin = 15 + textScale;
    ctx.font = `${textHeight}px Regular`;
    const rectHeight = rowCound * (textHeight + textMargin);
    ctx.fillStyle = '#fffbfb';
    ctx.fillRect(
      zeroWidth,
      zeroHeight,
      rectWidth,
      rectHeight,
    );
    let phraseOffset = 0;
    const leftCommonMargin = textHeight;
    const colorLineWidHeight = textHeight * 0.75;
    const legInfoOffValueX = leftCommonMargin * 7.5;
    const commonTopMargin = zeroHeight + rectHeight * 0.03;
    const legDetinfo = {
      from: {
        key: t('from'),
        value: format(dateFrom, dateFormat),
      },
      to: {
        key: t('to'),
        value: format(dateTo, dateFormat),
      },
      location: {
        key: t('monitoring:locationSelectorLabel'),
        value: currentLocation.title,
      },
      floor: {
        key: t('monitoring:floorSelectorLabel'),
        value: currentFloor.title,
      },
      object: {
        key: `${t('Objects')}`,
        value: '',
      },
    };
    ctx.fillStyle = '#000000';
    Object.values(legDetinfo).forEach(({ key, value }) => {
      const topOffset = commonTopMargin + phraseOffset + 10;
      ctx.fillText(
        `${key}:`,
        leftCommonMargin,
        topOffset + textMargin,
      );
      ctx.fillText(
        value,
        legInfoOffValueX,
        topOffset + textMargin,
      );
      phraseOffset += textHeight;
    });

    Object.values(legendState).forEach(({ color, title }) => {
      const topOffset = (commonTopMargin + phraseOffset);
      ctx.fillStyle = color;
      ctx.fillRect(
        leftCommonMargin,
        topOffset + topOffset * 0.06,
        colorLineWidHeight + 50,
        colorLineWidHeight - 30,
      );
      ctx.fillStyle = '#000000';
      const textXOffset = rectWidth * 0.4;
      ctx.fillText(
        title,
        textXOffset + 15,
        (topOffset + textMargin),
      );
      phraseOffset += textHeight;
    });

    const fullQuality = canvas.toDataURL('image/png', 1.0);
    FileSaver.saveAs(fullQuality, 'screenshot.png');
    canvas.remove();
  };

  const buildMapReport = ({ historyLines, legend }) => {
    const translate = {
      global: t('global'), local: t('local'), street: t('street'), satellite: t('satellite'), img: t('img'), noImg: t('noImg'),
    };
    const {
      lat, lon, hor, w, h, image_url: imageURL, pw, ph, image_bounds: imageBounds,
    } = currentFloor;

    if (!mapElement.current) {
      return;
    }
    if (map instanceof OpenLayersMap) {
      map.destroyMap();
    }
    const olMap = new OpenLayersMap({
      target: mapElement.current,
      imageURL,
      imageWidth: pw,
      imageHeight: ph,
      imageBounds,
      lat,
      lon,
      w,
      h,
      hor,
      isGlobalMap: false,
      styleUrl,
      token,
      translate,
      isAnimate: true,
    });

    // ResizeObserver is needed to check if a map item is missing in the viewport
    // otherwise, the map will be created with a blank canvas
    const { clientWidth, clientHeight } = mapElement.current;
    if (!clientWidth || !clientHeight) {
      const resize = (entires, observer) => {
        map.map.updateSize();
        observer.unobserve(mapElement.current);
      };
      new ResizeObserver(resize).observe(mapElement.current);
    }
    Object.keys(historyLines).forEach((objID) => {
      const hisLineArray = historyLines[objID];
      hisLineArray.forEach((hisLineObj) => {
        olMap.addCustomFeature({
          type: 'historyLine',
          coordinates: hisLineObj.points,
          geometryType: 'line',
          styles: {
            stroke: {
              color: legend[objID].color,
              width: 2.2,
            },
          },
        });
      });
    });

    setMap(olMap);
  };

  useEffect(() => {
    if (map instanceof OpenLayersMap) {
      map.map.updateSize();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayState]);

  const fetchReportsClick = async () => {
    const res = await dispatch(getHistoryListByObj());
    if (!res) {
      return;
    }
    const { historyLines, legend } = res;
    setLegendState(legend);
    setLinesState(historyLines);
    buildMapReport({ historyLines, legend });
  };

  const selectObject = (event, object) => {
    dispatch(setCurrentObject(object));
  };

  const [objectsFilter, setObjectsFilter] = useState('');

  const handleChangeLocation = (event) => {
    const locationId = event.target.value;
    dispatch(setLocation(locationsObject[locationId]));
    const floorId = locationsObject[locationId].floors[0].id;
    dispatch(setFloor(floorsObject[floorId]));
    dispatch(setSelectedZone({ id: selectNoOneConstant }));
    dispatch(setSelectedGroup({ id: allValuesConstant }));

    if (map instanceof OpenLayersMap) {
      map.destroyMap();
      setLegendState(null);
    }
  };

  const handleChangeFloor = (event) => {
    const floorId = event.target.value;
    dispatch(setFloor(floorsObject[floorId]));
    dispatch(setSelectedZone({ id: selectNoOneConstant }));
    dispatch(setSelectedGroup({ id: allValuesConstant }));

    if (map instanceof OpenLayersMap) {
      map.destroyMap();
      setLegendState(null);
    }
  };

  useEffect(() => {
    const getObjects = async () => {
      await dispatch(objectsByAppFetch(objectsFilter, 0, 4000));
    };

    getObjects();
  }, [objectsFilter, currentPage, alertReportLimit, currentApp, dispatch]);

  useEffect(() => {
    dispatch(setAlertPage(0));
  }, [objectsFilter, alertReportLimit, currentApp, dispatch]);

  const onAutocompliteInput = async (value) => {
    setObjectsFilter(value);
  };
  const displayFilters = () => {
    setDisplayState(!displayState);
  };
  const floorsObjectFiltred = Object.values(floorsObject)
    .filter((object) => object.location === currentLocation.id);
  return (
    // eslint-disable-next-line react/jsx-filename-extension
    <div className={!openMenu ? classes.content : classesContainer.noneContent}>
      <div className={classes.topControls}>
        <div className={displayState ? classes.formControls : classes.formControlsNo}>
          <form className={classes.formControls} noValidate>
            <div className={classes.controlWrapper}>
              <FormControl variant="standard">
                <ReportsLocationSelector
                  locations={locationsObject}
                  className={classes.selector}
                  value={currentLocation.id}
                  onChange={handleChangeLocation}
                />
              </FormControl>
            </div>
            <div className={classes.controlWrapper}>
              <ReportsFloorSelector
                floors={floorsObjectFiltred}
                className={classes.selector}
                onChange={handleChangeFloor}
                value={currentFloor.id}
                currentLocationId={currentLocation.id}
              />
            </div>
          </form>
          <form className={classes.formControls} noValidate>
            <div className={classes.selectorWrapp}>
              <ReportsAutoComplete
                className={classes.selectorObject}
                filteredObjectsArray={objectsByAppArray}
                currentObject={currentObject}
                selectObject={selectObject}
                onInputChange={onAutocompliteInput}
                bkgColor="#ffffff"
              />
            </div>
            <DatePickers />
          </form>
          <div className={classes.headContainer}>
            <div>
              <Typography variant="h6">{t('translation:workDayPic')}</Typography>
            </div>
            <div className={classes.btnWrapper}>
              <BuildButton
                className={classes.btn}
                variant="outlined"
                color="primary"
                disableRipple
                onClick={fetchReportsClick}
                disabled={isLoadingReport}
              >
                {t('buildReport')}
              </BuildButton>
              {map instanceof OpenLayersMap && (
              <div
                style={{ display: currentApp.role === 'demo' ? 'none' : null }}
                className={classes.downloadControlContainer}
                onClick={downloadMap}
                onKeyUp={downloadMap}
                tabIndex="0"
                role="button"
              >
                <CloudDownloadIcon
                  fontSize="large"
                />
                <div className={classes.downLoadPhraseContainer}>
                  <p className={classes.downLoadPhrase}>
                    {' '}
                    {t('downloadPng')}
                    {' '}
                  </p>
                </div>
              </div>
              )}
            </div>
          </div>
        </div>
        { displayState ? <ExpandLessIcon color="primary" style={{ margin: '0 auto' }} onClick={displayFilters} />
          : <ExpandMoreIcon color="primary" style={{ margin: '0 auto' }} onClick={displayFilters} />}
        {legendState ? (
          <LegendComponent
            legendState={legendState}
          />
        ) : null}
      </div>
      {isLoadingReport && (
      <div className={classes.spinerWrapper}>
        <CircularProgress size={26} />
      </div>
      )}
      <div ref={parentMapElement}>
        <div
          ref={mapElement}
          className={displayState
            ? classes.mapContainer : classes.mapContainerToggle}
        />
      </div>
    </div>
  );
}

export default WorkDayPicReport;
