import { PolarArea } from 'react-chartjs-2';
import React from 'react';
import 'chart.js/auto';
import { colorsChartArray } from 'constans';

function PolarChart({ data, sortingField, legendTitles }) {
  const newArrayFinalReport = [...data].sort((b, a) => a[sortingField] - b[sortingField]);
  const state = {
    labels: newArrayFinalReport.map((i) => i[legendTitles]),
    datasets: [
      {
        label: 'Rainfall',
        backgroundColor: colorsChartArray,
        borderColor: 'rgba(0,0,0,1)',
        borderWidth: 0.3,
        data: newArrayFinalReport.map((i) => i[sortingField]),
      },
    ],
  };
  return (
    <div>
      <PolarArea
        width={100}
        height={400}
        data={state}
        options={{
          type: 'stack',
          indexAxis: 'y',
          maintainAspectRatio: false,
          title: {
            display: true,
            text: 'Average Rainfall per month',
            fontSize: 20,
          },
          legend: {
            display: true,
            position: 'right',
          },
        }}
      />
    </div>
  );
}

export default PolarChart;
