/* eslint-disable max-len */
// /* eslint-disable max-len */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Slide from '@mui/material/Slide';
import Switch from '@mui/material/Switch';

import OpenLayersMap from '@navigine/openlayers-navigine';

import { fetch3DModel } from 'store/actions/floors';
import { fetchZoneTypesIfNeeded } from 'store/actions/zones';
import { createAlert } from 'store/slices/alert';
import { selectAppCurrentApp, selectAppOpenMenu } from 'store/slices/app';
import { selectCurrentFloor, selectFloorsObject, setFloor } from 'store/slices/floors';
import {
  selectGroupsCount, selectGroupsObject, selectGroupsSelectedGroup, setSelectedGroup,
} from 'store/slices/groups';
import { resetisFetchingObjectHistoric } from 'store/slices/history';
import { selectCurrentLocation, selectLocationsObject, setLocation } from 'store/slices/locations';
import { selectObjectsInMonitoringArray, setJson } from 'store/slices/objects';
import { selectReportsCurrentObject, setCurrentObject } from 'store/slices/reports';
import {
  selectSelectedZone, selectZoneTypes,
  selectZonesMap, setSelectedZone,
} from 'store/slices/zones';

import {
  allValuesConstant,
  monitoring3d,
  selectNoOneConstant,
  styleUrl,
  token,
} from 'constans';

import DialogArray from 'components/dialog/DailogArrayObjects';
import DialogMonitoring from 'components/dialog/DialogMonitorng';
import MainMenu from 'components/menu/MainMenu';
import AutoComplete from 'components/reports/ReportsAutoComplete';
import FloorSelector from 'components/reports/ReportsFloorSelector';
import GroupSelector from 'components/reports/ReportsGroupSelector';
import LocationSelector from 'components/reports/ReportsLocationSelector';
import ZoneSelector from 'components/reports/ReportsZoneSelector';
import GroupsInfinite from 'components/shared/GroupSelector/GroupsInfinite';
import useStyles from './style';

// eslint-disable-next-line react/jsx-props-no-spreading
const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

function Monitoring() {
  const dispatch = useDispatch();
  const openMenu = useSelector(selectAppOpenMenu);
  const currentApp = useSelector(selectAppCurrentApp);
  const floorsObject = useSelector(selectFloorsObject);
  const currentFloor = useSelector(selectCurrentFloor);
  const groupsObject = useSelector(selectGroupsObject);
  const selectedGroup = useSelector(selectGroupsSelectedGroup);
  const locationsObject = useSelector(selectLocationsObject);
  const currentLocation = useSelector(selectCurrentLocation);
  const objectsInMonitoringArray = useSelector(selectObjectsInMonitoringArray);
  const currentObject = useSelector(selectReportsCurrentObject);
  const zonesMap = useSelector(selectZonesMap);
  const selectedZone = useSelector(selectSelectedZone);
  const zoneTypes = useSelector(selectZoneTypes);
  const groupsCount = useSelector(selectGroupsCount);

  const selectRole = (state) => state?.app?.currentApp?.role;
  const userRole = useSelector(selectRole);

  const navigate = useNavigate();
  const { classes } = useStyles();
  const { t } = useTranslation(['monitoring']);
  const urlLocation = useLocation();
  const memoizedLocationState = useMemo(() => {
    const { offlineObject, highlightObject, positioning } = urlLocation.state || {};
    return { offlineObject, highlightObject, positioning };
  }, [urlLocation.state]);
  const { offlineObject, highlightObject, positioning } = memoizedLocationState;

  const objectTtl = useMemo(
    () => currentApp.properties.find((prop) => prop.type === 'object_ttl')?.value || 60,
    [currentApp],
  );

  const [zoomFromSettings, setZoomFromSettings] = useState(0);
  const [openObjectModal, setOpenObjectModal] = useState(false);
  const [map, setMap] = useState({});
  const [objectInfo, setObjectInfo] = useState(null);
  const [objectInfoArray, setObjectInfoArray] = useState([]);
  const [showTrackLines, setShowTrackLines] = useState(false);
  const [show3DState, setShow3DState] = useState(false);
  const [advanced3D, setAdvanced3D] = useState(false);
  const [antialias, setAntialias] = useState(false);

  // const [showGlobalMap, setShowGlobalMap] = useState(false);
  const mapElement = useRef();

  const zonesArray = [...zonesMap.keys()]
    .filter((zoneId) => parseInt(zonesMap.get(zoneId)?.floor, 10)
      === parseInt(currentFloor.id, 10));

  const filteredObjectsArray = objectsInMonitoringArray
    .filter((object) => {
      const floorFilter = currentFloor ? parseInt(object.attributes.sublocation_id, 10)
      === parseInt(currentFloor.id, 10) : false;
      const groupFilter = selectedGroup ? selectedGroup.id === allValuesConstant
      || parseInt(object.attributes.tracked_group_id, 10)
      === parseInt(selectedGroup.id, 10) : false;
      return floorFilter && groupFilter;
    })
    .sort((a, b) => a.attributes.title.localeCompare(b.attributes.title));

  const tempArray = Object.values(locationsObject)
    .filter((location) => location.id === currentLocation.id)
    .flatMap((location) => location.floors);
  const locationsObjectsArray = tempArray.map((item) => parseInt(item.id, 10));
  const filteredObjectsArrayInBuild = objectsInMonitoringArray
    .filter((object) => locationsObjectsArray
      .includes(object.attributes.sublocation_id));

  const floorsObjectFiltered = useMemo(
    () => Object.values(floorsObject)
      .filter((object) => object.location === currentLocation.id),
    [currentLocation.id, floorsObject],
  );
  // Click on map event callback
  const mapOnClickCallback = (data) => {
    if (!data.feature) {
      return;
    }
    const { feature, featuresArray } = data;
    if (featuresArray.length < 2) {
      const trackedObjectData = feature.trackedObject
        ? feature.trackedObject.attributes
        : null;
      if (trackedObjectData) {
        setObjectInfo(trackedObjectData);
      }
    } else {
      const filteredData = featuresArray.filter((item) => !!(item.trackedObject));
      setObjectInfoArray(filteredData.map((att) => att.trackedObject?.attributes));
    }
  };

  const filterTrackedObjects = (trackedObjectsArray, groupsToFilterArray) => {
    if (!trackedObjectsArray || trackedObjectsArray.length < 1) {
      return [];
    }
    const result = trackedObjectsArray.filter((trackedObject) => {
      const {
        tracked_group_id: trackedGroupId,
        sublocation_id: sublocationId,
      } = trackedObject.attributes;
      const sameFloor = sublocationId === parseInt(currentFloor.id, 10);
      let sameGroup = true;
      if (groupsToFilterArray && groupsToFilterArray.id > 0) {
        sameGroup = trackedGroupId === parseInt(groupsToFilterArray.id, 10);
      }

      return sameFloor && sameGroup;
    });

    return result || [];
  };

  const handleChangeLocation = useCallback((event) => {
    const locationId = event.target.value;
    dispatch(setLocation(locationsObject[locationId]));
    const floorId = locationsObject[locationId].floors[0].id;
    dispatch(setFloor(floorsObject[floorId]));
    dispatch(setSelectedZone({ id: selectNoOneConstant }));
    dispatch(setSelectedGroup({ id: allValuesConstant }));
    if (zoomFromSettings > 1) { dispatch(setCurrentObject()); }
    setZoomFromSettings(2);
  }, [dispatch, floorsObject, locationsObject, zoomFromSettings]);

  const handleChangeZone = useCallback((event) => {
    event.preventDefault();
    const zoneId = event.target.value;
    map.deleteAllFeaturesFromSourse(map.enum.zones);

    if (zoneId === allValuesConstant) {
      const zones = zonesArray.map((zoneID) => zonesMap.get(zoneID));
      dispatch(setSelectedZone({ id: allValuesConstant }));
      map.addZones(zones);
      return;
    }

    if (zoneId === selectNoOneConstant) {
      dispatch(setSelectedZone({ id: selectNoOneConstant }));
    }

    if (zoneId !== selectNoOneConstant && zoneId !== allValuesConstant) {
      dispatch(setSelectedZone(zonesMap.get(zoneId)));
      map.addZone(zonesMap.get(zoneId));
      map.zoomToFeature(map.getZoneFeature(zoneId));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, map, zonesMap]);

  const handleChangeGroup = useCallback((value) => {
    const groupId = value;
    if (groupId === allValuesConstant) {
      dispatch(setSelectedGroup({ id: allValuesConstant }));
      return;
    }
    const newSelectedGroup = groupsObject[groupId];
    dispatch(setSelectedGroup(newSelectedGroup));
    const trackedObjectType = map.enum.trackedObjects;

    if (map.sources[trackedObjectType]) {
      const filteredObjects = filterTrackedObjects(
        objectsInMonitoringArray,
        newSelectedGroup,
      );

      map.removeAllTrackedObjects();
      map.addTrackedObjects(filteredObjects);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, groupsObject, map]);

  const handleChangeFloor = useCallback((event) => {
    const floorId = event.target.value;
    dispatch(setFloor(floorsObject[floorId]));
    dispatch(setSelectedZone({ id: selectNoOneConstant }));
    dispatch(setSelectedGroup({ id: allValuesConstant }));
    setZoomFromSettings(2);
    if (zoomFromSettings > 1) { dispatch(setCurrentObject()); }
  }, [dispatch, floorsObject, zoomFromSettings]);

  const selectObject = (event, object) => {
    if (currentObject) {
      map.unHighlightTrackedObject(currentObject);
    }
    if (object) {
      map.highlightTrackedObject(object);
      map.zoomToTrackedObjectByID(object.id);
    }
    dispatch(setCurrentObject(object));
  };

  const handleCloseObjectModal = () => {
    setOpenObjectModal(false);
    setObjectInfo(null);
    setObjectInfoArray([]);
    dispatch(setJson(''));
  };

  const handleShowTrackLines = (event) => {
    const { checked } = event.target;
    setShowTrackLines(checked);
  };

  // load 3D map if it exists
  useEffect(() => {
    async function fetch3d() {
      try {
        await dispatch(fetch3DModel(currentFloor.id));
      } catch (error) {
        dispatch(createAlert({ messageType: 'warn', message: 'Error in fetching 3D map' }));
      }
    }
    if (currentFloor.id) {
      fetch3d();
    }
  }, [currentFloor.id, dispatch]);

  // Zone types hook
  useEffect(() => {
    const fetchZoneTypes = async () => {
      await dispatch(fetchZoneTypesIfNeeded());
    };
    fetchZoneTypes();
  }, [currentFloor.id, dispatch]);

  // Zones hook
  useEffect(() => {
    if (!(map instanceof OpenLayersMap)) {
      return;
    }

    if (zoneTypes) {
      map.setZoneTypes(zoneTypes);
    }
    if (userRole === 'demo') {
      const zones = zonesArray.map((zoneID) => zonesMap.get(zoneID));
      map.addZones(zones);
      dispatch(setSelectedZone({ id: allValuesConstant }));
    } else if (selectedZone && selectedZone.id) {
      if (selectedZone.id === allValuesConstant) {
        const zones = zonesArray.map((zoneID) => zonesMap.get(zoneID));
        map.addZones(zones);
      } else if (selectedZone.id > 0) {
        map.addZone(zonesMap.get(selectedZone.id));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, zoneTypes]);

  // Tracked objects hook - update, add new, delete old
  useEffect(() => {
    if (!(map instanceof OpenLayersMap)) {
      return;
    }
    const objectsFilteredByFloor = filterTrackedObjects(
      objectsInMonitoringArray,
      selectedGroup,
    );
    const trackedObjectsOnMap = map.getAllTrackedObjects();

    if (!objectsFilteredByFloor.length) {
      if (trackedObjectsOnMap) {
        trackedObjectsOnMap.forEach((featureOnMap) => {
          map.deleteTrackedObject(featureOnMap);
        });
      }
      return;
    }

    if (trackedObjectsOnMap.length > 0) {
      const updatedObjects = {};
      if (document.visibilityState !== 'visible') {
        return;
      }
      objectsFilteredByFloor.forEach((currentObjectElement) => {
        const objectOnMap = map.getFeaturePayload(
          map.getTrackedObjectFeature(currentObjectElement.id),
        );
        if (!objectOnMap) {
          map.addTrackedObject(currentObjectElement);
          return;
        }
        const { properties } = currentApp;
        const objTitleProp = properties?.find((prop) => prop.type === 'object_title');
        if (objTitleProp && objTitleProp.value === 'false') {
          objectOnMap.openLayersFeature.getStyle().setText();
        }
        objectOnMap.updateTrackedObject(currentObjectElement);
        updatedObjects[currentObjectElement.id] = 1;
      });

      trackedObjectsOnMap.forEach((featureOnMap) => {
        const currentObjectId = featureOnMap.getId();
        if (!updatedObjects[currentObjectId]) {
          map.deleteTrackedObject(featureOnMap);
        }
      });
    } else {
      map.addTrackedObjects(objectsFilteredByFloor);
      const { properties } = currentApp;

      const clusterDistProp = properties.filter((prop) => prop.type === 'cluster_distance')[0];
      if (clusterDistProp) {
        const clusterDist = Math.abs(parseFloat(clusterDistProp.value));
        map.addClusterizedFeatures(clusterDist, map.enum.trackedObjects);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, objectsInMonitoringArray, currentFloor, selectedGroup]);

  // Zoom into the object from the settings tab
  useEffect(() => {
    if (!(map instanceof OpenLayersMap) || zoomFromSettings > 1) {
      return;
    }
    if (highlightObject
      && Object.keys(highlightObject).length > 0
      && positioning
      && Object.keys(positioning).length > 0) {
      setZoomFromSettings((prev) => prev + 1);
      let objToZoom = null;
      if (filteredObjectsArray && filteredObjectsArray.length > 0) {
        filteredObjectsArray.forEach((floorObj) => {
          if (floorObj && floorObj.attributes
            && floorObj.attributes.title === highlightObject.attributes.title) {
            objToZoom = floorObj;
          }
        });
      }
      // delay to let the locations and sublocations change
      setTimeout(() => {
        if (objToZoom) {
          selectObject(null, objToZoom);
        }
      }, 1000);
    }
  // adding selectObject to the dependencies causes infinite calls to this useEffect
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, highlightObject, map]);

  // Tracklines hook - add, remove
  useEffect(() => {
    if (!(map instanceof OpenLayersMap)) {
      return;
    }

    if (showTrackLines) {
      map.addTrackHistory();
    } else {
      map.removeTrackHistory();
    }
  }, [showTrackLines, map]);

  useEffect(() => {
    if (!(map instanceof OpenLayersMap)) {
      return;
    }
    // only needs to work on initial render
    if (userRole === 'demo' && !showTrackLines) {
      setShowTrackLines(true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, userRole]);

  // Map OnClick event subscription hook
  useEffect(() => {
    if (!(map instanceof OpenLayersMap)) {
      return;
    }
    map.on('mapClicked', mapOnClickCallback.bind(this));
  }, [map]);

  // Destroy map hook
  useEffect(() => {
    if (map instanceof OpenLayersMap) {
      dispatch(setSelectedZone({ id: selectNoOneConstant }));
      dispatch(setSelectedGroup({ id: allValuesConstant }));
      map.destroyMap();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentApp?.id, dispatch]);
  // Map hook - initialize, destroy
  useEffect(() => {
    if (map instanceof OpenLayersMap) {
      dispatch(setSelectedZone({ id: selectNoOneConstant }));
      dispatch(setSelectedGroup({ id: allValuesConstant }));
      map.destroyMap();
    }

    try {
      const translate = {
        global: t('global'), local: t('local'), street: t('street'), satellite: t('satellite'), img: t('img'), noImg: t('noImg'),
      };
      const {
        lat, lon, hor, w, h,
      } = currentFloor;
      const newMap = new OpenLayersMap({
        target: mapElement.current,
        imageURL: currentFloor.image_url,
        imageWidth: currentFloor.pw,
        imageHeight: currentFloor.ph,
        imageBounds: currentFloor.image_bounds,
        lat,
        lon,
        w,
        h,
        hor,
        isGlobalMap: false,
        styleUrl,
        token,
        translate,
        isAnimate: true,
      });
      // ResizeObserver is needed to check if a map item is missing in the viewport
      // otherwise, the map will be created with a blank canvas
      const { clientWidth, clientHeight } = mapElement.current;
      if (!clientWidth || !clientHeight) {
        const resize = (entires, observer) => {
          newMap.map.updateSize();
          observer.unobserve(mapElement.current);
        };
        new ResizeObserver(resize).observe(mapElement.current);
      }
      setMap(newMap);
    } catch (error) {
      setMap({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFloor.id, currentFloor.pw, currentFloor.ph, currentFloor.image_url]);

  const handleShow3D = async () => {
    setShow3DState((prev) => !prev);
    dispatch(setSelectedZone({ id: selectNoOneConstant }));
    setTimeout(() => {
      navigate(monitoring3d, { state: { antialias } });
    }, 300);
  };

  useEffect(() => {
    if (!offlineObject || Object.keys(offlineObject).length === 0
    || !(map instanceof OpenLayersMap)) {
      return () => {};
    }
    async function addObject() {
      const properties = offlineObject
        .properties.map((prop) => {
          if (prop.key === 'objectColor') {
            return { ...prop, value: '#d3d3d3' };
          }
          return prop;
        });
      await map.addOfflineObject({
        ...offlineObject,
        attributes: { ...offlineObject.attributes, properties, title: offlineObject.title },
      });
    }
    let removeTimeout;
    addObject().then(() => {
      const offlineObjectType = map.enum.offlineObject;
      const sources = map.sources[offlineObjectType];

      if (sources) {
        const objectFeature = sources.getFeatureById(parseInt(offlineObject.id, 10));
        setTimeout(() => {
          map.zoomToFeature(objectFeature, 150, 600, 3);
        });
        removeTimeout = setTimeout(() => {
          map.removeOfflineObjects();
        }, objectTtl * 1000);
      }
    });
    return () => {
      clearTimeout(removeTimeout);
      dispatch(resetisFetchingObjectHistoric());
    };
  }, [dispatch, map, objectTtl, offlineObject]);

  return (
    <div className={!openMenu ? classes.history : classes.noneContainer}>
      <MainMenu openMenu={openMenu} dispatch={dispatch}>
        <div className={classes.mapControls}>
          <div className={classes.controlWrapper}>
            <LocationSelector
              name="location-selector"
              locations={locationsObject}
              className={classes.selector}
              value={currentLocation.id}
              onChange={handleChangeLocation}
            />
          </div>
          <div className={classes.controlWrapper}>
            <FloorSelector
              name="floor-selector"
              floors={floorsObjectFiltered}
              className={classes.selector}
              onChange={handleChangeFloor}
              value={currentFloor.id}
              currentLocationId={currentLocation.id}
            />
          </div>

          <div className={classes.controlWrapper}>
            <ZoneSelector
              name="zone-selector"
              onChange={handleChangeZone}
              className={classes.selector}
              zones={zonesMap}
              value={selectedZone.id}
              zoneTypes={zoneTypes}
              currentFloorId={currentFloor.id}
            />
          </div>

          <div className={classes.controlWrapper}>
            {(!groupsCount || groupsCount <= 100)
              ? (
                <GroupSelector
                  name="group-selector"
                  onChange={(event) => {
                    event.preventDefault();
                    handleChangeGroup(event.target.value);
                  }}
                  groups={groupsObject}
                  value={selectedGroup.id || allValuesConstant}
                  className={classes.selector}
                />
              ) : (
                <GroupsInfinite
                  value={selectedGroup}
                  handleChangeGroup={handleChangeGroup}
                  allowSelectAll
                  inputStyles={{ border: 'none', backgroundColor: '#F3F6F8' }}
                  borderStyle="none"
                  dropdownStyles={{ width: '200px !important' }}
                />
              )}
          </div>
          <div style={{ marginLeft: '20px', textAlign: 'left' }} className={classes.selectorWrapp}>

            <AutoComplete
              filteredObjectsArray={filteredObjectsArray}
              currentObject={currentObject}
              selectObject={selectObject}
              bkgColor="#F3F6F8"
            />
          </div>
          {floorsObject[currentFloor.id] && floorsObject[currentFloor.id].model
          && (
          <div className={classes.switch_wrapper}>
            <FormControlLabel
              className={classes.swich_element_monitoring}
              control={(
                <Switch
                  checked={show3DState}
                  onChange={handleShow3D}
                  name="show 3d map"
                  color="primary"
                />
              )}
              label={t('show3d')}
            />
            <FormControlLabel
              control={(
                <Checkbox
                  checked={advanced3D}
                  onChange={() => setAdvanced3D((prev) => !prev)}
                  name="advanced 3D"
                  disableRipple
                  checkedIcon={<KeyboardArrowUpIcon />}
                  icon={<KeyboardArrowDownIcon />}
                />
              )}
            />
          </div>
          )}
          {advanced3D
          && (
          <div className={classes.checkboxWrapper}>
            <FormControlLabel
              control={(
                <Checkbox
                  checked={antialias}
                  onChange={() => setAntialias((prev) => !prev)}
                  icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                  checkedIcon={<CheckBoxIcon fontSize="small" />}
                  name="AA"
                />
            )}
              label="3D Anti-aliasing"
            />
          </div>
          )}

          <div className={classes.switch_wrapper}>
            <FormControlLabel
              className={classes.swich_element_monitoring}
              control={(
                <Switch
                  checked={showTrackLines}
                  onChange={handleShowTrackLines}
                  name="show track lines"
                  color="primary"
                />
              )}
              label={t('showTrackLines')}
            />
          </div>
          {/* <div className={classes.control_wrapper}>
            <FormControlLabel
              className={classes.swich_element_monitoring}
              control={(
                <Switch
                  checked={showGlobalMap}
                  onChange={swichGlobalMap}
                  name="show track lines"
                  color="primary"
                />
              )}
              label={t('globalMap')}
            />
          </div> */}
          <div>
            <FormControl variant="standard">
              <div className={classes.objectsCount}>
                {' '}
                <p>
                  {' '}
                  {t('countOfObjects')}
                  {' '}
                  <b>{filteredObjectsArray.length}</b>
                </p>
                <p style={{ color: 'rgba(0, 20, 36, 0.5)' }}>
                  {' '}
                  {t('countOfObjectsInBuild')}
                  {' '}
                  <b>{filteredObjectsArrayInBuild.length}</b>
                </p>
              </div>
            </FormControl>
          </div>
        </div>
      </MainMenu>
      <div ref={mapElement} className={classes.map_container} />
      <DialogMonitoring
        openObjectModal={openObjectModal}
        TransitionComponent={Transition}
        handleCloseObjectModal={handleCloseObjectModal}
        objectInfo={objectInfo}
        setOpenObjectModal={setOpenObjectModal}
        objectInfoArray={objectInfoArray}
        floor={currentFloor}
      />
      <DialogArray
        setObjectInfo={setObjectInfo}
        setObjectInfoArray={setObjectInfoArray}
        openObjectModal={openObjectModal}
        TransitionComponent={Transition}
        handleCloseObjectModal={handleCloseObjectModal}
        objectInfo={objectInfo}
        objectInfoArray={objectInfoArray}
        setOpenObjectModal={setOpenObjectModal}
      />

    </div>
  );
}

export default Monitoring;
