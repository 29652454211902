import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  background: {
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 1000,
    display: 'flex',
    background: 'rgba(32,32,32, 0.7)',
  },
  modalContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '26em',
    height: '38em',
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '12px',
  },
  resultContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'white',
    width: '22em',
    height: '11em',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '12px',
    padding: '20px 0px',
  },
  checkIcon: {
    margin: '0px',
    padding: '0px',
    '& .MuiSvgIcon-root': {
      color: theme.palette.success.main,
      fontSize: '53px',
      cursor: 'pointer',
    },
  },
  checkText: {
    margin: '0px',
    fontSize: '16px',
    fontFamily: 'Circe',
    padding: '0px 35px',
  },
  headerModal: {
    width: '85%',
    display: 'flex',
    flexDirection: 'column',
    padding: '1em 0',
    gap: '1em',
  },
  headerFirstRow: {
    height: '1.5em',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontWeight: 600,
    fontSize: '1.5em',
  },
  headerSecondRow: {
    display: 'flex',
  },
  textParagraph: {
    margin: 0,
    padding: 0,
  },
  formContent: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '1em',
  },
  rowModal: {
    width: '85%',
    marginBottom: '0.5em',
  },
  labelElement: {
    marginBottom: '0.5em',
  },
  textInput: {
    height: '2.5em',
    width: '100%',
  },
  redText: {
    color: 'red',
  },
  purchaseButton: {
    width: '100%',
    height: '3em',
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    cursor: 'pointer',
    border: 0,
    borderRadius: '8px',
    fontWeight: 600,
    fontSize: '1.2em',
    '&:disabled': {
      opacity: 0.5,
      cursor: 'not-allowed',
    },
  },
  circularProgress: {
    color: 'white',
  },
  closeButton: {
    color: 'black',
    cursor: 'pointer',
  },
}
));

export default useStyles;
