/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  login: '',
  password: '',
  showPassword: false,
  isFetching: false,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    fetchFinished(state) {
      state.isFetching = false;
    },
    loginInProgress(state) {
      state.isFetching = true;
    },
    setLogin(state, action) {
      state.login = action.payload;
    },
    setPassword(state, action) {
      state.password = action.payload;
    },
    setShowPassword(state, action) {
      state.showPassword = action.payload;
    },
  },
});

export const selectAuth = (state) => state.auth;

export const selectAuthLogin = (state) => selectAuth(state).login;
export const selectAuthPassword = (state) => selectAuth(state).password;
export const selectAuthShowPassword = (state) => selectAuth(state).showPassword;
export const selectAuthIsFetching = (state) => selectAuth(state).isFetching;

export const {
  fetchFinished, loginInProgress, setLogin, setPassword, setShowPassword,
} = authSlice.actions;

export default authSlice.reducer;
