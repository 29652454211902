import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';

import { selectAppOpenMenu } from 'store/slices/app';
import { getNetworkErrors } from 'store/actions/debug';
import { selectDebugDebugNetwork, selectDebugFetchingDebugList } from 'store/slices/debug';

import useStyles from 'components/shared/settings/DebugStyles';
import containerStyles from 'components/shared/Container/styles';
import PaginationIcon from 'components/shared/Pagination/PaginationIcon';
import CustomTableHeader from 'components/shared/Tables/Header';

export default function DebugNetwork() {
  const dispatch = useDispatch();
  const openMenu = useSelector(selectAppOpenMenu);
  const fetchingDebugList = useSelector(selectDebugFetchingDebugList);
  const debugNetwork = useSelector(selectDebugDebugNetwork);

  const { t } = useTranslation(['settings']);
  const { classes } = useStyles();
  const { classes: classesContainer } = containerStyles();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  useEffect(() => {
    dispatch(getNetworkErrors());
  }, [dispatch]);

  return (
    <div className={!openMenu ? classes.content : classesContainer.noneContent}>
      {fetchingDebugList
      && (
        <div className={classes.spinerWrapper}>
          <CircularProgress size={26} />
        </div>
      )}
      <div className={classes.table}>
        <TableContainer className={classes.tableContainer} component={Paper}>
          <Table>
            <TableHead>
              <TableRow sx={{ marginBottom: '1vw', marginLeft: '15px', display: 'block' }}>
                <CustomTableHeader>
                  <div className="tableTheadCell">
                    Network Errors
                  </div>
                </CustomTableHeader>
              </TableRow>
            </TableHead>
            <TableBody>
              {debugNetwork && debugNetwork
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((bug, index) => (
                  <React.Fragment key={bug}>
                    <TableRow style={index % 2 === 0 ? { backgroundColor: 'white' } : { backgroundColor: '#ebebeb' }}>
                      <TableCell className={classes.extraDataCell}>
                        <p className={classes.logMessageList}>
                          {bug}
                        </p>
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[25, 50, 100, { label: t('All'), value: -1 }]}
            component="div"
            count={debugNetwork ? debugNetwork.length : 0}
            rowsPerPage={rowsPerPage}
            page={page}
            SelectProps={{
              IconComponent: PaginationIcon,
              inputProps: {
                id: 'selector-rows-per-page',
                name: 'selector-rows-per-page',
              },
            }}
            onPageChange={(_, pageNumber) => setPage(pageNumber)}
            onRowsPerPageChange={(event) => {
              setPage(0);
              setRowsPerPage(+event.target.value);
            }}
            labelDisplayedRows={({ from, to, count }) => (
              `${from}-${to} ${t('of')} ${count !== -1 ? count : `${t('moreThen')} ${to}`}`
            )}
            labelRowsPerPage={t('labelRowsPerPage')}
            nextIconButtonProps={{
              'aria-label': 'Next Page',
              style: {
                color: debugNetwork
                    && page === Math.ceil(debugNetwork.length / rowsPerPage) - 1
                  ? '#b5b8c4' : '#41afd7',
                background: 'white',
                width: '32px',
                height: '32px',
                margin: '5px',
              },
              autoid: 'pagination-button-previous-collector',
            }}
            backIconButtonProps={{
              'aria-label': 'Previous Page',
              style: {
                color: page === 0 ? '#b5b8c4' : '#41afd7', background: 'white', width: '32px', height: '32px',
              },
              autoid: 'pagination-button-next-collector',
            }}
          />
        </TableContainer>
      </div>
    </div>
  );
}
