import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';

import PauseIcon from '@mui/icons-material/Pause';
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';
import ReplayIcon from '@mui/icons-material/Replay';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import CircularProgress from '@mui/material/CircularProgress';

import { getHistoryList } from 'store/actions/history';
import {
  resetHistoryList, selectHistoryIsFetching, selectHistoryObject, selectHistoryObjectsList,
} from 'store/slices/history';
import {
  selectReportsDateFrom, selectReportsDateTo,
} from 'store/slices/reports';

import BuildButton from 'components/reports/BuildButton.component';

import { HISTORY_SPEEDS } from 'constans';
import useStyles from './HistoryStyles';

export default function HistoryPlayer({
  playedPercentage,
  playing,
  setPlaying,
  frameKeys,
  resetState,
  moveCallback,
  speed,
  setSpeed,
  stopPlayer,
}) {
  const dispatch = useDispatch();
  const { classes } = useStyles();
  const dateFrom = useSelector(selectReportsDateFrom);
  const dateTo = useSelector(selectReportsDateTo);
  const objectsList = useSelector(selectHistoryObjectsList);
  const isFetchingHistory = useSelector(selectHistoryIsFetching);
  const frames = useSelector(selectHistoryObject);

  const dateInMs = useMemo(() => {
    const percentage = Math.min(100, Math.max(0, playedPercentage || 0)) / 100;
    return dateFrom.getTime() + percentage * (dateTo.getTime() - dateFrom.getTime());
  }, [dateTo, dateFrom, playedPercentage]);

  const currentTime = useMemo(() => {
    if (Number.isNaN(dateInMs)) {
      return '00:00:00';
    }
    const newDate = new Date(dateInMs);
    if (newDate && newDate instanceof Date) {
      return format(newDate, 'HH:mm:ss');
    }
    return '00:00:00';
  }, [dateInMs]);

  const switchSpeed = (event) => {
    setSpeed(event.target.value);
    stopPlayer();
  };

  const togglePlaying = () => {
    setPlaying((prev) => !prev);
  };

  const fetchHistory = async () => {
    dispatch(resetHistoryList());
    resetState();
    await dispatch(getHistoryList({ offset: null, packet: null }));
  };

  const toggleReplay = async () => {
    await fetchHistory();
    togglePlaying();
  };

  const handlePlaying = async () => {
    if (objectsList && Object.keys(objectsList).length > 0) {
      setPlaying(true);
    } else {
      fetchHistory();
    }
    if (playing) {
      setPlaying(false);
    }
  };
  useEffect(() => {
    if (isFetchingHistory === 'done' && frames && Object.keys(frames).length > 0) {
      setPlaying(true);
    }
  }, [isFetchingHistory, frames, setPlaying]);

  return (
    <div className={classes.player}>
      <div className={classes.playBtn}>
        { playedPercentage < 100
          ? (
            <BuildButton
              className={classes.btnP}
              onClick={handlePlaying}
            >
              {playing && isFetchingHistory === 'done' && <PauseIcon fontSize="large" color="secondary" />}
              {!playing && (isFetchingHistory === 'done' || isFetchingHistory === 'not-started' || isFetchingHistory === 'rejected')
                && <PlayCircleFilledIcon fontSize="large" color="primary" />}
              {!playing && isFetchingHistory === 'pending'
                && <CircularProgress size={18} />}
            </BuildButton>
          ) : (
            <BuildButton
              className={classes.btnP}
              disabled={frameKeys.length < 1}
              onClick={toggleReplay}
            >
              <ReplayIcon fontSize="large" color="secondary" />
            </BuildButton>
          )}
      </div>
      <div
        className={classes.timeLine}
        tabIndex="-5"
        role="button"
        onKeyDown={moveCallback}
        onClick={moveCallback}
      >
        <div className={classes.line} style={{ width: `${Math.round(playedPercentage * 10) / 10}%` }} />
        <div className={classes.carret} style={{ left: `${Math.round(playedPercentage * 10) / 10}%` }} />
      </div>
      <div className={classes.durationTracker}>
        <span>
          {currentTime}
        </span>
      </div>
      <Select
        name="speed-selector"
        variant="standard"
        className={classes.speedSelect}
        value={speed}
        label="Speed"
        onChange={switchSpeed}
      >
        {HISTORY_SPEEDS.map((value) => (
          <MenuItem value={value} key={value}>
            {`x${value}`}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
}
