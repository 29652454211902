import React, {
  useState, useEffect, useCallback, useMemo,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import ruLocale from 'date-fns/locale/ru';
import enLocale from 'date-fns/locale/en-US';
import { debounce } from 'lodash';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';
import CircularProgress from '@mui/material/CircularProgress';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import Add from '@mui/icons-material/Add';

import { ReactComponent as EditButton } from 'assets/images/settings/icons/EditSettings.svg';
import { ReactComponent as DeleteButton } from 'assets/images/settings/icons/DeleteSettings.svg';

import { fetchDeviceModels, fetchDevicesList } from 'store/actions/devices';
import {
  setUpdateDeviceId, openDevicesModal,
  setSettingsModalDeviceTitle, setSettingsModalMac, setProperty,
  openDeleteDevicesModal,
  selectDevicesIsFetchingSettingsList,
  selectDevicesList,
  selectDevicesOpenModal,
  selectDevicesOpenDeleteModal,
  setDeviceModelId,
} from 'store/slices/devices';
import { createAlert, alertTypes } from 'store/slices/alert';
import { selectAppCurrentApp, selectAppOpenMenu } from 'store/slices/app';
import { selectFloorsObject } from 'store/slices/floors';
import { selectLocationsObject } from 'store/slices/locations';

import {
  sortDeviceByBattery, sortDeviceByMac, sortDeviceBySignal, sortDeviceByTitle,
} from 'helpers/sortByField';
import {
  formTypeCreate, formTypeUpdate,
} from 'constans';

import CustomButtonTitle from 'components/settings/ButtonTitle.component';
import CustomTextField from 'components/settings/TextFiled';
import PaginationIcon from 'components/shared/Pagination/PaginationIcon';
import containerStyles from 'components/shared/Container/styles';
import DialogModalComponent from './Dialog.component';

import useStyles from '../objects/objects.component.style';

const datePickerLocales = {
  ru: ruLocale,
  en: enLocale,
};

function Beacons() {
  const dispatch = useDispatch();
  const openMenu = useSelector(selectAppOpenMenu);
  const currentApp = useSelector(selectAppCurrentApp);
  const isFetchingSettingsList = useSelector(selectDevicesIsFetchingSettingsList);
  const devicesList = useSelector(selectDevicesList);
  const openModal = useSelector(selectDevicesOpenModal);
  const openDeleteModal = useSelector(selectDevicesOpenDeleteModal);
  const floorsObject = useSelector(selectFloorsObject);
  const locationsObject = useSelector(selectLocationsObject);

  const { t, i18n } = useTranslation(['settings', 'monitoring', 'notifications', 'report']);
  const { classes } = useStyles();
  const { classes: classesContainer } = containerStyles();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [shownDevices, setShownDevices] = useState(JSON.parse(JSON.stringify(devicesList)));
  const [objectsFilter, setObjectsFilter] = useState('');
  const [sort, setSort] = useState({
    title: 'none',
    mac: 'none',
    last_active: 'none',
    battery: 'none',
  });
  const [deviceToDelete, setDeviceToDelete] = useState({});

  const dateLocale = datePickerLocales[i18n.language];

  const handleSearch = useCallback((value) => {
    dispatch(fetchDevicesList(value));
  }, [dispatch]);

  const debouncedSendRequest = useMemo(() => debounce(handleSearch, 300), [handleSearch]);

  const handleChangeFilter = (event) => {
    setObjectsFilter(event.target.value);
    debouncedSendRequest(event.target.value);
  };

  useEffect(() => {
    setShownDevices(JSON.parse(JSON.stringify(devicesList)));
  }, [devicesList]);

  useEffect(() => {
    setPage(0);
  }, [currentApp]);

  useEffect(() => {
    const getDevices = async () => {
      dispatch(fetchDevicesList());
      dispatch(fetchDeviceModels());
    };
    getDevices();
  }, [currentApp, dispatch]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleCreateNewDevice = () => {
    dispatch(openDevicesModal(formTypeCreate));
  };

  const handleDelete = async (object) => {
    setDeviceToDelete(object);
    dispatch(openDeleteDevicesModal());
  };

  const handleEditDevice = (device) => {
    try {
      dispatch(setSettingsModalDeviceTitle(device.attributes.title));
      dispatch(setSettingsModalMac(device.attributes.mac_address));
      if (Array.isArray(device.properties)) {
        dispatch(setProperty(device.properties));
      }
      dispatch(setDeviceModelId(device.attributes.model_id));
      dispatch(openDevicesModal(formTypeUpdate));
      dispatch(setUpdateDeviceId(device.id));
    } catch (error) {
      dispatch(createAlert({ messageType: alertTypes.warn, message: 'Error in reading device properties' }));
    }
  };

  function sortColumn(column) {
    const order = sort[column] === 'desc' ? 'asc' : 'desc';
    const result = {};
    Object.keys(sort).forEach((key) => {
      result[key] = key !== column ? 'none' : order;
    });
    setSort(result);
    switch (column) {
      case 'title':
        setShownDevices(sortDeviceByTitle(sort.title, shownDevices));
        break;
      case 'mac':
        setShownDevices(sortDeviceByMac(sort.mac, shownDevices));
        break;
      case 'last_active':
        setShownDevices(sortDeviceBySignal(sort.last_active, shownDevices));
        break;
      case 'battery':
        setShownDevices(sortDeviceByBattery(sort.battery, shownDevices));
        break;
      default: break;
    }
  }

  const formatProperties = (properties) => {
    const result = [];
    if (properties && properties.length > 0) {
      properties.sort((a, b) => a.key.localeCompare(b.key));
      properties.forEach((el) => {
        if (el.key === 'location_id' && locationsObject[el.value]) {
          result.push(`${t('settings:location')}='${locationsObject[el.value].title}'`);
        }
        if (el.key === 'sublocation_id' && floorsObject[el.value]) {
          result.push(`${t('settings:floor')}='${floorsObject[el.value].title}'`);
        }
      });
    }
    return result.join('\n');
  };

  return (
    <div className={!openMenu ? classes.content : classesContainer.noneContent}>
      <div className={classes.topControls}>
        <CustomButtonTitle
          variant="contained"
          color="primary"
          onClick={handleCreateNewDevice}
          className={classes.createBtn}
        >
          <Add className={classes.createIcon} />
          {' '}
          <span>
            {t('New Device')}
          </span>
        </CustomButtonTitle>
        <CustomTextField
          id="standard-multiline-flexible"
          label={t('typeToSearch')}
          value={objectsFilter}
          onChange={handleChangeFilter}
          variant="standard"
        />
      </div>
      {isFetchingSettingsList
        ? (
          <div className={classes.spinerWrapper}>
            <CircularProgress size={26} />
          </div>
        )
        : (
          <div className={classes.table}>
            <TableContainer className={classes.tableContainer} component={Paper}>
              <Table stickyHeader className={classes.table} aria-label="simple table">
                <TableBody>
                  <TableRow className={classes.tableRow}>
                    <TableCell onClick={() => sortColumn('title')} className={classes.titleTheadCellSort}>
                      <div className={classes.tableTheadCell}>
                        <span className={classes.titleText}>{t('title')}</span>
                        {(sort.title === 'asc') && <ArrowDropUpIcon />}
                        {(sort.title === 'desc') && <ArrowDropDownIcon />}
                      </div>
                    </TableCell>
                    <TableCell onClick={() => sortColumn('mac')} className={classes.titleTheadCellSort}>
                      <div className={classes.tableTheadCell}>
                        <span className={classes.titleText}>{t('MAC address')}</span>
                        {(sort.mac === 'asc') && <ArrowDropUpIcon />}
                        {(sort.mac === 'desc') && <ArrowDropDownIcon />}
                      </div>
                    </TableCell>
                    <TableCell onClick={() => sortColumn('battery')} className={classes.titleTheadCellSort}>
                      <div className={classes.tableTheadCell}>
                        <span className={classes.titleText}>
                          {t('Battery')}
                        </span>
                        {sort.battery === 'asc' && <ArrowDropUpIcon />}
                        {sort.battery === 'desc' && <ArrowDropDownIcon />}
                      </div>
                    </TableCell>
                    <TableCell onClick={() => sortColumn('last_active')} className={classes.titleTheadCellSort}>
                      <div className={classes.tableTheadCell}>
                        <span className={classes.titleText}>{t('Last signal')}</span>
                        {(sort.last_active === 'asc') && <ArrowDropUpIcon />}
                        {(sort.last_active === 'desc') && <ArrowDropDownIcon />}
                      </div>
                    </TableCell>
                    <TableCell className={classes.titleTheadCell}>
                      <div className={classes.tableTheadCell}>
                        <span className={classes.titleText}>
                          {t('Properties')}
                        </span>
                      </div>
                    </TableCell>
                    <TableCell className={classes.titleTheadCell}>
                      <div className={classes.tableTheadCell}>
                        <span className={classes.titleText}>
                          {t('actions')}
                        </span>
                      </div>
                    </TableCell>
                  </TableRow>
                  {shownDevices.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((device) => {
                      const LastDateSignal = new Date(device.attributes.last_active_time * 1000);
                      const batteryProperty = device && device.telemetry
                      && device.telemetry.battery;
                      return (
                        <TableRow key={device.id} className={classes.tableRow}>
                          <TableCell className={classes.tableCell} component="th" scope="row">
                            {device.attributes.title}
                          </TableCell>
                          <TableCell className={classes.tableCell} component="th" scope="row">
                            {device.attributes.mac_address}
                          </TableCell>
                          <TableCell className={classes.tableCell} component="th" scope="row">
                            {batteryProperty >= 0
                              ? <div>{batteryProperty}</div> : <div>-</div>}
                          </TableCell>
                          <TableCell className={classes.tableCell} component="th" scope="row">
                            {!device.attributes.last_active_time ? '-' : format(LastDateSignal, 'dd LLL yyyy  HH:mm:ss', { locale: dateLocale }) || '-'}
                          </TableCell>
                          <TableCell className={`${classes.tableCell} ${classes.propsCell}`} component="th" scope="row">
                            {device && device.properties && device.properties.length > 0
                              ? (
                                <Tooltip
                                  enterDelay={500}
                                  title={(
                                    <Typography className={classes.tooltip}>
                                      {formatProperties(
                                        JSON.parse(JSON.stringify(device.properties)),
                                      )}
                                    </Typography>
                                  )}
                                >
                                  <div>
                                    {formatProperties(
                                      JSON.parse(JSON.stringify(device.properties)),
                                    )}
                                  </div>
                                </Tooltip>
                              )
                              : '-'}
                          </TableCell>
                          <TableCell className={`${classes.tableCell} ${classes.actionCell}`}>
                            <div className={classes.actionButtonsContainer}>
                              <EditButton
                                onClick={() => handleEditDevice(device)}
                                className={classes.actionButton}
                              />
                              <DeleteButton
                                onClick={() => handleDelete(device)}
                                className={classes.actionButton}
                              />
                            </div>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50, { label: t('All'), value: -1 }]}
              component="div"
              count={devicesList.length}
              rowsPerPage={rowsPerPage}
              page={page}
              className={classes.pagination}
              SelectProps={{
                IconComponent: PaginationIcon,
                inputProps: {
                  id: 'selector-rows-per-page',
                  name: 'selector-rows-per-page',
                },
              }}
              classes={{
                root: classes.root,
                select: classes.select,
                selectIcon: classes.selectIcon,
                caption: classes.caption,
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelDisplayedRows={({ from, to, count }) => (
                `${from}-${to} ${t('of')} ${count !== -1 ? count : `${t('moreThen')} ${to}`}`
              )}
              labelRowsPerPage={<>{t('labelRowsPerPage')}</>}
              nextIconButtonProps={{
                'aria-label': 'Next Page',
                style: {
                  color: (shownDevices
                    && page === Math.ceil(shownDevices.length / rowsPerPage) - 1)
                    ? '#b5b8c4' : '#41afd7',
                  background: 'white',
                  width: '32px',
                  height: '32px',
                  margin: '5px',
                },
                autoid: 'pagination-button-previous-collector',
              }}
              backIconButtonProps={{
                'aria-label': 'Previous Page',
                style: {
                  color: page === 0 ? '#b5b8c4' : '#41afd7',
                  background: 'white',
                  width: '32px',
                  height: '32px',
                },
                autoid: 'pagination-button-next-collector',
              }}
            />
            {(openModal || openDeleteModal)
            && <DialogModalComponent deviceToDelete={deviceToDelete} />}
          </div>
        )}
    </div>
  );
}

export default Beacons;
