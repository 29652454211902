export const sortObjectByObjectTitle = (order, objectsArray) => {
  const result = JSON.parse(JSON.stringify(objectsArray));
  const multiplier = order === 'desc' ? 1 : -1;
  if (result.length) {
    result.sort((a, b) => a.attributes.title
      .localeCompare(b.attributes.title)
      * multiplier);
  }
  return result;
};

export const sortObjectByGroupName = (order, objectsArray) => {
  const result = JSON.parse(JSON.stringify(objectsArray));
  const multiplier = order === 'desc' ? 1 : -1;
  if (result.length) {
    result.sort((a, b) => {
      if (!a.relationships['tracked-group'].data.title
      && !b.relationships['tracked-group'].data.title) return 0;
      if (!a.relationships['tracked-group'].data.title) return 1;
      if (!b.relationships['tracked-group'].data.title) return -1;
      return a.relationships['tracked-group'].data.title
        .localeCompare(b.relationships['tracked-group'].data.title)
        * multiplier;
    });
  }
  return result;
};

export const sortObjectByDeviceTitle = (order, objectsArray) => {
  const result = JSON.parse(JSON.stringify(objectsArray));
  const multiplier = order === 'desc' ? 1 : -1;
  if (result.length) {
    result.sort((a, b) => {
      if (!a.relationships['tracked-beacon']?.data?.title
      && !b.relationships['tracked-beacon']?.data?.title) return 0;
      if (!a.relationships['tracked-beacon']?.data?.title) return 1;
      if (!b.relationships['tracked-beacon']?.data?.title) return -1;
      return a.relationships['tracked-beacon'].data.title
        .localeCompare(b.relationships['tracked-beacon'].data.title)
        * multiplier;
    });
  }
  return result;
};

export const sortObjectByDeviceSignalTime = (order, objectsArray) => {
  const result = JSON.parse(JSON.stringify(objectsArray));
  const multiplier = order === 'desc' ? 1 : -1;
  if (result.length) {
    result.sort((a, b) => {
      if (a.last_active_time === null && b.last_active_time === null) return 0;
      if (a.last_active_time === null) return 1;
      if (b.last_active_time === null) return -1;
      return (a.last_active_time - b.last_active_time) * multiplier;
    });
  }
  return result;
};

export const sortObjectByOnlineStatus = (order, objectsArray, onlineObjects) => {
  const result = JSON.parse(JSON.stringify(objectsArray));
  const multiplier = order === 'desc' ? 1 : -1;
  if (result.length) {
    result.sort((a, b) => {
      if (onlineObjects.find((onlineObj) => +onlineObj.id === a.id)
      && onlineObjects.find((onlineObj) => +onlineObj.id === b.id)) {
        return a.last_active_time - b.last_active_time;
      }
      if (!onlineObjects.find((onlineObj) => +onlineObj.id === a.id)
       && onlineObjects.find((onlineObj) => +onlineObj.id === b.id)) {
        return multiplier;
      }
      if (!onlineObjects.find((onlineObj) => +onlineObj.id === b.id)
       && onlineObjects.find((onlineObj) => +onlineObj.id === a.id)) {
        return -multiplier;
      }
      if (!a.last_active_time && !b.last_active_time) {
        return a.attributes.title
          .localeCompare(b.attributes.title)
          * multiplier;
      }
      return (b.last_active_time - a.last_active_time) * multiplier;
    });
  }
  return result;
};

export const sortDeviceByTitle = (order, objectsArray) => {
  const result = JSON.parse(JSON.stringify(objectsArray));
  const multiplier = order === 'desc' ? 1 : -1;
  if (result.length) {
    result.sort((a, b) => a.attributes.title
      .localeCompare(b.attributes.title)
      * multiplier);
  }
  return result;
};

export const sortDeviceByMac = (order, objectsArray) => {
  const result = JSON.parse(JSON.stringify(objectsArray));
  const multiplier = order === 'desc' ? 1 : -1;
  if (result.length) {
    result.sort((a, b) => a.attributes.mac_address
      .localeCompare(b.attributes.mac_address)
      * multiplier);
  }
  return result;
};

export const sortDeviceBySignal = (order, objectsArray) => {
  const result = JSON.parse(JSON.stringify(objectsArray));
  const multiplier = order === 'desc' ? 1 : -1;
  if (result.length) {
    result.sort((a, b) => {
      if (a.attributes.last_active_time === null
        && b.attributes.last_active_time === null) return 0;
      if (a.attributes.last_active_time === null) return 1;
      if (b.attributes.last_active_time === null) return -1;
      return (b.attributes.last_active_time
        - a.attributes.last_active_time)
        * multiplier;
    });
  }
  return result;
};

export const sortDeviceByBattery = (order, objectsArray) => {
  const result = JSON.parse(JSON.stringify(objectsArray));
  const multiplier = order === 'desc' ? 1 : -1;
  if (result.length) {
    result.sort((a, b) => {
      if (!a.properties && !b.properties) return 0;
      if (!a.properties) return 1;
      if (!b.properties) return -1;
      if (a.properties.length === 0 && b.properties.length === 0) return 0;
      const aBattery = a.properties.find((prop) => prop.key === 'battery');
      const bBattery = b.properties.find((prop) => prop.key === 'battery');
      if (!aBattery && !bBattery) return 0;
      if (!aBattery) return 1;
      if (!bBattery) return -1;
      return (bBattery.value - aBattery.value) * multiplier;
    });
  }
  return result;
};

export const sortConditionByTitle = (order, conditions, translatedConditionTypes) => {
  const result = JSON.parse(JSON.stringify(conditions));
  const multiplier = order === 'desc' ? 1 : -1;
  if (result.length) {
    result.sort((a, b) => {
      const first = translatedConditionTypes[a.attributes.condition]
      ?? translatedConditionTypes.other;
      const second = translatedConditionTypes[b.attributes.condition]
      ?? translatedConditionTypes.other;
      return first.localeCompare(second) * multiplier;
    });
  }
  return result;
};

export const sortConditionByLocation = (order, conditions) => {
  const result = JSON.parse(JSON.stringify(conditions));
  const multiplier = order === 'desc' ? 1 : -1;
  if (result.length) {
    result.sort((a, b) => {
      const firstLoc = a.relationships?.location?.title;
      const secondLoc = b.relationships?.location?.title;
      if (!firstLoc && !secondLoc) return 0;
      if (!firstLoc) return -1;
      if (!secondLoc) return 1;
      return firstLoc.localeCompare(secondLoc) * multiplier;
    });
  }
  return result;
};

export const sortConditionBySublocation = (order, conditions) => {
  const result = JSON.parse(JSON.stringify(conditions));
  const multiplier = order === 'desc' ? 1 : -1;
  if (result.length) {
    result.sort((a, b) => {
      const firstLoc = a.relationships?.sublocation?.name;
      const secondLoc = b.relationships?.sublocation?.name;
      if (!firstLoc && !secondLoc) return 0;
      if (!firstLoc) return -1;
      if (!secondLoc) return 1;
      return firstLoc.localeCompare(secondLoc) * multiplier;
    });
  }
  return result;
};

export const sortObjectByStringKey = (order, array, key) => {
  const result = JSON.parse(JSON.stringify(array));
  const multiplier = order === 'desc' ? -1 : 1;
  if (result.length) {
    result.sort((a, b) => {
      if (typeof a[key] === 'number' && typeof b[key] === 'number') {
        return (a[key] - b[key]) * multiplier;
      }
      if (!a[key] && !b[key]) return 0;
      if (!a[key]) return 1;
      if (!b[key]) return -1;
      return a[key].localeCompare(b[key]) * multiplier;
    });
  }
  return result;
};

export const sortGroupTitle = (order, array) => {
  const result = JSON.parse(JSON.stringify(array));
  const multiplier = order === 'desc' ? -1 : 1;
  if (result.length) {
    result.sort((a, b) => a.attributes.title.localeCompare(b.attributes.title) * multiplier);
  }
  return result;
};

export const sortObjectByNumericalKey = (order, array, key) => {
  // eslint-disable-next-line
  const result = structuredClone(array);
  const multiplier = order === 'desc' ? -1 : 1;
  if (result.length) {
    result.sort((a, b) => {
      if (!a[key] && !b[key]) return 0;
      if (!a[key]) return 1;
      if (!b[key]) return -1;
      return (+a[key] - +b[key]) * multiplier;
    });
  }
  return result;
};
