import { withStyles } from 'tss-react/mui';
import Autocomplete from '@mui/material/Autocomplete';

export const customAutocomplete = withStyles(Autocomplete, (materialTheme, _params, classes) => ({
  input: {
    padding: 0,
  },
  inputRoot: {
    height: '36px',
    width: '100%',
    borderRadius: '4px',
    border: 'none',
    color: materialTheme.CustomStyles.GlobalTextColor,
    [`& .${classes.input}`]: {
      width: 30,
      minWidth: 30,
      color: materialTheme.CustomStyles.GlobalTextColor,
    },
    '& fieldset': {
      border: 'none',
      borderRadius: '4px',
      color: materialTheme.CustomStyles.GlobalTextColor,
    },
    '&[class*="MuiOutlinedInput-root"]': {
      [`& .${classes.input}`]: {
        padding: 0,
      },
    },
  },
}));
export default customAutocomplete;
