import React from 'react';
import {
  Navigate, Route, Routes,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import BuildOutlinedIcon from '@mui/icons-material/BuildOutlined';
import ObjectSettings from 'components/settings/objects/objects.component';
import LeftMenuComponent from 'components/leftMenu/left.menu';
import NotificationsComponent from 'components/settings/notifications/notifications.component';
import Groups from 'components/settings/groups/groups.component';
import Devices from 'components/settings/devices/devices.component';
import DebugDevices from 'components/settings/debug-devices/DebugDevices.component';
import DebugLocations from 'components/settings/debug-locations/DebugLocations.component';
import DebugQueues from 'components/settings/debug-queues/DebugQueues.component';
import DebugNetwork from 'components/settings/debug-network/DebugNetwork.component';

import { selectAppOpenMenu } from 'store/slices/app';

import ObjectIcon from 'assets/images/Users.svg';
import NotificationIcon from 'assets/images/Chat.svg';
import GroupIcon from 'assets/images/User2.svg';
import DeviceIcon from 'assets/images/Device.svg';
import useStyles from './settings.style';

const selectRole = (state) => state?.user?.role;

function Settings() {
  const openMenu = useSelector(selectAppOpenMenu);
  const userRole = useSelector(selectRole);

  const { classes } = useStyles();
  const { t } = useTranslation(['settings']);
  const objectsPath = 'objects';
  const notificationsPath = 'notifications';
  const groupsPath = 'groups';
  const devicesPath = 'devices';
  const debugDevicesPath = 'debug-devices';
  const debugLocationsPath = 'debug-locations';
  const debugQueuesPath = 'debug-queues';
  const debugNetworkPath = 'debug-network';

  const menu = [
    {
      name: t('objectsLinkName'),
      path: objectsPath,
      appKey: 'inventory_reports', // Todo: fix in api and change key here
      component: <ObjectSettings />,
      icon: {
        url: ObjectIcon,
        width: '27px',
        height: '24px',
      },
    },
    {
      name: t('notificationsLink'),
      path: notificationsPath,
      icon: {
        url: NotificationIcon,
        width: '27px',
        height: '24px',
      },
    },
    {
      name: t('groupsLink'),
      path: groupsPath,
      icon: {
        url: GroupIcon,
        width: '27px',
        height: '24px',
      },
    },
    {
      name: t('Devices'),
      path: devicesPath,
      icon: {
        url: DeviceIcon,
        width: '27px',
        height: '24px',
      },
    },
  ];

  const rootMenu = [{
    name: 'Debug Devices',
    path: debugDevicesPath,
    icon: {
      svg: BuildOutlinedIcon,
      fontSize: '24px',
    },
  },
  {
    name: 'Debug Locations',
    path: debugLocationsPath,
    icon: {
      svg: BuildOutlinedIcon,
      fontSize: '24px',
    },
  },
  {
    name: 'Debug Queues',
    path: debugQueuesPath,
    icon: {
      svg: BuildOutlinedIcon,
      fontSize: '24px',
    },
  },
  {
    name: 'Debug Network',
    path: debugNetworkPath,
    icon: {
      svg: BuildOutlinedIcon,
      fontSize: '24px',
    },
  }];

  return (
    <div className={!openMenu ? classes.container : classes.noneContainer}>
      <LeftMenuComponent
        options={(typeof userRole === 'string' && userRole?.toLowerCase() === 'role_root')
          ? [...menu, ...rootMenu] : menu}
        openMenu={openMenu}
      />
      <Routes>
        <Route
          path="/*"
          element={(<Navigate to={objectsPath} replace />)}
        />
        <Route path={objectsPath} element={<ObjectSettings />} />
        <Route path={notificationsPath} element={<NotificationsComponent />} />
        <Route path={groupsPath} element={<Groups />} />
        <Route path={devicesPath} element={<Devices />} />
        {typeof userRole === 'string' && userRole?.toLowerCase() === 'role_root'
        && [
          <Route key="devices" path={debugDevicesPath} element={<DebugDevices />} />,
          <Route key="locations" path={debugLocationsPath} element={<DebugLocations />} />,
          <Route key="queues" path={debugQueuesPath} element={<DebugQueues />} />,
          <Route key="network" path={debugNetworkPath} element={<DebugNetwork />} />,
        ]}
      </Routes>
    </div>
  );
}

export default Settings;
