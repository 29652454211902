import request, { endPointsEnum } from 'services/http';
import { createAlert } from 'store/slices/alert';
import {
  fetchLocationsInProgress, fetchedLocations, setLocation, setLocationsLst,
} from 'store/slices/locations';

export const fetchLocations = () => async (dispatch, getState) => {
  const state = getState();
  try {
    const params = {
      api_key: state.app.currentApp?.api_key,
    };

    dispatch(fetchLocationsInProgress());

    const response = await request.client.get(endPointsEnum.locationsGetAll, { params });

    const locationsArray = response.data;

    const locationsObject = {};
    let firstLocation = {};
    locationsArray.map((location, index) => {
      locationsObject[location.id] = {
        id: location.id,
        title: location.attributes.title,
        floors: location.relationships.sublocations.data,
      };
      if (index === 0) {
        firstLocation = { ...locationsObject[location.id] };
      }
      return location;
    });

    dispatch(setLocationsLst(locationsObject));
    dispatch(setLocation(firstLocation));
  } catch (error) {
    dispatch(fetchedLocations());
    if (state.app.currentApp.api_key) {
      dispatch(createAlert({ messageType: 'error', message: error.message }));
    }
  }
};

export default fetchLocations;
