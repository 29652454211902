import { withStyles } from 'tss-react/mui';
import TextField from '@mui/material/TextField';

const CustomSelector = withStyles(TextField, (materialTheme) => {
  // eslint-disable-next-line no-unused-vars
  const { fontRegular } = materialTheme;

  return {
    root: {
      width: '100%',
      margin: '7px 0',
      '& label': {
        fontSize: '19px',
        color: materialTheme.CustomStyles.GlobalTextColor,
        marginLeft: '0',
      },
      '& .MuiInputBase-root': {
        '& .MuiSvgIcon-root .MuiSelect-icon': {
          marginRight: '18px',
        },
      },
    },

  };
});

export default CustomSelector;
