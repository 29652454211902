import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import 'chart.js/auto';

import CircularProgress from '@mui/material/CircularProgress';
import FormControl from '@mui/material/FormControl';
import { Typography } from '@mui/material';

import { selectAppCurrentApp, selectAppOpenMenu } from 'store/slices/app';
import {
  selectCurrentFloor, selectFloorsObject, setFloor,
} from 'store/slices/floors';
import {
  selectGroupsCount, selectGroupsObject, selectGroupsSelectedGroup, setSelectedGroup,
} from 'store/slices/groups';
import { selectCurrentLocation, selectLocationsObject, setLocation } from 'store/slices/locations';
import { fetchNumberDevicesReport } from 'store/actions/reports';
import {
  selectReportsBeaconAnalyticReport,
  selectReportsDateFrom, selectReportsDateTo, selectReportsDistanceTraveledReport,
  selectReportsIsFetchingDistanceTraveledReport,
} from 'store/slices/reports';
import { setSelectedZone } from 'store/slices/zones';

import { allValuesConstant, selectNoOneConstant } from 'constans';

import BuildButton from 'components/reports/BuildButton.component';
import DatePickers from 'components/reports/datePickers/datePickers.component';
import LocationSelector from 'components/reports/ReportsLocationSelector';
import FloorSelector from 'components/reports/ReportsFloorSelector';
import GroupsInfinite from 'components/shared/GroupSelector/GroupsInfinite';
import GroupSelector from 'components/reports/ReportsGroupSelector';
import containerStyles from 'components/shared/Container/styles';
import useStyles from './numberDevices.style';
import BarChart from './Bar';

import { downloadXls } from './xls.export';

function NumbersDevices() {
  const { classes: classesContainer } = containerStyles();
  const { classes } = useStyles();
  const { t } = useTranslation(['report', 'monitoring', 'translation']);

  const dispatch = useDispatch();
  const openMenu = useSelector(selectAppOpenMenu);
  const currentApp = useSelector(selectAppCurrentApp);
  const floorsObject = useSelector(selectFloorsObject);
  const currentFloor = useSelector(selectCurrentFloor);
  const locationsObject = useSelector(selectLocationsObject);
  const currentLocation = useSelector(selectCurrentLocation);
  const dateFrom = useSelector(selectReportsDateFrom);
  const dateTo = useSelector(selectReportsDateTo);
  const isFetching = useSelector(selectReportsIsFetchingDistanceTraveledReport);
  const report = useSelector(selectReportsDistanceTraveledReport);
  const beaconAnalyticReport = useSelector(selectReportsBeaconAnalyticReport);
  const groupsCount = useSelector(selectGroupsCount);
  const groupsObject = useSelector(selectGroupsObject);
  const selectedGroup = useSelector(selectGroupsSelectedGroup);

  const fetchReport = async () => {
    await dispatch(fetchNumberDevicesReport());
  };

  const handleChangeLocation = (event) => {
    const locationId = event.target.value;
    dispatch(setLocation(locationsObject[locationId]));
    const floorId = locationsObject[locationId].floors[0].id;
    dispatch(setFloor(floorsObject[floorId]));
    dispatch(setSelectedZone({ id: selectNoOneConstant }));
    dispatch(setSelectedGroup({ id: allValuesConstant }));
  };

  const handleChangeFloor = (event) => {
    const floorId = event.target.value;
    dispatch(setFloor(floorsObject[floorId]));
    dispatch(setSelectedZone({ id: selectNoOneConstant }));
    dispatch(setSelectedGroup({ id: allValuesConstant }));
  };

  const handleChangeGroup = useCallback((value) => {
    const groupId = value;
    if (groupId === allValuesConstant) {
      dispatch(setSelectedGroup({ id: allValuesConstant }));
      return;
    }
    const newSelectedGroup = groupsObject[groupId];
    dispatch(setSelectedGroup(newSelectedGroup));
  }, [dispatch, groupsObject]);

  const handleDownload = () => {
    downloadXls({
      report,
      translate: t,
    });
  };
  const floorsObjectFiltred = Object.values(floorsObject)
    .filter((object) => object.location === currentLocation.id);
  return (
    <div className={!openMenu ? classes.content : classesContainer.noneContent}>
      <div className={classes.topControls}>
        <div className={classes.formControls}>
          <form className={classes.formControls} noValidate>
            <div className={classes.controlWrapper}>
              <FormControl variant="standard">
                <LocationSelector
                  locations={locationsObject}
                  className={classes.selector}
                  value={currentLocation.id}
                  onChange={handleChangeLocation}
                />
              </FormControl>
            </div>
            <div className={classes.controlWrapper}>
              <FormControl variant="standard">
                <FloorSelector
                  floors={floorsObjectFiltred}
                  className={classes.selector}
                  onChange={handleChangeFloor}
                  value={currentFloor.id}
                  currentLocationId={currentLocation.id}
                />
              </FormControl>
            </div>
            <div className={classes.controlWrapper}>
              {(!groupsCount || groupsCount <= 100)
                ? (
                  <GroupSelector
                    name="group-selector"
                    onChange={(e) => handleChangeGroup(e.target.value)}
                    groups={groupsObject}
                    value={selectedGroup.id}
                    className={classes.selector}
                  />
                ) : (
                  <GroupsInfinite
                    value={selectedGroup}
                    handleChangeGroup={handleChangeGroup}
                    allowSelectAll
                    inputStyles={{ border: 'none', backgroundColor: 'white' }}
                    borderStyle="none"
                    dropdownStyles={{ width: '200px !important' }}
                  />
                )}
            </div>
          </form>
          <form className={classes.formControls} noValidate>
            <DatePickers />
          </form>
        </div>
      </div>
      {isFetching
        ? (
          <div className={classes.spinerWrapper}>
            <CircularProgress size={26} />
          </div>
        )
        : (
          <div>
            <div className={classes.headContainer}>
              <div>
                <Typography variant="h6">{t('translation:numberOfDevices')}</Typography>
              </div>
              <div className={classes.btnWrapper}>
                <BuildButton
                  style={{ margin: '5px' }}
                  variant="outlined"
                  color="primary"
                  disableRipple
                  onClick={fetchReport}
                  disabled={isFetching}
                >
                  {t('buildReport')}
                </BuildButton>
                {report.length > 0 ? (
                  <div
                    style={{ display: currentApp.role === 'demo' ? 'none' : null }}
                    className={classes.downloadControlContainer}
                    onClick={handleDownload}
                    onKeyUp={() => handleDownload()}
                    tabIndex="0"
                    role="button"
                  >
                    <div className={classes.downloadIcon} />
                    <div className={classes.downLoadPhraseContainer}>
                      <p className={classes.downLoadPhrase}>
                        {' '}
                        {t('downloadXls')}
                        {' '}
                      </p>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
            <div className={classes.table}>
              {Object.keys(beaconAnalyticReport).length > 0
                ? (
                  <BarChart
                    data={beaconAnalyticReport}
                    dateFrom={dateFrom}
                    dateTo={dateTo}
                  />
                )
                : null}
            </div>
          </div>
        )}
    </div>
  );
}

export default NumbersDevices;
