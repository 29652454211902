/* eslint-disable react/jsx-props-no-spreading */

import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import AppBar from '@mui/material/AppBar';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';

import isZoneRelatedNotification from 'helpers/isZoneRelatedNotification';
import { fetchDevicesList } from 'store/actions/devices';
import { createCondition, getConditions } from 'store/actions/notifications';
import { selectAppCurrentApp } from 'store/slices/app';
import { selectCurrentFloor } from 'store/slices/floors';
import {
  selectGroupsSelectedGroup,
} from 'store/slices/groups';
import { selectCurrentLocation } from 'store/slices/locations';
import {
  closeMainModal,
  selectNotifSetConditionType,
  selectNotifSetFormType,
  selectNotifSetIsConditionCreating,
  selectNotifSetOpenModal,
  selectNotifSetUpdatedConditionId,
} from 'store/slices/notifications.settings';
import { selectSelectedZone } from 'store/slices/zones';

import CustomButton from 'components/settings/Button.component';
import TabPanel from 'components/shared/Tabs/Tabs';
import a11yProps from 'components/shared/Tabs/allProps';
import { formTypeCreate } from 'constans';
import { createConditionsSocial, fetchConditionsSocial } from 'store/actions/notifications.social';
import { resetConditionsSocial, resetTemporaryConditionsSocial } from 'store/slices/notifications.social';
import NotificationBaseTab from './CreateEditTabs/NotificationBaseTab';
import NotificationDeliveryTab from './CreateEditTabs/NotificationDeliveryTab';

import useStyles from '../notifications.component.style';

function CreateEditNotification() {
  const { classes } = useStyles();
  const { t } = useTranslation(['settings', 'monitoring', 'notifications']);

  const dispatch = useDispatch();
  const currentFloor = useSelector(selectCurrentFloor);
  const selectedGroup = useSelector(selectGroupsSelectedGroup);
  const currentLocation = useSelector(selectCurrentLocation);
  const openModal = useSelector(selectNotifSetOpenModal);
  const conditionType = useSelector(selectNotifSetConditionType);
  const formType = useSelector(selectNotifSetFormType);
  const isCreating = useSelector(selectNotifSetIsConditionCreating);
  const selectedZone = useSelector(selectSelectedZone);
  const currentApp = useSelector(selectAppCurrentApp);
  const conditionId = useSelector(selectNotifSetUpdatedConditionId);

  const isAdvancedNotification = useMemo(() => {
    const advancedNotification = currentApp?.properties
      ?.findLast((prop) => prop.type === 'advanced_notifications');
    return advancedNotification && advancedNotification.value === 'true';
  }, [currentApp?.properties]);

  const [valueTabs, setValueTabs] = useState(0);
  const checkFormValidation = useMemo(() => {
    const check = isCreating || !conditionType
      || !currentLocation || !currentFloor
      || !selectedGroup;
    if (isZoneRelatedNotification(conditionType)) {
      const isZoneSelected = selectedZone ? selectedZone.id > 0 : false;
      return check || !isZoneSelected;
    }
    return check;
  }, [conditionType, currentFloor, currentLocation, isCreating, selectedGroup, selectedZone]);

  const handleCreateCondition = async () => {
    dispatch(createCondition(t)).then((action) => {
      if (action && action.payload) {
        dispatch(createConditionsSocial(action.payload))
          .then(() => { dispatch(getConditions()); });
      } else {
        dispatch(getConditions());
      }
      dispatch(resetConditionsSocial());
      dispatch(resetTemporaryConditionsSocial());
    });
    dispatch(fetchDevicesList());
  };

  const handleClose = () => {
    dispatch(resetTemporaryConditionsSocial());
    dispatch(resetConditionsSocial());
    dispatch(closeMainModal());
  };

  const handleChangeTabs = (_, newValue) => {
    setValueTabs(newValue);
  };

  useEffect(() => {
    if (!conditionId || !isAdvancedNotification || formType === formTypeCreate) {
      return;
    }
    dispatch(fetchConditionsSocial({ id: conditionId }));
  }, [conditionId, dispatch, isAdvancedNotification, formType]);

  return (
    <Dialog
      open={openModal}
      onClose={handleClose}
      scroll="paper"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      PaperProps={{
        style: {
          position: 'absolute',
          top: '5vh',
        },
      }}
    >
      <form onSubmit={(e) => e.preventDefault()}>
        <div className={classes.tabsMainWrapper}>
          <div className={classes.tabContainer}>
            <DialogTitle id="scroll-dialog-title" className={classes.dialogTitle}>
              <div className={classes.divToH3}>
                {formType === formTypeCreate
                  ? t('createConditionTitle')
                  : t('editConditionTitle')}
              </div>
              <IconButton size="small" onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            {isAdvancedNotification
              ? (
                <>
                  <AppBar position="static" className={classes.wrappApp}>
                    <Tabs
                      value={valueTabs}
                      onChange={handleChangeTabs}
                      className={classes.tabsLabel}
                      TabIndicatorProps={{ style: { background: '#30AAD9' } }}
                    >
                      <Tab label={t('base')} {...a11yProps(0)} className={classes.tabLabel} />
                      <Tab label={t('actions')} {...a11yProps(1)} className={classes.tabLabel} />
                    </Tabs>
                  </AppBar>
                  <TabPanel value={valueTabs} index={0}>
                    <NotificationBaseTab />
                  </TabPanel>
                  <TabPanel value={valueTabs} index={1}>
                    <NotificationDeliveryTab />
                  </TabPanel>
                </>
              ) : <NotificationBaseTab />}
          </div>
        </div>
        <DialogActions className={classes.dialogActions}>
          <CustomButton
            type="submit"
            disabled={checkFormValidation}
            onClick={handleCreateCondition}
            color="primary"
            className={classes.customBtn}
          >
            {isCreating ? <CircularProgress size={26} /> : null}
            {formType === formTypeCreate ? t('createConditionButton') : t('saveBtn')}
          </CustomButton>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default CreateEditNotification;
