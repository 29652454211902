/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Typography } from '@mui/material';
import TablePagination from '@mui/material/TablePagination';
import CircularProgress from '@mui/material/CircularProgress';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import { objectsByAppFetch } from 'store/actions/objects';
import { fetchZoneReport, fetchFinalZoneReport } from 'store/actions/reports';
import { selectAppCurrentApp, selectAppOpenMenu } from 'store/slices/app';
import { selectCurrentFloor } from 'store/slices/floors';
import { selectGroupsObject } from 'store/slices/groups';
import {
  selectReportsDateFrom, selectReportsDateTo,
  selectReportsIsFetching, selectReportsZoneFinalReportsArray,
  selectReportsZoneReportsArray,
} from 'store/slices/reports';
import {
  selectSelectedZone, selectZoneTypes,
  selectZonesMap, setSelectedZone,
} from 'store/slices/zones';

import { allValuesConstant, selectNoOneConstant } from 'constans';
import BuildButton from 'components/reports/BuildButton.component';
import DatePickers from 'components/reports/datePickers/datePickers.component';
import BarChart from 'components/shared/Charts/Bar';
import containerStyles from 'components/shared/Container/styles';
import a11yProps from 'components/shared/Tabs/allProps';
import TabPanel from 'components/shared/Tabs/Tabs';
import PaginationIcon from 'components/shared/Pagination/PaginationIcon';
import FinalReport from './finalReport/finalReport';
import useStyles from './objectReport.component.style';
import ZoneSelector from '../ReportsZoneSelector';

function ZoneReport() {
  const { classes: classesContainer } = containerStyles();
  const { classes } = useStyles();
  const { t } = useTranslation(['report']);

  const dispatch = useDispatch();
  const openMenu = useSelector(selectAppOpenMenu);
  const currentApp = useSelector(selectAppCurrentApp);
  const currentFloor = useSelector(selectCurrentFloor);
  const groupsObject = useSelector(selectGroupsObject);
  const dateFrom = useSelector(selectReportsDateFrom);
  const dateTo = useSelector(selectReportsDateTo);
  const isFetching = useSelector(selectReportsIsFetching);
  const zoneFinalReportsArray = useSelector(selectReportsZoneFinalReportsArray);
  const zoneReportsArray = useSelector(selectReportsZoneReportsArray);
  const zonesMap = useSelector(selectZonesMap);
  const selectedZone = useSelector(selectSelectedZone);
  const zoneTypes = useSelector(selectZoneTypes);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [btnDisabledByDate, setBtnDisabledByDate] = useState(true);
  const [valueTabs, setValueTabs] = React.useState(0);

  const handleChangeTabs = (event, newValue) => {
    setValueTabs(newValue);
  };

  const fetchContactReportsClick = async () => {
    setPage(0);
    await dispatch(fetchZoneReport(selectedZone));
  };
  const fetchFinalReportsClick = async () => {
    setPage(0);
    await dispatch(fetchFinalZoneReport());
  };

  const formatTime = (time) => {
    if (time <= 0) {
      return `< 0.1 ${t('sec')}`;
    }
    const days = Math.trunc(time / 86400);
    const hours = Math.trunc(time / 3600) % 24;
    const minutes = Math.trunc(time / 60) % 60;
    const seconds = time % 60;
    return `${days > 0 ? `${days} ${t('day')}` : ''} ${hours > 0 ? `${hours} ${t('hour')}` : ''} ${minutes > 0 ? `${minutes} ${t('min')}` : ''} ${seconds > 0 ? `${parseFloat((seconds).toFixed(2))} ${t('sec')}` : ''}`;
  };

  useEffect(() => {
    const getObjects = async () => {
      await dispatch(objectsByAppFetch(groupsObject, 0, 4000));
    };

    getObjects();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupsObject, page, rowsPerPage, currentApp]);

  useEffect(() => {
    setPage(0);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupsObject, rowsPerPage, currentApp, dateFrom, dateTo]);
  useEffect(() => {
    if (new Date(Date.now()) > dateFrom && dateTo > dateFrom) {
      setBtnDisabledByDate(false);
    } else {
      setBtnDisabledByDate(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateFrom, dateTo]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleChangeZone = (event) => {
    event.preventDefault();
    const zoneId = event.target.value;

    if (zoneId === allValuesConstant) {
      dispatch(setSelectedZone({ id: allValuesConstant }));
      return;
    }
    if (zoneId === selectNoOneConstant) {
      dispatch(setSelectedZone({ id: selectNoOneConstant }));
    }
    if (zoneId !== selectNoOneConstant && zoneId !== allValuesConstant) {
      dispatch(setSelectedZone(zonesMap.get(zoneId)));
    }
  };
  const zonesArray = Object.values(zoneReportsArray)[0] || [];

  return (
    <div className={!openMenu ? classes.content : classesContainer.noneContent}>
      <div className={classes.topControls}>
        <div className={classes.formControls}>
          <form className={classes.formControls} noValidate>

            <div style={{ marginTop: '15px' }} className={classes.controlWrapper}>
              <ZoneSelector
                className={classes.selector}
                onChange={handleChangeZone}
                zones={zonesMap}
                value={selectedZone.id}
                zoneTypes={zoneTypes}
                currentFloorId={currentFloor.id}
              />
            </div>
            <DatePickers />
          </form>
          <div className={classes.headContainer}>
            <Typography variant="h6">{t('oneZoneReport')}</Typography>
            <div className={classes.btnWrapper}>
              <BuildButton
                className={classes.buildButton}
                style={{ marginRight: '5px' }}
                variant="outlined"
                color="primary"
                disableRipple
                onClick={fetchContactReportsClick}
                disabled={isFetching || btnDisabledByDate}
              >
                {t('buildReport')}
              </BuildButton>
            </div>
          </div>
        </div>
      </div>
      {isFetching
        ? (
          <div className={classes.spinerWrapper}>
            <CircularProgress size={26} />
          </div>
        )
        : (
          <div>
            <div className={classes.table}>
              {zonesArray.length > 0
                ? (
                  <AppBar position="static" style={{ background: '#f8f8f8', boxShadow: 'none' }}>
                    <Tabs
                      value={valueTabs}
                      onChange={handleChangeTabs}
                      aria-label="simple tabs example"
                      TabIndicatorProps={{ style: { background: 'black' } }}
                    >
                      <Tab label={t('Table')} {...a11yProps(0)} style={{ maxWidth: '10%', color: 'black' }} />
                      <Tab label={t('Charts')} {...a11yProps(1)} style={{ maxWidth: '10%', color: 'black' }} />
                    </Tabs>
                  </AppBar>
                )
                : null}
              <TabPanel value={valueTabs} index={0}>
                <TableContainer className={classes.tableContainer} component={Paper}>
                  <Table stickyHeader className={classes.table} aria-label="simple table">
                    <TableBody>
                      <TableRow>
                        <TableCell className={classes.tableCell} align="left">{t('Object')}</TableCell>
                        <TableCell className={classes.tableCell} align="left">{t('Group')}</TableCell>
                        <TableCell className={classes.tableCell} align="left">{t('Time')}</TableCell>
                      </TableRow>
                      {zonesArray.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((objectReport, index) => {
                          const key = index + 1;
                          return (
                            <TableRow key={key}>
                              <TableCell className={classes.tableCell} align="left" component="th" scope="row">
                                {objectReport.title ? objectReport.title : t('deletedZone')}
                              </TableCell>
                              <TableCell className={classes.tableCell} align="left">
                                {objectReport.trackedGroupTitle ? objectReport.trackedGroupTitle : t('deletedZone')}
                              </TableCell>
                              <TableCell className={classes.tableCell} align="left">
                                {objectReport.totalTimeInside ? formatTime(objectReport.totalTimeInside) : t('deletedZone')}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 50, { label: t('All'), value: -1 }]}
                  component="div"
                  count={zonesArray.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  className={classes.pagination}
                  SelectProps={{
                    IconComponent: PaginationIcon,
                  }}
                  classes={{
                    root: classes.root,
                    select: classes.select,
                    selectIcon: classes.selectIcon,
                    caption: classes.caption,
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${t('of')} ${count !== -1 ? count : `${t('moreThen')} ${to}`}`}
                  labelRowsPerPage={t('labelRowsPerPage')}
                  nextIconButtonProps={{
                    'aria-label': 'Next Page',
                    style: {
                      color: '#000', background: 'white', width: '32px', height: '32px', margin: '5px',
                    },
                    autoid: 'pagination-button-previous-collector',
                  }}
                  backIconButtonProps={{
                    'aria-label': 'Previous Page',
                    style: {
                      color: page === 0 ? '#b5b8c4' : '#41afd7', background: 'white', width: '32px', height: '32px',
                    },
                    autoid: 'pagination-button-next-collector',
                  }}
                />
              </TabPanel>
              <TabPanel value={valueTabs} index={1}>
                {zonesArray.length > 0
                  ? (
                    <BarChart
                      data={zonesArray}
                      sortingField="totalCountInside"
                      legendTitles="title"
                    />
                  )
                  : null}
              </TabPanel>
            </div>

            <div className={classes.headContainer}>
              <div>
                <Typography variant="h6">{t('allReportZone')}</Typography>
              </div>
              <div className={classes.btnWrapper}>
                <div className={classes.btnWrapper}>
                  <BuildButton
                    className={classes.buildButton}
                    variant="outlined"
                    color="primary"
                    disableRipple
                    onClick={fetchFinalReportsClick}
                    disabled={isFetching || btnDisabledByDate}
                  >
                    {t('buildReport')}
                  </BuildButton>
                </div>
              </div>

            </div>
            <FinalReport
              zoneFinalReportsArray={zoneFinalReportsArray}
              zonesObject={zonesMap}
            />
          </div>
        )}
    </div>
  );
}

export default ZoneReport;
