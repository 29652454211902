/* eslint-disable no-underscore-dangle */
import React from 'react';
import { createRoot } from 'react-dom/client';
import './i18n';
import './index.css';
import * as Sentry from '@sentry/react';
import Root from 'containers/Root';
import defaultTheme from './themes-materialUI/default.theme';

const configEnv = window._configuration_env;

if (configEnv.SENTRY_URL) {
  Sentry.init({
    dsn: configEnv.SENTRY_URL,
    environment: configEnv.SENTRY_ENV,
    release: '956c1fed',
    autoSessionTracking: false,
  });
}

(async () => {
  let theme = defaultTheme;

  try {
    if (configEnv.THEME_URL) {
      theme = await (await fetch(`${configEnv.THEME_URL}/theme.json`)).json();
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error in theme loading. Using default. Error:', error);
  }

  const root = createRoot(document.getElementById('root'));
  root.render(<Root theme={theme} />);
})();
