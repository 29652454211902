import { withStyles } from 'tss-react/mui';
import Select from '@mui/material/Select';

const StyledSelector = withStyles(Select, (materialTheme) => ({
  root: {
    '& label': {
      fontSize: '19px',
      color: materialTheme.CustomStyles.GlobalTextColor,
      marginLeft: '20px',
    },
    '& .MuiInputBase-root': {
      '& .MuiSelect-icon': {
        marginRight: '8px',
      },
      '& .MuiSelect-select.MuiSelect-select': {
        paddingLeft: '15px',
        lineHeight: '130%',
        fontSize: '18px',
        color: materialTheme.CustomStyles.GlobalTextColor,
        textAlign: 'left',
        width: '100%',
      },
    },
  },
}));

export default StyledSelector;
