import React, { useState } from 'react';
import ClearIcon from '@mui/icons-material/Clear';
import { makeStyles } from 'tss-react/mui';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { selectSettingsModal, setModalForm } from 'store/slices/settings';
import { createAlert } from 'store/slices/alert';
import { ReactComponent as GalleryIcon } from 'assets/images/gallery.svg';
import InputComponent from '../../Input.component';

const useStyles = makeStyles()({
  fileFormWrap: {
    width: '100%',
    margin: 'auto',
  },
  inputContainer: {
    marginBottom: '10px',
  },
  inputComponent: {
    height: '35px',
    padding: '0 5px 0 5px',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    borderRadius: '8px',
  },
  inputElement: {
    '& :hover': {
      cursor: 'pointer',
    },
  },
  label: {
    width: '100%',
    fontSize: '14px',
    color: '#929292',
    display: 'flex',
    flexDirection: 'column',
  },
  fileUpload: {
    flexDirection: 'row',
    justifyContent: 'center',
    position: 'relative',
    overflow: 'hidden',
    width: '100%',
    height: '38px',
    fontSize: '18px',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    borderRadius: '8px',
    color: 'rgb(120,120,120)',
    '& input': {
      visibility: 'hidden',
    },
  },
  imgCamera: {
    float: 'right',
    margin: '5px 10px',
  },
  imgInput: {
    position: 'absolute',
    left: '170px',
    fill: 'currentColor',
    maxWidth: '1.8em',
    maxHeight: '1.5em',
    display: 'inline-block',
    fontSize: '2rem',
    transition: 'fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;',
    flexShrink: '0',
    userSelect: 'none',
  },
  cancelBtn: {
    float: 'right',
    margin: '5px 10px',
    '&:hover': {
      color: 'black',
    },
  },
});

function InputTypeFile() {
  const { classes } = useStyles();
  const modal = useSelector(selectSettingsModal);
  const {
    image,
    fileInfo,
    iconImageUrl,
  } = modal;

  const ACCEPTED_TYPE = 'image/*, .svg';

  const { t } = useTranslation(['settings', 'report']);
  const dispatch = useDispatch();
  const [fetchTimeout, setFetchTimeout] = useState(null);

  const convertBase64 = (file) => new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      resolve(fileReader.result);
    };
    fileReader.onerror = (error) => {
      reject(error);
    };
  });

  const handleChangeForm = async (event) => {
    const y = new Error(t('chooseAnotherFile'));
    const info = event.target.files[0] || '';
    const b64 = await convertBase64(info);
    const isLt5M = info.size < 10 * 1024 * 1024;
    const isName = /\.(jpe?g|bmp|gif|png|svg|)$/i.test(info.name);
    if (!isLt5M || !isName) {
      dispatch(createAlert({ messageType: 'error', message: y.message }));
    } else {
      dispatch(
        setModalForm({
          fileInfo: info,
          image: b64,
        }),
      );
    }
  };

  const fetchFile = async (link) => {
    const y = new Error(t('chooseAnotherFile'));
    const fileName = link.substring(link.lastIndexOf('/') + 1);
    try {
      const response = await fetch(link);

      if (!response.ok) {
        throw new Error('File not found');
      }
      const blob = await response.blob();
      const file = new File([blob], fileName, { type: blob.type });
      const b64 = await convertBase64(file);
      const isLt5M = file.size < 10 * 1024 * 1024;
      const isName = /\.(jpe?g|bmp|gif|png|svg|svg\+xml)$/i.test(link);
      if (!isLt5M && !isName) {
        dispatch(createAlert({ messageType: 'error', message: y.message }));
      } else {
        dispatch(setModalForm({
          fileInfo: file,
          image: b64,
        }));
      }
    } catch (e) {
      dispatch(createAlert({ messageType: 'error', message: e.message }));
    }
  };

  const handleChangeLink = (value) => {
    const link = value.trim();
    if (link === '') {
      const element = document.getElementById('contained-file-exists');
      if (element) { element.value = ''; }
      dispatch(setModalForm({
        fileInfo: ' ',
        iconImageUrl: '',
        image: '',
      }));
      return;
    }
    if (fetchTimeout) {
      clearTimeout(fetchTimeout);
    }

    const newFetchTimeout = setTimeout(() => {
      fetchFile(link);
    }, 500);

    setFetchTimeout(newFetchTimeout);
    const resultObject = {};
    resultObject.iconImageUrl = value;
    dispatch(setModalForm(resultObject));
  };

  const HandleClearIcon = (e) => {
    e.stopPropagation();
    dispatch(setModalForm({
      fileInfo: ' ',
      iconImageUrl: '',
      image: '',
    }));
    const element = document.getElementById('contained-file-temporary')
    || document.getElementById('contained-file-nofile')
    || document.getElementById('contained-file');
    element.value = '';
  };

  return (
    <div className={classes.fileFormWrap}>
      {!fileInfo.name ? (
        <div className={classes.inputContainer}>
          {iconImageUrl ? (
            <div className={classes.inputContainer}>
              <span style={{ fontSize: '14px', color: '#929292' }}>
                {t('ChangeFile')}
              </span>
              <div className={classes.fileUpload}>
                <label htmlFor="contained-file-temporary">
                  <input
                    type="file"
                    accept={ACCEPTED_TYPE}
                    onChange={handleChangeForm}
                    placeholder={t('ChangeFile')}
                    id="contained-file-temporary"
                    className={classes.inputElement}
                  />
                  <img
                    className={classes.imgInput}
                    alt="noImg"
                    height="40px"
                    src={iconImageUrl}
                  />
                </label>
                <ClearIcon
                  className={classes.cancelBtn}
                  onClick={HandleClearIcon}
                />
              </div>
            </div>
          ) : (
            <div className={classes.inputContainer}>
              <span style={{ fontSize: '14px', color: '#929292' }}>{t('ChooseFile')}</span>
              <div className={classes.fileUpload}>
                <label htmlFor="containedfilenofile">
                  {}
                  <input
                    type="file"
                    accept={ACCEPTED_TYPE}
                    onChange={handleChangeForm}
                    placeholder={t('ChooseFile')}
                    id="containedfilenofile"
                    name="containedfilenofile"
                    className={classes.inputElement}
                  />
                  <GalleryIcon className={classes.imgCamera} />
                </label>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className={classes.inputContainer}>
          <span style={{ fontSize: '14px', color: '#929292' }}>{t('ChangeFile')}</span>
          <div className={classes.fileUpload}>
            <label htmlFor="contained-file">
              <input
                type="file"
                accept={ACCEPTED_TYPE}
                onChange={handleChangeForm}
                placeholder={t('ChangeFile')}
                id="contained-file"
                className={classes.inputElement}
              />
              <img
                src={image}
                height="40px"
                alt="noImg"
                className={classes.imgInput}
              />
            </label>
            <ClearIcon className={classes.cancelBtn} onClick={HandleClearIcon} />
          </div>
        </div>
      )}
      <label className={classes.label} htmlFor="image-url-input">
        {t('objectFormFieldIcon')}
        <InputComponent
          className={classes.inputComponent}
          id="image-url-input"
          value={iconImageUrl}
          fullWidth
          onChange={(event) => {
            handleChangeLink(event.target.value);
          }}
          InputLabelProps={{ shrink: true }}
          InputProps={{ disableUnderline: true }}
          variant="standard"
        />
      </label>
    </div>
  );
}

export default InputTypeFile;
