/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { getNetworkErrors } from 'store/actions/debug';

const initialState = {
  fetchingDebugList: true,
  debugDevices: [],
  debugLocations: [],
  debugQueues: [],
  debugNetwork: [],
};

const debugSlice = createSlice({
  name: 'debug',
  initialState,
  reducers: {
    setFetchingDebugList(state, action) {
      state.fetchingDebugList = action.payload;
    },
    setDebugDevices(state, action) {
      state.debugDevices = action.payload;
    },
    setDebugLocations(state, action) {
      state.debugLocations = action.payload;
    },
    setDebugQueues(state, action) {
      state.debugQueues = action.payload;
    },

    setDeviceExpanded(state, action) {
      state.debugDevices?.forEach((device) => {
        if (device.id === action.payload) {
          device.expanded = !device.expanded;
        }
      });
    },
    setLocationExpanded(state, action) {
      state.debugLocations?.forEach((location) => {
        if (location.id === action.payload) {
          location.expanded = !location.expanded;
        }
      });
    },
    setQueueExpanded(state, action) {
      state.debugQueues?.forEach((queue) => {
        if (queue.name === action.payload) {
          queue.expanded = !queue.expanded;
        }
      });
    },

    setDeviceExtra(state, action) {
      const {
        id, extra, bind_location_id: locationId, bind_sublocation_id: sublocationId,
      } = action.payload;
      return {
        ...state,
        debugDevices: state.debugDevices.map((device) => {
          if (device.id !== id
            || device.bind_sublocation_id !== sublocationId
            || device.bind_location_id !== locationId) {
            return device;
          }
          return {
            ...device,
            extra: { ...extra, log_messages: extra?.log_messages },
          };
        }),
      };
    },
    setDeviceExtraLoading(state, action) {
      const {
        id, bind_location_id: locationId, bind_sublocation_id: sublocationId, loading,
      } = action.payload;
      return {
        ...state,
        debugDevices: state.debugDevices.map((device) => {
          if (device.id !== id
            || device.bind_sublocation_id !== sublocationId
            || device.bind_location_id !== locationId) {
            return device;
          }
          return {
            ...device,
            loading,
          };
        }),
      };
    },
    setLocationExtra(state, action) {
      const { extra, id } = action.payload;
      return {
        ...state,
        debugLocations: state.debugLocations.map((location) => {
          if (location.id !== id) {
            return location;
          }
          return {
            ...location,
            extra,
          };
        }),
      };
    },
    setLocationExtraLoading(state, action) {
      const { loading, id } = action.payload;
      return {
        ...state,
        debugLocations: state.debugLocations.map((location) => {
          if (location.id !== id) {
            return location;
          }
          return {
            ...location,
            loading,
          };
        }),
      };
    },
    setQueueExtra(state, action) {
      const { extra, name } = action.payload;
      return {
        ...state,
        debugQueues: state.debugQueues.map((queue) => {
          if (queue.name !== name) {
            return queue;
          }
          return {
            ...queue,
            extra,
          };
        }),
      };
    },
    setQueueExtraLoading(state, action) {
      const { loading, name } = action.payload;
      return {
        ...state,
        debugQueues: state.debugQueues.map((queue) => {
          if (queue.name !== name) {
            return queue;
          }
          return {
            ...queue,
            loading,
          };
        }),
      };
    },

  },
  extraReducers: (builder) => {
    builder.addCase(getNetworkErrors.pending, (state) => {
      state.fetchingDebugList = true;
    });
    builder.addCase(getNetworkErrors.fulfilled, (state, action) => {
      state.debugNetwork = action.payload;
      state.fetchingDebugList = false;
    });
    builder.addCase(getNetworkErrors.rejected, (state) => {
      state.fetchingDebugList = false;
    });
  },
});

export const selectDebug = (state) => state.debug;

export const selectDebugFetchingDebugList = (state) => selectDebug(state).fetchingDebugList;
export const selectDebugDebugDevices = (state) => selectDebug(state).debugDevices;
export const selectDebugDebugLocations = (state) => selectDebug(state).debugLocations;
export const selectDebugDebugQueues = (state) => selectDebug(state).debugQueues;
export const selectDebugDebugNetwork = (state) => selectDebug(state).debugNetwork;

export const {
  setDebugDevices, setDebugLocations, setDebugQueues, setFetchingDebugList,
  setDeviceExpanded, setLocationExpanded, setQueueExpanded,
  setDeviceExtra, setDeviceExtraLoading,
  setLocationExtra, setLocationExtraLoading,
  setQueueExtra, setQueueExtraLoading,
} = debugSlice.actions;

export default debugSlice.reducer;
