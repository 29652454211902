import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import i18next from 'i18next';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Drawer from '@mui/material/Drawer';
import Hidden from '@mui/material/Hidden';
import IconButton from '@mui/material/IconButton';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';

import { RU_DEMO_USER } from 'constans';
import { setOpenMenu } from 'store/slices/app';
import DemoUserFooter from './DemouserFooter/DemouserFooter';

import useStyles from './styles';

const selectRole = (state) => state?.app?.currentApp?.role;
const selectEmail = (state) => state?.user?.email;

function MainMenu(props) {
  const { window, openMenu } = props;
  const dispatch = useDispatch();
  const { children } = props;
  const { classes } = useStyles();
  const userRole = useSelector(selectRole);
  const userEmail = useSelector(selectEmail);

  const handleDrawerClose = () => {
    dispatch(setOpenMenu(false));
  };

  const drawerSwich = () => {
    dispatch(setOpenMenu(!openMenu));
  };
  const handleDrawer = () => {
    if (!openMenu) {
      dispatch(setOpenMenu(true));
    } else {
      dispatch(setOpenMenu(false));
    }
  };

  useEffect(() => {
    // hard code RU translation for this demo user
    if (userEmail === RU_DEMO_USER) {
      i18next.changeLanguage('ru');
    }
  }, [userEmail]);

  const container = window !== undefined ? () => window().document.body : undefined;
  return (
    <div className={classes.root}>

      <nav className={classes.drawer}>
        <Hidden mdUp>
          <SwipeableDrawer
            hysteresis={0.22}
            minFlingVelocity={250}
            container={container}
            variant="temporary"
            anchor="left"
            onOpen={() => dispatch(setOpenMenu(true))}
            open={openMenu}
            onClose={handleDrawerClose}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {children}
            <IconButton className={classes.openCloseMenu} onClick={drawerSwich} size="large">
              {openMenu && <ChevronLeftIcon />}
            </IconButton>
          </SwipeableDrawer>
          <IconButton
            sx={{
              zIndex: 1000,
              position: 'absolute',
              left: 0,
              bottom: 120,
              borderRadius: '0 40% 40% 0',
              backgroundColor: '#f8f8f8',
              border: '1px solid rgba(0, 0, 0, 0.2)',
            }}
            onClick={drawerSwich}
            size="large"
          >
            {!openMenu && <ChevronRightIcon />}
          </IconButton>
        </Hidden>

        <Hidden mdDown>
          <Drawer
            sx={{ display: openMenu ? 'none' : 'block' }}
            classes={{
              paper: classes.drawerPaperFullSize,
            }}
            variant="permanent"
            // true
          >
            {children}
          </Drawer>
          <IconButton
            sx={{ left: !openMenu && '230px' }}
            className={classes.btnBigScreen}
            onClick={handleDrawer}
            size="large"
          >
            {openMenu
              ? <ChevronRightIcon />
              : <ChevronLeftIcon />}
          </IconButton>
        </Hidden>
      </nav>
      {userRole === 'demo' && userEmail !== RU_DEMO_USER
      && <DemoUserFooter />}
    </div>
  );
}

export default MainMenu;
