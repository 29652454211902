import { makeStyles } from 'tss-react/mui';
import ContactIcon from 'assets/images/reports/icons/team.svg';
import XlsIcon from 'assets/images/reports/icons/xlsnew.svg';
import timeIcon from 'assets/images/reports/icons/timeIcon.svg';

const useStyles = makeStyles()((materialTheme) => {
  const theme = materialTheme.CustomStyles;
  // eslint-disable-next-line no-unused-vars
  const { fontRegular } = materialTheme;
  return {
    controlWrapper: {
      width: '222px',
      minWidth: '222px',
      marginRight: '2vw',
    },
    selectorWrapp: {
      width: '222px',
      marginRight: '2vw',
    },
    selector: {
      width: '222px',
      height: '36px',
      borderRadius: '4px',
      backgroundColor: '#ffffff',
      padding: '0 15px',
    },
    selectorObject: {
      marginRight: '10px',
    },
    icon: {
      width: '27px',
      height: '19px',
      background: `url(${ContactIcon}) no-repeat`,
    },
    textDiv: {
      marginLeft: '17px',
    },
    text: {
      color: materialTheme.CustomStyles.GlobalTextColor,
      fontSize: '15px',
      fontWeight: 400,
    },
    LeftLink: {
      height: '62px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      paddingLeft: theme.MainLeftMargin,
      borderBottom: '1px  solid #e2e6ec',
    },
    leftSelector: {
      width: theme.LeftBarWidth,
      height: 'calc(100vh - 80px)',
      display: 'flex',
      flexDirection: 'column',
    },
    topControls: {
      marginTop: '22px',
      marginBottom: '27px',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
      borderBottom: '1px solid #edeff3',
    },
    totalNumbersItem: {
      marginLeft: '14px',
    },
    totalNumbersItemText: {
      color: materialTheme.CustomStyles.GlobalTextColor,
    },
    timeIcon: {
      width: '15px',
      height: '15px',
      background: `url(${timeIcon}) no-repeat`,
    },
    downloadControlContainer: {
      width: '167px',
      height: '36px',
      backgroundColor: '#fff',
      borderRadius: '4px',
      border: '1px solid #30AAD9',
      color: '#30AAD9',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      '&:hover': {
        cursor: 'pointer',
        backgroundColor: '#12161408',
      },
    },
    downloadIcon: {
      width: 37,
      height: 35,
      background: `url(${XlsIcon}) center no-repeat `,
      marginLeft: '10px',
    },
    downLoadPhraseContainer: {
      width: '100%',
      marginLeft: 14,
    },
    downLoadPhraseContainerNull: {
      display: 'none',
      width: '100%',
      marginLeft: 14,
    },
    downLoadPhrase: {
      fontSize: '15px',
    },
    formControl: {
      borderRadius: 0,
      margin: '0 6px',
      paddingLeft: '17px',
      height: '35px',
      width: '270px',
      border: '1px solid #edeff3',
      backgroundColor: '#ffffff',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    inputAutoComplite: {
      backgrpund: 'red',
    },
    formControls: {
      display: 'flex',
      width: '100%',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      flexWrap: 'wrap',
    },
    formControlsNo: {
      display: 'none',
      width: '100%',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      flexWrap: 'wrap',
    },
    totalNumbers: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
    },
    table: {
      width: '100%',
      maxHeight: '60vh',
    },
    spinerWrapper: {
      width: '100%',
      maxHeight: '60vh',
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
    },
    content: {
      width: 'calc(100vw - 246px -32px)',
      height: 'calc(100vh - 80px)',
      display: 'flex',
      flexDirection: 'column',
      padding: '0 0 0 32px',
      overflowY: 'auto',
      [materialTheme.breakpoints.down('800')]: {
        width: '100%',
        marginLeft: '-1vw',
      },
    },
    container: {
      margin: '0 auto',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      height: '100%',
      textAlign: 'left',
      background: '#f8f8f8',
    },
    mapContainer: {
      width: 'calc(100vw - 246px - 32px)',
      height: 'calc(100vh - 80px - 15px - 300px)',
      display: 'block',
      '&::backdrop': {
        background: '#fafafa',
      },
      [materialTheme.breakpoints.down('600')]: {
        width: 'calc(100vw - 42px)',
      },
      [materialTheme.breakpoints.down('1300')]: {
        width: 'calc(100vw - 320px)',
        height: 'calc(100vh - 450px)',
      },
      [materialTheme.breakpoints.down('945')]: {
        width: 'calc(100vw - 320px)',
        height: 'calc(100vh - 560px)',
      },
    },
    mapContainerToggle: {
      width: 'calc(100vw - 246px - 32px)',
      height: 'calc(100vh - 220px)',
      display: 'block',
      '&::backdrop': {
        background: '#fafafa',
      },
      [materialTheme.breakpoints.down('600')]: {
        width: 'calc(100vw - 42px)',
      },
    },
    mapFakeContainer: {
      width: '1050px;',
      height: '720',
      display: 'block',
      position: 'absolute',
      top: '-99999px',
    },
    mainButton: {
      width: '100%',
      root: {
        background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
        borderRadius: 3,
        border: 0,
        color: 'white',
        height: 48,
        padding: '0 30px',
        boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
      },
      label: {
        textTransform: 'capitalize',
      },
    },
    btn: {
    },
    downloadBtn: {
      marginTop: '1vh',
      padding: '5px',
      height: '40px',
      display: 'flex',
      alignItems: 'center',
      border: '1px #dcdcdc solid',
      borderRadius: '2px',
      background: 'white',
      '&:hover': {
        border: '2px #41afd7 solid',
      },
    },
    headContainer: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      margin: '36px 0 10px 0',
    },
    btnWrapper: {
      display: 'flex',
      marginRight: '2vw',
    },
  };
});

export default useStyles;
