import { createAsyncThunk } from '@reduxjs/toolkit';
import request, { endPointsEnum } from 'services/http';
import { alertTypes, createAlert } from 'store/slices/alert';

export const fetchConditionsSocial = createAsyncThunk(
  'notificationsDelivery/fetchConditionSocial',
  async ({ id }, { getState, dispatch }) => {
    try {
      const state = getState();
      const { api_key: apiKey } = state.app.currentApp;
      const response = await request.tracking.get(`${endPointsEnum.trackedConditionsSocial}/${id}`, {
        params: {
          api_key: apiKey,
        },
      });
      return response;
    } catch (err) {
      dispatch(createAlert({ messageType: alertTypes.err, message: 'Error getting social conditions' }));
      return [];
    }
  },
);

export const createConditionsSocial = createAsyncThunk(
  'notificationsDelivery/createConditionsSocial',
  async (id, { getState, dispatch }) => {
    try {
      const state = getState();
      const { api_key: apiKey } = state.app.currentApp;
      const { conditionsSocial, conditionSocialTemporary, subject } = state.notificationsSocial;
      const formattedCondition = conditionsSocial.map((social) => ({
        channel: social.channel,
        destination: social.destination,
      }));
      if (conditionSocialTemporary.isValid && conditionSocialTemporary.channel
      && conditionSocialTemporary.destination) {
        formattedCondition.push({
          channel: conditionSocialTemporary.channel,
          destination: conditionSocialTemporary.destination,
        });
      }
      const response = await request.tracking.put(endPointsEnum.trackedConditionsSocial, {
        body: {
          api_key: apiKey,
          conditions_social: formattedCondition,
          condition_id: id,
          subject,
        },
      });
      return response;
    } catch (err) {
      dispatch(createAlert({ messageType: alertTypes.err, message: 'Error creating a social condition' }));
      return [];
    }
  },
);
