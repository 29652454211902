import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import MenuItem from '@mui/material/MenuItem';

import { noValuesConstant } from 'constans';
import OutlinedSelector from './OutlinedSelector/OutlinedSelector';

const FloorSelector = memo((props) => {
  const {
    className, allowAllValues, onChange,
    allowSelectNoValues, floors, currentLocationId,
    loading, value, label, children, ...rest
  } = props;
  const { t } = useTranslation(['monitoring']);

  const floorsArray = Array.isArray(floors) ? floors : Object.keys(floors);

  const currentValue = floorsArray.length > 0
        && value ? value : '';

  return (
    <div>
      <div style={{ color: '#8194A4', fontSize: '12px', fontWeight: '600' }}>{t('sublocation')}</div>
      <OutlinedSelector
        id="select-floor"
        className={className}
        novalueslabel={currentValue === noValuesConstant ? t('noFloors') : null}
        // allowNoValues={currentValue === noValuesConstant}
        // allowAllValues={allValuesConstant}
        allowSelectNoValues={allowSelectNoValues}
        value={currentValue}
        onChange={onChange}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...rest}
      >
        {Array.isArray(floors)
          ? floors.map((floor) => (
            <MenuItem key={floor.id} value={floor.id}>
              {floor.title}
            </MenuItem>
          ))
          : floorsArray.map((locationId) => (
            <MenuItem key={locationId} value={locationId}>
              {floors[locationId].title}
            </MenuItem>
          ))}
      </OutlinedSelector>
    </div>
  );
});

export default FloorSelector;
