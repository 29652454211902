import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((materialTheme) => ({
  history: {
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    textAlign: 'left',
    background: materialTheme.CustomStyles.MainBackgroundColor,
  },
  noneContainer: {
    margin: '0 auto',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    textAlign: 'left',
    background: materialTheme.CustomStyles.MainBackgroundColor,
  },
  sceneElement: {
    width: '100%',
    height: '100%',
  },
  mapControls: {
    width: '100%',
    background: '#fff',
    height: 'calc(100vh - 80px)',
    display: 'flex',
    flexDirection: 'column',
  },
  control_wrapper: {
    width: '207px',
    minWidth: '207px',
    marginRight: '2vw',
    textAlign: 'left',
    marginLeft: '20px',
    marginBottom: '20px',
  },
  switch_wrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  selector: {
    width: '207px',
    height: '36px',
    borderRadius: '4px',
    backgroundColor: '#F3F6F8',
    padding: '0 15px',
  },
  selectorWrapp: {
    width: '207px',
    marginRight: '2vw',
  },
  objectsCount: {
    marginLeft: '15px',
    fontSize: '16px',
  },
  switch_element_monitoring: {
    marginLeft: '9px',
  },
  switch_element_history: {
    marginLeft: '9px',
    paddingBottom: '15px',
  },
  historyDate: {
    marginLeft: '20px',
  },
  historyInput: {
    marginRight: 0,
    marginLeft: '20px',
    backgroundColor: '#f8f8f8',
    width: '207px',
  },
  backdrop: {
    zIndex: materialTheme.zIndex.drawer + 1,
    color: '#fff',
  },
  historyButton: {
    width: '206px',
    height: '36px',
    minWidth: '140px',
    backgroundColor: '#30AAD9',
    borderRadius: '4px',
    color: '#fff',
    marginLeft: '20px',
    marginTop: '20px',
  },
}));

export default useStyles;
