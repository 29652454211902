import checkObjectInWindowConfig from 'helpers/checkEnv';

export const staticTypeId = 2;
export const dynamicTypeId = 1;

export const oldDevice = 1;
export const newDevice = 2;

export const allValuesConstant = '-1';
export const selectNoOneConstant = '-2';
export const noValuesConstant = '-3';

export const formTypeCreate = 1;
export const formTypeUpdate = 2;

export const monitoring = '/monitoring';
export const history = '/history';
export const serviceReports = '/service-reports/*';
export const settings = '/settings/*';
export const profile = '/profile';
export const monitoring3d = '/monitoring3d';
export const history3d = '/history3d';

export const token = 'pk.eyJ1IjoiZHNlcmdlZXYiLCJhIjoiY2twM3o4d3lhMGcxeDJ3cjJ5Z25vcTVndyJ9.YFqj96mNp9Dv5c8qP06fAQ';
export const styleUrl = 'mapbox://styles/mapbox/streets-v11';

export const timeTypes = {
  seconds: 1,
  minutes: 2,
  hours: 3,
  days: 4,
  weeks: 5,
};

export const timeConstants = {
  1: 1,
  2: 60,
  3: 3600,
  4: 86400,
  5: 604800,
};

export const notificationTypes = {
  standing: 'STANDING',
  standingOut: 'STANDING_OUT',
  exit: 'EXIT',
  entry: 'ENTRY',
  sos: 'SOS',
  fall: 'FALL',
  custom: 'OTHER',
  immobility: 'IMMOBILITY',
};

export const conditionSoundTypes = {
  alarm: 'alarm',
  none: 'none',
};

export const notificationPriorities = {
  low: 'LOW',
  normal: 'MEDIUM',
  high: 'HIGH',
};

export const roles = {
  owner: 'owner',
  watcher: 'watcher',
  editor: 'editor',
};

export const RU_DEMO_USER = 'info+demo@nvgn.ru';

export const domainEnv = 'DOMAIN';
export const cookieTokenName = 'navtok';
export const cookieRefTokenName = 'navreftok';

export const cookOpts = {
  expires: 1,
  path: '/',
  domain: checkObjectInWindowConfig(domainEnv) || undefined,
};

export const colorsChartArray = [
  'rgba(254, 108, 157, 1)',
  'rgba(77, 153, 229, 1)',
  'rgba(245, 185, 31, 1)',
  'rgba(74, 174, 66, 1)',
  'rgba(107, 75, 226, 1)',
  'rgba(217, 12, 12, 1)',
  'rgba(12, 161, 186, 1)',
  'rgba(215, 61, 172, 1)',
  'rgba(0, 128, 255, 1)',
  'rgba(255, 102, 16, 1)',
  'rgba(9, 129, 0, 1)',
  'rgba(54, 0, 255, 1)',
  'rgba(146, 0, 0, 1)',
  'rgba(0, 110, 129, 1)',
  'rgba(155, 58, 128, 1)',
  'rgba(13, 67, 121, 1)',
  'rgba(184, 75, 14, 1)',
  'rgba(6, 79, 0, 1)',
  'rgba(31, 1, 144, 1)',
  'rgba(81, 0, 0, 1)',
];

export const HISTORY_SPEEDS = [1, 2, 4, 8, 16];
