import { withStyles } from 'tss-react/mui';
import { MobileDateTimePicker } from '@mui/x-date-pickers';

const styledDataPicker = withStyles(MobileDateTimePicker, () => ({
  root: {
    // height: '280px',
    // width: '100%',
  },
  '.MuiTimeClock-root': {
    height: '800px ',
  },
}));

export default styledDataPicker;
