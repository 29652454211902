import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((materialTheme) => {
  const theme = materialTheme.CustomStyles;
  // eslint-disable-next-line no-unused-vars
  const { fontRegular } = materialTheme;

  return {
    leftSelector: {
      width: '100%',
      height: 'calc(100vh - 80px)',
      background: '#fff',
      display: 'flex',
      flexDirection: 'column',
      minWidth: '140px',
    },
    LeftLink: {
      height: '62px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      paddingLeft: theme.MainLeftMargin,
      borderBottom: '1px  solid #F3F6F8',
    },
    icon: {
      width: '27px',
      height: '19px',
    },
    textDiv: {
      marginLeft: '17px',
    },
    text: {
      color: materialTheme.CustomStyles.GlobalTextColor,
      fontSize: '15px',
      fontWeight: 400,
      textDecoration: 'none',
    },
    listLinkActive: {
      color: materialTheme.palette.primary.main,
    },
  };
});

export default useStyles;
