import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  buttonPurchaseContainer: {
    position: 'absolute',
    bottom: '3%',
    right: '0px',
    transform: 'translate(-50%, -50%)',
    zIndex: 100,
  },
  buttonGuidelinesContainer: {
    position: 'absolute',
    bottom: '3%',
    right: '9em',
    transform: 'translate(-50%, -50%)',
    zIndex: 100,
  },
  buttonElement: {
    height: '52px',
    borderRadius: '8px',
    border: 0,
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    fontWeight: 600,
    fontSize: '16px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '0.5em',
    cursor: 'pointer',
  },
  purchaseButton: {
    width: '139px',
  },
  guidelinesButton: {
    width: '160px',
  },
}
));

export default useStyles;
