/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  appsObject: {},
  currentApp: {},
  isFetching: false,
  openMenu: false,
};

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setAppsObject(state, action) {
      return {
        ...state,
        appsObject: action.payload,
        isFetching: false,
      };
    },
    setApp(state, action) {
      return {
        ...state,
        currentApp: action.payload,
      };
    },
    fetchAppInProgress(state) {
      return {
        ...state,
        isFetching: true,
      };
    },
    fetchedAppsList(state) {
      return {
        ...state,
        isFetching: false,
      };
    },
    setOpenMenu(state, action) {
      return {
        ...state,
        openMenu: action.payload,
      };
    },
  },
});

export const selectApp = (state) => state.app;

export const selectAppAppsObject = (state) => selectApp(state).appsObject;
export const selectAppCurrentApp = (state) => selectApp(state).currentApp;
export const selectAppIsFetching = (state) => selectApp(state).isFetching;
export const selectAppOpenMenu = (state) => selectApp(state).openMenu;

export const {
  fetchAppInProgress, fetchedAppsList, setApp, setAppsObject, setOpenMenu,
} = appSlice.actions;

export default appSlice.reducer;
