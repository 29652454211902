import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import { deleteCondition, getConditions } from 'store/actions/notifications';
import {
  closeDeleteConditionModal, selectNotifSetIsConditionCreating, selectNotifSetOpenDeleteModal,
} from 'store/slices/notifications.settings';

import CustomButton from 'components/settings/Button.component';

function DeleteNotification({ conditionToDelete }) {
  const { t } = useTranslation(['settings']);

  const dispatch = useDispatch();
  const openDeleteModal = useSelector(selectNotifSetOpenDeleteModal);
  const isCreating = useSelector(selectNotifSetIsConditionCreating);

  const handleCloseDelete = () => {
    dispatch(closeDeleteConditionModal());
  };

  const handleDeteleConfirm = async () => {
    await dispatch(deleteCondition({ condition: conditionToDelete, translate: t }));
    await dispatch(getConditions());
    handleCloseDelete();
  };

  return (
    <Dialog
      open={openDeleteModal}
      onClose={handleCloseDelete}
      scroll="paper"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle id="scroll-dialog-title">
        {t('deleteConditionTitle')}
      </DialogTitle>
      <DialogContent>
        <p>
          {t('deleteConditionText')}
        </p>
      </DialogContent>
      <DialogActions>
        <CustomButton disabled={isCreating} onClick={handleCloseDelete} color="primary">
          {t('cancelBtn')}
        </CustomButton>
        <CustomButton disabled={isCreating} onClick={handleDeteleConfirm} color="primary">
          {isCreating ? <CircularProgress size={26} /> : null}
          {t('deleteBtn')}
        </CustomButton>
      </DialogActions>
    </Dialog>
  );
}

export default DeleteNotification;
