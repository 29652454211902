import { makeStyles } from 'tss-react/mui';
import 'assets/fonts/Roboto/RobotoMono.css';

const useStyles = makeStyles()((materialTheme) => ({
  history: {
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    textAlign: 'left',
    background: materialTheme.CustomStyles.MainBackgroundColor,
  },
  controlWrapper: {
    width: '207px',
    minWidth: '207px',
    marginRight: '2vw',
    textAlign: 'left',
    marginLeft: '20px',
    marginBottom: '20px',
  },
  selectorWrapp: {
    width: '207px',
    marginRight: '2vw',
  },
  selector: {
    width: '207px',
    height: '36px',
    borderRadius: '4px',
    backgroundColor: '#F3F6F8',
    padding: '0 15px',
  },
  selectorObject: {
    marginRight: '10px',
  },
  noneContainer: {
    margin: '0 auto',
    // display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    textAlign: 'left',
    background: materialTheme.CustomStyles.MainBackgroundColor,
  },
  mapControls: {
    width: '100%',
    background: '#fff',
    height: 'calc(100vh - 80px)',
    display: 'flex',
    flexDirection: 'column',
  },
  progress: {
    width: '30px',
    height: '30px',
  },
  speedControls: {
    marginRight: '10px',
    float: 'left',
  },
  map_container: {
    width: 'calc(100vw)',
    [materialTheme.breakpoints.down('800')]: {
      width: '100vw',
    },
    height: 'calc(100vh - 80px)',
    display: 'block',
    margin: '0 auto',
    '&::backdrop': {
      background: '#fafafa',
    },
  },
  switch_wrapper: {
    marginBottom: '15px',
    display: 'flex',
  },
  swich_element: {
    marginLeft: '9px',
  },
  description: {
    flexWrap: 'wrap',
    width: 'fitContent',
    display: 'flex',
    alignItems: 'center',
    lineHeight: '15px',
    flexDirection: 'row',
    whiteSpace: 'normal',
  },
  descriptionTime: {
    width: '125px',
    lineHeight: '1px',
    textOverflow: 'clip',
    whiteSpace: 'nowrap',
  },
  btnEvent: {
    width: '30px',
    height: '30px',
    borderRadius: '5px',
    border: '1px solid rgba(0, 0, 0, 0.2)',
    backgroundColor: '#ffffff',
    backgroundPosition: '50% 50%',
    backgroundRepeat: 'no-repeat',
    lineHeight: '0.5',
  },
  btn: {
    width: '206px',
    height: '36px',
    minWidth: '140px',
    // backgroundColor: '#30AAD9',
    borderRadius: '4px',
    color: '#fff',
    marginLeft: '20px',
    marginTop: '20px',
  },
  historyDate: {
    marginLeft: '20px',
  },
  historyInput: {
    marginRight: 0,
    marginLeft: '20px',
    backgroundColor: '#f8f8f8',
    width: '207px',
  },
  objectsCount: {
    marginLeft: '15px',
    fontSize: '16px',
  },
}));

export default useStyles;
