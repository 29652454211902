export default {
  colors: {
    primary: {
      main: '#559B27',
      light: '#D2EFFB',
      dark: '#002884',
      contrastText: '#fff',
    },
    secondary: {
      main: '#4badd4',
    },
    error: {
      main: '#f57c00',
    },
    warning: {
      main: '#ffa726',
    },
    info: {
      main: '#335df4',
    },
    success: {
      main: '#559B27',
    },
  },
  company_name: 'Navigine',
  images: {
    favicon: '/assets/images/favicons/default/favicon.ico',
    logo_mini: '/assets/img/navigine_logo_half.svg',
    logo: '/assets/images/welcomeLogo/prod/logo.svg',
  },
  resources: {
    webpage: 'https://navigine.com/blog',
    facebook: 'https://www.facebook.com/navigine',
    linkedin: 'http://www.linkedin.com/company/3363029?trk=prof-e',
    twitter: 'https://twitter.com/Navigine',
    github: 'https://github.com/Navigine',
    docs: 'https://docs.navigine.com',
    android_market: 'https://play.google.com/store/apps/details?id=com.navigine.navigine&hl=en',
    ios_market: 'https://itunes.apple.com/ru/app/navigine/id972099798?l=en&mt=8',
    android_sdk: 'https://github.com/Navigine/Indoor-Navigation-Android-Mobile-SDK-2.0',
    ios_sdk: 'https://github.com/Navigine/Indoor-Navigation-iOS-Mobile-SDK-2.0',
    privacyPolicy: 'https://navigine.com/privacy/',
  },
  fonts: {},
  feedback_form: true,
};
