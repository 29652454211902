import { Bar } from 'react-chartjs-2';
import React from 'react';
import 'chart.js/auto';
import { colorsChartArray } from 'constans';

function BarChart({ zoneNames, countZones }) {
  const state = {
    labels: zoneNames.map((i) => i.name),
    datasets: [
      {
        backgroundColor: colorsChartArray,
        borderColor: 'rgba(0,0,0,1)',
        borderWidth: 0.3,
        data: countZones.sort((a, b) => a - b),
      },
    ],
  };
  return (
    <div>
      <Bar
        width={100}
        height={400}
        data={state}
        options={{
          plugins: {
            legend: {
              display: false,
            },
          },
          type: 'stack',
          indexAxis: 'y',
          maintainAspectRatio: false,
          legend: {
            display: true,
            position: 'right',
          },
          scales: {
            x: {
              title: {
                display: true,
              },
              min: 0,
              // max: countZones.sort((a, b) => b - a)[0] + 10,
              ticks: {
                stepSize: 10,
              },
            },
          },
        }}
      />
    </div>
  );
}

export default BarChart;
