import mqtt from 'mqtt';
import React, { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';
import { appFetch } from 'store/actions/app';
import {
  addNewNotification,
  getNotifications,
} from 'store/actions/notifications';
import { objectsFetch } from 'store/actions/objects';
import { userFetchIfNeeded } from 'store/actions/user';
import {
  selectNotificationsConnectedMQTT,
  selectNotificationsSubbedMQTT,
  setConnectedMqtt, setSubbedToMqtt, updateNotificationsPeriod,
} from 'store/slices/notifications';
import { selectWorkerObjectTTL, selectWorkerUpdatePeriod } from 'store/slices/worker';

import checkObjectInWindowConfig from 'helpers/checkEnv';
import init from 'helpers/init';
import { createAlert } from 'store/slices/alert';
import { selectAppCurrentApp, selectAppIsFetching } from 'store/slices/app';
import Loading from './Loading';

const MQTT_TOPIC = 'tracking/notifications';

function PrivateComponent({
  children,
}) {
  const { t } = useTranslation(['notifications']);
  const dispatch = useDispatch();
  const app = useSelector(selectAppCurrentApp);
  const isFetchingApps = useSelector(selectAppIsFetching);
  const connectedMQTT = useSelector(selectNotificationsConnectedMQTT);
  const subbedMQTT = useSelector(selectNotificationsSubbedMQTT);
  const updatePeriod = useSelector(selectWorkerUpdatePeriod);
  const objectTTL = useSelector(selectWorkerObjectTTL);
  const socket = useRef();

  useEffect(() => {
    async function fetchData() {
      const doNeedToFetch = await dispatch(userFetchIfNeeded());
      if (doNeedToFetch) {
        await dispatch(appFetch());
        await init(dispatch);
      }
    }
    fetchData();
  }, [dispatch]);

  const getObjects = async () => {
    dispatch(updateNotificationsPeriod());
    await dispatch(objectsFetch());
  };

  const getNotificationsInterval = async () => {
    await dispatch(getNotifications(t));
  };

  useEffect(() => {
    const intervalId = setInterval(getObjects, updatePeriod);
    getObjects();

    return () => clearInterval(intervalId);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatePeriod]);

  // continue polling notifications unless or until socket connection is established
  useEffect(() => {
    let intervalId;
    if (!subbedMQTT || !connectedMQTT) {
      intervalId = setInterval(getNotificationsInterval, updatePeriod);
    }
    if (subbedMQTT && connectedMQTT && intervalId) {
      clearInterval(intervalId);
    }

    return () => {
      if (intervalId) { clearInterval(intervalId); }
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatePeriod, subbedMQTT, connectedMQTT]);

  const handleMqttNotificationMessage = useCallback((_topic, message) => {
    dispatch(addNewNotification(message));
  }, [dispatch]);

  const handleSubscription = useCallback((error) => {
    if (!error) {
      dispatch(setSubbedToMqtt(true));
    } else {
      dispatch(setSubbedToMqtt(false));
    }
  }, [dispatch]);

  useEffect(() => {
    const connectToWebSocket = () => {
      const domain = checkObjectInWindowConfig('MQTT_DOMAIN');
      const login = checkObjectInWindowConfig('MQTT_LOGIN');
      const password = checkObjectInWindowConfig('MQTT_PASSWORD');
      const port = checkObjectInWindowConfig('MQTT_PORT');
      const mqttUrl = `wss://${login}:${password}@${domain}:${port}/ws`;
      socket.current = mqtt.connect(mqttUrl, {
        clientId: `TRACKING-FRONT${Math.random() * 1000}`,
        keepalive: 45,
      });
    };
    if (!socket.current && app.id) {
      connectToWebSocket();
    }
    let reconnectAttempts = 0;
    if (app.id && socket.current) {
      socket.current.on('close', () => {
        reconnectAttempts += 1;
        if (reconnectAttempts >= 5) {
          socket.current.end();
          dispatch(setConnectedMqtt(false));
          dispatch(setSubbedToMqtt(false));
        }
      });
      socket.current.subscribe(`${MQTT_TOPIC}/${app.id}`, handleSubscription);
      socket.current.on('message', handleMqttNotificationMessage);
      socket.current.on('connect', () => {
        dispatch(setConnectedMqtt(true));
      });
      socket.current.on('disconnect', () => {
        dispatch(setConnectedMqtt(false));
      });
    }
    return () => {
      if (socket.current) {
        socket.current.unsubscribe(`${MQTT_TOPIC}/${app.id}`);
        socket.current.removeListener('message', handleMqttNotificationMessage);
        dispatch(setConnectedMqtt(false));
        dispatch(setSubbedToMqtt(false));
        socket.current.end();
        socket.current = null;
      }
    };
  }, [app?.id, dispatch, handleMqttNotificationMessage, handleSubscription]);

  useEffect(() => {
    dispatch(updateNotificationsPeriod());
    dispatch(objectsFetch())
      .then(async () => {
        await dispatch(getNotifications(t));
        getObjects();
      })
      .catch(() => {
        dispatch(createAlert({ messageType: 'error', message: 'Error in timer' }));
      });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [app?.api_key, app?.id, objectTTL, dispatch]);

  if (app?.id && !isFetchingApps) {
    return (
      children
    );
  }
  return <Loading />;
}

export default PrivateComponent;
