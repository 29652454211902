import { combineReducers } from '@reduxjs/toolkit';

import alertReducer from 'store/slices/alert';
import userReducer from 'store/slices/user';
import authReducer from 'store/slices/auth';
import appReducer from 'store/slices/app';
import debugReducer from 'store/slices/debug';
import devicesReducer from 'store/slices/devices';
import floorReducer from 'store/slices/floors';
import groupsReducer from 'store/slices/groups';
import historyReducer from 'store/slices/history';
import locationsReducer from 'store/slices/locations';
import notificationsReducer from 'store/slices/notifications';
import notificationsSettingsReducer from 'store/slices/notifications.settings';
import notificationsSocialReducer from 'store/slices/notifications.social';
import objectsReducer from 'store/slices/objects';
import reportsResucer from 'store/slices/reports';
import settingsReducer from 'store/slices/settings';
import workerReducer from 'store/slices/worker';
import zonesReducer from 'store/slices/zones';

const combinedReducer = combineReducers({
  user: userReducer,
  auth: authReducer,
  alert: alertReducer,
  app: appReducer,
  location: locationsReducer,
  floor: floorReducer,
  worker: workerReducer,
  objects: objectsReducer,
  zones: zonesReducer,
  groups: groupsReducer,
  reports: reportsResucer,
  settings: settingsReducer,
  devices: devicesReducer,
  notifications: notificationsReducer,
  history: historyReducer,
  notificationsSettings: notificationsSettingsReducer,
  notificationsSocial: notificationsSocialReducer,
  debug: debugReducer,
});

const rootReducer = (state, action) => {
  if (action.type === 'user/destroyStore') {
    // eslint-disable-next-line no-param-reassign
    state = {};
  }
  return combinedReducer(state, action);
};

export default rootReducer;
