import OpenLayersMap from '@navigine/openlayers-navigine';
// eslint-disable-next-line import/no-extraneous-dependencies
import { boundingExtent } from 'ol/extent';

function relativeCoordsToPixels(coords, map) {
  const imageLayer = map.mainLayer;
  const imageSource = imageLayer.getSource();
  const imageSize = imageSource.getImageExtent();
  const imageWidth = imageSize[2] - imageSize[0];
  const imageHeight = imageSize[3] - imageSize[1];
  const [minx, miny, maxx, maxy] = coords;
  const minPixelX = minx * imageWidth;
  const minPixelY = miny * imageHeight;
  const maxPixelX = maxx * imageWidth;
  const maxPixelY = maxy * imageHeight;

  return [
    [minPixelX, minPixelY],
    [minPixelX, maxPixelY],
    [maxPixelX, maxPixelY],
    [maxPixelX, minPixelY],
  ];
}

function zoomByCoords(map, coords, { duration = 600, maxZoom = 15 } = {}) {
  if (!(map instanceof OpenLayersMap) || !coords || !(coords instanceof Array)) {
    return;
  }
  const coordsInPixels = relativeCoordsToPixels(coords, map);
  const mapView = map?.map?.getView();
  if (!mapView || !coordsInPixels) {
    return;
  }

  const extent = boundingExtent(coordsInPixels);
  if (!extent) {
    return;
  }
  mapView.fit(extent, {
    size: map.map.getSize(),
    maxZoom,
    easing: duration,
  });
}

export default zoomByCoords;
