import * as THREE from 'three';

import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader';
import TextSprite from './classes/TextSprite';

import { initThreeD, initSky, addGround } from './init.3d';
import disposeSingleNode from './helpers/disposeSingleNode';

class ThreeDScene {
  constructor(sceneElement, antialias) {
    this.removableItems = [];
    this.trackList = {};
    this.trackHistory = {};
    this.pathLines = [];
    this.objModel = null;
    this.model = null;
    this.sceneBounds = null;
    this.antialias = antialias;

    const domElement = sceneElement.current;

    this.domElWidthPx = domElement.clientWidth;
    this.domElHeightPx = domElement.clientHeight;

    const {
      camera, scene, renderer, controls, interactionManager, id,
    } = initThreeD(domElement, this.domElWidthPx, this.domElHeightPx, this.antialias);

    this.camera = camera;
    this.scene = scene;
    this.renderer = renderer;
    this.interactionManager = interactionManager;
    this.controls = controls;
    this.id = id;
    this.TEXT_SCALING = 0.018;

    initSky(this.scene);
    addGround(this.scene);
    this.renderer.render(this.scene, this.camera);

    domElement.addEventListener('resize', this.onWindowResize.bind(this));
    window.addEventListener('resize', this.onWindowResize.bind(this));
  }

  static culcCoords(sceneBounds, kx, ky) {
    const diffX = sceneBounds.max.x - sceneBounds.min.x;
    const diffZ = sceneBounds.max.z - sceneBounds.min.z;
    const culcX = (sceneBounds.min.x + diffX * kx);
    const culcZ = (sceneBounds.min.z + diffZ * (1 - ky));
    return { culcX, culcZ };
  }

  static calculateDistanceToGroup(group, camera) {
    if (group instanceof THREE.Group && camera instanceof THREE.PerspectiveCamera) {
      const groupCenter = new THREE.Vector3();
      group.getWorldPosition(groupCenter);
      const distance = camera.position.distanceTo(groupCenter);
      return distance;
    }
    return null;
  }

  static textToObjectDistance(element, camera) {
    const exponent = 0.6;
    const zoomFactor = camera.zoom;
    const scaleFactor = zoomFactor ** exponent;
    const textSize = element.fontSize;
    return { x: 0, y: textSize, z: -1.5 * textSize * scaleFactor - 2 };
  }

  updateTextScaling(group, distance) {
    if (group instanceof THREE.Group) {
      group.children.forEach((child) => {
        if (child instanceof TextSprite) {
          // eslint-disable-next-line no-param-reassign
          child.fontSize = Math.max((distance * this.TEXT_SCALING), 1);
          const { x, y, z } = ThreeDScene.textToObjectDistance(child, this.camera);
          child.position.set(x, y, z);
        }
      });
    }
  }

  updateModelCoordinates(kx, ky, id, showTrackLines) {
    if (!this.sceneBounds) {
      return;
    }
    const { sceneBounds, trackList } = this;

    const { culcX, culcZ } = ThreeDScene.culcCoords(sceneBounds, kx, ky);

    // storing coordinates if showTrackLines is toggled
    if (!this.trackHistory[id]) {
      this.trackHistory[id] = [];
    } else if (showTrackLines) {
      this.trackHistory[id].push({ x: culcX, z: culcZ });
    } else {
      this.trackHistory[id].length = 0;
    }
    const distance = ThreeDScene.calculateDistanceToGroup(trackList[id], this.camera);
    this.updateTextScaling(trackList[id], distance);
    trackList[id].position.set(culcX, 0, culcZ);
    this.renderFn();
  }

  drawTrackLines() {
    const { trackHistory } = this;

    if (!trackHistory) {
      return;
    }
    const material = new THREE.LineBasicMaterial({ color: 0x0000ff });
    Object.keys(trackHistory).forEach((key) => {
      const points = trackHistory[key].map((obj) => new THREE.Vector3(obj.x, 0, obj.z));
      if (points.length > 1) {
        const lineGeometry = new THREE.BufferGeometry().setFromPoints([
          points[points.length - 2],
          points[points.length - 1],
        ]);
        const line = new THREE.Line(lineGeometry, material);
        this.scene.add(line);
        this.pathLines.push(line);
      }
    });
  }

  disposeTrackLines() {
    if (this.pathLines.length > 0) {
      this.pathLines.forEach((line) => {
        line.geometry.dispose();
        line.material.dispose();
        this.scene.remove(line);
      });
      this.pathLines.length = 0;
    }
  }

  onWindowResize() {
    if (this.camera) {
      this.camera.aspect = this.domElWidthPx / this.domElHeightPx;
      this.camera.updateProjectionMatrix();
    }
    this.renderer.setSize(this.domElWidthPx, this.domElHeightPx);
  }

  renderFn() {
    if (!this.renderer) {
      return;
    }
    this.renderer.render(this.scene, this.camera);
    this.interactionManager.update();
  }

  lookFromAbove(id) {
    const { height, distance, center } = this.getCenterOfObject(id);
    if (!height || !distance || !center) {
      return;
    }

    this.camera.position.set(center.x, center.y + distance, center.z);

    this.camera.up.set(0, 1, 0);
    this.camera.lookAt(new THREE.Vector3(center.x, center.y + distance - 1, center.z));
  }

  lookFromAboveObject(object) {
    const { camera } = this;
    if (!object || !object.attributes || !object.attributes.kx || !object.attributes.ky) {
      return;
    }
    const { kx, ky } = object.attributes;
    const { culcX, culcZ } = ThreeDScene.culcCoords(this.sceneBounds, kx, ky);
    const CAMERA_HEIGHT = 75;
    camera.position.set(culcX, CAMERA_HEIGHT, culcZ);
    camera.up.set(0, 1, 0);
    camera.lookAt(new THREE.Vector3(culcX, CAMERA_HEIGHT - 1, culcZ));
  }

  async downloadMapModel(modelUrl, setProgress, draco = false) {
    return new Promise((resolve, reject) => {
      const gltfLoader = new GLTFLoader();
      if (draco) {
        const dracoLoader = new DRACOLoader();
        dracoLoader.setDecoderPath('https://www.gstatic.com/draco/v1/decoders/');
        gltfLoader.setDRACOLoader(dracoLoader);
      }
      gltfLoader.load(
        modelUrl,
        (gltf) => {
          this.model = gltf.scene;
          this.scene.add(this.model);
          this.sceneBounds = new THREE.Box3().setFromObject(this.model);
          const { max, min } = this.sceneBounds;
          const boxSize = this.sceneBounds.getSize(new THREE.Vector3());
          const maxDimension = Math.max(boxSize.x, boxSize.y, boxSize.z);
          const distance = (maxDimension / (2 * Math.tan((this.camera.fov * Math.PI) / 360)));
          const center = this.sceneBounds.getCenter(new THREE.Vector3());

          this.camera.position.set((max.x + min.x) / 2, center.y + distance, (max.z - min.z) / 2);
          this.camera.lookAt(new THREE.Vector3(
            (max.x + min.x) / 2,
            center.y + distance - 1,
            (max.z - min.z) / 2,
          ));
          this.removableItems.push(boxSize);
          resolve();
        },
        (xhr) => {
          setProgress((xhr.loaded / xhr.total) * 100);
        },
        async (error) => {
          if ('message' in error && error.message.includes('DRACOLoader')) {
            setProgress(() => 0);
            await this.downloadMapModel(modelUrl, setProgress, true);
            resolve();
          }
          reject(error);
        },
      );
    });
  }

  addObjectModel(id, kx, ky, title) {
    if (!this.objModel) {
      return null;
    }
    const model = this.objModel.clone();
    model.objectId = id;

    const { culcX, culcZ } = ThreeDScene.culcCoords(this.sceneBounds, kx, ky);

    model.position.set(culcX, culcZ);
    this.scene.add(model);
    this.interactionManager.add(model);
    this.trackList[id] = model;
    model.traverse((child) => {
      if (child.children.length === 0) {
        if (child.material) {
          // eslint-disable-next-line no-param-reassign
          child.material = child.material.clone();
          // eslint-disable-next-line no-param-reassign
          child.userData.initialEmissive = child.material.emissive.clone();
          // eslint-disable-next-line no-param-reassign
          child.material.emissiveIntensity = 0.5;
        }
        if (this.removeableItems) {
          this.removableItems.push(child);
        }
      }
    });
    const distance = ThreeDScene.calculateDistanceToGroup(model, this.camera);
    const textSize = distance * this.TEXT_SCALING;
    const text = ThreeDScene.createTextShape(title, textSize);
    const { x, y, z } = ThreeDScene.textToObjectDistance(text, this.camera);
    text.position.set(x, y, z);
    text.renderOrder = 5;
    model.add(text);
    this.removableItems.push(text);
    return model;
  }

  static createTextShape(text, size) {
    const instance = new TextSprite({
      alignment: 'center',
      backgroundColor: 'white',
      color: 'black',
      fontWeight: 'bold',
      fontSize: size,
      text,
    });
    return instance;
  }

  highlightZone(zone) {
    const { sceneBounds } = this;

    if (!sceneBounds) {
      return;
    }

    const shape = new THREE.Shape();

    let firstPoint = {};
    zone.points.forEach(({ kx, ky }, i, arr) => {
      const diffX = sceneBounds.max.x - sceneBounds.min.x;
      const diffZ = sceneBounds.max.z - sceneBounds.min.z;
      const culcX = (sceneBounds.min.x + diffX * kx);
      const culcZ = (sceneBounds.min.z + diffZ * (1 - ky));

      if (i === 0) {
        shape.moveTo(culcX, culcZ);
        firstPoint = { culcX, culcZ };
      } else {
        shape.lineTo(culcX, culcZ);
      }

      if (i === arr.length - 1) {
        shape.lineTo(firstPoint.culcX, firstPoint.culcZ);
      }
    });

    const geometry = new THREE.ExtrudeGeometry(shape, {
      depth: -16,
    });
    geometry.rotateX(Math.PI * 0.5);
    const material = new THREE.MeshBasicMaterial({
      color: zone.color,
      opacity: 0.45,
      transparent: true,
      depthTest: false,
    });
    const mesh = new THREE.Mesh(geometry, material);
    mesh.name = zone.id;
    const findMesh = this.scene.getObjectByName(zone.id);
    if (!findMesh) {
      this.scene.add(mesh);
    }
    this.removableItems.push(shape, geometry, material, mesh);
  }

  removeHighlightZone(zone) {
    const findMesh = this.scene.getObjectByName(zone.id);
    if (findMesh) {
      this.scene.remove(findMesh);
    }
  }

  async downloadObjectModel(url, setProgress) {
    return new Promise((resolve, reject) => {
      new GLTFLoader().load(
        url,
        (gltf) => {
          this.objModel = gltf.scene;
          resolve();
        },
        (xhr) => {
          setProgress((xhr.loaded / xhr.total) * 100);
        },
        (error) => {
          reject(error);
        },
      );
    });
  }

  setDefaultCameraPosition(inputScene = this.scene) {
    if (!this.sceneBounds) {
      return;
    }
    const boundingBox = new THREE.Box3().setFromObject(inputScene);
    const boxSize = boundingBox.getSize(new THREE.Vector3());
    const height = boxSize.y;
    const maxDimension = Math.max(boxSize.x, boxSize.y, boxSize.z);
    const distance = (maxDimension / (2 * Math.tan((this.camera.fov * Math.PI) / 360)));
    const center = boundingBox.getCenter(new THREE.Vector3());

    this.camera.position.set(center.x, center.y + distance, center.z);
    this.camera.fov = (2 * Math.atan((height / 2 + distance) / distance) * 180) / Math.PI;
    this.camera.up.set(0, 1, 0);
    this.camera.lookAt(new THREE.Vector3(center.x, center.y + distance - 1, center.z));
    this.removableItems.push(boundingBox);
  }

  getCenterOfObject(objName) {
    const feature = this.scene.getObjectByName(objName);
    if (!feature) {
      return { height: null, distance: null, center: null };
    }
    const boundingBox = new THREE.Box3().setFromObject(feature);
    const boxSize = boundingBox.getSize(new THREE.Vector3());
    const height = boxSize.y;
    const maxDimension = Math.max(boxSize.x, boxSize.y, boxSize.z);
    const distance = 2 * (maxDimension / (2 * Math.tan((this.camera.fov * Math.PI) / 360)));
    const center = boundingBox.getCenter(new THREE.Vector3());
    this.removableItems.push(boundingBox);
    return { height, distance, center };
  }

  destroy() {
    cancelAnimationFrame(this.id);
    this.renderer.domElement.addEventListener('dblclick', null, false);

    this.removableItems.forEach((item) => {
      disposeSingleNode(item);
    });
    this.scene.remove(this.model);
    this.camera = null;
    this.controls = null;
    this.sceneBounds = null;
    this.model.traverse(disposeSingleNode);
    this.objModel.traverse(disposeSingleNode);
    this.objModel = null;
    this.model = null;
    THREE.Cache.clear();
    this.renderer.renderLists.dispose();
  }
}

export default ThreeDScene;
