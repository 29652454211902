import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import TextField from '@mui/material/TextField';
import { makeStyles } from 'tss-react/mui';
import CustomAutocomplete from 'components/reports/CustomAutocomplete.component';
import dropIconArrow from 'assets/images/chevron-down.svg';
import { sortObjectByObjectTitle } from 'helpers/sortByField';

const useStyles = makeStyles()({
  wrapper: {
    paddingTop: '1vh',
    width: '100%',
  },
  title: {
    color: '#8194A4',
    fontSize: '12px',
    fontWeight: '600',
    marginBottom: '4px',
  },
  popupIndicator: {
    '& span': {
      '& svg': {
        '& path': {
          d: "path('M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z')",
        },
      },
    },
  },
});

function AutoComplete(props) {
  const { classes } = useStyles();
  const {
    filteredObjectsArray,
    currentObject,
    selectObject,
    onAutocompliteInput,
    bkgColor,
  } = props;
  const { t } = useTranslation(['monitoring']);
  const getOptionLabel = (option) => option.attributes.title;
  const getOptionSelected = (option, value) => {
    if (!option || !value) {
      return false;
    }
    return +option.id === +value.id;
  };
  // eslint-disable-next-line
  const sortedObject = sortObjectByObjectTitle('desc', structuredClone(filteredObjectsArray));
  return (
    <div className={classes.wrapper}>
      <div className={classes.title}>{t('object')}</div>
      <CustomAutocomplete
        id="objects"
        options={sortedObject}
        value={currentObject}
        onChange={selectObject}
        onInputChange={onAutocompliteInput}
        getOptionLabel={getOptionLabel}
        noOptionsText={t('noObjects')}
        isOptionEqualToValue={getOptionSelected}
        style={{ backgroundColor: bkgColor }}
        popupIcon={<img src={dropIconArrow} alt="ArrowDown" />}
        renderOption={(listProps, option) => (
          // key after props spread to re-write the key
          // eslint-disable-next-line react/jsx-props-no-spreading
          <li {...listProps} key={option.id}>
            {option.attributes.title}
          </li>
        )}
        renderInput={(params) => (
          <TextField
            variant="outlined"
            type="text"
            placeholder={t('autoCompletePlaceholder')}
            style={{ borderRadius: '4px' }}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...params}
          />
        )}
      />
    </div>
  );
}

export default memo(AutoComplete);
