import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Button, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

import { objectsFetchByFloor } from 'store/actions/objects';
import { fetchActivityReport } from 'store/actions/reports';
import { selectCurrentLocation, selectLocationsObject, setLocation } from 'store/slices/locations';
import { selectCurrentFloor, selectFloorsObject, setFloor } from 'store/slices/floors';
import { selectObjectsByFloor } from 'store/slices/objects';
import {
  selectReportsActivityReportArray, selectReportsCurrentObject, selectReportsDateFrom,
  selectReportsDateTo, selectReportsIsFetching, setActivityReport,
  setCurrentObject,
} from 'store/slices/reports';

import DatePickers from 'components/reports/datePickers/datePickers.component';
import { selectAppOpenMenu } from 'store/slices/app';
import LocationSelector from '../ReportsLocationSelector';
import FloorSelector from '../ReportsFloorSelector';
import LineChart from './Chart';
import AutoComplete from '../ReportsAutoComplete';
import useStyles from './objectReport.component.style';
import containerStyles from '../../shared/Container/styles';

function ActivityReport() {
  const { classes: classesContainer } = containerStyles();
  const { classes } = useStyles();
  const { t } = useTranslation(['report']);

  const dispatch = useDispatch();
  const openMenu = useSelector(selectAppOpenMenu);
  const floorsObject = useSelector(selectFloorsObject);
  const currentFloor = useSelector(selectCurrentFloor);
  const locationsObject = useSelector(selectLocationsObject);
  const currentLocation = useSelector(selectCurrentLocation);
  const objectsByFloor = useSelector(selectObjectsByFloor);
  const currentObject = useSelector(selectReportsCurrentObject);
  const dateFrom = useSelector(selectReportsDateFrom);
  const dateTo = useSelector(selectReportsDateTo);
  const isFetching = useSelector(selectReportsIsFetching);
  const activityReportArray = useSelector(selectReportsActivityReportArray);

  // eslint-disable-next-line no-unused-vars
  const [objectsFilter, setObjectsFilter] = useState('');
  const [btnDisabledByDate, setBtnDisabledByDate] = useState(true);

  const fetchContactReportsClick = async () => {
    if (currentObject) {
      await dispatch(fetchActivityReport([currentObject]));
    } else {
      await dispatch(fetchActivityReport(objectsByFloor));
    }
  };

  const selectObject = (event, object) => {
    dispatch(setActivityReport([]));
    dispatch(setCurrentObject(object));
  };

  useEffect(() => {
    dispatch(objectsFetchByFloor(currentFloor.id));
  }, [currentFloor, dispatch]);

  useEffect(() => {
    if (new Date(Date.now()) > dateFrom && dateTo > dateFrom) {
      setBtnDisabledByDate(false);
    } else {
      setBtnDisabledByDate(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateFrom, dateTo]);

  useEffect(() => {
    dispatch(setActivityReport([]));
  }, [dateFrom, dateTo, dispatch]);

  const onAutocompliteInput = async (value) => {
    setObjectsFilter(value);
  };
  const handleChangeLocation = (event) => {
    const locationId = event.target.value;
    dispatch(setActivityReport([]));
    dispatch(setLocation(locationsObject[locationId]));
    const floorId = locationsObject[locationId].floors[0].id;
    dispatch(setFloor(floorsObject[floorId]));
  };

  const handleChangeFloor = (event) => {
    dispatch(setActivityReport([]));
    const floorId = event.target.value;
    dispatch(setFloor(floorsObject[floorId]));
  };

  const hourList = [];
  const currentDate = new Date(dateFrom);

  while (currentDate <= dateTo) {
    const currentHour = currentDate.getHours();
    hourList.push(currentHour);
    currentDate.setTime(currentDate.getTime() + (1 * 60 * 60 * 1000));
  }
  hourList.pop();

  function calculateTimePeriod(enterTimestamp, exitTimestamp) {
    const enterDate = new Date(enterTimestamp);
    const exitDate = new Date(exitTimestamp);

    const timeDifference = exitDate - enterDate;

    const hours = Math.floor(timeDifference / (1000 * 60 * 60));
    const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

    return {
      hours,
      minutes,
      seconds,
    };
  }

  function calculateTimeToPixels(period, pixelsPerSecond) {
    const { hours, minutes, seconds } = period;

    const totalSeconds = hours * 3600 + minutes * 60 + seconds;

    const totalPixels = totalSeconds * pixelsPerSecond;

    return totalPixels;
  }
  const floorsObjectFiltred = Object.values(floorsObject)
    .filter((object) => object.location === currentLocation.id);
  return (
    <div className={!openMenu ? classes.content : classesContainer.noneContent}>
      <div className={classes.topControls}>
        <div className={classes.formControls}>
          <form className={classes.formControls} noValidate>
            <div className={classes.selectorWrapp}>
              <LocationSelector
                locations={locationsObject}
                className={classes.selector}
                value={currentLocation.id}
                onChange={handleChangeLocation}
              />
            </div>
            <div className={classes.selectorWrapp}>
              <FloorSelector
                floors={floorsObjectFiltred}
                className={classes.selector}
                onChange={handleChangeFloor}
                value={currentFloor.id}
                currentLocationId={currentLocation.id}
              />
            </div>
            <DatePickers />
            <div className={classes.selectorWrapp}>
              <AutoComplete
                filteredObjectsArray={objectsByFloor}
                currentObject={currentObject}
                selectObject={selectObject}
                onInputChange={onAutocompliteInput}
                bkgColor="#ffffff"
              />
            </div>
            <Button
              variant="contained"
              color="primary"
              className={classes.btn}
              onClick={fetchContactReportsClick}
              disabled={btnDisabledByDate}
            >
              {t('BUILD')}
            </Button>
          </form>
        </div>
      </div>
      {isFetching
        ? (
          <div className={classes.spinerWrapper}>
            <CircularProgress size={26} />
          </div>
        )
        : (
          <div>
            <div className={classes.headContainer}>
              <Typography variant="h6">{t('ActivityReport')}</Typography>

            </div>
            <div className={classes.table}>
              <TableContainer className={classes.tableContainer} component={Paper}>
                <Table stickyHeader className={classes.table} aria-label="simple table">
                  <TableBody>
                    <TableRow>
                      <TableCell className={classes.tableCell}>
                        {t('Object')}
                      </TableCell>
                      {hourList.map((hour, index) => (
                        <TableCell
                        // eslint-disable-next-line react/no-array-index-key
                          key={`${hour}_${index}`}
                          style={{ width: hourList.length === 1 ? '540px' : '180px' }}
                          className={classes.tableCellToPeriod}
                          align="right"
                        >
                          {hour}
                        </TableCell>
                      ))}
                      <TableCell className={classes.tableCellToZones} align="right">{t('TimeSpentInZones')}</TableCell>
                      <TableCell className={classes.tableCellToZones} align="right">{t('TotalEnteriesInZones')}</TableCell>
                      <TableCell className={classes.tableCellToZones} align="right">{t('TotalExitsFromZones')}</TableCell>
                      <TableCell className={classes.tableCellToZones} align="right">{t('CrossZoneMovements')}</TableCell>
                      <TableCell className={classes.tableCellToZones} align="right">{t('TotalDistanceTraveledM')}</TableCell>
                    </TableRow>
                    {activityReportArray
                      .map((
                        objectReport,
                      ) => (
                        <TableRow key={objectReport.object_id}>
                          <TableCell className={classes.tableCell} align="right">
                            {objectReport.object_title || objectReport.object_id}
                          </TableCell>
                          <TableCell className={classes.tableCellToZones} align="right" colSpan={hourList.length}>

                            <LineChart
                              style={{ heigth: '100px' }}
                              eventData={objectReport.events}
                              hours={hourList}
                              data={objectReport.velocity}
                            />

                            <div style={{
                              display: 'flex', position: 'relative', bottom: '-1px',
                            }}
                            >
                              {objectReport.zone_entries.map((zone) => {
                                const period = calculateTimePeriod(zone.enter, zone.exit);
                                let pixelsPerSecond = 1 / 20;
                                if (hourList.length === 1) {
                                  pixelsPerSecond = 1 / 6.75;
                                }
                                const pixels = calculateTimeToPixels(period, pixelsPerSecond);
                                return (
                                  <div style={{
                                    overflow: 'hidden',
                                    backgroundColor: zone.zone_id ? zone.color : 'white',
                                    width: pixels,
                                    height: '20px',
                                    textAlign: 'left',
                                  }}
                                  >
                                    {
                                      // eslint-disable-next-line no-nested-ternary
                                      zone.color ? pixels > 30 ? 'zone' || '' : '\u00A0' : ''
                                      }
                                  </div>
                                );
                              })}
                            </div>
                          </TableCell>
                          {/* ))} */}
                          <TableCell className={classes.tableCellToZones} align="right">
                            {objectReport.total.time}
                          </TableCell>
                          <TableCell className={classes.tableCellToZones} align="right">
                            {objectReport.total.entries}
                          </TableCell>
                          <TableCell className={classes.tableCellToZones} align="right">
                            {objectReport.total.exits}
                          </TableCell>
                          <TableCell className={classes.tableCellToZones} align="right">
                            {objectReport.total.cross}
                          </TableCell>
                          <TableCell className={classes.tableCellToZones} align="right">
                            {Math.ceil(objectReport.total.distance)}
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
        )}
    </div>
  );
}

export default ActivityReport;
