import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((materialTheme) => ({
  content: {
    width: 'calc(100vw - 30vh)',
    height: 'calc(100vh - 80px)',
    display: 'flex',
    flexDirection: 'column',
    padding: '0 45px 0 32px',
    overflowY: 'auto',
    [materialTheme.breakpoints.down('800')]: {
      width: '90%',
      marginLeft: '-1vw',
    },
  },
  table: {
    width: '100%',
  },
  topControls: {
    display: 'flex',
    flexDirection: 'row',
    margin: '26px 0px',
  },
  spinerWrapper: {
    width: '100%',
    maxHeight: '60vh',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  idTitle: {
    color: 'grey',
    fontSize: '0.9rem',
  },
  tableCell: {
    borderBottom: '1px  solid #f4f4f4',
    padding: '10px 30px',
  },
  tableContainer: {
    boxShadow: '0px 4px 30px rgba(2, 0, 120, 0.03)',
    borderRadius: '12px',
  },
  createBtn: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '10px 16px 10px 10px',
    margin: '0px 10px 0px 0px',
    height: '40px',
    borderRadius: '8px',
    fontSize: '14px',
    fontWeight: '400',
    '& span': {
      marginTop: '0.1rem',
    },
    '& .MuiSvgIcon-root': {
      fontSize: '1.2rem',
      marginBottom: '0.1rem',
    },
  },
  inputComponent: {
    height: '35px',
    padding: '0 5px 0 5px',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    borderRadius: '8px',
  },
  formControl: {
    width: '100%',
  },
  tabLabel: {
    width: '33%',
  },
  dialogTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 10px',
    borderBottom: '1px solid rgba(0, 0, 0, 0.04)',
    color: 'black',
  },
  dialogTitleText: {
    display: 'block',
    marginBlockStart: '1em',
    marginBlockEnd: '1em',
    fontWeight: 'bold',
    marginBottom: '0.5em',
  },
  customBtn: {
    width: '90%',
    background: materialTheme.palette.primary.main,
    color: 'white',
    '&:disabled': {
      background: '#D7D7D7',
      color: '#fff',
    },
    '&:hover': {
      cursor: 'pointer',
      background: materialTheme.palette.primary.main,
    },
  },
  dialogActions: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    // padding: '0 10px',
    borderBottom: '1px solid rgba(0, 0, 0, 0.04)',
    color: 'black',
  },
  actionButton: {
    width: '45px',
    height: '24px',
    marginRight: '5px',
    marginLeft: '5px',
    borderRadius: '47px',
    cursor: 'pointer',
    background: 'rgb(233, 237, 239)',
    '&:hover': { background: 'rgb(223, 227, 229)' },
    '&:active': { background: 'rgb(213, 217, 219)' },
  },
}));

export default useStyles;
