import React from 'react';
import CustomAutocomplete from 'components/reports/CustomAutocomplete.component';
import TextField from '@mui/material/TextField';

export default function AutoComplete(props) {
  const {
    optionsArray,
    currentItem,
    selectItem,
    onAutocompleteInput,
    placeholderText,
  } = props;
  const getOptionLabel = (option) => option.title;
  const getOptionSelected = (option, value) => option.id === value.id;

  return (
    <CustomAutocomplete
      options={optionsArray}
      value={currentItem}
      onChange={selectItem}
      onInputChange={onAutocompleteInput}
      getOptionLabel={getOptionLabel}
      isOptionEqualToValue={getOptionSelected}
      renderInput={(params) => (
        <TextField
          inputProps={{ disableUnderline: true }}
          variant="outlined"
          type="text"
          placeholder={placeholderText}
        // eslint-disable-next-line react/jsx-props-no-spreading
          {...params}
        />
      )}
    />
  );
}
