import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { debounce } from 'lodash';

import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import Button from '@mui/material/Button';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import RefreshIcon from '@mui/icons-material/Refresh';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

import CustomTextField from 'components/settings/TextFiled';

import { sortObjectByStringKey } from 'helpers/sortByField';
import {
  getDebugDevices, getDeviceData,
} from 'store/actions/debug';
import { selectAppAppsObject, selectAppOpenMenu } from 'store/slices/app';
import {
  selectDebugDebugDevices, selectDebugFetchingDebugList,
  setDebugDevices, setDeviceExpanded, setFetchingDebugList,
} from 'store/slices/debug';

import PaginationIcon from 'components/shared/Pagination/PaginationIcon';
import useStyles from 'components/shared/settings/DebugStyles';
import containerStyles from 'components/shared/Container/styles';
import AutoCompleteDebug from 'components/shared/AutoComplete/AutoCompleteDebug';
import { selectLocationsObject } from 'store/slices/locations';
import CustomTableHeader from 'components/shared/Tables/Header';

function DebugDevices() {
  const dispatch = useDispatch();
  const openMenu = useSelector(selectAppOpenMenu);
  const fetchingDebugList = useSelector(selectDebugFetchingDebugList);
  const debugDevices = useSelector(selectDebugDebugDevices);
  const apps = useSelector(selectAppAppsObject);
  const appKeys = Object.keys(apps);
  const locations = useSelector(selectLocationsObject);
  const locationKeys = Object.keys(locations);

  const { t } = useTranslation(['settings']);
  const { classes } = useStyles();
  const { classes: classesContainer } = containerStyles();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [filter, setFilter] = useState('');
  const [appFilter, setAppFilter] = useState(null);
  const [locationFilter, setLocationFilter] = useState(null);

  const [sort, setSort] = useState({
    mac_address: 'none',
    bind_location_id: 'none',
    bind_sublocation_id: 'none',
    tracked_object_id: 'none',
    application_id: 'none',
  });

  const [shownLogMessage, setShownLogMessages] = useState({});

  useEffect(() => {
    if (debugDevices) {
      const transformedObject = debugDevices.reduce((result, item) => {
        if (item) {
          const id = `${item.mac_address}${item.bind_location_id}${item.bind_sublocation_id}`;
          // eslint-disable-next-line no-param-reassign
          result[id] = item.extra ? item.extra.log_messages.slice(0, 20) : null;
        }
        return result;
      }, {});
      setShownLogMessages(transformedObject);
    }
  }, [debugDevices]);

  useEffect(() => {
    dispatch(getDebugDevices());
  }, [dispatch]);

  function expandData(device) {
    if (device && typeof device.id === 'number') {
      dispatch(setDeviceExpanded(device.id));
      if (!device.expanded) {
        dispatch(getDeviceData(device));
      }
    }
  }

  const handleSearch = useCallback((value) => {
    dispatch(setFetchingDebugList(true));
    dispatch(getDebugDevices(value));
  }, [dispatch]);

  const debouncedSendRequest = useMemo(() => debounce(handleSearch, 300), [handleSearch]);

  function handleChangeFilter(event) {
    setFilter(event.target.value);
    debouncedSendRequest(event.target.value);
  }

  function showMore(device, directive) {
    const identifier = `${device.mac_address}${device.bind_location_id}${device.bind_sublocation_id}`;
    const currentLength = shownLogMessage[identifier]?.length;
    const potentialLength = device.extra?.log_messages?.length;
    if (currentLength && potentialLength && device.extra?.log_messages) {
      if (directive === 'more') {
        setShownLogMessages((prev) => ({
          ...prev,
          [identifier]: device.extra.log_messages
            .slice(0, Math.min(currentLength + 20, potentialLength)),
        }));
      }
      if (directive === 'all') {
        setShownLogMessages((prev) => ({
          ...prev,
          [identifier]: device.extra.log_messages,
        }));
      }
    }
  }

  async function refreshLogMessages(device) {
    await dispatch(getDeviceData(device));
  }

  function sortColumn(column) {
    const order = sort[column] === 'desc' ? 'asc' : 'desc';
    const result = {};
    Object.keys(sort).forEach((key) => {
      result[key] = key !== column ? 'none' : order;
    });
    setSort(result);
    const array = sortObjectByStringKey(order, debugDevices, column);
    if (filter !== '') {
      dispatch(setDebugDevices(array
        .filter((device) => device.mac_address.toLowerCase()
          .includes(filter.toLowerCase()))));
    } else {
      dispatch(setDebugDevices(array));
    }
  }

  const selectAppFilter = (_, object) => {
    dispatch(setFetchingDebugList(true));
    setAppFilter(object);
    setLocationFilter(null);
    dispatch(getDebugDevices(filter, object?.id || null, null));
  };

  const selectLocationFilter = (_, object) => {
    dispatch(setFetchingDebugList(true));
    setLocationFilter(object);
    setAppFilter(null);
    dispatch(getDebugDevices(filter, null, object?.id || null));
  };

  return (
    <div className={!openMenu ? classes.content : classesContainer.noneContent}>
      <div className={classes.topControls}>
        <CustomTextField
          id="standard-multiline-flexible"
          label={t('typeToSearch')}
          value={filter}
          onChange={(e) => handleChangeFilter(e)}
          variant="standard"
        />
        <div className={classes.selectorWrap}>
          <AutoCompleteDebug
            optionsArray={appKeys.map((key) => apps[key])}
            currentItem={appFilter}
            selectItem={selectAppFilter}
            placeholderText="Filter by App"
            // using key to force re-render and clear the field
            key={appFilter}
          />
        </div>
        <div className={classes.selectorWrap}>
          <AutoCompleteDebug
            optionsArray={locationKeys.map((key) => locations[key])}
            currentItem={locationFilter}
            selectItem={selectLocationFilter}
            placeholderText="Filter by Location"
            key={locationFilter}
          />
        </div>
      </div>
      {fetchingDebugList
        && (
          <div className={classes.spinerWrapper}>
            <CircularProgress size={26} />
          </div>
        )}
      {debugDevices && !fetchingDebugList && (
      <div className={classes.table}>
        <TableContainer className={classes.tableContainer} component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <CustomTableHeader
                  onClick={() => sortColumn('mac_address')}
                  sx={{ width: '25em' }}
                  canBeSorted
                >
                  <div className="tableTheadCell">
                    <span className="titleText">
                      Mac Address
                    </span>
                    { (sort.mac_address === 'asc')
                        && <ArrowDropUpIcon /> }
                    { (sort.mac_address === 'desc')
                        && <ArrowDropDownIcon /> }
                  </div>
                </CustomTableHeader>
                <CustomTableHeader
                  onClick={() => sortColumn('bind_location_id')}
                  sx={{ width: '5em' }}
                  canBeSorted
                >
                  <div className="tableTheadCell">
                    <span className="titleText">
                      Location ID
                    </span>
                    { (sort.bind_location_id === 'asc')
                        && <ArrowDropUpIcon /> }
                    { (sort.bind_location_id === 'desc')
                        && <ArrowDropDownIcon /> }
                  </div>
                </CustomTableHeader>
                <CustomTableHeader
                  onClick={() => sortColumn('bind_sublocation_id')}
                  sx={{ width: '5em' }}
                  canBeSorted
                >
                  <div className="tableTheadCell">
                    <span className="titleText">
                      Sublocation ID
                    </span>
                    { (sort.bind_sublocation_id === 'asc')
                        && <ArrowDropUpIcon /> }
                    { (sort.bind_sublocation_id === 'desc')
                        && <ArrowDropDownIcon /> }
                  </div>
                </CustomTableHeader>
                <CustomTableHeader
                  onClick={() => sortColumn('application_id')}
                  sx={{ width: '5em' }}
                  canBeSorted
                >
                  <div className="tableTheadCell">
                    <span className="titleText">
                      Application ID
                    </span>
                    { (sort.application_id === 'asc')
                        && <ArrowDropUpIcon /> }
                    { (sort.application_id === 'desc')
                        && <ArrowDropDownIcon /> }
                  </div>
                </CustomTableHeader>
                <CustomTableHeader sx={{ width: '5em' }}>
                  External
                </CustomTableHeader>
                <CustomTableHeader>
                  Input/Output messages/sec
                </CustomTableHeader>
                <CustomTableHeader
                  onClick={() => sortColumn('tracked_object_id')}
                  sx={{ width: '5em' }}
                  canBeSorted
                >
                  <div className="tableTheadCell">
                    <span className="titleText">
                      Tracked Object ID
                    </span>
                    { (sort.tracked_object_id === 'asc')
                        && <ArrowDropUpIcon /> }
                    { (sort.tracked_object_id === 'desc')
                        && <ArrowDropDownIcon /> }
                  </div>
                </CustomTableHeader>
                <CustomTableHeader>
                  More
                </CustomTableHeader>
              </TableRow>
            </TableHead>
            <TableBody>
              {debugDevices && debugDevices
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((device, index) => {
                  const identifier = `${device.mac_address}${device.bind_location_id}${device.bind_sublocation_id}`;
                  return (
                    <React.Fragment key={device.id}>
                      <TableRow
                        onClick={() => expandData(device)}
                        style={index % 2 === 0 ? { backgroundColor: 'white' } : { backgroundColor: '#ebebeb' }}
                      >
                        <TableCell>
                          {device.mac_address}
                        </TableCell>
                        <TableCell>
                          {device.bind_location_id}
                        </TableCell>
                        <TableCell>
                          {device.bind_sublocation_id}
                        </TableCell>
                        <TableCell>
                          {device.application_id}
                        </TableCell>
                        <TableCell>
                          {device.is_external?.toString()}
                        </TableCell>
                        <TableCell>
                          {device.input_messages_per_sec
                            ? device.input_messages_per_sec.toFixed(2)
                            : device.input_messages_per_sec}
                          {' / '}
                          {device.output_messages_per_sec
                            ? device.output_messages_per_sec.toFixed(2)
                            : device.output_messages_per_sec}
                        </TableCell>
                        <TableCell>
                          {device.tracked_object_id}
                        </TableCell>
                        <TableCell className={classes.expandCell}>
                          <div className={classes.expandIconContainer}>
                            {device.expanded
                              ? (
                                <ExpandLessIcon
                                  className={classes.expandButton}
                                />
                              ) : (
                                <ExpandMoreIcon
                                  className={classes.expandButton}
                                />
                              )}
                          </div>
                        </TableCell>
                      </TableRow>

                      {device.extra && device.expanded === true && (
                      <TableRow style={index % 2 === 0 ? { backgroundColor: 'white' } : { backgroundColor: '#ebebeb' }}>
                        <TableCell colSpan={7} className={classes.extraTableContainer}>
                          <Table className={classes.containedTable}>
                            <TableBody>
                              {device.extra.last_measurement
                              && (
                              <TableRow>
                                <TableCell className={classes.extraDataCell}>
                                  {device.extra.last_measurement}
                                </TableCell>
                              </TableRow>
                              )}
                              {device.extra.last_position
                                && (
                                <TableRow>
                                  <TableCell className={classes.extraDataCell}>
                                    {`Accuracy: ${device.extra.last_position.accuracy}, `}
                                    {`Altitude: ${device.extra.last_position.altitude}, `}
                                    {`kx: ${device.extra.last_position.kx}, `}
                                    {`ky: ${device.extra.last_position.ky}, `}
                                    {`time: ${device.extra.last_position.time}`}
                                  </TableCell>
                                </TableRow>
                                )}
                              <TableRow>
                                <TableCell className={classes.extraDataCell}>
                                  <ul className={classes.logMessageList}>
                                    {shownLogMessage[identifier]
                                       && shownLogMessage[identifier]
                                         .map((message) => (
                                           <li key={identifier + message}>
                                             {message}
                                           </li>
                                         ))}
                                  </ul>
                                  <div className={classes.buttonsDiv}>
                                    <Button
                                      variant="contained"
                                      onClick={() => showMore(device, 'more')}
                                      disabled={shownLogMessage[identifier]?.length
                                        === device.extra?.log_messages?.length
                                        || device.loading === true}
                                    >
                                      Show more
                                    </Button>
                                    <Button
                                      variant="contained"
                                      onClick={() => showMore(device, 'all')}
                                      disabled={shownLogMessage[identifier]?.length
                                        >= device.extra?.log_messages?.length
                                        || device.loading === true}
                                    >
                                      Show all
                                      {device.extra?.log_messages?.length
                                        ? ` (${device.extra.log_messages.length})` : null}
                                    </Button>
                                    <div className={classes.refreshExtraDataContainer}>
                                      <Button
                                        onClick={() => refreshLogMessages(device)}
                                        disabled={device.loading === true}
                                      >
                                        <RefreshIcon />
                                        Refresh data
                                      </Button>
                                      {device.loading === true && <CircularProgress size="1.5em" />}
                                    </div>
                                  </div>
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </TableCell>
                      </TableRow>
                      )}
                      {!device.extra && device.expanded === true && (
                      <TableRow>
                        <TableCell className={classes.spinnerCell} colSpan={3}>
                          <CircularProgress />
                        </TableCell>
                      </TableRow>
                      )}
                    </React.Fragment>
                  );
                })}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[25, 50, 100, 500, { label: t('All'), value: -1 }]}
            component="div"
            count={debugDevices ? debugDevices.length : 0}
            rowsPerPage={rowsPerPage}
            page={page}
            SelectProps={{
              IconComponent: PaginationIcon,
              inputProps: {
                id: 'selector-rows-per-page',
                name: 'selector-rows-per-page',
              },
            }}
            onPageChange={(_, pageNumber) => setPage(pageNumber)}
            onRowsPerPageChange={(event) => {
              setPage(0);
              setRowsPerPage(+event.target.value);
            }}
            labelDisplayedRows={({ from, to, count }) => (
              `${from}-${to} ${t('of')} ${count !== -1 ? count : `${t('moreThen')} ${to}`}`
            )}
            labelRowsPerPage={t('labelRowsPerPage')}
            nextIconButtonProps={{
              'aria-label': 'Next Page',
              style: {
                color: debugDevices
                    && page === Math.ceil(debugDevices.length / rowsPerPage) - 1
                  ? '#b5b8c4' : '#41afd7',
                background: 'white',
                width: '32px',
                height: '32px',
                margin: '5px',
              },
              autoid: 'pagination-button-previous-collector',
            }}
            backIconButtonProps={{
              'aria-label': 'Previous Page',
              style: {
                color: page === 0 ? '#b5b8c4' : '#41afd7', background: 'white', width: '32px', height: '32px',
              },
              autoid: 'pagination-button-next-collector',
            }}
          />
        </TableContainer>
      </div>
      )}
    </div>
  );
}

export default DebugDevices;
