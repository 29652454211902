import React from 'react';

import { makeStyles } from 'tss-react/mui';
import { useSelector } from 'react-redux';
import logorus from 'assets/images/welcomeLogo/ru-demo/logo_rus.svg';
import { RU_DEMO_USER } from 'constans';

const useStyles = makeStyles()((materialTheme) => {
  const theme = materialTheme.CustomStyles;
  return {
    welcomeLogo: {
      width: theme.Logo.imageWidth,
      height: theme.Logo.imageHeight,
      background: theme.LogoImageBackground,
    },
    ruLogo: {
      width: theme.Logo.imageWidth,
      height: theme.Logo.imageHeight,
      backgroundImage: `url(${logorus})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: '90%',
    },
  };
});
const selectEmail = (state) => state?.user?.email;

function WelcomeLogo() {
  const { classes: classNames } = useStyles();
  const userEmail = useSelector(selectEmail);
  return (
    <div
    // eslint-disable-next-line no-underscore-dangle
      style={{ display: window._configuration_env.THEME === 'whiteLabel' ? 'none' : null }}
      className={userEmail !== RU_DEMO_USER ? classNames.welcomeLogo : classNames.ruLogo}
    />
  );
}

export default WelcomeLogo;
