/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const getThreeHoursBefore = () => {
  const currentDate = new Date().getTime();
  const threeHours = 3 * 60 * 60 * 1000;

  return currentDate - threeHours;
};

const initialState = {
  devicesMonitoringArray: [],
  inventoryReport: [],
  beaconAnalyticReport: {},
  inventoryRepLastCoords: [],
  isFetchingInventoryReport: false,
  isFetchingDistanceTraveledReport: false,
  distanceTraveledReport: [],
  contactReportsArray: [],
  contactZoneReportsArray: [],
  objectReportsArray: [],
  objectFinalReportsArray: [],
  zoneReportsArray: [],
  zoneFinalReportsArray: [],
  outOfzonesTime: {},
  finalReportsNoData: {},
  alertReportsArray: [],
  activityReportArray: [],
  isFetching: false,
  dateFrom: new Date(getThreeHoursBefore()),
  dateTo: new Date(),
  dateFromNoZones: new Date(getThreeHoursBefore()),
  dateToNoZones: new Date(),
  currentObject: null,
  isFetchingAlertReport: false,
  isFetchingHistoryPicReport: false,
  alertReportLimit: 10,
  currentPage: 0,
  duration: '120',
  getReportDate: '',
};

const reportsSlice = createSlice({
  name: 'reports',
  initialState,
  reducers: {
    fetchAlertReportInProgress(state) {
      state.isFetchingAlertReport = true;
    },
    fetchAlertReportEnd(state) {
      state.isFetchingAlertReport = false;
    },
    fetchDistanceTraveledReportEnd(state) {
      state.isFetchingDistanceTraveledReport = false;
    },
    fetchDistanceTraveledInProgress(state) {
      state.isFetchingDistanceTraveledReport = true;
    },
    fetchInventoryReportEnd(state) {
      state.isFetchingInventoryReport = false;
    },
    fetchInventoryInProgress(state) {
      state.isFetchingInventoryReport = true;
    },
    resetReports(state) {
      state.inventoryReport = [];
      state.devicesMonitoringArray = [];
      state.contactReportsArray = [];
      state.contactZoneReportsArray = [];
      state.objectReportsArray = [];
      state.alertReportsArray = [];
      state.isFetching = false;
      state.currentObject = null;
    },
    setAlertPage(state, action) {
      state.currentPage = action.payload;
    },
    setActivityReport(state, action) {
      state.activityReportArray = action.payload;
    },
    setAlertReportLimit(state, action) {
      state.alertReportLimit = action.payload;
    },
    setAlertReportList(state, action) {
      state.alertReportsArray = action.payload;
    },
    setBeaconAnalytic(state, action) {
      state.beaconAnalyticReport = action.payload;
    },
    setContactReports(state, action) {
      state.contactReportsArray = action.payload;
      state.isFetching = false;
    },
    fetchContactReportsInProgress(state) {
      state.isFetching = true;
    },
    fetchedContactReports(state) {
      state.isFetching = false;
    },
    filterDeviceArray(state, action) {
      state.filterDevicesMonitoringArray = action.payload;
    },
    setContactZoneReports(state, action) {
      state.contactZoneReportsArray = action.payload;
      state.isFetching = false;
    },
    setCurrentObject(state, action) {
      state.currentObject = action.payload || null;
    },
    setDateFrom(state, action) {
      state.dateFrom = action.payload;
    },
    setDateTo(state, action) {
      state.dateTo = action.payload;
    },
    setGatewayStatus(state, action) {
      state.devicesMonitoringArray = action.payload;
    },
    setDistanceTraveledReport(state, action) {
      state.distanceTraveledReport = action.payload;
    },
    setDuration(state, action) {
      state.duration = action.payload;
    },
    setFinalZoneReport(state, action) {
      state.zoneFinalReportsArray = action.payload;
    },
    setInventoryReport(state, action) {
      const { report, lastCoordinates } = action.payload;
      state.inventoryReport = report;
      if (lastCoordinates) {
        state.inventoryRepLastCoords = lastCoordinates;
      }
    },
    setObjectReport(state, action) {
      const { list, dateFrom, dateTo } = action.payload;
      state.objectReportsArray = list;
      state.dateFromNoZones = dateFrom;
      state.dateToNoZones = dateTo;
    },
    setFinalObjectReport(state, action) {
      const { list, dateFrom, dateTo } = action.payload;
      state.objectFinalReportsArray = list;
      state.dateFromNoZones = dateFrom;
      state.dateToNoZones = dateTo;
    },
    setReportDate(state, action) {
      state.getReportDate = action.payload;
    },
    setTimeOutOfZones(state, action) {
      state.outOfzonesTime = action.payload;
    },
    setTimeFinalReportNoZoneData(state, action) {
      state.finalReportsNoData = action.payload;
    },
    setZoneReport(state, action) {
      state.zoneReportsArray = action.payload;
    },
  },
});

export const selectReports = (state) => state.reports;

export const selectReportsDevicesMonitoringArray = (state) => selectReports(state)
  .devicesMonitoringArray;
export const selectReportsInventoryReport = (state) => selectReports(state)
  .inventoryReport;
export const selectReportsBeaconAnalyticReport = (state) => selectReports(state)
  .beaconAnalyticReport;
export const selectReportsInventoryRepLastCoords = (state) => selectReports(state)
  .inventoryRepLastCoords;
export const selectReportsIsFetchingInventoryReport = (state) => selectReports(state)
  .isFetchingInventoryReport;
export const selectReportsIsFetchingDistanceTraveledReport = (state) => selectReports(state)
  .isFetchingDistanceTraveledReport;
export const selectReportsDistanceTraveledReport = (state) => selectReports(state)
  .distanceTraveledReport;
export const selectReportsContactReportsArray = (state) => selectReports(state).contactReportsArray;
export const selectReportsContactZoneReportsArray = (state) => selectReports(state)
  .contactZoneReportsArray;
export const selectReportsObjectReportsArray = (state) => selectReports(state).objectReportsArray;
export const selectReportsObjectFinalReportsArray = (state) => selectReports(state)
  .objectFinalReportsArray;
export const selectReportsZoneReportsArray = (state) => selectReports(state).zoneReportsArray;
export const selectReportsZoneFinalReportsArray = (state) => selectReports(state)
  .zoneFinalReportsArray;
export const selectReportsOutOfzonesTime = (state) => selectReports(state).outOfzonesTime;
export const selectFinalReportsNoData = (state) => selectReports(state).finalReportsNoData;
export const selectReportsAlertReportsArray = (state) => selectReports(state).alertReportsArray;
export const selectReportsActivityReportArray = (state) => selectReports(state).activityReportArray;
export const selectReportsIsFetching = (state) => selectReports(state).isFetching;
export const selectReportsDateFrom = (state) => selectReports(state).dateFrom;
export const selectReportsDateTo = (state) => selectReports(state).dateTo;
export const selectReportsDateFromNoZones = (state) => selectReports(state).dateFromNoZones;
export const selectReportsDateToNoZones = (state) => selectReports(state).dateToNoZones;
export const selectReportsCurrentObject = (state) => selectReports(state).currentObject;
export const selectReportsIsFetchingAlertReport = (state) => selectReports(state)
  .isFetchingAlertReport;
export const selectReportsIsFetchingHistoryPicReport = (state) => selectReports(state)
  .isFetchingHistoryPicReport;
export const selectReportsAlertReportLimit = (state) => selectReports(state).alertReportLimit;
export const selectReportsCurrentPage = (state) => selectReports(state).currentPage;
export const selectReportsDuration = (state) => selectReports(state).duration;
export const selectReportsGetReportDate = (state) => selectReports(state).getReportDate;

export const {
  fetchAlertReportEnd, fetchAlertReportInProgress,
  fetchContactReportsInProgress, fetchDistanceTraveledInProgress,
  fetchDistanceTraveledReportEnd, fetchInventoryInProgress, fetchInventoryReportEnd,
  fetchedContactReports, filterDeviceArray, resetReports, setActivityReport,
  setAlertPage, setAlertReportLimit, setAlertReportList,
  setBeaconAnalytic, setContactReports,
  setContactZoneReports, setCurrentObject, setDateFrom, setDateTo, setGatewayStatus,
  setDistanceTraveledReport, setDuration, setFinalObjectReport, setFinalZoneReport,
  setInventoryReport, setObjectReport, setReportDate,
  setTimeOutOfZones, setZoneReport, setTimeFinalReportNoZoneData,
} = reportsSlice.actions;

export default reportsSlice.reducer;
