import React, {
  useEffect, useRef, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import Backdrop from '@mui/material/Backdrop';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Slide from '@mui/material/Slide';
import Switch from '@mui/material/Switch';

import {
  allValuesConstant, monitoring, selectNoOneConstant,
} from 'constans';

import { fetch3DModel } from 'store/actions/floors';
import { alertTypes, createAlert } from 'store/slices/alert';
import { selectAppOpenMenu } from 'store/slices/app';
import { selectCurrentFloor, selectFloorsObject, setFloor } from 'store/slices/floors';
import {
  selectGroupsCount, selectGroupsObject, selectGroupsSelectedGroup, setSelectedGroup,
} from 'store/slices/groups';
import { selectCurrentLocation, selectLocationsObject } from 'store/slices/locations';
import { selectObjectsInMonitoringArray } from 'store/slices/objects';
import { selectReportsCurrentObject, setCurrentObject } from 'store/slices/reports';
import {
  selectSelectedZone, selectZoneTypes,
  selectZonesMap, setSelectedZone,
} from 'store/slices/zones';

import DialogMonitoring from 'components/dialog/DialogMonitorng';
import MainMenu from 'components/menu/MainMenu';
import AutoComplete from 'components/reports/ReportsAutoComplete';
import FloorSelector from 'components/reports/ReportsFloorSelector';
import GroupSelector from 'components/reports/ReportsGroupSelector';
import LocationSelector from 'components/reports/ReportsLocationSelector';
import ZoneSelector from 'components/reports/ReportsZoneSelector';
import GroupsInfinite from 'components/shared/GroupSelector/GroupsInfinite';
import CircularProgressWithLabel from './progress';

import ThreeD from './3d';
import useStyles from './style';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} />);

const modelUrl = 'https://cdn.navigine.com/sublocations/3dmap-1520-1702889963043.glb';

function Three3d() {
  const dispatch = useDispatch();
  const openMenu = useSelector(selectAppOpenMenu);
  const floorsObject = useSelector(selectFloorsObject);
  const currentFloor = useSelector(selectCurrentFloor);
  const groupsObject = useSelector(selectGroupsObject);
  const selectedGroup = useSelector(selectGroupsSelectedGroup);
  const locationsObject = useSelector(selectLocationsObject);
  const currentLocation = useSelector(selectCurrentLocation);
  const objectsInMonitoringArray = useSelector(selectObjectsInMonitoringArray);
  const currentObject = useSelector(selectReportsCurrentObject);
  const zonesMap = useSelector(selectZonesMap);
  const selectedZone = useSelector(selectSelectedZone);
  const zoneTypes = useSelector(selectZoneTypes);
  const groupsCount = useSelector(selectGroupsCount);

  const uselocation = useLocation();
  const antialias = uselocation.state?.antialias;

  const filObjArr = objectsInMonitoringArray
    .filter((object) => {
      const floorFilter = parseInt(object.attributes.sublocation_id, 10)
      === parseInt(currentFloor.id, 10);
      const groupFilter = selectedGroup.id === allValuesConstant
      || parseInt(object.attributes.tracked_group_id, 10)
      === parseInt(selectedGroup.id, 10);
      return floorFilter && groupFilter;
    })
    .sort((a, b) => a.attributes.title.localeCompare(b.attributes.title));
  const tempArray = Object.values(locationsObject)
    .filter((location) => location.id === currentLocation.id)
    .flatMap((location) => location.floors);
  const locationsObjectsArray = tempArray.map((item) => parseInt(item.id, 10));
  const filteredObjectsArrayInBuild = objectsInMonitoringArray
    .filter((object) => locationsObjectsArray
      .includes(object.attributes.sublocation_id));

  const { t } = useTranslation(['monitoring']);
  const navigate = useNavigate();
  const { classes } = useStyles();
  const sceneElement = useRef();
  const three = useRef();
  const [openObjectModal, setOpenObjectModal] = useState(false);

  const [showTrackLines, setShowTrackLines] = useState(false);
  const [objectInfo, setObjectInfo] = useState(null);

  const [mapProgress, setMapProgress] = useState(0);
  const [objectProgress, setObjectProgress] = useState(0);
  const [showLoader, setShowLoader] = useState(false);

  const [readyState, setReadyState] = useState(false);
  const [show3D, setShow3D] = useState(true);

  const handleCloseObjectModal = () => {
    setOpenObjectModal(false);
    setObjectInfo(null);
  };

  useEffect(() => {
    let evt;
    if (!floorsObject || !floorsObject[currentFloor.id]) {
      return undefined;
    }

    const { model } = floorsObject[currentFloor.id];

    const timer = setTimeout(() => {
      if (three.current instanceof ThreeD) { three.current.destroy(); }
      navigate(monitoring);
    }, 2500);

    if (!model) {
      return undefined;
    }
    clearTimeout(timer);

    if (!(three.current instanceof ThreeD)) {
      three.current = new ThreeD(sceneElement, antialias);
      evt = three.current.onWindowResize;

      setShowLoader(true);
      three.current.downloadMapModel(model.full_url, setMapProgress)
        .then(() => {
          three.current.downloadObjectModel(modelUrl, setObjectProgress)
            .then(() => {
              setShowLoader(false);
              setReadyState(true);
            })
            .catch(() => {
              navigate(monitoring);
              dispatch(createAlert({ messageType: alertTypes.err, message: 'Error in fetching 3D objects' }));
            });
        })
        .catch(() => {
          navigate(monitoring);
          dispatch(createAlert({ messageType: alertTypes.err, message: 'Error in fetching 3D map' }));
        });
    }
    return () => {
      window.removeEventListener('resize', evt, false);
      window.cancelAnimationFrame(three.current.id);
    };
  }, [currentFloor, floorsObject, antialias, navigate, dispatch]);

  useEffect(() => {
    const threeEl = three.current;
    if (filObjArr.length > 0 && threeEl?.trackList && readyState) {
      filObjArr.forEach(({ id, attributes }) => {
        const { kx, ky, title } = attributes;
        if (!threeEl.trackList[id]) {
          const model = threeEl.addObjectModel(id, kx, ky, title);
          if (model) {
            model.addEventListener('click', () => {
              setObjectInfo(attributes);
            });

            model.addEventListener('mouseover', () => {
              document.body.style.cursor = 'pointer';
              model.scale.set(1.2, 1.2, 1.2);
            });

            model.addEventListener('mouseout', () => {
              model.scale.set(1, 1, 1);
              document.body.style.cursor = 'default';
            });
          }
        } else {
          threeEl.updateModelCoordinates(kx, ky, id, showTrackLines);
        }
        threeEl.renderFn();
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filObjArr, readyState]);

  const handleChangeFloor = async (event) => {
    event.preventDefault();
    const floorId = event.target.value;
    dispatch(setFloor(floorsObject[floorId]));
    dispatch(setSelectedZone({ id: selectNoOneConstant }));
    dispatch(setSelectedGroup({ id: allValuesConstant }));
    await dispatch(fetch3DModel(floorId));
  };

  const handleChangeZone = (event) => {
    event.preventDefault();
    const zoneId = event.target.value;

    const selectedAll = zoneId === allValuesConstant;
    const selectedNone = zoneId === selectNoOneConstant;

    if (selectedAll) {
      dispatch(setSelectedZone({ id: allValuesConstant }));
      [...zonesMap.values()].forEach((zone) => {
        three.current.highlightZone(zone);
      });
      three.current.setDefaultCameraPosition(three.current.scene);
    }

    if (selectedNone) {
      dispatch(setSelectedZone({ id: selectNoOneConstant }));
      [...zonesMap.values()].forEach((zone) => {
        three.current.removeHighlightZone(zone);
      });
      three.current.setDefaultCameraPosition(three.current.scene);
    }

    if (!selectedNone && !selectedAll) {
      dispatch(setSelectedZone(zonesMap.get(zoneId)));
      three.current.highlightZone(zonesMap.get(zoneId));
      [...zonesMap.values()].forEach((zone) => {
        if (zone.id !== +zoneId) {
          three.current.removeHighlightZone(zone);
        }
      });

      three.current.lookFromAbove(zonesMap.get(zoneId).id);
    }
  };

  const selectObject = (_event, object) => {
    dispatch(setCurrentObject(object));
    if (three.current instanceof ThreeD) {
      three.current.lookFromAboveObject(object);
    }
  };

  const handleShowTrackLines = () => {
    setShowTrackLines((prev) => !prev);
  };

  useEffect(() => {
    if (three.current instanceof ThreeD) {
      if (showTrackLines) {
        three.current.drawTrackLines();
      } else {
        three.current.disposeTrackLines();
      }
    }
  }, [showTrackLines, objectsInMonitoringArray]);

  const handleShow3D = () => {
    setShow3D((prev) => !prev);
    dispatch(setSelectedZone({ id: selectNoOneConstant }));
    setTimeout(() => {
      if (three.current instanceof ThreeD) { three.current.destroy(); }
      navigate(monitoring);
    }, 300);
  };

  return (
    <div className={!openMenu ? classes.history : classes.noneContainer}>
      <MainMenu openMenu={openMenu} dispatch={dispatch}>
        <div className={classes.mapControls}>
          <div className={classes.control_wrapper}>
            <LocationSelector
              name="location-selector"
              locations={locationsObject}
              className={classes.selector}
              value={currentLocation.id}
              // onChange={handleChangeLocation}
            />
          </div>
          <div className={classes.control_wrapper}>
            <FloorSelector
              name="floor-selector"
              floors={floorsObject}
              className={classes.selector}
              onChange={handleChangeFloor}
              value={currentFloor.id}
              currentLocationId={currentLocation.id}
            />
          </div>
          <div className={classes.control_wrapper}>
            <ZoneSelector
              name="zone-selector"
              zones={zonesMap}
              className={classes.selector}
              onChange={handleChangeZone}
              value={selectedZone.id}
              zoneTypes={zoneTypes}
              currentFloorId={currentFloor.id}
            />
          </div>

          <div className={classes.control_wrapper}>
            {(!groupsCount || groupsCount <= 100)
              ? (
                <GroupSelector
                  name="group-selector"
                  // onChange={(event) => {
                  //   event.preventDefault();
                  //   handleChangeGroup(event.target.value);
                  // }}
                  groups={groupsObject}
                  value={selectedGroup.id || allValuesConstant}
                  className={classes.selector}
                />
              ) : (
                <GroupsInfinite
                  value={selectedGroup}
                  // handleChangeGroup={handleChangeGroup}
                  allowSelectAll
                  inputStyles={{ border: 'none', backgroundColor: '#F3F6F8' }}
                  borderStyle="none"
                  dropdownStyles={{ width: '200px !important' }}
                />
              )}
          </div>

          <div style={{ marginLeft: '20px', textAlign: 'left' }} className={classes.control_wrapper}>

            <AutoComplete
              filteredObjectsArray={filObjArr}
              currentObject={currentObject}
              bkgColor="#F3F6F8"
              selectObject={selectObject}
            />
          </div>

          <div className={classes.switch_wrapper}>
            <FormControlLabel
              className={classes.switch_element_monitoring}
              control={(
                <Switch
                  checked={show3D}
                  onChange={handleShow3D}
                  name="show 3d map"
                  color="primary"
                />
              )}
              label={t('show3d')}
            />
          </div>

          <div className={classes.switch_wrapper}>
            <FormControlLabel
              className={classes.switch_element_monitoring}
              control={(
                <Switch
                  checked={showTrackLines}
                  onChange={handleShowTrackLines}
                  name="show track lines"
                  color="primary"
                />
              )}
              label={t('showTrackLines')}
            />
          </div>
          <div className={classes.formControls}>
            <FormControl variant="standard">
              <div className={classes.objectsCount}>
                {' '}
                <p>
                  {' '}
                  {t('countOfObjects')}
                  {' '}
                  <b>{filObjArr.length}</b>
                </p>
                <p style={{ color: 'rgba(0, 20, 36, 0.5)' }}>
                  {' '}
                  {t('countOfObjectsInBuild')}
                  {' '}
                  <b>{filteredObjectsArrayInBuild.length}</b>
                </p>
              </div>
            </FormControl>
          </div>
        </div>
      </MainMenu>
      <div className={classes.sceneElement} ref={sceneElement} />
      <Backdrop className={classes.backdrop} open={showLoader}>
        <CircularProgressWithLabel value={mapProgress} />
        <CircularProgressWithLabel value={objectProgress} />
      </Backdrop>
      {openObjectModal
      && (
      <DialogMonitoring
        openObjectModal={openObjectModal}
        TransitionComponent={Transition}
        handleCloseObjectModal={handleCloseObjectModal}
        objectInfo={objectInfo}
        setOpenObjectModal={setOpenObjectModal}
        objectInfoArray={[]}
        floor={currentFloor}
      />
      )}
    </div>
  );
}

export default Three3d;
