/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import QRCode from 'react-qr-code';

import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import RemoveIcon from '@mui/icons-material/Remove';
import AppBar from '@mui/material/AppBar';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import TextField from '@mui/material/TextField';

import {
  createDeviceModalAction,
  deleteDevice,
} from 'store/actions/devices';
import {
  fetchGroups,
} from 'store/actions/groups';
import {
  addCustomProperty,
  closeDeleteDevicesModal,
  closeDevicesModal,
  deleteBackProperty,
  selectDeviceModelId,
  selectDeviceModels,
  selectDevicesCustomProps,
  selectDevicesFormType,
  selectDevicesIsModalLoading,
  selectDevicesMacAddress,
  selectDevicesModalTitle,
  selectDevicesOpenDeleteModal,
  selectDevicesOpenModal,
  selectDevicesPropsFromBack,
  selectDevicesUpdateDeviceId,
  selectFetchingDeviceModels,
  setDeviceModelId,
  setProperty,
  setSettingsModalDeviceTitle,
  setSettingsModalMac,
} from 'store/slices/devices';
import { selectCurrentFloor, selectFloorsObject } from 'store/slices/floors';
import { selectCurrentLocation, selectLocationsObject } from 'store/slices/locations';
import {
  editCustomProperty,
  selectSettingsModal,
  setModalForm,
} from 'store/slices/settings';
import { selectUserHash } from 'store/slices/user';

import { formTypeCreate } from 'constans';

import SelectorComponent from 'components/settings/Selector.component';
import CustomButton from 'components/settings/Button.component';
import InputComponent from 'components/settings/Input.component';
import TabPanel from 'components/shared/Tabs/Tabs';
import a11yProps from 'components/shared/Tabs/allProps';
import useStyles from '../objects/objects.component.style';

function DialogModalComponent(props) {
  const { classes } = useStyles();
  const [keyProps, setKeyProps] = useState();
  const [valueTabs, setValueTabs] = useState(0);
  const [valueProps, setValueProps] = useState();
  const [locationId, setLocationId] = useState();
  const [sublocationId, setSubLocationId] = useState();
  const [shownPropsFromBack, setShownPropsFromBack] = useState();
  const [shownCustomProps, setShownCustomProps] = useState();
  const [debugLink, showDebugLink] = useState(false);
  const handleChangeTabs = (event, newValue) => {
    setValueTabs(newValue);
  };

  const {
    deviceToDelete,
  } = props;
  const dispatch = useDispatch();
  const customProps = useSelector(selectDevicesCustomProps);
  const propsFromBack = useSelector(selectDevicesPropsFromBack);
  const formType = useSelector(selectDevicesFormType);
  const isModalLoading = useSelector(selectDevicesIsModalLoading);
  const openModal = useSelector(selectDevicesOpenModal);
  const openDeleteModal = useSelector(selectDevicesOpenDeleteModal);
  const modalTitle = useSelector(selectDevicesModalTitle);
  const macAddress = useSelector(selectDevicesMacAddress);
  const updateDeviceId = useSelector(selectDevicesUpdateDeviceId);
  const floorsObject = useSelector(selectFloorsObject);
  const currentFloor = useSelector(selectCurrentFloor);
  const locationsObject = useSelector(selectLocationsObject);
  const currentLocation = useSelector(selectCurrentLocation);
  const modal = useSelector(selectSettingsModal);
  const hash = useSelector(selectUserHash);
  const deviceModels = useSelector(selectDeviceModels);
  const fetchingDeviceModels = useSelector(selectFetchingDeviceModels);
  const modelId = useSelector(selectDeviceModelId);

  const locationsList = Object.keys(locationsObject).map((element) => locationsObject[element]);
  const floorList = Object.keys(floorsObject).map((element) => floorsObject[element]);
  const filteredFloorList = floorList.filter((floor) => floor.location === locationId);

  const { t } = useTranslation([
    'settings',
    'monitoring',
    'notifications',
    'report',
  ]);
  const checkFormValidation = !modalTitle || !macAddress;

  useEffect(() => {
    if (propsFromBack) {
      setShownPropsFromBack(JSON.parse(JSON.stringify(propsFromBack))
        .map((el, i) => {
          el.i = i;
          return el;
        }));
    }
  }, [propsFromBack]);

  useEffect(() => {
    if (customProps) {
      setShownCustomProps(JSON.parse(JSON.stringify(customProps))
        .map((el, i) => {
          el.i = i + 100;
          return el;
        }));
    }
  }, [customProps]);

  useEffect(() => {
    const locationProp = customProps.findLast((prop) => prop.key === 'location_id')
    || propsFromBack.find((prop) => prop.key === 'location_id');
    if (locationProp) {
      const matchingLocation = Object.values(locationsObject)
        .find((location) => location.id === locationProp.value);
      if (matchingLocation) {
        setLocationId(matchingLocation?.id);
      }
    } else {
      setLocationId();
    }

    const sublocationProp = customProps.findLast((prop) => prop.key === 'sublocation_id')
    || propsFromBack.find((prop) => prop.key === 'sublocation_id');
    if (sublocationProp) {
      const matchingSublocation = Object.values(floorsObject)
        .find((floor) => floor.id === sublocationProp.value);
      if (matchingSublocation) {
        setSubLocationId(matchingSublocation.id);
      }
    } else if (locationProp) {
      setSubLocationId(filteredFloorList[0]?.id);
    } else {
      setSubLocationId();
    }
  // filteredFloorList is missing because it gets derived from floorsObject
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [propsFromBack, customProps, locationsObject, floorsObject]);

  const handleCloseDelete = () => {
    dispatch(closeDeleteDevicesModal());
  };

  const handleDeteleConfirm = async () => {
    await dispatch(deleteDevice(deviceToDelete, t));
    handleCloseDelete();
    await dispatch(fetchGroups({ page: 0, limit: 300 }));
  };

  const handleChangeForm = (fieldName, value) => {
    const resultObject = {};
    let key = '';
    if (value) {
      resultObject[fieldName] = value;
      if (fieldName === 'location') {
        const filteredFloors = floorList.filter((floor) => floor.location === value.id);
        key = 'location_id';
        if (filteredFloors.length > 0) {
          const firstFloor = filteredFloors[0];
          resultObject.sublocation_id = firstFloor;
          dispatch(addCustomProperty({
            key: 'sublocation_id',
            id: firstFloor.id,
            value: firstFloor.id,
          }));
        }
      }
      if (fieldName === 'floor') {
        key = 'sublocation_id';
      }
      dispatch(setModalForm(resultObject));
      dispatch(addCustomProperty({
        key,
        id: value.id,
        value: value.id,
      }));
    } else {
      if (fieldName === 'location') {
        setLocationId();
      }
      setSubLocationId();
    }
  };

  const handleMainModalActionClick = async () => {
    const deviceProperties = {};
    shownPropsFromBack
      .filter((prop) => {
        if (!locationId && prop.key === 'location_id') {
          return false;
        }
        if (!sublocationId && prop.key === 'sublocation_id') {
          return false;
        }
        return true;
      })
      .forEach((prop) => {
        deviceProperties[prop.key] = prop.value;
      });
    shownCustomProps.forEach((prop) => {
      deviceProperties[prop.key] = prop.value;
    });
    await dispatch(createDeviceModalAction(t, deviceProperties));
    dispatch(editCustomProperty([]));
    setValueTabs(0);
    setKeyProps();
    setValueProps();
    showDebugLink(false);
  };

  const handleCloseDeviceModal = () => {
    dispatch(closeDevicesModal());
    dispatch(setProperty([]));
    setValueTabs(0);
    showDebugLink(false);
  };

  const handleCustomKeyChangeForm = (value) => {
    setKeyProps(value);
  };
  const handleCustomValueChangeForm = (value) => {
    setValueProps(value);
  };

  const addProperty = () => {
    const resultObject = {
      key: keyProps,
      value: valueProps,
      id: Math.floor(Math.random() * 101),
      btn: false,
    };
    dispatch(addCustomProperty(resultObject));
    setKeyProps();
    setValueProps();
  };

  const removeCustomProps = (prop) => {
    const newForm = JSON.parse(JSON.stringify(customProps));
    const newCustomProps = newForm.filter((i, index) => index !== prop);
    dispatch(editCustomProperty(newCustomProps));
  };

  const deletePropsFromBack = async (key) => {
    setShownPropsFromBack(shownPropsFromBack.filter((prop) => prop.key !== key));
  };

  const changeCustomProps = (value, changedField, index) => {
    const newForm = JSON.parse(JSON.stringify(shownCustomProps));
    newForm[index][changedField] = value;
    setShownCustomProps(newForm);
  };

  const changePropsFromBack = (prop, value, changedField, unchangedField) => {
    const newForm = JSON.parse(JSON.stringify(propsFromBack));
    newForm.forEach((el) => {
      if (el[unchangedField] === prop[unchangedField]) {
        el[changedField] = value;
      }
    });
    dispatch(deleteBackProperty(newForm));
  };

  const handleGetDebugLink = () => {
    showDebugLink(true);
  };
  // eslint-disable-next-line no-underscore-dangle
  const getPathQrcode = () => `${window._configuration_env.DEBUG}/debug?location=${currentLocation.id}&floor=${currentFloor.id}&hash=${hash}&mac=${macAddress}`;

  return (
    <div>
      <Dialog
        open={openDeleteModal}
        onClose={handleCloseDelete}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle className={classes.dialogTitle} id="scroll-dialog-title">
          {t('deleteDeviceTitle')}
        </DialogTitle>
        <DialogContent>
          <p>
            {' '}
            {t('deleteDeviceText')}
          </p>
        </DialogContent>
        <DialogActions>
          <CustomButton disabled={isModalLoading} onClick={handleCloseDelete} color="primary">
            {t('cancelBtn')}
          </CustomButton>
          <CustomButton disabled={isModalLoading} onClick={handleDeteleConfirm} color="primary">
            {isModalLoading ? <CircularProgress size={26} /> : null}
            {t('deleteBtn')}
          </CustomButton>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openModal}
        onClose={handleCloseDeviceModal}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        PaperProps={{
          style: {
            position: 'absolute',
            top: '5vh',
          },
        }}
      >
        <form onSubmit={(e) => e.preventDefault()}>
          <div className={classes.tabsMainWrapper}>
            <div className={classes.tabContainer}>
              <DialogTitle className={classes.dialogTitle} id="scroll-dialog-title">
                <div className={classes.divToH3}>
                  {formType === formTypeCreate
                    ? t('createDeviceTitle')
                    : t('editDeviceTitle')}
                  {formType === formTypeCreate ? (
                    null
                  ) : (
                    <div className={classes.idTitle}>
                      id
                      {' '}
                      {updateDeviceId}
                    </div>
                  )}
                </div>
                <IconButton size="small" onClick={handleCloseDeviceModal}>
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <AppBar position="static" className={classes.wrappApp}>
                <Tabs
                  value={valueTabs}
                  onChange={handleChangeTabs}
                  aria-label="simple tabs example"
                  className={classes.tabsLabel}
                  TabIndicatorProps={{ style: { background: '#30AAD9' } }}
                >
                  <Tab
                    label={t('base')}
                    {...a11yProps(0)}
                    className={classes.tabLabel}
                    style={{ maxWidth: '10%' }}
                  />
                  <Tab
                    label={t('custom')}
                    {...a11yProps(2)}
                    className={classes.tabLabel}
                  />
                  <Tab
                    disabled={formType === formTypeCreate}
                    label="debug"
                    {...a11yProps(3)}
                    className={classes.tabLabel}
                  />
                </Tabs>
              </AppBar>
              <TabPanel value={valueTabs} index={0}>
                <DialogContent>
                  <FormControl variant="standard" className={classes.formControl}>
                    <label className={classes.label} htmlFor="title-input">
                      {t('title')}
                      <InputComponent
                        className={classes.inputComponent}
                        value={modalTitle}
                        onChange={(event) => {
                          dispatch(setSettingsModalDeviceTitle(event.target.value));
                        }}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{ disableUnderline: true }}
                        inputProps={{ id: 'title-input' }}
                        variant="standard"
                      />
                    </label>
                  </FormControl>
                  <br />
                  <FormControl variant="standard" className={classes.formControl}>
                    <label className={classes.label} htmlFor="mac-input">
                      MAC address
                    </label>
                    <InputComponent
                      className={classes.inputComponent}
                      value={macAddress}
                      onChange={(event) => {
                        dispatch(setSettingsModalMac(event.target.value));
                      }}
                      InputLabelProps={{ shrink: true }}
                      InputProps={{ disableUnderline: true }}
                      inputProps={{ id: 'mac-input' }}
                      variant="standard"
                    />
                  </FormControl>
                  <FormControl variant="standard" className={classes.formControl}>
                    <label className={classes.label} htmlFor="object-location">
                      {t('monitoring:locationSelectorLabel')}
                    </label>
                    <SelectorComponent
                      className={classes.inputComponent}
                      select
                      onChange={(event) => handleChangeForm('location', locationsObject[event.target.value])}
                      value={locationId || t('No location')}
                      InputLabelProps={{ shrink: true }}
                      InputProps={{ disableUnderline: true }}
                      inputProps={{ id: 'object-location' }}
                      variant="standard"
                    >
                      <MenuItem value={t('No location')}>{t('No location')}</MenuItem>
                      {locationsList.map((location) => (
                        <MenuItem key={location.id} value={location.id}>
                          {location.title}
                        </MenuItem>
                      ))}
                    </SelectorComponent>
                  </FormControl>
                  {locationId
                    && (
                    <FormControl variant="standard" className={classes.formControl}>
                      <label className={classes.label} htmlFor="object-floor">
                        {t('monitoring:floorSelectorLabel')}
                      </label>
                      <SelectorComponent
                        className={classes.inputComponent}
                        select
                        onChange={(event) => handleChangeForm('floor', floorsObject[event.target.value])}
                        value={sublocationId || filteredFloorList[0]?.id}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{ disableUnderline: true }}
                        inputProps={{ id: 'object-floor' }}
                        variant="standard"
                      >
                        {filteredFloorList
                          .map((floor) => (
                            <MenuItem key={floor.id} value={floor.id}>
                              {floor.title}
                            </MenuItem>
                          ))}
                      </SelectorComponent>
                    </FormControl>
                    )}
                  <FormControl variant="standard" className={classes.formControl}>
                    <label className={classes.label} htmlFor="device-model">
                      {t('deviceModel')}
                    </label>
                    <SelectorComponent
                      className={classes.inputComponent}
                      select
                      onChange={(event) => dispatch(setDeviceModelId(event.target.value))}
                      value={modelId || ''}
                      InputLabelProps={{ shrink: true }}
                      InputProps={{ disableUnderline: true }}
                      inputProps={{ id: 'device-model' }}
                      variant="standard"
                      disabled={!deviceModels?.length || fetchingDeviceModels}
                    >
                      {deviceModels.map((model) => (
                        <MenuItem key={model.model_id} value={model.model_id}>
                          {model.model_name
                            ? model.model_name
                            : ''}
                        </MenuItem>
                      ))}
                    </SelectorComponent>
                  </FormControl>
                </DialogContent>
              </TabPanel>
              <TabPanel value={valueTabs} index={1}>
                <div className={classes.customPropsContainer}>
                  <FormControl variant="standard" className={classes.customPropsWrapp}>
                    <label className={classes.label} htmlFor="object-custom-property">
                      Property
                    </label>
                    <TextField
                      variant="standard"
                      className={classes.inputComponent}
                      type="text"
                      value={keyProps || ''}
                      onChange={(event) => {
                        handleCustomKeyChangeForm(event.target.value);
                      }}
                      InputLabelProps={{ shrink: true }}
                      InputProps={{ disableUnderline: true }}
                      inputProps={{ id: 'object-custom-property' }}
                    />
                  </FormControl>
                  <FormControl variant="standard" className={classes.customPropsWrapp}>
                    <label className={classes.label} htmlFor="object-custom-value">
                      Value
                    </label>
                    <TextField
                      variant="standard"
                      className={classes.inputComponent}
                      type="text"
                      value={valueProps || ''}
                      onChange={(event) => {
                        handleCustomValueChangeForm(event.target.value);
                      }}
                      InputLabelProps={{ shrink: true }}
                      InputProps={{ disableUnderline: true }}
                      inputProps={{ id: 'object-custom-value' }}
                    />
                  </FormControl>
                  <div className={classes.btnCusotmContainer}>
                    <IconButton
                      disabled={!keyProps || !valueProps}
                      onClick={addProperty}
                      className={classes.btnCustomProps}
                      size="large"
                    >
                      <AddIcon />
                    </IconButton>
                  </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column-reverse' }}>
                  {shownCustomProps && shownCustomProps
                    .map((prop, index) => (
                      <div key={prop.i} className={classes.customPropsContainer}>
                        <FormControl variant="standard" className={classes.customPropsWrapp}>
                          <label className={classes.label} htmlFor={`custom-property-${prop.i}`}>
                            Property
                          </label>
                          <TextField
                            variant="standard"
                            className={classes.inputComponent}
                            type="text"
                            onChange={(event) => changeCustomProps(event.target.value, 'key', index)}
                            InputLabelProps={{ shrink: true }}
                            InputProps={{ disableUnderline: true, id: `custom-property-${prop.i}` }}
                            value={prop.key}
                          />
                        </FormControl>
                        <FormControl variant="standard" className={classes.customPropsWrapp}>
                          <label className={classes.label} htmlFor={`custom-value-${prop.i}`}>
                            Value
                          </label>
                          <TextField
                            variant="standard"
                            value={prop.value}
                            className={classes.inputComponent}
                            type="text"
                            onChange={(event) => changeCustomProps(event.target.value, 'value', 'key')}
                            InputLabelProps={{ shrink: true }}
                            InputProps={{ disableUnderline: true, id: `custom-value-${prop.i}` }}
                          />
                        </FormControl>
                        <div className={classes.btnCusotmContainer}>
                          <IconButton
                            onClick={() => removeCustomProps(index)}
                            className={classes.btnCustomProps}
                            size="large"
                          >
                            <RemoveIcon />
                          </IconButton>
                        </div>
                      </div>
                    ))}
                </div>
                {shownPropsFromBack && shownPropsFromBack
                  .map((prop) => (
                    <div key={prop.i} className={classes.customPropsContainer}>
                      <FormControl variant="standard" className={classes.customPropsWrapp}>
                        <label className={classes.label} htmlFor={`fromBack-property-${prop.i}`}>
                          Property
                        </label>
                        <TextField
                          variant="standard"
                          className={classes.inputComponent}
                          value={prop.key}
                          type="text"
                          onChange={(event) => changePropsFromBack(prop, event.target.value, 'key', 'value')}
                          InputLabelProps={{ shrink: true }}
                          InputProps={{ disableUnderline: true, id: `fromBack-property-${prop.i}` }}
                        />
                      </FormControl>
                      <FormControl variant="standard" className={classes.customPropsWrapp}>
                        <label className={classes.label} htmlFor={`fromBack-value-${prop.i}`}>
                          Value
                        </label>
                        <TextField
                          variant="standard"
                          className={classes.inputComponent}
                          type="text"
                          value={prop.value}
                          onChange={(event) => {
                            changePropsFromBack(prop, event.target.value, 'value', 'key');
                          }}
                          InputLabelProps={{ shrink: true }}
                          InputProps={{ disableUnderline: true, id: `fromBack-value-${prop.i}` }}
                        />
                      </FormControl>
                      <div className={classes.btnCusotmContainer}>
                        <IconButton
                          onClick={() => deletePropsFromBack(prop.key)}
                          className={classes.btnCustomProps}
                          size="large"
                        >
                          <RemoveIcon />
                        </IconButton>
                      </div>
                    </div>
                  ))}
              </TabPanel>
              <TabPanel value={valueTabs} index={2}>
                <DialogContent>
                  <FormControl variant="standard" className={classes.formControl}>
                    <label className={classes.label} htmlFor="object-location">
                      {t('monitoring:locationSelectorLabel')}
                      <SelectorComponent
                        className={classes.inputComponent}
                        select
                        onChange={(event) => {
                          handleChangeForm('location', locationsObject[event.target.value]);
                        }}
                        value={modal.location.id || ''}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{ disableUnderline: true }}
                        inputProps={{ id: 'object-location' }}
                        variant="standard"
                      >
                        {locationsList.map((location) => (
                          <MenuItem key={location.id} value={location.id}>
                            {location.title}
                          </MenuItem>
                        ))}
                      </SelectorComponent>
                    </label>
                  </FormControl>
                  <FormControl variant="standard" className={classes.formControl}>
                    <label className={classes.label} htmlFor="object-floor">
                      {t('monitoring:floorSelectorLabel')}
                      <SelectorComponent
                        className={classes.inputComponent}
                        select
                        onChange={(event) => {
                          handleChangeForm('floor', floorsObject[event.target.value]);
                        }}
                        value={modal.floor.id || ''}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{ disableUnderline: true }}
                        inputProps={{ id: 'object-floor' }}
                        variant="standard"
                      >
                        {floorList.map((floor) => (
                          <MenuItem key={floor.id} value={floor.id}>
                            {floor.title}
                          </MenuItem>
                        ))}
                      </SelectorComponent>
                    </label>
                  </FormControl>
                  <CustomButton
                // disabled={isModalLoading}
                    onClick={handleGetDebugLink}
                    color="secondary"
                    style={{ float: 'right' }}
                  >
                    GET LINK
                  </CustomButton>
                  <br />
                  {debugLink ? (
                    <div>
                      <QRCode value={getPathQrcode()} />
                      <br />
                      <a target="blank" href={getPathQrcode()}>
                        link
                      </a>
                    </div>
                  ) : null}

                </DialogContent>
              </TabPanel>
            </div>
          </div>
          <DialogActions className={classes.dialogActions}>
            <CustomButton
              type="submit"
              disabled={checkFormValidation || isModalLoading}
              onClick={handleMainModalActionClick}
              color="primary"
              className={classes.customBtn}
            >
              {isModalLoading ? <CircularProgress size={26} /> : null}
              {!isModalLoading && (formType === formTypeCreate
                ? t('createDeviceButton')
                : t('saveBtn'))}
            </CustomButton>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}

export default DialogModalComponent;
