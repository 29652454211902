import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import CircularProgress from '@mui/material/CircularProgress';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import Add from '@mui/icons-material/Add';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import MailOutlineRoundedIcon from '@mui/icons-material/MailOutlineRounded';

import { ReactComponent as DeleteButton } from 'assets/images/settings/icons/DeleteSettings.svg';
import { ReactComponent as EditButton } from 'assets/images/settings/icons/EditSettings.svg';

import {
  allValuesConstant,
  conditionSoundTypes,
  formTypeCreate, formTypeUpdate, notificationTypes,
  timeConstants,
  timeTypes,
} from 'constans';

import { fetchDevicesList } from 'store/actions/devices';
import { alertTypes, createAlert } from 'store/slices/alert';
import { selectAppCurrentApp, selectAppOpenMenu } from 'store/slices/app';
import { selectFloorsObject, setFloor } from 'store/slices/floors';
import { selectGroupsCount, selectGroupsObject, setSelectedGroup } from 'store/slices/groups';
import { selectLocationsObject, setLocation } from 'store/slices/locations';
import {
  openDeleteConditionModal, openMainModal, selectNotifSetConditionsArray,
  selectNotifSetIsConditionsLoading,
  selectNotifSetOpenDeleteModal, selectNotifSetOpenModal, setConditionSound, setConditionTime,
  setConditionTimeType, setConditionType, setConditionsList, setEditingConditionID,
  setNotificationPriority,
} from 'store/slices/notifications.settings';
import {
  NOTIFICATION_CHANNELS,
  setConditionSocialSubject,
} from 'store/slices/notifications.social';
import { selectZonesMap, setSelectedZone } from 'store/slices/zones';

import { sortConditionByLocation, sortConditionBySublocation, sortConditionByTitle } from 'helpers/sortByField';

import CustomButtonWithTitle from 'components/settings/ButtonTitle.component';
import containerStyles from 'components/shared/Container/styles';
import PaginationIcon from 'components/shared/Pagination/PaginationIcon';
import CreateEditNotification from './Dialogs/CreateEditNotification';
import DeleteNotification from './Dialogs/DeleteNotification';
import useStyles from './notifications.component.style';

function NotificationsComponent() {
  const dispatch = useDispatch();
  const openMenu = useSelector(selectAppOpenMenu);
  const currentApp = useSelector(selectAppCurrentApp);
  const floorsObject = useSelector(selectFloorsObject);
  const groupsObject = useSelector(selectGroupsObject);
  const groupsCount = useSelector(selectGroupsCount);
  const locationsObject = useSelector(selectLocationsObject);
  const openModal = useSelector(selectNotifSetOpenModal);
  const openDeleteModal = useSelector(selectNotifSetOpenDeleteModal);
  const conditions = useSelector(selectNotifSetConditionsArray);
  const zonesMap = useSelector(selectZonesMap);

  const isConditionsLoading = useSelector(selectNotifSetIsConditionsLoading);

  const { t } = useTranslation(['settings', 'monitoring', 'notifications', 'report']);
  const { classes: classesContainer } = containerStyles();
  const { classes } = useStyles();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [conditionToDelete, setConditionToDelete] = useState({});
  const [sort, setSort] = useState({
    title: 'none',
    location: 'none',
    floor: 'none',
  });

  const translatedConditionTypes = {
    ENTRY: t('notifications:ENTRY_FULL'),
    EXIT: t('notifications:EXIT_FULL'),
    STANDING: t('notifications:STANDING'),
    STANDING_OUT: t('notifications:STANDING_OUT'),
    SOS: t('notifications:SOS'),
    fall: t('notifications:FALL'),
    other: 'other',
  };

  useEffect(() => {
    dispatch(fetchDevicesList());
    setPage(0);
  }, [currentApp, dispatch]);

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleCreateNewCondition = () => {
    dispatch(openMainModal(formTypeCreate));
  };

  const handleOpenEditCondition = (condition) => {
    try {
      const { relationships, attributes, id } = condition;
      dispatch(setConditionType(attributes.condition));
      const time = parseInt(attributes.time, 10);
      let conditionTypeSeconds = timeTypes.seconds;
      let timeToSet = time;
      Object.keys(timeTypes).map((constantTypeId) => {
        const timeValue = timeConstants[timeTypes[constantTypeId]];
        const timeTypeChecker = Math.trunc(time / timeValue);
        if (timeTypeChecker >= 1) {
          conditionTypeSeconds = timeTypes[constantTypeId];
          timeToSet = time / timeValue;
        }
        return constantTypeId;
      });
      dispatch(setConditionTime(timeToSet));

      if (attributes.priority) {
        dispatch(setNotificationPriority(attributes.priority));
      }
      if (relationships.trackedConditionsSubjects) {
        const subject = relationships.trackedConditionsSubjects.data?.subject || null;
        dispatch(setConditionSocialSubject(subject));
      }
      if (relationships.sound_file) {
        dispatch(setConditionSound(
          { type: conditionSoundTypes.alarm, file: attributes.sound_file },
        ));
      } else {
        dispatch(setConditionSound({ type: 'none', file: null }));
      }
      dispatch(setConditionTimeType(conditionTypeSeconds));
      if (relationships.location) {
        dispatch(setLocation(locationsObject[relationships.location.id]));
      }
      if (relationships.sublocation) {
        dispatch(setFloor(floorsObject[relationships.sublocation.id]));
      }
      if (relationships.zone?.id) {
        dispatch(setSelectedZone(zonesMap.get(+relationships.zone.id)));
      } else {
        dispatch(setSelectedZone({ id: '' }));
      }
      if (relationships.trackedGroups && Array.isArray(relationships.trackedGroups)) {
        const editGroupsLength = relationships.trackedGroups.length;
        const groupsLength = Object.keys(groupsObject).length;
        if (groupsLength === editGroupsLength) {
          dispatch(setSelectedGroup({ id: allValuesConstant }));
        } else {
          dispatch(setSelectedGroup({ id: relationships.trackedGroups[0].id }));
        }
      }

      dispatch(setEditingConditionID(id));
      dispatch(openMainModal(formTypeUpdate));
    } catch (error) {
      dispatch(createAlert({ messageType: alertTypes.warn, message: 'Error in reading condition properties' }));
    }
  };

  const handleDelete = async (object) => {
    setConditionToDelete(object);
    dispatch(openDeleteConditionModal());
  };

  const formatConditionTitle = (condition) => {
    let result = '';

    switch (condition) {
      case notificationTypes.entry:
        result = t('notifications:ENTRY_FULL');
        break;
      case notificationTypes.exit:
        result = t('notifications:EXIT_FULL');
        break;
      case notificationTypes.standing:
        result = t('notifications:STANDING');
        break;
      case notificationTypes.standingOut:
        result = t('notifications:STANDING_OUT');
        break;
      case notificationTypes.fall:
        result = t('notifications:FALL');
        break;
      case notificationTypes.immobility:
        result = t('notifications:IMMOBILITY');
        break;
      default:
        result = condition;
        break;
    }
    return result;
  };

  const sortColumn = (column) => {
    const order = sort[column] === 'desc' ? 'asc' : 'desc';
    const result = {};
    Object.keys(sort).forEach((key) => {
      result[key] = key !== column ? 'none' : order;
    });
    setSort(result);
    switch (column) {
      case 'title':
        dispatch(setConditionsList(sortConditionByTitle(
          sort.title,
          conditions,
          translatedConditionTypes,
        )));
        break;
      case 'location':
        dispatch(setConditionsList(sortConditionByLocation(sort.location, conditions)));
        break;
      case 'floor':
        dispatch(setConditionsList(sortConditionBySublocation(sort.floor, conditions)));
        break;
      default:
        break;
    }
  };

  return (
    <div className={!openMenu ? classes.content : classesContainer.noneContent}>
      <div className={classes.topControls}>
        <CustomButtonWithTitle
          variant="contained"
          color="primary"
          onClick={handleCreateNewCondition}
          className={classes.createBtn}
        >
          <Add className={classes.createIcon} />
          <span>
            {t('createConditionButton')}
          </span>
        </CustomButtonWithTitle>
      </div>
      {isConditionsLoading
        ? (
          <div className={classes.spinerWrapper}>
            <CircularProgress size={26} />
          </div>
        )
        : (
          <div className={classes.table}>
            <TableContainer className={classes.tableContainer} component={Paper}>
              <Table stickyHeader className={classes.table} aria-label="simple table">
                <TableBody>
                  <TableRow className={classes.tableRow}>
                    <TableCell onClick={() => sortColumn('title')} className={classes.titleTheadCellSort}>
                      <div className={classes.tableTheadCell}>
                        <span className={classes.titleText}>{t('condition')}</span>
                        { (sort.title === 'asc')
                        && <ArrowDropUpIcon /> }
                        { (sort.title === 'desc')
                        && <ArrowDropDownIcon /> }
                      </div>
                    </TableCell>
                    <TableCell onClick={() => sortColumn('location')} className={classes.titleTheadCellSort}>
                      <div className={classes.tableTheadCell}>
                        <span className={classes.titleText}>{t('location')}</span>
                        { (sort.location === 'asc')
                        && <ArrowDropUpIcon /> }
                        { (sort.location === 'desc')
                        && <ArrowDropDownIcon /> }
                      </div>
                    </TableCell>
                    <TableCell onClick={() => sortColumn('floor')} className={classes.titleTheadCellSort}>
                      <div className={classes.tableTheadCell}>
                        <span className={classes.titleText}>{t('floor')}</span>
                        { (sort.floor === 'asc')
                        && <ArrowDropUpIcon /> }
                        { (sort.floor === 'desc')
                        && <ArrowDropDownIcon /> }
                      </div>
                    </TableCell>
                    <TableCell className={classes.titleTheadCell}>
                      {t('zone')}
                    </TableCell>
                    <TableCell className={classes.titleTheadCell}>
                      {t('group')}
                    </TableCell>
                    <TableCell className={classes.titleTheadCell}>
                      {t('actions')}
                    </TableCell>
                    <TableCell className={classes.titleTheadCell}>
                      {t('editBtn')}
                    </TableCell>
                  </TableRow>
                  {conditions
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((condition) => (
                      <TableRow key={condition.id}>
                        <TableCell className={classes.tableCell} component="th" scope="row">
                          {formatConditionTitle(condition.attributes.condition)}
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          {condition.relationships.location
                            ? condition.relationships.location.title : '-'}
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          {condition.relationships.sublocation
                            ? condition.relationships.sublocation.name : '-'}
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          {condition.relationships.zone
                            ? condition.relationships.zone.name : '-'}
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          {condition.relationships.trackedGroups
                          && condition.relationships.trackedGroups.length < groupsCount
                          && condition.relationships.trackedGroups.map((group) => (`${group.title} `))}
                          {condition.relationships.trackedGroups
                          && condition.relationships.trackedGroups.length === groupsCount
                          && '-'}
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          {condition.relationships['tracked-conditions-social']
                          && condition.relationships['tracked-conditions-social'].data
                          && Array.isArray(condition.relationships['tracked-conditions-social'].data)
                          && condition.relationships['tracked-conditions-social'].data.length > 0 ? (
                            <Tooltip
                              enterDelay={500}
                              title={
                              condition.relationships['tracked-conditions-social'].data
                                .filter(
                                  (social) => social.channel_id === NOTIFICATION_CHANNELS.Email,
                                )
                                .map((social) => (
                                  <Typography
                                    className={classes.tooltip}
                                    key={social.destination}
                                  >
                                    {social.destination}
                                  </Typography>
                                ))
                            }
                            >
                              <MailOutlineRoundedIcon />
                            </Tooltip>
                            )
                            : '-'}
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          <EditButton
                            onClick={() => handleOpenEditCondition(condition)}
                            className={classes.actionButton}
                          />
                          <DeleteButton
                            onClick={() => handleDelete(condition)}
                            className={classes.actionButton}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50, { label: t('All'), value: -1 }]}
              component="div"
              count={conditions.length}
              rowsPerPage={rowsPerPage}
              page={page}
              className={classes.pagination}
              SelectProps={{
                IconComponent: PaginationIcon,
                inputProps: {
                  id: 'selector-rows-per-page',
                },
              }}
              classes={{
                root: classes.root,
                select: classes.select,
                selectIcon: classes.selectIcon,
                caption: classes.caption,
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelDisplayedRows={({ from, to, count }) => (
                `${from}-${to} ${t('of')} ${count !== -1 ? count : `${t('moreThen')} ${to}`}`
              )}
              labelRowsPerPage={t('labelRowsPerPage')}
              nextIconButtonProps={{
                'aria-label': 'Next Page',
                style: {
                  color: (page >= Math.ceil(conditions.length / rowsPerPage) - 1)
                    ? '#b5b8c4' : '#41afd7',
                  background: 'white',
                  width: '32px',
                  height: '32px',
                  margin: '5px',
                },
                autoid: 'pagination-button-previous-collector',
              }}
              backIconButtonProps={{
                'aria-label': 'Previous Page',
                style: {
                  color: page === 0
                    ? '#b5b8c4' : '#41afd7',
                  background: 'white',
                  width: '32px',
                  height: '32px',
                },
                autoid: 'pagination-button-next-collector',
              }}
            />
          </div>
        )}
      <div>
        {openDeleteModal
        && (<DeleteNotification conditionToDelete={conditionToDelete} />)}
        {openModal
        && (<CreateEditNotification />)}
      </div>
    </div>
  );
}

export default NotificationsComponent;
