/* eslint-disable no-unused-vars */
import React, {
  cloneElement, forwardRef, useEffect, useState,
} from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  FormControl, FormControlLabel, Slide,
} from '@mui/material';

import MainMenu from 'components/menu/MainMenu';
import { createAlert } from 'store/slices/alert';
import LocationSelector from 'components/reports/ReportsLocationSelector';
import FloorSelector from 'components/reports/ReportsFloorSelector';
import ZoneSelector from 'components/reports/ReportsZoneSelector';
import GroupSelector from 'components/reports/ReportsGroupSelector';
import DialogArray from 'components/dialog/DailogArrayObjects';
import DialogMonitoring from 'components/dialog/DialogMonitorng';
import Switch from '@mui/material/Switch/Switch';
import Checkbox from '@mui/material/Checkbox';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { fetch3DModel } from 'store/actions/floors';
import {
  selectZonesObject, selectZonesMap, selectSelectedZone, selectZoneTypes, setSelectedZone,
} from 'store/slices/zones';
import { selectAppCurrentApp, selectAppOpenMenu } from 'store/slices/app';
import { selectCurrentFloor, selectFloorsObject, setFloor } from 'store/slices/floors';
import {
  selectGroupsCount, selectGroupsObject, selectGroupsSelectedGroup, setSelectedGroup,
} from 'store/slices/groups';
import { selectCurrentLocation, selectLocationsObject, setLocation } from 'store/slices/locations';
import { selectObjectsInMonitoringArray, setJson } from 'store/slices/objects';
import { selectReportsCurrentObject } from 'store/slices/reports';

import GroupsInfinite from 'components/shared/GroupSelector/GroupsInfinite';
import checkObjectInWindowConfig from 'helpers/checkEnv';
import { objectsFetchByFloor } from 'store/actions/objects';
import { allValuesConstant, selectNoOneConstant, monitoring3d } from 'constans';
import { useTranslation } from 'react-i18next';
import { OnlineMapComponent } from '@navigine/ts-map';
import AutoComplete from './AutoComplete';
import useStyles from './style';
import { logout } from '../../store/actions/user';

const servers = {
  api: checkObjectInWindowConfig('NODE_URL')
            || 'https://api.stage.navigine.com/client',
  imt: `${checkObjectInWindowConfig('API_URL')}`
            || 'https://api.stage.navigine.com/tracking',
  auth: `${checkObjectInWindowConfig('AUTH_URL')}`
            || 'https://api.stage.navigine.com/auth',
};

// eslint-disable-next-line no-underscore-dangle

const mqttConfig = {
  login: checkObjectInWindowConfig('MQTT_LOGIN'),
  password: checkObjectInWindowConfig('MQTT_PASSWORD'),
  domain: checkObjectInWindowConfig('MQTT_DOMAIN'),
  port: checkObjectInWindowConfig('MQTT_PORT'),
  topic: 'qtracker_output_mqtt',
};

function MonitoringTS(props) {
  const currentFloor = useSelector(selectCurrentFloor);
  const dispatch = useDispatch();
  const openMenu = useSelector(selectAppOpenMenu);
  const currentApp = useSelector(selectAppCurrentApp);
  const floorsObject = useSelector(selectFloorsObject);
  const groupsObject = useSelector(selectGroupsObject);
  const selectedGroup = useSelector(selectGroupsSelectedGroup);
  const locationsObject = useSelector(selectLocationsObject);
  const currentLocation = useSelector(selectCurrentLocation);
  const objectsInMonitoringArray = useSelector(selectObjectsInMonitoringArray);
  const currentObject = useSelector(selectReportsCurrentObject);
  const zonesMap = useSelector(selectZonesMap);
  const selectedZone = useSelector(selectSelectedZone);
  const zoneTypes = useSelector(selectZoneTypes);
  const groupsCount = useSelector(selectGroupsCount);

  const selectRole = (state) => state?.app?.currentApp?.role;
  const userRole = useSelector(selectRole);
  const { classes } = useStyles();
  const { t } = useTranslation(['monitoring']);
  // eslint-disable-next-line react/no-unstable-nested-components
  const Transition = forwardRef(
    (properties, ref) => cloneElement(
      <Slide direction="up" />,
      {
        ...properties,
        ref,
      },
    ),
  );
  const navigate = useNavigate();
  const [antialias, setAntialias] = useState(false);
  const [showTrackLines, setShowTrackLines] = useState(false);
  const [objectInfo, setObjectInfo] = useState(null);
  const [objectInfoArray, setObjectInfoArray] = useState([]);
  const [isOpenObjectModal, setOpenObjectModal] = useState(false);
  const [parentObjectsArray, setParentObjectsArray] = useState([]);
  const [open, setOpen] = useState(false);
  const [objectsArr, setObjArr] = useState([]);
  const [objectsArrBuild, setObjArrBuild] = useState([]);
  const [objToZoom, setObjToZoom] = useState();
  const [zonesArr, setZonesArr] = useState([]);
  const [groupId, setFroupId] = useState(0);
  const [advanced3D, setAdvanced3D] = useState(false);
  const [show3DState, setShow3DState] = useState(false);
  const zonesObject = useSelector(selectZonesObject);
  const floorsObjectFiltred = Object.values(floorsObject)
    .filter((object) => object.location === currentLocation.id);

  let tempArray = [];
  Object.values(locationsObject)
    .filter((location) => location.id === currentLocation.id)
    .forEach((location) => { tempArray = [...tempArray, ...location.floors]; });

  const handleChangeLocation = (event) => {
    const locationId = event.target.value;
    dispatch(setLocation(locationsObject[locationId]));
    const floorId = locationsObject[locationId].floors[0].id;
    dispatch(setFloor(floorsObject[floorId]));
    dispatch(setSelectedZone({ id: selectNoOneConstant }));
    dispatch(setSelectedGroup({ id: allValuesConstant }));
  };

  const handleChangeFloor = (event) => {
    const floorId = event.target.value;
    dispatch(setFloor(floorsObject[floorId]));
    dispatch(setSelectedZone({ id: selectNoOneConstant }));
    dispatch(setSelectedGroup({ id: allValuesConstant }));
  };

  const handleChangeZone = (event) => {
    if (event && event.target && event.target.value) {
      event.preventDefault();
      const zoneId = event.target.value;

      if (zoneId === allValuesConstant) {
        dispatch(setSelectedZone({ id: allValuesConstant }));
        const idArray = Array.from(zonesMap.keys());
        setZonesArr(idArray);
        return;
      }

      if (zoneId === selectNoOneConstant) {
        dispatch(setSelectedZone({ id: selectNoOneConstant }));
        setZonesArr([]);
      }

      if (zoneId !== selectNoOneConstant && zoneId !== allValuesConstant) {
        dispatch(setSelectedZone(zonesMap.get(zoneId)));
        setZonesArr([zoneId]);
      }
    }
  };

  const selectObject = (event, object) => {
    // todo here
    if (object) {
      setObjToZoom(object.object_id);
    }
  };

  const handleCloseObjectModal = () => {
    setOpenObjectModal(false);
    setObjectInfo(null);
    setOpen(false);
    setObjectInfoArray([]);
    dispatch(setJson(''));
  };

  const objConfig = {
    trackLines: showTrackLines,
    filterGroupId: Number(selectedGroup.id),
    objToZoom,
  };

  const handleObjArrChange = (newObjArr) => {
    setObjArr(newObjArr);
  };
  const handleObjArrChangeBuild = (newObjArr) => {
    setObjArrBuild(newObjArr);
  };
  const handleChangeGroup = (event) => {
    event.preventDefault();
    const groupsId = event.target.value;
    if (groupsId === allValuesConstant) {
      setShowTrackLines(false);
      dispatch(setSelectedGroup({ id: allValuesConstant }));
      return;
    }
    const newSelectedGroup = groupsObject[groupsId];
    dispatch(setSelectedGroup(newSelectedGroup));
    setFroupId(newSelectedGroup);
    setShowTrackLines(false);
  };
  useEffect(() => {
    const mapContainer = document.getElementById('map');

    if (mapContainer) {
      mapContainer.style.width = '100%';
      mapContainer.style.height = '100%';
    }
  }, []);

  useEffect(() => {
    dispatch(objectsFetchByFloor(currentFloor.id));
    return () => {

    };
  }, [currentFloor, dispatch]);

  // load 3D map if it exists
  useEffect(() => {
    async function fetch3d() {
      try {
        await dispatch(fetch3DModel(currentFloor.id));
      } catch (error) {
        dispatch(createAlert({ messageType: 'warn', message: 'Error in fetching 3D map' }));
      }
    }
    if (currentFloor.id) {
      fetch3d();
    }
  }, [currentFloor.id, dispatch]);

  const handleShow3D = async () => {
    setShow3DState((prev) => !prev);
    dispatch(setSelectedZone({ id: selectNoOneConstant }));
    setTimeout(() => {
      navigate(monitoring3d, { state: { antialias } });
    }, 300);
  };
  const userToken = localStorage.getItem('userToken');
  useEffect(() => {
    if (!userToken) {
      dispatch(logout());
    }
  }, [dispatch, userToken]);

  // console.log('objectInfo', objectInfo);
  // console.log('open', open);

  return (
    <div className={!openMenu ? classes.history : classes.noneContainer}>
      <MainMenu openMenu={openMenu} dispatch={dispatch}>
        <div className={classes.mapControls}>
          <div className={classes.controlWrapper}>
            <FormControl>
              <LocationSelector
                className={classes.selector}
                locations={locationsObject}
                value={currentLocation.id > 0 ? currentLocation.id : ''}
                onChange={handleChangeLocation}
              />
            </FormControl>
          </div>
          <div className={classes.controlWrapper}>
            {currentFloor.id
              && (
              <FloorSelector
                className={classes.selector}
                floors={floorsObjectFiltred}
                onChange={handleChangeFloor}
                value={currentFloor.id}
                currentLocationId={currentLocation.id}
                inputprops={{
                  disableUnderline: true,
                }}
              />
              )}
          </div>
          <div className={classes.controlWrapper}>
            <ZoneSelector
              name="zone-selector"
              onChange={handleChangeZone}
              className={classes.selector}
              zones={zonesMap}
              value={selectedZone?.id}
              zoneTypes={zoneTypes}
              currentFloorId={currentFloor.id}
            />
          </div>
          <div className={classes.controlWrapper}>
            {(!groupsCount || groupsCount <= 100)
              ? (
                <GroupSelector
                  name="group-selector"
                  onChange={handleChangeGroup}
                  groups={groupsObject}
                  value={selectedGroup.id}
                  className={classes.selector}
                />
              ) : (
                <GroupsInfinite
                  value={selectedGroup}
                  handleChangeGroup={handleChangeGroup}
                  allowSelectAll
                  inputStyles={{ border: 'none', backgroundColor: '#F3F6F8' }}
                  borderStyle="none"
                  dropdownStyles={{ width: '200px !important' }}
                />
              )}
          </div>
          <div
            style={{ marginLeft: '20px', textAlign: 'left' }}
            className={classes.selectorWrapp}
          >
            <AutoComplete
              filteredObjectsArray={objectsArr}
              currentObject={objToZoom}
              selectObject={selectObject}
              bkgColor="#F3F6F8"
              objectsInMonitoringArray={objectsInMonitoringArray}
            />
          </div>
          {floorsObject[currentFloor.id] && floorsObject[currentFloor.id].model
          && (
          <div className={classes.switch_wrapper}>
            <FormControlLabel
              className={classes.swich_element_monitoring}
              control={(
                <Switch
                  checked={show3DState}
                  onChange={handleShow3D}
                  name="show 3d map"
                  color="primary"
                />
              )}
              label={t('show3d')}
            />
            <FormControlLabel
              control={(
                <Checkbox
                  checked={advanced3D}
                  onChange={() => setAdvanced3D((prev) => !prev)}
                  name="advanced 3D"
                  disableRipple
                  checkedIcon={<KeyboardArrowUpIcon />}
                  icon={<KeyboardArrowDownIcon />}
                />
              )}
            />
          </div>
          )}
          {advanced3D
          && (
          <div className={classes.checkboxWrapper}>
            <FormControlLabel
              control={(
                <Checkbox
                  checked={antialias}
                  onChange={() => setAntialias((prev) => !prev)}
                  icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                  checkedIcon={<CheckBoxIcon fontSize="small" />}
                  name="AA"
                />
            )}
              label="3D Anti-aliasing"
            />
          </div>
          )}
          <div className={classes.control_wrapper}>
            <FormControlLabel
              className={classes.swich_element_monitoring}
              control={(
                <Switch
                  checked={showTrackLines}
                  onChange={(event) => setShowTrackLines(event.target.checked)}
                  name="show track lines"
                  color="primary"
                />
                )}
              label={t('showTrackLines')}
            />
          </div>
          <div className={classes.formControls}>
            <FormControl>
              <div className={classes.objectsCount}>
                <p>
                  {t('countOfObjects')}
                  {/* <b>{filteredObjectsArray.length}</b> */}
                  {objectsArr.length}
                </p>
                {/* <p style={{ color: 'rgba(0, 20, 36, 0.5)' }}>
                  {t('countOfObjectsInBuild')}
                  <b>{objectsInMonitoringArray?.length}</b>
                </p> */}
              </div>
            </FormControl>
          </div>
        </div>

      </MainMenu>
      <div className={classes.map_container}>
        {userToken && servers && currentFloor
          && currentApp && currentLocation && mqttConfig && objConfig ? (
            <OnlineMapComponent
              userToken={userToken}
              servers={servers}
              floorId={currentFloor.id}
              appId={currentApp.id}
              locationId={currentLocation.id}
              mqttConfig={mqttConfig}
              objConfig={objConfig}
              setZones={() => { }}
              zonesArr={zonesArr}
              onObjClick={(objectsArray) => {
                setParentObjectsArray(objectsArray);
                const filteredArray = objectsInMonitoringArray.filter((obj) => obj.attributes.id
                 === objectsArray[0]?.object_id);
                if (filteredArray.length) {
                  setObjectInfo(filteredArray[0].attributes);
                }
                setOpen(true);
              }}
              zoomObjId={objToZoom}
              onObjArrChange={handleObjArrChange}
              onObjArrChangeBuild={handleObjArrChangeBuild}
            />
          ) : (
            <p>Loading...</p>
          )}
      </div>
      <DialogMonitoring
        openObjectModal={open}
        TransitionComponent={Transition}
        handleCloseObjectModal={handleCloseObjectModal}
        objectInfo={objectInfo}
        setOpenObjectModal={setOpenObjectModal}
        objectInfoArray={objectInfoArray}
        floor={currentFloor}
      />
      <DialogArray
        setObjectInfo={setObjectInfo}
        setObjectInfoArray={setObjectInfoArray}
        openObjectModal={open}
        TransitionComponent={Transition}
        handleCloseObjectModal={handleCloseObjectModal}
        objectInfo={objectInfo}
        objectInfoArray={[objectInfo]}
        setOpenObjectModal={setOpenObjectModal}
      />
    </div>
  );
}

export default MonitoringTS;
