import { configureStore } from '@reduxjs/toolkit';
import checkObjectInWindowConfig from 'helpers/checkEnv';

import rootReducer from 'store/slices/root';

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false,
    thunk: true,
    immutableCheck: { warnAfter: 1000 },
    actionCreatorCheck: true,
  }),
  devTools: `${checkObjectInWindowConfig('NODE_ENV')}` === 'dev',
});

export default store;
