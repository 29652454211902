import React, {
  memo, useCallback, useEffect, useRef, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { setModalForm } from 'store/slices/settings';
import { selectGroupsCount, selectGroupsIsFetching, selectGroupsObject } from 'store/slices/groups';
import { fetchGroups } from 'store/actions/groups';

import {
  Box, Fade, MenuItem, TextField,
} from '@mui/material';
import Popper from '@mui/material/Popper';
import { ArrowDropDownIcon } from '@mui/x-date-pickers/';
import CloseOutlined from '@mui/icons-material/CloseOutlined';
import ArrowDropUp from '@mui/icons-material/ArrowDropUp';
import CircularProgress from '@mui/material/CircularProgress';
import dropIconArrow from 'assets/images/chevron-down.svg';

import { allValuesConstant } from 'constans';
import useStyles from './GroupsInfinite.style';

const LoadingComponent = ({ endAdornment: <CircularProgress size={20} /> });

function GroupsInfinite({
  value, handleChangeGroup, inputStyles, allowSelectAll = false,
  borderStyle = '', dropdownStyles = {},
}) {
  const { t } = useTranslation(['settings', 'monitoring']);
  const allGroups = t('monitoring:all');
  const { classes, cx } = useStyles();
  const dispatch = useDispatch();

  const [hasMore, setHasMore] = useState(false);
  const [query, setQuery] = useState(value?.title || '');
  const [pageNumber, setPageNumber] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const groupsObject = useSelector(selectGroupsObject);
  const groupsCount = useSelector(selectGroupsCount);
  const isFetchingGroups = useSelector(selectGroupsIsFetching);

  const isFirstRender = useRef(true);
  const open = Boolean(anchorEl);
  const textField = useRef();

  const groupsList = Object.keys(groupsObject).map((element) => groupsObject[element]);
  const shownItemsAmount = Math.max(50, (pageNumber + 1) * 50);

  useEffect(() => {
    if (groupsCount > Object.keys(groupsObject)?.length && isFetchingGroups !== 'loading') {
      dispatch(fetchGroups({ page: 0, limit: groupsCount + 1 }));
    }
  }, [dispatch, groupsCount, groupsObject, isFetchingGroups]);

  useEffect(() => {
    if (groupsList) {
      setHasMore(groupsList.length > 0);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, pageNumber]);

  function handleChangeForm(item) {
    dispatch(setModalForm({ group: item }));
  }

  const observer = useRef();
  const lastElementRef = useCallback((node) => {
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver((entries) => {
      // observing only one node, so checking only that one
      if (entries[0].isIntersecting && hasMore) {
        setPageNumber((prev) => prev + 1);
      }
    });
    if (node) observer.current.observe(node);
  }, [hasMore]);

  function handleChangeFilter(event) {
    const text = event.target.value;
    setPageNumber(0);
    setQuery(text);
    if (text.length >= 3) {
      (groupsList.filter((item) => item.title.includes(text)));
    }
  }

  const handleClickArrow = () => {
    setAnchorEl(anchorEl ? null : textField.current);
  };

  const handleClear = () => {
    setAnchorEl(null);
    setTimeout(() => {
      setQuery('');
    }, 300);
  };

  const selectItem = (item) => {
    setTimeout(() => {
      setAnchorEl(null);
    }, 0);
    if (!item.title && item.id === allValuesConstant) {
      setQuery(allGroups);
    } else {
      setQuery(item.title);
      handleChangeForm(item);
    }
    if (handleChangeGroup) {
      handleChangeGroup(item.id);
    }
  };

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    if (query !== '') {
      setAnchorEl(textField.current);
    } else {
      setAnchorEl(null);
      handleChangeForm({});
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  const AdornmentIcon = !open ? ({
    endAdornment: (
      <>
        {query !== ''
        && (
          <CloseOutlined
            onClick={handleClear}
            className={classes.clearText}
          />
        )}
        {allowSelectAll
          ? (
            // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
            <img
              style={{ margin: '0 5px 0 0' }}
              src={dropIconArrow}
              alt="selector"
              onClick={handleClickArrow}
              className={classes.arrowDropdowns}
              onKeyUp={() => {}}
            />
          )
          : (
            <ArrowDropDownIcon
              onClick={handleClickArrow}
              className={classes.arrowDropdowns}
            />
          )}
      </>
    ),
  }) : ({
    endAdornment: (
      <>
        {query !== ''
        && (
          <CloseOutlined
            onClick={handleClear}
            className={classes.clearText}
          />
        )}
        {allowSelectAll
          ? (
            // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
            <img
              style={{ margin: '0 5px 0 0', transform: 'rotate(180deg)' }}
              src={dropIconArrow}
              alt="selector"
              onClick={handleClickArrow}
              className={classes.arrowDropdowns}
              onKeyUp={() => {}}
            />
          )
          : (
            <ArrowDropUp
              onClick={handleClickArrow}
              className={classes.arrowDropdowns}
            />
          )}
      </>
    ),
  });

  return (
    <>
      {allowSelectAll
        ? (
          <label
            htmlFor="object-group"
            style={{ color: '#8194A4', fontSize: '12px', fontWeight: '600' }}
          >
            {t('group')}
          </label>
        )
        : (
          <label className={classes.label} htmlFor="object-group">
            {t('objectFormFieldGroup')}
          </label>
        )}
      <TextField
        autoComplete="off"
        id="object-group"
        sx={{
          '& .MuiOutlinedInput-root': {
            '& > fieldset': {
              border: `${borderStyle}`,
              borderColor: 'rgba(0, 0, 0, 0.15)',
            },
            '&:hover': {
              '& > fieldset': {
                borderColor: 'rgba(0, 0, 0, 0.15)',
              },
            },
            borderRadius: '6px',
            paddingRight: '6px',
          },
          ...inputStyles,
        }}
        value={query}
        variant="outlined"
        InputLabelProps={{ shrink: true }}
        InputProps={isFetchingGroups === 'loading' ? LoadingComponent : AdornmentIcon}
        onChange={(e) => handleChangeFilter(e)}
        focused={false}
        className={cx(classes.infiniteScrollInput, classes.formControl)}
        ref={textField}
        disabled={isFetchingGroups === 'loading'}
      />
      <Popper
        open={open}
        anchorEl={anchorEl}
        style={{ zIndex: 10000 }}
        transition
      >
        {({ TransitionProps }) => (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <Fade {...TransitionProps} timeout={100}>
            <Box
              sx={{ bgcolor: 'background.paper', boxShadow: 3, ...dropdownStyles }}
              className={classes.dataBox}
            >
              {allowSelectAll && (
              <MenuItem onClick={() => selectItem({ id: allValuesConstant })}>
                {allGroups}
              </MenuItem>
              )}
              {groupsList && groupsList.length > 0 && groupsList
                .filter((item) => {
                  if (query !== '') return item?.title?.includes(query);
                  return true;
                })
                .slice(0, shownItemsAmount)
                .map((item, index) => {
                  if (index + 1 === shownItemsAmount) {
                    return (
                      <MenuItem
                        ref={(node) => lastElementRef(node)}
                        key={item.id}
                        onClick={() => selectItem(item)}
                      >
                        {item.title}
                      </MenuItem>
                    );
                  }
                  return (
                    <MenuItem
                      key={item.id}
                      onClick={() => selectItem(item)}
                    >
                      {item.title}
                    </MenuItem>
                  );
                })}
            </Box>
          </Fade>
        )}
      </Popper>
    </>
  );
}

export default memo(GroupsInfinite);
