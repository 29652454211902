import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((materialTheme) => {
  const customStyles = materialTheme.CustomStyles;
  const mainTopMenuTheme = customStyles.MainTopMenu;

  return {
    menuIcon: {
      display: 'none',
      [materialTheme.breakpoints.down('900')]: {
        width: '50px',
        display: 'block',
      },
    },
    menuIconOff: {
      [materialTheme.breakpoints.down('900')]: {
        display: 'none',
      },
    },
    menu: {
      height: '80px',
      width: '100%',
      top: 0,
      left: 0,
      background: mainTopMenuTheme.background,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      whiteSpace: 'nowrap',
    },
    menuList: {
      width: '60vw',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'start',
      paddingLeft: '35px',
      [materialTheme.breakpoints.down('900')]: {
        display: 'none',
      },
    },
    list: {
      listStyleType: 'none',
      margin: '0',
      padding: '0',
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'start',
      alignItems: 'center',
      whiteSpace: 'nowrap',
      [materialTheme.breakpoints.down('800')]: {
        paddingLeft: '20px',
      },
    },
    listLink: {
      display: 'flex',
      marginRight: '5px',
      padding: '10px',
      borderRadius: '8px',
      color: customStyles.GlobalTextColor,
      textDecoration: 'none',
      fontSize: '16px',
      fontWeight: 600,
      transition: 'all 0.3s ease-in-out',
      WebkitTransition: 'all 0.3s ease-in-out',
      '&:hover': {
        color: materialTheme.palette.primary.main,
        background: '#D2EFFB',
        cursor: 'pointer',
      },
      [materialTheme.breakpoints.down('800')]: {
        marginRight: '20px',
      },
    },
    listLinkVideo: {
      padding: '6px 16px',
      margin: 0,
    },
    listLinkActive: {
      padding: '10px',
      borderRadius: '8px',
      background: '#D2EFFB',
      color: materialTheme.palette.primary.main,
      '&:аfter': {
        height: '2px',
        color: materialTheme.palette.primary.main,
      },
    },
    profile: {
      minWidth: '200px',
      marginRight: '10px',
    },
    logo: {
      width: customStyles.LeftBarWidth,
      minWidth: customStyles.LeftBarWidth,
      marginLeft: customStyles.MainLeftMargin,
      [materialTheme.breakpoints.down('900')]: {
        display: 'none',
      },
    },
    MuiInputUnderline: {
      '&:before': {
        content: '""',
      },
    },
    MuiSelectIcon: {
      color: 'white',
    },
    inActiveLink: {
      color: 'gray',
      '&:hover': {
        color: '#d0c9c9',
        cursor: 'not-allowed',
      },
    },
    accessHint: {
      fontSize: '12px',
    },
    svgIcon: {
      fill: customStyles.GlobalTextColor,
      margin: '0 10px 0 0',

    },
    svgIconActive: {
      fill: materialTheme.palette.primary.main,
      margin: '0 10px 0 0',

    },
    svgIconStroke: {
      stroke: customStyles.GlobalTextColor,
      margin: '0 10px 0 0',

    },
    svgIconStrokeActive: {
      stroke: materialTheme.palette.primary.main,
      margin: '0 10px 0 0',
    },
    svgIconReports: {
      fill: customStyles.GlobalTextColor,
      margin: '0 10px 0 0',

    },
    svgIconReportsActive: {
      fill: materialTheme.palette.primary.main,
      margin: '0 10px 0 0',
    },
  };
});

export default useStyles;
