import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import DialogContent from '@mui/material/DialogContent';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';

import { selectCurrentFloor, selectFloorsObject, setFloor } from 'store/slices/floors';
import {
  selectGroupsCount, selectGroupsObject, selectGroupsSelectedGroup, setSelectedGroup,
} from 'store/slices/groups';
import { selectCurrentLocation, selectLocationsObject, setLocation } from 'store/slices/locations';
import {
  selectNotifSetConditionSound,
  selectNotifSetConditionTime,
  selectNotifSetConditionTimeType,
  selectNotifSetConditionType,
  selectNotifSetNotificationPriority,
  setConditionSound, setConditionTime, setConditionTimeType,
  setConditionType, setNotificationPriority,
} from 'store/slices/notifications.settings';
import { selectSelectedZone, selectZonesMap, setSelectedZone } from 'store/slices/zones';

import CustomSelector from 'components/monitoring/selector';
import FloorSelector from 'components/shared/FloorSelector/FloorSelector';
import GroupsInfinite from 'components/shared/GroupSelector/GroupsInfinite';
import LocationSelector from 'components/shared/LocationSelector/LocationSelector';

import {
  allValuesConstant,
  conditionSoundTypes,
  noValuesConstant,
  notificationPriorities,
  notificationTypes,
  selectNoOneConstant,
  timeTypes,
} from 'constans';

import useStyles from '../../notifications.component.style';

export default function NotificationBaseTab() {
  const { classes } = useStyles();
  const { t } = useTranslation(['settings', 'monitoring', 'notifications']);
  const dispatch = useDispatch();
  const floorsObject = useSelector(selectFloorsObject);
  const currentFloor = useSelector(selectCurrentFloor);
  const groupsObject = useSelector(selectGroupsObject);
  const selectedGroup = useSelector(selectGroupsSelectedGroup);
  const locationsObject = useSelector(selectLocationsObject);
  const currentLocation = useSelector(selectCurrentLocation);
  const conditionType = useSelector(selectNotifSetConditionType);
  const conditionTime = useSelector(selectNotifSetConditionTime);
  const conditionTimeType = useSelector(selectNotifSetConditionTimeType);
  const notificationPriority = useSelector(selectNotifSetNotificationPriority);
  const zonesMap = useSelector(selectZonesMap);
  const selectedZone = useSelector(selectSelectedZone);
  const groupsCount = useSelector(selectGroupsCount);
  const conditionSound = useSelector(selectNotifSetConditionSound);

  const groupsArray = Object.keys(groupsObject);
  const zonesArray = [...zonesMap.keys()]
    .filter((zoneId) => parseInt(zonesMap.get(zoneId).floor, 10) === parseInt(currentFloor.id, 10));

  const handleChangeLocation = (event) => {
    const locationId = event.target.value;
    dispatch(setLocation(locationsObject[locationId]));
    const floorId = locationsObject[locationId].floors[0].id;
    dispatch(setFloor(floorsObject[floorId]));
    dispatch(setSelectedZone({ id: selectNoOneConstant }));
    dispatch(setSelectedGroup({ id: allValuesConstant }));
  };

  const handleChangeFloor = (event) => {
    const floorId = event.target.value;
    dispatch(setFloor(floorsObject[floorId]));
    dispatch(setSelectedZone({ id: selectNoOneConstant }));
    dispatch(setSelectedGroup({ id: allValuesConstant }));
  };

  const handleChangeZone = (event) => {
    event.preventDefault();
    const zoneId = event.target.value;
    if (zoneId === allValuesConstant) {
      dispatch(setSelectedZone({ id: allValuesConstant }));
      return;
    }
    if (zoneId === selectNoOneConstant) {
      dispatch(setSelectedZone({ id: selectNoOneConstant }));
    }
    if (zoneId !== selectNoOneConstant && zoneId !== allValuesConstant) {
      dispatch(setSelectedZone(zonesMap.get(zoneId)));
    }
  };

  const handleChangeGroup = useCallback((value) => {
    const groupId = value;
    if (groupId === noValuesConstant) {
      dispatch(setSelectedGroup({ id: noValuesConstant }));
      return;
    }
    if (groupId === allValuesConstant) {
      dispatch(setSelectedGroup({ id: allValuesConstant }));
      return;
    }
    const newSelectedGroup = groupsObject[groupId];
    dispatch(setSelectedGroup(newSelectedGroup));
  }, [dispatch, groupsObject]);

  const handleChangePriority = (event) => {
    const priority = event.target.value;
    dispatch(setNotificationPriority(priority));
  };

  const handleChangeConditionType = (event) => {
    dispatch(setConditionType(event.target.value));
  };

  const handleChangeConditionTime = (event) => {
    dispatch(setConditionTime(event.target.value));
  };

  const handleChangeConditionTimeType = (event) => {
    dispatch(setConditionTimeType(event.target.value));
  };

  const handleChangeConditionSound = (event) => {
    dispatch(setConditionSound({ type: event.target.value, file: null }));
  };

  return (
    <DialogContent>
      <div>
        <div className={classes.controlWrapper}>
          <FormControl variant="standard" className={classes.formControl}>
            <label className={classes.label} htmlFor="object-location">
              {t('monitoring:locationSelectorLabel')}
            </label>
            <LocationSelector
              id="object-location"
              className={classes.inputComponent}
              locations={locationsObject}
              value={currentLocation.id}
              onChange={handleChangeLocation}
              style={{ width: '100%' }}
              variant="standard"
              InputProps={{ disableUnderline: true }}
            />
          </FormControl>
        </div>
        <div className={classes.controlWrapper}>
          <FormControl variant="standard" className={classes.formControl}>
            <label className={classes.label} htmlFor="object-floor">
              {t('monitoring:floorSelectorLabel')}
            </label>
            <FloorSelector
              id="object-floor"
              className={classes.inputComponent}
              floors={floorsObject}
              onChange={handleChangeFloor}
              value={currentFloor.id}
              currentLocationId={currentLocation.id}
              style={{ width: '100%' }}
              variant="standard"
              InputProps={{ disableUnderline: true }}
            />
          </FormControl>
        </div>

        <div className={classes.controlWrapper}>
          <FormControl variant="standard" className={classes.formControl}>
            <label className={classes.label} htmlFor="select-zone">
              {t('monitoring:zonesSelectorLabel')}
            </label>
            <CustomSelector
              className={classes.inputComponent}
              id="select-zone-selector"
              select
              value={selectedZone && selectedZone.id >= 0 ? selectedZone.id : ''}
              onChange={handleChangeZone}
              InputLabelProps={{ shrink: true }}
              variant="standard"
              InputProps={{ disableUnderline: true, id: 'select-zone' }}
            >
              {zonesArray.map((zoneId) => (
                <MenuItem key={zoneId} value={zoneId}>
                  {zonesMap.get(+zoneId).title}
                </MenuItem>
              ))}
            </CustomSelector>
          </FormControl>
        </div>
        <div className={classes.controlWrapper}>
          {(!groupsCount || groupsCount <= 100)
            ? (
              <FormControl variant="standard" className={classes.formControl}>
                <label className={classes.label} htmlFor="select-group">
                  {t('monitoring:groupsSelectorLabel')}
                </label>
                <CustomSelector
                  id="select-group-selector"
                  select
                  value={selectedGroup?.id || allValuesConstant}
                  onChange={(e) => handleChangeGroup(e.target.value)}
                  className={classes.inputComponent}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{ disableUnderline: true, id: 'select-group' }}
                  variant="standard"
                >
                  <MenuItem key={allValuesConstant} value={allValuesConstant}>
                    {t('monitoring:all')}
                  </MenuItem>
                  {groupsArray.map((groupId) => (
                    <MenuItem key={groupId} value={groupId}>
                      {groupsObject[groupId].title}
                    </MenuItem>
                  ))}
                </CustomSelector>
              </FormControl>
            ) : (
              <FormControl variant="standard" className={classes.formControl}>
                <GroupsInfinite
                  value={selectedGroup}
                  handleChangeGroup={handleChangeGroup}
                  allowSelectAll
                />
              </FormControl>
            )}
        </div>
        <div className={classes.controlWrapper}>
          <FormControl variant="standard" className={classes.formControl}>
            <label className={classes.label} htmlFor="select-priority">
              {t('selectConditionProirity')}
            </label>
            <CustomSelector
              id="select-priority-selector"
              className={classes.inputComponent}
              select
              value={notificationPriority}
              onChange={handleChangePriority}
              InputLabelProps={{ shrink: true }}
              InputProps={{ disableUnderline: true, id: 'select-priority' }}
              variant="standard"
            >
              <MenuItem
                key={notificationPriorities.low}
                value={notificationPriorities.low}
              >
                {t('notifications:LOW')}
              </MenuItem>
              <MenuItem
                key={notificationPriorities.normal}
                value={notificationPriorities.normal}
              >
                {t('notifications:MEDIUM')}
              </MenuItem>
              <MenuItem
                key={notificationPriorities.high}
                value={notificationPriorities.high}
              >
                {t('notifications:HIGH')}
              </MenuItem>
            </CustomSelector>
          </FormControl>
        </div>
        <div className={classes.controlWrapper}>
          <FormControl variant="standard" className={classes.formControl}>
            <label className={classes.label} htmlFor="select-condition">
              {t('selectCondition')}
            </label>
            <CustomSelector
              className={classes.inputComponent}
              id="select-condition-selector"
              select
              value={conditionType || ''}
              onChange={handleChangeConditionType}
              InputLabelProps={{ shrink: true }}
              InputProps={{ disableUnderline: true, id: 'select-condition' }}
              variant="standard"
            >
              <MenuItem key={notificationTypes.entry} value={notificationTypes.entry}>
                {t('notifications:ENTRY_FULL')}
              </MenuItem>
              <MenuItem key={notificationTypes.exit} value={notificationTypes.exit}>
                {t('notifications:EXIT_FULL')}
              </MenuItem>
              <MenuItem
                key={notificationTypes.standing}
                value={notificationTypes.standing}
              >
                {t('notifications:STANDING')}
              </MenuItem>
              <MenuItem
                key={notificationTypes.standingOut}
                value={notificationTypes.standingOut}
              >
                {t('notifications:STANDING_OUT')}
              </MenuItem>
              <MenuItem key={notificationTypes.sos} value={notificationTypes.sos}>
                {t('notifications:SOS')}
              </MenuItem>
              <MenuItem key={notificationTypes.fall} value={notificationTypes.fall}>
                {t('notifications:FALL')}
              </MenuItem>
              <MenuItem
                key={notificationTypes.immobility}
                value={notificationTypes.immobility}
              >
                {t('notifications:IMMOBILITY')}
              </MenuItem>
            </CustomSelector>
          </FormControl>
        </div>
        {(conditionType === notificationTypes.standing
                        || conditionType === notificationTypes.standingOut)
                        && (
                          <div className={classes.controlWrapper}>
                            <FormControl variant="standard" className={classes.formControl}>
                              <label className={classes.label} htmlFor="select-time-сondition">
                                {t('selectConditionTime')}
                              </label>
                              <CustomSelector
                                id="select-time-сondition"
                                className={classes.inputComponent}
                                sx={{ padding: '0px' }}
                                type="text"
                                value={conditionTime || ''}
                                onChange={handleChangeConditionTime}
                                InputLabelProps={{ shrink: true }}
                                InputProps={{ disableUnderline: true }}
                                variant="standard"
                              />
                              <label htmlFor="select-timer-type" className={classes.label}>
                                {' '}
                              </label>
                              <CustomSelector
                                className={classes.inputComponent}
                                select
                                value={conditionTimeType}
                                onChange={handleChangeConditionTimeType}
                                InputLabelProps={{ shrink: true }}
                                InputProps={{ disableUnderline: true, id: 'select-timer-type' }}
                                variant="standard"
                                sx={{ marginTop: '5px' }}
                              >
                                <MenuItem
                                  key={timeTypes.seconds}
                                  value={timeTypes.seconds}
                                >
                                  {t('seconds')}
                                </MenuItem>
                                <MenuItem
                                  key={timeTypes.minutes}
                                  value={timeTypes.minutes}
                                >
                                  {t('minutes')}
                                </MenuItem>
                                <MenuItem
                                  key={timeTypes.hours}
                                  value={timeTypes.hours}
                                >
                                  {t('hours')}
                                </MenuItem>
                                <MenuItem
                                  key={timeTypes.days}
                                  value={timeTypes.days}
                                >
                                  {t('days')}
                                </MenuItem>
                                <MenuItem
                                  key={timeTypes.weeks}
                                  value={timeTypes.weeks}
                                >
                                  {t('weeks')}
                                </MenuItem>
                              </CustomSelector>
                            </FormControl>
                          </div>
                        )}
        {(conditionType === notificationTypes.custom) && (
        <FormControl variant="standard" className={classes.formControl}>
          <TextField
            variant="standard"
            className={classes.txtControl}
            label={t('selectCondition')}
            value={conditionType || ''}
            onChange={handleChangeConditionType}
          />
        </FormControl>
        )}

        <div className={classes.controlWrapper}>
          <FormControl variant="standard" className={classes.formControl}>
            <label className={classes.label} htmlFor="notification-sound">
              {t('selectSound')}
            </label>
            <CustomSelector
              className={classes.inputComponent}
              id="notification-sound-selector"
              select
              value={conditionSound.type}
              onChange={handleChangeConditionSound}
              InputLabelProps={{ shrink: true }}
              InputProps={{ disableUnderline: true, id: 'notification-sound' }}
              variant="standard"
            >
              <MenuItem value={conditionSoundTypes.alarm}>
                {t('soundOn')}
              </MenuItem>
              <MenuItem value={conditionSoundTypes.none}>
                {t('soundOff')}
              </MenuItem>
            </CustomSelector>
          </FormControl>
        </div>

      </div>
    </DialogContent>
  );
}
