/* eslint-disable react/no-unstable-nested-components */
import React from 'react';

import { useTranslation } from 'react-i18next';

import MenuItem from '@mui/material/MenuItem';
import dropIconArrow from 'assets/images/chevron-down.svg';
import { allValuesConstant, noValuesConstant, selectNoOneConstant } from 'constans';
import StyledSelector from './MaterialOutlinedSelector';

export default function OutlinedSelector(props) {
  const {
    allowNoValues,
    allowSelectNoValues,
    allowAllValues,
    className,
    children,
    onChange,
    label,
    value,
    id,
    novalueslabel,
    ...rest
  } = props;
  const { t } = useTranslation(['monitoring']);

  return (
    <StyledSelector
      id={id}
      label={label}
      value={value || ''}
      onChange={onChange}
      className={className}
      // eslint-disable-next-line react/jsx-props-no-spreading, no-shadow
      IconComponent={(props) => (<img style={{ margin: '0 10px 0 0' }} src={dropIconArrow} alt="selector" {...props} />)}
      MenuProps={{
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
      }}
      variant="standard"
      disableUnderline
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
    >
      {allowNoValues ? (
        <MenuItem key={noValuesConstant} value={noValuesConstant}>
          {novalueslabel}
        </MenuItem>
      ) : null}
      {allowAllValues ? (
        <MenuItem key={allValuesConstant} value={allValuesConstant}>
          {t('all')}
        </MenuItem>
      ) : null}
      {allowSelectNoValues ? (
        <MenuItem key={selectNoOneConstant} value={selectNoOneConstant}>
          {t('noOne')}
        </MenuItem>
      ) : null}
      {children}
    </StyledSelector>
  );
}
