import { createAsyncThunk } from '@reduxjs/toolkit';
import request, { endPointsEnum } from 'services/http';
import { createAlert } from 'store/slices/alert';

const dateToSec = (date) => Math.round((date / 1000) - ((date / 1000) % 60));

export const getHistoryList = createAsyncThunk(
  'history/getHistoryList',
  async (
    {
      offset = null,
      packet = null,
    },
    { dispatch, getState, rejectWithValue },
  ) => {
    const state = getState();
    const { dateFrom, dateTo } = state.reports;
    const { currentFloor } = state.floor;
    const { selectedGroup } = state.groups;
    const { objectSelected } = state.history;
    try {
      const querryParrams = {
        api_key: state.app.currentApp.api_key,
        from: dateToSec(dateFrom.getTime()),
        to: dateToSec(dateTo.getTime()),
        'filter[sublocationId]': currentFloor.id,
        'filter[offset]': offset,
        'filter[packet]': packet,
      };

      if (selectedGroup && selectedGroup.id > 0) {
        querryParrams['filter[groupId]'] = selectedGroup.id;
      }
      const response = await request.reports.get(
        endPointsEnum.heatmapHistory,
        { params: querryParrams },
      );
      const historyObject = response.data;
      const objectsArray = response.included;
      const heatmapLength = response.dataLength;
      const objectsList = {};
      objectsArray
        .map((object) => {
          if (!objectSelected) {
            objectsList[object.id] = object;
          } else if (object.id === objectSelected) {
            objectsList[object.id] = object;
          }
          return object;
        });
      return { historyObject, objectsList, heatmapLength };
    } catch (error) {
      if (error && !Array.isArray(error.message)) {
        dispatch(createAlert({ messageType: 'error', message: error.message }));
      } else {
        dispatch(createAlert({ messageType: 'error', message: 'Error in provided data' }));
      }
      return rejectWithValue({ historyObject: {}, objectsList: {}, heatmapLength: 0 });
    }
  },
);

export const fetchObjectHistoric = createAsyncThunk(
  'history/fetchObjectHistoric',
  async (
    {
      objectId,
      timestamp,
      coordinates = 'false',
      fetchingObjectHistory,
    },
    { getState, dispatch, rejectWithValue },
  ) => {
    try {
      const state = getState();
      if (fetchingObjectHistory === 'pending') {
        dispatch(createAlert({ messageType: 'error', message: 'A request is already running' }));
        return undefined;
      }
      const params = {
        timestamp,
        api_key: state.app.currentApp.api_key,
        coordinates,
      };
      const response = await request.tracking.get(
        `${endPointsEnum.trackedObjects}/historic/${objectId}`,
        { params },
      );
      if (response) {
        return response;
      }
      return {};
    } catch (error) {
      dispatch(createAlert({ messageType: 'error', message: error.message }));
      return rejectWithValue({});
    }
  },
);
